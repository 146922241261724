import { getQuery, patchQuery, postQuery } from '../../apiFunctions'

export const getUncertainty = async (token, simulation_id) => {
  return getQuery<any>({
    path: `/simulations/uncertainty/${simulation_id}/`,
    token,
    callback: (data) => data.data
  })
}

export const addUncertainty = async (token, simulation_id, body) => {
  return postQuery<any>({
    path: `/simulations/uncertainty/${simulation_id}/`,
    token,
    body
  })
}

export const updateUncertainty = async (token, simulation_id, body) => {
  return patchQuery<any>({
    path: `/simulations/uncertainty/${simulation_id}/`,
    token,
    body
  })
}