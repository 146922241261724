import { Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer'
import Html from 'react-pdf-html'
import ImageRender from './GenerateReport/ImageRender'
import roboto from '../assets/Fonts/Roboto/Roboto-Regular.ttf'
import robotoItalic from '../assets/Fonts/Roboto/Roboto-Italic.ttf'
import robotoMedium from '../assets/Fonts/Roboto/Roboto-Medium.ttf'
import robotoBold from '../assets/Fonts/Roboto/Roboto-Bold.ttf'
import robotoBoldItalic from '../assets/Fonts/Roboto/Roboto-BoldItalic.ttf'
import robotoMono from '../assets/Fonts/Roboto_Mono/static/RobotoMono-Regular.ttf'
import robotoMonoItalic from '../assets/Fonts/Roboto_Mono/static/RobotoMono-Italic.ttf'
import robotoMonoBold from '../assets/Fonts/Roboto_Mono/static/RobotoMono-Bold.ttf'
import robotoMonoBoldItalic from '../assets/Fonts/Roboto_Mono/static/RobotoMono-BoldItalic.ttf'
import merriweather from '../assets/Fonts/Merriweather/Merriweather-Regular.ttf'
import merriweatherItalic from '../assets/Fonts/Merriweather/Merriweather-Italic.ttf'
import merriweatherBold from '../assets/Fonts/Merriweather/Merriweather-Bold.ttf'
import merriweatherBoldItalic from '../assets/Fonts/Merriweather/Merriweather-BoldItalic.ttf'
import { useMemo } from 'react'
import { Page as Tpage, PageSingle } from '../types/generateReport'
import LogoRender from './GenerateReport/LogoRender'
import HeaderRender from './GenerateReport/HeaderRender'
import ListInfoRender from './GenerateReport/ListInfoRender'
import GoalRender from './GenerateReport/GoalRender'
import ContactRender from './GenerateReport/ContactRender'
import TableOfContentsRender from './GenerateReport/TableOfContentsRender'
import GridRender from './GenerateReport/GridRender'
import TableRender from './GenerateReport/TableRender'
import ImageGaleryRender from './GenerateReport/ImageGaleryRender'
import LinkRender from './GenerateReport/LinkRender'
import { DateFormats } from '../types/index'
import ExpirationDateRender from './GenerateReport/ExpirationDateRender'

Font.register({
  family: 'Roboto',
  fonts: [
    { src: roboto }, // font-style: normal, font-weight: normal
    {
      src: robotoItalic,
      fontStyle: 'italic',
    },
    {
      src: robotoMedium,
      fontWeight: 'medium',
    },
    {
      src: robotoBold,
      fontWeight: 'bold',
    },
    {
      src: robotoBoldItalic,
      fontStyle: 'italic',
      fontWeight: 'bold',
    },
  ],
})

Font.register({
  family: 'Roboto Mono',
  fonts: [
    { src: robotoMono }, // font-style: normal, font-weight: normal
    {
      src: robotoMonoItalic,
      fontStyle: 'italic',
    },
    {
      src: robotoMonoBold,
      fontWeight: 'bold',
    },
    {
      src: robotoMonoBoldItalic,
      fontStyle: 'italic',
      fontWeight: 'bold',
    },
  ],
})

Font.register({
  family: 'Merriweather',
  fonts: [
    { src: merriweather }, // font-style: normal, font-weight: normal
    {
      src: merriweatherItalic,
      fontStyle: 'italic',
    },
    {
      src: merriweatherBold,
      fontWeight: 'bold',
    },
    {
      src: merriweatherBoldItalic,
      fontStyle: 'italic',
      fontWeight: 'bold',
    },
  ],
})

const styles = StyleSheet.create({
  page: {
    // paddingBottom: 64 + 32, // Header height + padding
    paddingBottom: 64 + 16, // Footer height + padding
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    position: 'relative',
    padding: '0 32pt 0',
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: 12,
  },
  footer: {
    height: 64,
    // backgroundColor: '#0000ff88',
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '16pt 32pt 16pt',
  },
  footerNumber: {
    position: 'absolute',
    right: '50%',
    left: '50%',
    fontSize: 12,
    textAlign: 'center',
  },
})

const stylesheet = {
  ['*']: {
    padding: 0,
    margin: 0,
  },
  ['p, h1, h2, h3, h4, h5, h6']: {
    fontFamily: 'Roboto',
    fontWeight: 400,
  },
  ['.ql-font-monospace']: {
    fontFamily: 'Roboto Mono',
  },
  ['.ql-font-serif']: {
    fontFamily: 'Merriweather',
  },
  ['.ql-align-center']: {
    textAlign: 'center',
  },
  ['.ql-align-right']: {
    textAlign: 'right',
  },
  ['.ql-align-justify']: {
    textAlign: 'justify',
  },
  ['.ql-indent-1']: {
    paddingLeft: 30,
  },
  ['.ql-indent-2']: {
    paddingLeft: 60,
  },
  ['.ql-indent-3']: {
    paddingLeft: 90,
  },
  ['.ql-indent-4']: {
    paddingLeft: 120,
  },
  ['.ql-indent-5']: {
    paddingLeft: 150,
  },
  ['.ql-indent-6']: {
    paddingLeft: 180,
  },
  ['.ql-indent-7']: {
    paddingLeft: 210,
  },
  ['.ql-indent-8']: {
    paddingLeft: 240,
  },
}

type Props = {
  pages: Array<Tpage>;
  pageSelected: PageSingle;
  dateFormat: DateFormats;
  language: 'en' | 'du'
};

export default function PageRender({ pages, pageSelected, dateFormat, language }: Props) {
  if (!pageSelected) return null

  const body = useMemo(() => {
    if (pageSelected.tableOfContents) {
      return <TableOfContentsRender pages={pages} />
    }

    return pageSelected.sections.map((section) => {
      if (typeof section.active === 'boolean' && !section.active) return null
      if (section.type === 'text' || section.type === 'text_title') {
        return (
          <Text key={section.id} style={styles.text}>
            {section.value}
          </Text>
        )
      } else if (
        section.type === 'text_conf' ||
        section.type === 'text_special'
      ) {
        return (
          <Html
            stylesheet={stylesheet}
            style={styles.text}
            key={section.id}
          >
            {section.value}
          </Html>
        )
      } else if (section.type === 'text_link') {
        return <LinkRender key={section.id} section={section} />
      } else if (
        section.type === 'image' ||
        section.type === 'image_select' ||
        section.type === 'graph'
      ) {
        return <ImageRender key={section.id} section={section} />
      } else if (section.type === 'image_galery') {
        return <ImageGaleryRender key={section.id} section={section} />
      } else if (section.type === 'list_info') {
        return <ListInfoRender key={section.id} section={section} dateFormat={dateFormat} />
      } else if (section.type === 'goal') {
        return <GoalRender key={section.id} section={section} />
      } else if (section.type === 'grid') {
        return <GridRender key={section.id} section={section} />
      } else if (section.type === 'table') {
        return <TableRender key={section.id} section={section} />
      } else if (section.type === 'contact') {
        return <ContactRender key={section.id} section={section} />
      } else if (section.type === 'expiration_date') {
        return <ExpirationDateRender key={section.id} section={section} language={language} dateFormat={dateFormat} />
      }
      return null
    })
  }, [pageSelected])

  const footer = useMemo(() => {
    const page = pages.find(
      (page) => page.id === 'ba3a2a00-9238-43c7-bcb4-22df7011ada7'
    )
    if (!page) return []
    if (!('footer' in page)) return []
    return page.footer
  }, [pages])

  return (
    <Page size="A4" style={styles.page}>
      <HeaderRender pageSelected={pageSelected}/>
      {/* {pageSelected.isSortEnabled ? (
        <View style={styles.content} render={() => body} />
      ) : ( */}
      <View style={styles.content}>
        {body}
      </View>
      {/* )} */}

      <View style={styles.footer} fixed>
        {pageSelected.hiddenInTableOfContents ? (
          <View style={styles.footerNumber} />
        ) : (
          <Text
            render={({ pageNumber,  }) => pageNumber}
            style={styles.footerNumber}
            fixed
          />
        )}
        {footer.map(
          (section) =>
            section.type === 'image_logo' && (
              <LogoRender key={section.id} logo={section} />
            )
        )}
      </View>
    </Page>
  )
}

