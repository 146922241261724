import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { addEconomicParameters, updateEconomicParameters } from '../../api/simulations/financial-parameters/economic-parameters'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import CreateSimulationContainer from '../../containers/CreateSimulationContainer'
import SimulationFormikHandler from '../../containers/SimulationFormikHandler'
import CreateSimulationTitle from '../../components/CreateSimulationTitle'
import Input from '../../components/Input'
import InputSmall from '../../components/InputSmall'
import { submitStep } from '../../utils/submitStep'
import UpIcon from '../../assets/icons/up-black.svg'
import { normalizePercentToSend, normalizePercentToShow, threeDecimalRegex } from '../../constants'

const EconomicParameters = () => {
  const { token } = useAppContext()
  const { newSimulation, updateNewSimulation, setIsStepLoading } = useNewSimulationContext()

  const yupPercentageValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('min', 'Min: 0%', value => value >= 0)
    // .test('max', 'Max: 100%', value => value <= 100)
    .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))

  const formik = useFormik({
    initialValues: {
      inflation_rate: null,
      discount_rate: null,
      interest_rate: null,
      O_and_M_escalation_rates: null,
      project_economic_lifetime: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      inflation_rate: yupPercentageValidation,
      discount_rate: yupPercentageValidation,
      interest_rate: yupPercentageValidation,
      O_and_M_escalation_rates: yupPercentageValidation,
      project_economic_lifetime: Yup.number().typeError('Only numbers').required('Required').min(20, 'Min: 20 years').max(25, 'Max: 25 years').integer('Only integers'),
    }),
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        inflation_rate: normalizePercentToSend(values.inflation_rate),
        discount_rate: normalizePercentToSend(values.discount_rate),
        interest_rate: normalizePercentToSend(values.interest_rate),
        O_and_M_escalation_rates: normalizePercentToSend(values.O_and_M_escalation_rates),
      }

      await submitStep({
        pathname: location.pathname,
        lastStep: newSimulation.last_step,
        addFunction: () => addEconomicParameters(token, newSimulation.id, newValues),
        updateFunction: () => updateEconomicParameters(token, newSimulation.id, newValues),
        updateNewSimulation,
        setIsStepLoading
      })
    }
  })

  // Show saved simulation step
  useEffect(() => {
    formik.setValues({
      inflation_rate: normalizePercentToShow(newSimulation.steps.economic_parameters.inflation_rate),
      discount_rate: normalizePercentToShow(newSimulation.steps.economic_parameters.discount_rate),
      interest_rate: normalizePercentToShow(newSimulation.steps.economic_parameters.interest_rate),
      O_and_M_escalation_rates: normalizePercentToShow(newSimulation.steps.economic_parameters.O_and_M_escalation_rates),
      project_economic_lifetime: newSimulation.steps.economic_parameters.project_economic_lifetime,
    })
  }, [newSimulation])

  return (
    <SimulationFormikHandler formik={formik}>
      <Helmet>
        <title>Create Simulation: Economic Parameters</title>
      </Helmet>

      <CreateSimulationContainer>
        <header className="d-flex align-items-center flex-wrap gap-2">
          <img
            src={UpIcon}
            className="CreateSimulation__Title-Icon"
            alt='Energy price'
            title='Energy price'
          />

          <CreateSimulationTitle>Economic parameters</CreateSimulationTitle>
        </header>

        <InputSmall
          topLabel='Inflation rates'
          rightLabel='% per year'
          name='inflation_rate'
          placeholder='0'
          value={formik.values.inflation_rate}
          onChange={formik.handleChange}
          error={formik.errors.inflation_rate}
        />

        <InputSmall
          topLabel='Discount rates'
          rightLabel='% per year'
          name='discount_rate'
          placeholder='0'
          value={formik.values.discount_rate}
          onChange={formik.handleChange}
          error={formik.errors.discount_rate}
        />
        
        <InputSmall
          topLabel='Interest rates'
          rightLabel='% per year'
          name='interest_rate'
          placeholder='0'
          value={formik.values.interest_rate}
          onChange={formik.handleChange}
          error={formik.errors.interest_rate}
        />

        <InputSmall
          topLabel='O&M escalation rates'
          rightLabel='% per year'
          name='O_and_M_escalation_rates'
          placeholder='0'
          value={formik.values.O_and_M_escalation_rates}
          onChange={formik.handleChange}
          error={formik.errors.O_and_M_escalation_rates}
        />

        <Input
          label='Project economic lifetime'
          name='project_economic_lifetime'
          type='number'
          placeholder='Enter years (min: 20, max: 25)'
          value={formik.values.project_economic_lifetime}
          onChange={formik.handleChange}
          error={formik.errors.project_economic_lifetime}
        />

      </CreateSimulationContainer>
    </SimulationFormikHandler>
  )
}

export default EconomicParameters