import useAppContext from '../hooks/useAppContext'
import { TableSimulations } from './tables/TableSimulations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { IAdmin, ICalendar, ILocation, IOrganization } from '../iconComponents'

dayjs.extend(customParseFormat)


const SimulationInfo = () => {
  const { simulationsToTransfer, setsimulationsToTransfer } = useAppContext()

  const handleRemoveSimulation = (index: number) => { 
    setsimulationsToTransfer(prev => (
      [
        ...prev.slice(0, index),
        ...prev.slice(index + 1)
      ]
    ))
  }

  return (
    <div className="TransferSimulation__Simulation_Info">
      <div className="TransferSimulation__Simulation_Info_content d-none d-md-flex">
        <div className="TransferSimulation__Simulation_Info_Title">
          <p className="text-4 fw-medium">{simulationsToTransfer.length}</p>
          <p className="text-5">{simulationsToTransfer.length == 1 ? 'Simulation' : 'Simulations'} selected</p>
        </div>
        <div className="TransferSimulation__Simulation_Info_Item">
          <p className="TransferSimulation__Simulation_Info_Item_Title text-4 fw-medium text-center">
            Simulation’s info
          </p>
          {simulationsToTransfer.map((simulation, index) => (
            <div key={simulation.id} className="TransferSimulation__Simulation_Info_Item_Content">
              <FontAwesomeIcon
                icon={faTimes}
                className="TransferSimulation__Simulation_Info_Item_Content_Cancel"
                onClick={() => handleRemoveSimulation(index)}
              />
              <p className='TransferSimulation__Simulation_Info_Item_Content_Tilte text-4 fw-medium'>{simulation.project_name}</p>
              <div className="TransferSimulation__Simulation_Info_Item_Content_Item">
                <div className="DeleteSimulationModal__Grid_Item_Title">
                  <IAdmin className='DeleteSimulationModal__Grid_Item_Title_Icon' />
                  <p className="text-5">ID & status</p>
                </div>
                <div className="DeleteSimulationModal__Grid_Item_Info text-5 text-primary fw-medium">
                  {simulation.id_number}
                </div>
                <div className="DeleteSimulationModal__Grid_Item_Info d-flex align-items-center gap-2">
                  <div className={`TableItem__Circle--${simulation.status}`} />
                  <p className='text-capitalize text-6 fw-medium'>{simulation.status || '-'}</p>
                </div>
              </div>
              <div className="TransferSimulation__Simulation_Info_Item_Content_Item">
                <div className="DeleteSimulationModal__Grid_Item_Title">
                  <IAdmin className='DeleteSimulationModal__Grid_Item_Title_Icon' />
                  <p className="text-5">Owned by</p>
                </div>
                <div className="DeleteSimulationModal__Grid_Item_Info text-5 text-primary fw-medium">
                  {simulation.client_name}
                </div>
              </div>
              <div className="TransferSimulation__Simulation_Info_Item_Content_Item">
                <div className="DeleteSimulationModal__Grid_Item_Title">
                  <ICalendar className='DeleteSimulationModal__Grid_Item_Title_Icon' />
                  <p className="text-5">Date</p>
                </div>
                <div className="DeleteSimulationModal__Grid_Item_Info text-5 text-primary fw-medium">
                  {simulation.updated_at ? dayjs(simulation.updated_at, 'YYYY-MM-DD').format('DD/MM/YY') : '-'}
                </div>
              </div>
              <div className="TransferSimulation__Simulation_Info_Item_Content_Item">
                <div className="DeleteSimulationModal__Grid_Item_Title">
                  <ILocation className='DeleteSimulationModal__Grid_Item_Title_Icon' />
                  <p className="text-5">Country</p>
                </div>
                <div className="DeleteSimulationModal__Grid_Item_Info text-5 text-primary fw-medium">
                  {simulation.location_country_name || '-'}
                </div>
              </div>
              <div className="TransferSimulation__Simulation_Info_Item_Content_Item">
                <div className="DeleteSimulationModal__Grid_Item_Title">
                  <IOrganization className='DeleteSimulationModal__Grid_Item_Title_Icon' />
                  <p className="text-5">Organization</p>
                </div>
                <div className="DeleteSimulationModal__Grid_Item_Info text-5 text-primarys fw-medium">
                  {simulation.organization_name || '-'}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="TransferSimulation__Simulation_Info_content d-flex d-md-none">
        <p className="text-5 fw-light text-primary">Simulation information  ( {simulationsToTransfer.length} Selected )</p>
        <div className='Dashboard__Table_path_info_Info_mobile'>
          {simulationsToTransfer.map(simulation => (
            <TableSimulations key={simulation.id} data={simulation} isMobile unselectable />
          ))}
        </div>
      </div>
    </div>
  )
}

export default SimulationInfo