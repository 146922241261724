import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import ImageSvg from './ImageSvg'
import { SectionImage } from '../../types/generateReport'

type Props = {
  section: SectionImage;
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    gap: 8,
  },
  image: {
    height: 250,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    marginHorizontal: 'auto',
    objectFit: 'cover',
  },
  emptyImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 250,
    backgroundColor: '#D9D9D9',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 14,
  },
  subtitle: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 12,
  },
  description: {
    fontFamily: 'Roboto',
    fontSize: 12,
  },
})

// function convertImageToBase64(imgUrl, callback) {
//   const image = new Image()
//   image.crossOrigin='anonymous'
//   image.onload = () => {
//     const canvas = document.createElement('canvas')
//     const ctx = canvas.getContext('2d')
//     canvas.height = image.naturalHeight
//     canvas.width = image.naturalWidth
//     ctx.drawImage(image, 0, 0)
//     const dataUrl = canvas.toDataURL()
//     callback && callback(dataUrl)
//   }
//   image.src = imgUrl
// }

export default function ImageRender({ section }: Props) {
  return (
    <>
      {section.value ? (
        <Image
          src={section.value}
          style={{ ...styles.image, ...section.style }}
        />
      ) : (
        <View
          style={
            section.style?.height
              ? { ...styles.emptyImage, height: section.style.height }
              : styles.emptyImage
          }
          wrap={false}
        >
          <ImageSvg width={32} height={32} />
        </View>
      )}
      {('text' in section ||
        'subtitle' in section ||
        'description' in section) && (
        <View
          style={styles.container}
          render={() => (
            <>
              {'text' in section && section.text && (
                <Text style={styles.title}>{section.text}</Text>
              )}
              {'subtitle' in section && section.subtitle && (
                <Text style={styles.subtitle}>{section.subtitle}</Text>
              )}
              {'description' in section && section.description && (
                <Text style={styles.description}>{section.description}</Text>
              )}
            </>
          )}
        />
      )}
    </>
  )
}
