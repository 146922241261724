import { Helmet } from 'react-helmet'

const Statistics = () => {
  return (
    <>
      <Helmet>
        <title>Solarus: Statistics</title>
      </Helmet>

      <div className='w-100 d-flex justify-content-center align-items-center py-5 px-3'>
        <h4>Here will go the Statistics</h4>
      </div>
    </>
  )
}

export default Statistics