import { useMemo, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import useAppContext from '../../hooks/useAppContext'
import { createNewClient } from '../../api/clients'
import Modal from './Modal'
import Input from '../Input'
import CheckModal from './CheckModal'
import SearchInput from '../SearchInput'
import { useQuery } from 'react-query'
import { getCountries, getOrganizations } from '../../api/organizations'
import LoadingDots from '../LoadingDots'
import 'react-phone-number-input/style.css'
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneNumberInput from '../PhoneNumberInput'
import Button from '../buttons/Button'

const NewClientModal = () => {
  const {
    token,
    user,
    isCentralAdmin,
    toggleShowNewClientModal,
    newClientCallback,
    setNewClientCallback,
    refreshClients,
  } = useAppContext()
  const [step, setStep] = useState(1)
  const [serverError, setServerError] = useState(false)
  const initialData = { page: 1, total_items: 0, total_pages: 1, data: [] }
  const { data: organizatoins } = useQuery(
    ['getOrganizations'],
    () => getOrganizations(token, initialData),
    {
      initialData,
      enabled: token !== '' && isCentralAdmin,
    }
  )
  const { data } = useQuery(['getCountries'], () => getCountries(token), {
    initialData: {
      user_countries: [],
      all_countries: [],
    },
    enabled: token !== '',
  })

  const organizatoinsForInput = useMemo(
    () =>
      organizatoins.data?.map((organizatoin) => ({
        value: organizatoin.id,
        label: organizatoin.name,
      })),
    [organizatoins]
  )

  const countries = useMemo(() => {
    const userCountries = data.user_countries.map((country) => ({
      value: country.id,
      label: country.name,
    }))
    const allCountries = data.all_countries.map((country) => ({
      value: country.id,
      label: country.name,
    }))
    return [...userCountries, ...allCountries]
  }, [data])

  const formik = useFormik<{
    name: string;
    country: number | null;
    organization: number | null;
    company: string;
    role: string;
    address: string;
    email: string;
    phone: string;
  }>({
    initialValues: {
      name: '',
      country: null,
      organization: user.organization?.id ?? null,
      company: '',
      role: '',
      address: '',
      email: '',
      phone: '+1',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      country: Yup.number().nullable().required('Required'),
      organization: Yup.number().required('Required'),
      company: Yup.string(),
      role: Yup.string(),
      address: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email').required('Required'),
      phone: Yup.string()
        .required('Required')
        .test((value) => isValidPhoneNumber(value)),
    }),
    onSubmit: async (values) => {
      const response = await createNewClient(token, values)

      if (
        response.name &&
        response.name.includes(
          'The fields name, organization must make a unique set.'
        )
      )
        formik.setFieldError('name', 'Client with this name already exists')
      else if (
        response.error?.non_field_errors &&
        response.error.non_field_errors.includes(
          'The fields name, organization must make a unique set.'
        )
      )
        formik.setFieldError('name', 'Client with this name already exists')
      else if (response.error) setServerError(true)
      else if (response) {
        setStep(2)
        if (newClientCallback) {
          await newClientCallback(response)
          setNewClientCallback(null)
        }
        await refreshClients()
      }
    },
  })

  if (step == 1)
    return (
      <Modal title="Add new client" closeModal={toggleShowNewClientModal}>
        <div className="Modal__Scroll-Content d-flex flex-column align-items-center gap-3 py-3">
          <Input
            label="Name"
            name="name"
            placeholder="Enter your name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.errors.name}
          />

          {isCentralAdmin && (
            <SearchInput
              label="Organization"
              name="organization"
              placeholder="Choose organization"
              search={organizatoinsForInput}
              value={Number(formik.values.organization)}
              onChange={(id) => formik.setFieldValue('organization', id)}
              error={formik.errors.organization}
            />
          )}

          <SearchInput
            label="Country"
            name="country"
            placeholder="Select a country"
            search={countries}
            value={formik.values.country}
            onChange={(id) => formik.setFieldValue('country', id)}
            error={formik.errors.country}
          />

          <Input
            label="Company"
            name="company"
            placeholder="Company"
            value={formik.values.company}
            onChange={formik.handleChange}
            error={formik.errors.company}
          />

          <Input
            label="Role"
            name="role"
            placeholder="Role"
            value={formik.values.role}
            onChange={formik.handleChange}
            error={formik.errors.role}
          />

          <Input
            label="Address"
            name="address"
            placeholder="Address"
            value={formik.values.address}
            onChange={formik.handleChange}
            error={formik.errors.address}
          />

          <Input
            label="Email"
            name="email"
            type="email"
            placeholder="someone@gmail.com"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email}
          />

          <PhoneNumberInput
            label="Phone number"
            name="phone"
            value={formik.values.phone}
            onChange={(value) => formik.setFieldValue('phone', value)}
            errors={formik.errors.phone}
          />

          {serverError ? (
            <p className="text-red">Something went wrong</p>
          ) : (
            <></>
          )}

          <Button
            className="w-100"
            type="submit"
            onClick={() => formik.handleSubmit()}
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? (
              <>
                <span>Adding</span>
                <LoadingDots className="enter-done" />
              </>
            ) : (
              <span>Add client</span>
            )}
          </Button>
        </div>
      </Modal>
    )

  if (step === 2)
    return (
      <CheckModal
        closeModal={toggleShowNewClientModal}
        title="Add new client"
        message="¡New client added!"
      />
    )
}

export default NewClientModal
