import { SVGProps } from 'react'
const SvgIEditUser = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.24 23.673c-1.938 0-3.831-.553-5.442-1.59a9.521 9.521 0 0 1-3.607-4.235 9.117 9.117 0 0 1-.557-5.452 9.334 9.334 0 0 1 2.68-4.832A9.913 9.913 0 0 1 18.33 4.98c1.9-.364 3.868-.177 5.658.537a9.724 9.724 0 0 1 4.395 3.476 9.194 9.194 0 0 1 1.65 5.242c-.007 2.501-1.04 4.898-2.876 6.666-1.835 1.768-4.322 2.764-6.917 2.77Zm0-15.098a6.035 6.035 0 0 0-3.265.954 5.713 5.713 0 0 0-2.165 2.54 5.471 5.471 0 0 0-.334 3.272 5.602 5.602 0 0 0 1.608 2.899 5.948 5.948 0 0 0 3.009 1.55 6.083 6.083 0 0 0 3.395-.323 5.835 5.835 0 0 0 2.637-2.085c.645-.931.99-2.026.99-3.146a5.56 5.56 0 0 0-1.721-4.003 5.99 5.99 0 0 0-4.155-1.658ZM1.958 41.288c-.52 0-1.018-.2-1.385-.553A1.853 1.853 0 0 1 0 39.4c0-11.952 14.18-11.952 20.24-11.952 1.88 0 3.55 0 5.065.176.516.038.994.271 1.332.649.337.377.506.867.47 1.364a1.89 1.89 0 0 1-.69 1.284 2.034 2.034 0 0 1-1.425.452c-1.41 0-2.977-.15-4.753-.15-13.527 0-16.322 3.27-16.322 8.177.004.25-.044.496-.142.727a1.86 1.86 0 0 1-.424.615 1.94 1.94 0 0 1-.639.409c-.239.093-.495.14-.753.137Zm23.79 1.912c-.519 0-1.017-.2-1.384-.554a1.807 1.807 0 0 1-.464-.678 1.749 1.749 0 0 1-.11-.806l.418-4.832c.032-.444.226-.863.548-1.182l14.415-13.89a5.493 5.493 0 0 1 3.657-1.382c1.355 0 2.661.493 3.656 1.381a5.02 5.02 0 0 1 1.137 1.665c.26.622.388 1.288.377 1.959a4.532 4.532 0 0 1-.344 1.807 4.667 4.667 0 0 1-1.04 1.54L32.2 42.118c-.32.316-.746.512-1.202.554l-5.066.453-.183.075Zm2.299-5.888-.157 1.912 2.037-.176 13.92-13.412a.959.959 0 0 0 .234-.68c0-.35-.13-.689-.365-.956a1.526 1.526 0 0 0-.888-.283c-.32 0-.632.1-.888.283L28.047 37.312Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgIEditUser
