export const userTypes = [
  { value: 'central_admin', label: 'Central Admin' },
  { value: 'organization_admin', label: 'Organization Admin' },
  { value: 'region_admin', label: 'Region Admin' },
  { value: 'operator', label: 'Operator' },
]

export const userTypeObject = {
  central_admin: 'Central Admin',
  organization_admin: 'Organization Admin',
  region_admin: 'Region Admin',
  operator: 'Operator',
}