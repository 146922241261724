import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { addInverter, updateInverter } from '../../api/simulations/system-parameters/inverter'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import CreateSimulationContainer from '../../containers/CreateSimulationContainer'
import SimulationFormikHandler from '../../containers/SimulationFormikHandler'
import CreateSimulationSelector from '../../components/CreateSimulationSelector'
import EditButton from '../../components/EditButton'
import InputSmall from '../../components/InputSmall'
import { checkboxOptions, normalizeNumberWithCommas, threeDecimalRegex } from '../../constants'
import { submitStep } from '../../utils/submitStep'
import PanelIcon from '../../assets/icons/panel.svg'
import CreateSimulationDisabled from '../../components/CreateSimulationDisabled'

const Inverter = () => {
  const { token } = useAppContext()
  const { newSimulation, updateNewSimulation, flowDecisions, setIsStepLoading } = useNewSimulationContext()
  const [ edit, setEdit ] = useState(false)
  const [ available, setAvailable ] = useState(false)
  const [ loading, setLoading ] = useState(true)

  const yupNumberValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))
    .test('min', 'Min: 0', value => value >= 0)
    .test('min', 'Max: 1.2', value => value <= 1.2)

  const formik = useFormik({
    initialValues: {
      include_pv_system_loss: false,
      DC_AC_ratio_PV: 1.2,
      DC_AC_ratio_PVT: 1.2
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      DC_AC_ratio_PV: Yup.lazy(() => {
        if(flowDecisions.include_a_pv_field) return yupNumberValidation
        else return Yup.number().typeError('Only numbers').min(0, 'Min: 0')
      }),
      DC_AC_ratio_PVT: Yup.lazy(() => {
        if(flowDecisions.include_a_t_or_pvt) return yupNumberValidation
        else return Yup.number().typeError('Only numbers').min(0, 'Min: 0')
      }),
    }),
    onSubmit: async (values) => {
      const newValues = {
        include_pv_system_loss: String(values.include_pv_system_loss),
        DC_AC_ratio_PV: normalizeNumberWithCommas(values.DC_AC_ratio_PV),
        DC_AC_ratio_PVT: normalizeNumberWithCommas(values.DC_AC_ratio_PVT)
      }

      if(!available) {
        delete newValues.include_pv_system_loss
        delete newValues.DC_AC_ratio_PV
        delete newValues.DC_AC_ratio_PVT
      }

      await submitStep({
        pathname: location.pathname,
        lastStep: newSimulation.last_step,
        addFunction: () => addInverter(token, newSimulation.id, newValues),
        updateFunction: () => updateInverter(token, newSimulation.id, newValues),
        updateNewSimulation,
        setIsStepLoading
      })
    }
  })

  const hasPVTpanel = useMemo(() => {
    const solarThermalField = newSimulation.steps.solar_thermal_field
    return solarThermalField.panel_one?.model_panel_parameters?.panel_type == 'PVT' || solarThermalField.panel_two?.model_panel_parameters?.panel_type == 'PVT'
  }, [newSimulation.steps.solar_thermal_field])

  useEffect(() => {
    if(flowDecisions?.include_a_t_or_pvt) {
      if(hasPVTpanel) setAvailable(true)
      else setAvailable(false)
    } else if(flowDecisions?.include_a_pv_field) {
      setAvailable(true)
    } else if(available) {
      setAvailable(false)
    }

    setLoading(false)
  }, [flowDecisions])

  // Show saved simulation step
  useEffect(() => {
    if(available) {
      formik.setValues({
        include_pv_system_loss: newSimulation.steps.inverter.include_pv_system_loss,
        DC_AC_ratio_PV: newSimulation.steps.inverter.DC_AC_ratio_PV,
        DC_AC_ratio_PVT: newSimulation.steps.inverter.DC_AC_ratio_PVT,
      })
    }
  }, [newSimulation.steps.inverter, available])

  // Listen to edit state
  useEffect(() => {
    if(!edit && (!formik.values.DC_AC_ratio_PV || !formik.values.DC_AC_ratio_PVT)) {
      formik.setValues({
        ...formik.values,
        DC_AC_ratio_PV: 1.2,
        DC_AC_ratio_PVT: 1.2
      })
    }
  }, [edit])

  if(loading) return null

  if(!available) return (
    <SimulationFormikHandler formik={formik}>
      <Helmet>
        <title>Create Simulation: Solar storage</title>
      </Helmet>

      <CreateSimulationDisabled
        title='Inverter'
        message={
          flowDecisions?.include_a_t_or_pvt && !hasPVTpanel && (
            <span>
              If you want to complete this step, you must include a PVT field in the <Link to='/dashboard/create-simulation/step/solar-thermal-field' className='link'>Solar Thermal Field</Link> step.
            </span>
          )
          || !flowDecisions?.include_a_pv_field && (
            <span>
              If you want to complete this step, yo must include a PV field in the <Link to='/dashboard/create-simulation/step/pv-field' className='link'>PV Field</Link> step.
            </span>
          )
        }
      />
    </SimulationFormikHandler>
  )

  return (
    <SimulationFormikHandler formik={formik}>
      <Helmet>
        <title>Create Simulation: Inverter</title>
      </Helmet>

      {/* First Container */}
      <CreateSimulationSelector
        title='Inverter'
        subtitle='Include a PV system loss?'
        subtitleAlert='We always recommend to use system loss'
        options={checkboxOptions}
        optionSelected={formik.values.include_pv_system_loss}
        handleSelect={(value) => formik.setFieldValue('include_pv_system_loss', value)}
      />

      {/* Second Container */}
      <CreateSimulationContainer>
        <EditButton
          setEdit={setEdit}
          available={flowDecisions?.include_a_pv_field || (flowDecisions?.include_a_t_or_pvt && hasPVTpanel)}
          className="ms-auto d-md-none"
        />
        <header className='d-flex align-items-center gap-2'>
          <img
            src={PanelIcon}
            className="CreateSimulation__Title-Icon"
            alt='System'
            title='System'
          />

          <h2 className='text-4 fw-semibold'>System</h2>

          <EditButton
            setEdit={setEdit}
            available={flowDecisions?.include_a_pv_field || (flowDecisions?.include_a_t_or_pvt && hasPVTpanel)}
            className="d-none d-md-flex"
          />
        </header>

        
        <InputSmall
          topLabel='DC_AC ratio for PV system'
          name='DC_AC_ratio_PV'
          placeholder='1.2'
          value={formik.values.DC_AC_ratio_PV}
          onChange={formik.handleChange}
          error={formik.errors.DC_AC_ratio_PV}
          disabled={!flowDecisions.include_a_pv_field || !edit}
          edit={'There must be a PV system to edit this field'}
        />
        <InputSmall
          topLabel='DC_AC ratio for PVT system'
          name='DC_AC_ratio_PVT'
          placeholder='1.2'
          value={formik.values.DC_AC_ratio_PVT}
          onChange={formik.handleChange}
          error={formik.errors.DC_AC_ratio_PVT}
          disabled={!(flowDecisions?.include_a_t_or_pvt && hasPVTpanel) || !edit}
          edit={'There must be a PVT system to edit this field'}
        />
      </CreateSimulationContainer>
    </SimulationFormikHandler>
  )
}

export default Inverter