import { useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { getPVModelDetail } from '../api/simulations/system-parameters/pv-field'
import useAppContext from '../hooks/useAppContext'
import useNewSimulationContext from '../hooks/useNewSimulationContext'
import useModalsContext from '../hooks/useModalsContext'
import CreateSimulationContainer from '../containers/CreateSimulationContainer'
import Checkboxes from './Checkboxes'
import EditButton from './EditButton'
import Input from './Input'
import InputSmall from './InputSmall'
import SearchInput from './SearchInput'
import PanelIcon from '../assets/icons/panel.svg'
import { checkboxOptions, threeDecimalRegex } from '../constants'
import SimulationSwitch from './SimulationSwitch'

interface FormProps {
  isSecondForm?: boolean;
  available: boolean;
  checkboxSelected?: boolean;
  handleSelect?: (value: boolean) => void;
  fieldName?: string;
  fields: any;
  setFields: (value: any) => void;
}

const PVForm = ({
  isSecondForm,
  available,
  checkboxSelected,
  handleSelect,
  fieldName,
  fields,
  setFields,
}: FormProps) => {
  const { token } = useAppContext()
  const { pvModels } = useNewSimulationContext()
  const { pvModelTypeSelected, setPVModelTypeSelected, 
    // toggleShowManagePVModelsModal 
  } = useModalsContext()
  const [ edit, setEdit ] = useState(false)
  const [normalizedModels, setNormalizedModels] = useState([])
  const [modelSelected, setModelSelected] = useState({
    name: '',
    panel_type: '',
    ag: '',
    h_col: '',
    w_col: '',
    ppvt: '',
    vmpp: '',
    impp: '',
    voc: '',
    isc: '',
    ref_eff: '',
    gamma_Pmpp: '',
    gamma_Voc: '',
    gamma_Isc: '',
    t_NOCT: '',
    n_cell_series: '',
  })

  const yupNumberValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))

  const yupIntegerValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('integer', 'Only integers', value => Number.isInteger(Number(value)))

  const yupDegreeValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))
    .test('min', 'Min: -90°', value => value >= -90)
    .test('max', 'Max: 90°', value => value <= 90)

  const formik = useFormik({
    initialValues: {
      name: '',
      pv_type: '',
      model: null,
      number_of_panels: '',
      type_of_roof: '',
      panel_orientation: '',
      panel_tilt: '',
      number_of_panels_per_row: '',
      distance_between_rows: 4.5,
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      pv_type: Yup.string().required('Required'),
      model: Yup.number().nullable().required('Required'),
      number_of_panels: yupIntegerValidation.test('min', 'Min: 1', value => value >= 1).test('max', 'Max: 5000', value => value <= 5000),
      type_of_roof: Yup.string().required('Required'),
      panel_orientation: yupDegreeValidation,
      panel_tilt: yupDegreeValidation,
      number_of_panels_per_row: yupIntegerValidation.test('min', 'Min: 1', value => value >= 1).test('max', 'Max: 20', value => value <= 20),
      distance_between_rows: yupNumberValidation.test('min', 'Min: 0.1', value => value >= 0.1).test('max', 'Max: 10', value => value <= 10),
    }),
    onSubmit: () => {
      return
    },
  })

  // Set formik field
  useEffect(() => {
    const newFields = fields

    newFields[fieldName] = formik

    setFields(newFields)
  }, [formik.isValidating])

  // Set pv model type selected in context
  useEffect(() => {
    if (formik.values.pv_type) {
      if (!isSecondForm) {
        setPVModelTypeSelected({
          ...pvModelTypeSelected,
          firstPanel: formik.values.pv_type
        })
      } else if(pvModelTypeSelected.firstPanel) {
        setPVModelTypeSelected({
          ...pvModelTypeSelected,
          secondPanel: formik.values.pv_type
        })
      }
    }
  }, [formik.values.pv_type])

  // Set pv model selected
  useEffect(() => {
    const getCurrentModelDetails = async () => {
      const response = await getPVModelDetail(token, formik.values.model)

      if (response?.error) {
        setModelSelected({
          name: '',
          panel_type: '',
          ag: '',
          h_col: '',
          w_col: '',
          ppvt: '',
          vmpp: '',
          impp: '',
          voc: '',
          isc: '',
          ref_eff: '',
          gamma_Pmpp: '',
          gamma_Voc: '',
          gamma_Isc: '',
          t_NOCT: '',
          n_cell_series: '',
        })
        formik.setFieldError('model', null)
        formik.setStatus('reset')
        setTimeout(() => formik.setStatus(''), 150)
      } else {
        setModelSelected(response)
      }
    }

    if (token && formik.values.model && normalizedModels.length > 0 && pvModelTypeSelected) {
      getCurrentModelDetails()
    } else {
      setModelSelected({
        name: '',
        panel_type: '',
        ag: '',
        h_col: '',
        w_col: '',
        ppvt: '',
        vmpp: '',
        impp: '',
        voc: '',
        isc: '',
        ref_eff: '',
        gamma_Pmpp: '',
        gamma_Voc: '',
        gamma_Isc: '',
        t_NOCT: '',
        n_cell_series: '',
      })
    }
  }, [token, formik.values.model, normalizedModels, pvModelTypeSelected])

  // Normalize pv models
  useEffect(() => {
    const getCurrentPanelModels = async () => {
      const newNormalizedModels = pvModels
        .map((model) => ({
          id: model.id,
          value: model.name,
          type: model.panel_type,
        }))

      // Reset if pv was changed
      if(formik.values.model) {
        const existPVModel = newNormalizedModels.find((model) => model.id == formik.values.model)
        if(!existPVModel || existPVModel.value !== modelSelected.name) {
          formik.setFieldValue('model', '')
          formik.setStatus('reset')
          setTimeout(() => formik.setStatus(''), 100)
        }
      }

      setNormalizedModels(newNormalizedModels)
    }

    if (token && pvModels) {
      getCurrentPanelModels()
    }
  }, [token, pvModels])

  // Listen to edit state
  useEffect(() => {
    if(!edit && !formik.values.distance_between_rows) {
      formik.setValues({
        ...formik.values,
        distance_between_rows: 4.5,
      })
    }
  }, [edit])

  // const handleOpenManageModelsModal = () => {
  //   if (!isSecondForm) {
  //     setPVModelTypeSelected({
  //       panelSelected: 'firstPanel',
  //       firstPanel: formik.values.pv_type,
  //       secondPanel: pvModelTypeSelected.secondPanel,
  //     })
  //   } else {
  //     setPVModelTypeSelected({
  //       panelSelected: 'secondPanel',
  //       firstPanel: pvModelTypeSelected.firstPanel,
  //       secondPanel: formik.values.pv_type,
  //     })
  //   }

  //   toggleShowManagePVModelsModal()
  // }

  const filterPVModels = useMemo(() => {
    const filteredModels = normalizedModels
      .filter(model => model.type === formik.values.pv_type)
      .map(model => ({
        value: model.id,
        label: model.value,
      })) || []

    return filteredModels
  }, [normalizedModels, formik.values.pv_type])

  const pvOptions = [
    {
      label: 'Portrait',
      value: 'Portrait',
    },
    {
      label: 'Landscape',
      value: 'Landscape',
    },
  ]

  const roofOptions = [
    {
      label: 'Flat',
      value: 'Flat',
    },
    {
      label: 'Sloped',
      value: 'Sloped',
    },
  ]

  return (
    <>
      <CreateSimulationContainer available={available} hide={isSecondForm && !available}>
        <header className="d-flex align-items-center flex-wrap gap-2">
          <img src={PanelIcon} alt="PV field" title="PV field" />

          <h2 className="text-4 fw-semibold">PV field</h2>
        </header>

        <Input
          label="Name of the PV field"
          name="name"
          value={formik.values.name}
          placeholder="Enter PV field name"
          onChange={formik.handleChange}
          error={formik.errors.name}
          disabled={!available}
        />

        <Input
          label="Type of PV"
          name="pv_type"
          list={pvOptions}
          value={
            formik.values.pv_type
              ? pvOptions.find(
                (option) => option.value === formik.values.pv_type
              )
              : {
                label: '',
                value: '',
              }
          }
          placeholder="Select an option"
          onChange={(name, item) => {
            formik.setFieldValue(name, item.value)
            formik.setStatus('reset')
            setTimeout(() => formik.setStatus(''), 150)
          }}
          error={formik.errors.pv_type}
          disabled={!available}
        />

        <SearchInput
          label="Model"
          name="model"
          search={filterPVModels}
          value={formik.values.model}
          placeholder="Select an option"
          onChange={(id) => formik.setFieldValue('model', id)}
          error={formik.errors.model}
          reset={formik.status}
          disabled={!available || !formik.values.pv_type || !filterPVModels.length}
          disabledPlaceholder={!formik.values.pv_type ? 'Select a pv type' : !filterPVModels.length ? 'No models available for this panel type' : undefined }
        // buttonLabel={formik.values.pv_type ? 'Manage' : ''}
        // buttonIcon={ListIcon}
        // buttonOnClick={handleOpenManageModelsModal}
        />

        <SimulationSwitch label='Show additional information of the PV field'>
          <InputSmall
            topLabel="Ag"
            name="ag"
            value={modelSelected.ag}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="H_col"
            name="h_col"
            value={modelSelected.h_col}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="W_col"
            name="w_col"
            value={modelSelected.w_col}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="Ppvt"
            name="ppvt"
            value={modelSelected.ppvt}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="Vmpp"
            name="vmpp"
            value={modelSelected.vmpp}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="Impp"
            name="impp"
            value={modelSelected.impp}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="Voc"
            name="voc"
            value={modelSelected.voc}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="Isc"
            name="isc"
            value={modelSelected.isc}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="ref_eff"
            name="ref_eff"
            value={modelSelected.ref_eff}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="gamma_Pmpp"
            name="gamma_Pmpp"
            value={modelSelected.gamma_Pmpp}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="gamma_Voc"
            name="gamma_Voc"
            value={modelSelected.gamma_Voc}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="gamma_Isc"
            name="gamma_Isc"
            value={modelSelected.gamma_Isc}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="T_NOCT"
            name="t_NOCT"
            value={modelSelected.t_NOCT}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="Ncell_series"
            name="n_cell_series"
            value={modelSelected.n_cell_series}
            placeholder="0"
            disabled
          />
        </SimulationSwitch>
      </CreateSimulationContainer>
      
      <CreateSimulationContainer available={available} hide={isSecondForm && !available}>
        <EditButton setEdit={setEdit} className="ms-auto ms-md-0" available={available} />

        <Input
          label="Number of panels"
          name="number_of_panels"
          type="number"
          value={formik.values.number_of_panels}
          placeholder="Enter number of panels"
          onChange={formik.handleChange}
          error={formik.errors.number_of_panels}
          disabled={!available}
        />

        <Input
          label="Type of roof"
          name="type_of_roof"
          list={roofOptions}
          value={
            formik.values.type_of_roof
              ? roofOptions.find(
                (roof) => roof.value === formik.values.type_of_roof
              )
              : {
                label: '',
                value: '',
              }
          }
          placeholder="Select an option"
          onChange={(name, item) => formik.setFieldValue(name, item.value)}
          error={formik.errors.type_of_roof}
          disabled={!available}
        />

        <InputSmall
          topLabel="Panel orientation"
          rightLabel="deg°"
          name="panel_orientation"
          value={formik.values.panel_orientation}
          placeholder="0"
          onChange={formik.handleChange}
          error={formik.errors.panel_orientation}
          disabled={!available}
          tip="Panel orientation is defined positive when facing the equator: 0 = facing the equator, 90 facing the west, -90 facing the east"
        />

        <InputSmall
          topLabel="Panel tilt"
          rightLabel="deg°"
          name="panel_tilt"
          value={formik.values.panel_tilt}
          placeholder="0"
          onChange={formik.handleChange}
          error={formik.errors.panel_tilt}
          disabled={!available}
          tip="Panel tilt is defined positive when tilted towards the equator: 0 = horizontal, 90 = vertical and facing the azimuth, -90 = vertical and facing away from the axis"
        />

        <InputSmall
          topLabel="Number of panels in a row"
          rightLabel="n°"
          name="number_of_panels_per_row"
          value={formik.values.number_of_panels_per_row}
          placeholder="0"
          onChange={formik.handleChange}
          error={formik.errors.number_of_panels_per_row}
          disabled={!available}
        />

        <InputSmall
          topLabel="Distance between two rows"
          rightLabel="m"
          name="distance_between_rows"
          value={formik.values.distance_between_rows}
          placeholder="0"
          onChange={formik.handleChange}
          error={formik.errors.distance_between_rows}
          disabled={!available || !edit}
          edit
        />

        {!isSecondForm && (
          <Checkboxes
            label="Include another PV field?"
            subLabel="Important decision: The “yes” answer enables below content"
            options={checkboxOptions}
            optionSelected={checkboxSelected}
            small
            handleSelect={handleSelect}
            disabled={!available}
          />
        )}
      </CreateSimulationContainer>
    </>
  )
}

export default PVForm
