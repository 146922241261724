import { useState, useRef } from 'react'
import { IAlert, IAngleUp } from '../../iconComponents'
import { useOnClickOutside } from 'usehooks-ts'
import classNames from 'classnames'

type Props<T extends string | number> = {
  label?: string;
  labelActions?: React.ReactNode;
  name: string;
  error?: string;
  selected: T;
  options: Record<T, string>;
  onSelect: (value: T) => void;
};

export default function Selector<T extends string | number>({
  label,
  labelActions,
  name,
  error,
  selected,
  options,
  onSelect,
}: Props<T>) {
  const [showOptions, setShowOptions] = useState(false)
  const optionsContainer = useRef<HTMLDivElement>(null)

  useOnClickOutside(optionsContainer, () => {
    setShowOptions(false)
  })

  const handleSelect = (value: T) => {
    onSelect(value)
    setShowOptions(false)
  }

  const handleToggle = () => setShowOptions(p => !p)

  return (
    <div className="Input__Container gap-2">
      {(label || labelActions) && (
        <div className="Input__Label_Container">
          <label
            htmlFor={name}
            className="Input__Label text-5 font-medium"
            dangerouslySetInnerHTML={{ __html: label }}
          />
          {labelActions}
        </div>
      )}

      <div className="w-100 d-flex flex-column align-items-start gap-1 position-relative" ref={optionsContainer}>
        <div className="Selector text-6" onClick={handleToggle}>
          {selected in options && (
            <p
              className="d-flex flex-grow-1"
              dangerouslySetInnerHTML={{ __html: options[selected] }}
            />
          )}

          <IAngleUp
            className={classNames('Selector__Arrow', { active: showOptions })}
          />
        </div>
        <div
          className={classNames('Selector__Options', { active: showOptions })}
        >
          {Object.entries<string>(options).map(([key, value]) => (
            <button
              className={classNames('Selector__Options_Item text-6', {
                active: key === selected,
              })}
              key={key}
              onClick={() => handleSelect(key as T)}
              dangerouslySetInnerHTML={{ __html: value }}
            />
          ))}
        </div>
      </div>

      {error && (
        <p className="Input__Error text-6">
          <IAlert width="1.25rem" height="1.25rem" className="text-red" />
          <span dangerouslySetInnerHTML={{ __html: error }} />
        </p>
      )}
    </div>
  )
}
