import { SVGProps } from 'react'
const SvgIUser = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 24.662a10.16 10.16 0 0 1-5.591-1.674 9.96 9.96 0 0 1-3.707-4.456 9.808 9.808 0 0 1-.573-5.738 9.891 9.891 0 0 1 2.754-5.085 10.107 10.107 0 0 1 5.154-2.718 10.19 10.19 0 0 1 5.815.565c1.839.752 3.41 2.025 4.516 3.658a9.838 9.838 0 0 1 1.697 5.517c0 2.634-1.06 5.16-2.948 7.022A10.134 10.134 0 0 1 24 24.663Zm0-15.89c-1.194 0-2.362.35-3.355 1.005a5.976 5.976 0 0 0-2.224 2.674 5.885 5.885 0 0 0-.344 3.443 5.935 5.935 0 0 0 1.653 3.05c.845.834 1.92 1.401 3.092 1.631a6.114 6.114 0 0 0 3.489-.339 6.022 6.022 0 0 0 2.71-2.195 5.903 5.903 0 0 0 1.018-3.31c0-1.58-.637-3.096-1.769-4.213A6.08 6.08 0 0 0 24 8.772ZM42.787 43.2a2.054 2.054 0 0 1-1.416-.59 2 2 0 0 1-.597-1.396c0-5.164-2.845-8.607-16.774-8.607-13.93 0-16.774 3.443-16.774 8.607 0 .527-.212 1.032-.59 1.404-.377.373-.89.582-1.423.582-.534 0-1.046-.21-1.423-.582a1.973 1.973 0 0 1-.59-1.404c0-12.58 14.573-12.58 20.8-12.58s20.8 0 20.8 12.58a2 2 0 0 1-.597 1.397 2.054 2.054 0 0 1-1.416.59Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgIUser
