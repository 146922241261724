import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import App from './router/App'
import AppContextProvider from './context/AppContext'
import NewSimulationContextProvider from './context/NewSimulationContext'
import ModalContextProvider from './context/ModalsContext'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/thumbs'
import 'swiper/css/effect-fade'
import 'swiper/css/free-mode'

import './styles/index.sass'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: false
    },
  },
})

root.render(
  <QueryClientProvider client={queryClient}>
    <AppContextProvider>
      <ModalContextProvider>
        <NewSimulationContextProvider>
          <App />
        </NewSimulationContextProvider>
      </ModalContextProvider>
      <ReactQueryDevtools />
    </AppContextProvider>
  </QueryClientProvider>
)
