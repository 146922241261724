import { useState, useMemo } from 'react'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  UniqueIdentifier,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import PagesSortItem from './PagesSortItem'
import useReportContext from '../hooks/useReportContext'
import PagesSortOverlay from './GenerateReport/PagesSortOverlay'

export default function PagesSort() {
  const { pages, setPages, handleAddPage, showPages } = useReportContext()
  const [activePageId, setActivePageId] = useState<UniqueIdentifier | null>(
    null
  )
  const activePage = useMemo(
    () => pages.find((page) => page.id === activePageId),
    [pages, activePageId]
  )

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  function handleDragStart(event: DragStartEvent) {
    setActivePageId(event.active.id)
  }

  function handleDragEnd(event: DragEndEvent) {
    setActivePageId(null)
    const { active, over } = event
    if (!active && !over) return
    if (active.id !== over.id) {
      setPages((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id)
        const newIndex = items.findIndex((item) => item.id === over.id)

        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }

  const handleDragCancel = () => setActivePageId(null)

  return (
    <div className="PagesSort" style={showPages ? { display: 'block' } : null}>
      <button className="PagesSort__New_Item" onClick={handleAddPage}>
        + Add page
      </button>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <SortableContext
          items={pages as Array<{ id: string }>} // I add this becouse the proyect isn't strict
          strategy={verticalListSortingStrategy}
        >
          {pages.map((page, index) => (
            <PagesSortItem key={page.id} index={index} page={page} />
          ))}
        </SortableContext>
        <DragOverlay>
          {activePageId ? <PagesSortOverlay page={activePage} /> : null}
        </DragOverlay>
      </DndContext>
    </div>
  )
}
