import { useEffect } from 'react'


interface Props {
  value: string;
  handleClose: () => void;
}

const ShowCopyToClipboard = ({ value, handleClose }: Props) => {

  useEffect(() => {
    const doClose = setTimeout(() => handleClose(), 5000)
    
    return () => clearTimeout(doClose)
  }, [value])
  

  return (
    <div className='ShowCopyToClipboard'>
      <p className="text-4 text-blue_primary fw-medium">{value}</p>
    </div>
  )
}

export default ShowCopyToClipboard