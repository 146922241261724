import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Home from '../pages/home/Home'

import Login from '../pages/auth/Login'
import ChangePassword from '../pages/auth/ChangePassword'
import AboutYou from '../pages/auth/AboutYou'
import ForgotPassword from '../pages/auth/ForgotPassword'

import ManageSimulations from '../pages/admin/ManageSimulations'
import TransferSimulation from '../pages/admin/TransferSimulation'
import Clients from '../pages/admin/Clients'

import ProjectSummary from '../pages/create-simulation/ProjectSummary'
import RunningSimulation from '../pages/create-simulation/RunningSimulation'

import NotFound from '../pages/error/NotFound'
import DashboardNotFound from '../pages/error/DashboardNotFound'

import Layout from '../layouts/Layout'
import AuthLayout from '../layouts/AuthLayout'
import DashboardLayout from '../layouts/DashboardLayout'
import NewSimulationLayout from '../layouts/NewSimulationLayout'

import GeneralInformation from '../pages/create-simulation/GeneralInformation'
import LocationDetails from '../pages/create-simulation/LocationDetails'
import HotWaterDemand from '../pages/create-simulation/HotWaterDemand'
import ElectricityDemand from '../pages/create-simulation/ElectricityDemand'
import HotWaterSystem from '../pages/create-simulation/HotWaterSystem'
import EnviromentalIndicators from '../pages/create-simulation/EnviromentalIndicators'
import SolarThermalField from '../pages/create-simulation/SolarThermalField'
import PanelSpecifications from '../pages/create-simulation/SolarStorage'
import AuxiliaryHeatingSystem from '../pages/create-simulation/AuxiliaryHeatingSystem'
import PVField from '../pages/create-simulation/PVField'
import Inverter from '../pages/create-simulation/Inverter'
import SystemPerformanceAging from '../pages/create-simulation/SystemPerformanceAging'
import EnergyPrice from '../pages/create-simulation/EnergyPrice'
import EconomicParameters from '../pages/create-simulation/EconomicParameters'
import SubsidyTaxBenefits from '../pages/create-simulation/SubsidyTaxBenefits'
import SystemCosting from '../pages/create-simulation/SystemCosting'
import Uncertainty from '../pages/create-simulation/Uncertainty'
import PPAFinancialModel from '../pages/create-simulation/PPAFinancialModel'
import RunningComplete from '../pages/create-simulation/RunningComplete'
import UploadReport from '../pages/create-simulation/UploadReport'
import Parameters from '../pages/admin/Parameters'
import DeleteUser from '../pages/admin/DeleteUser/DeleteUser'
import Users from '../pages/admin/Users'
import Organizations from '../pages/admin/Organizations'
import Statistics from '../pages/admin/Statistics'
import Logs from '../pages/admin/Logs'
import OrganizationDetail from '../pages/admin/OrganizationDetail'
import Regions from '../pages/admin/Regions'
import useAppContext from '../hooks/useAppContext'
import GenerateReport from '../pages/create-simulation/GenerateReport'

const App = () => {
  const { isCentralAdmin, isOrganizationAdmin, isRegionAdmin } = useAppContext()

  return (
    <BrowserRouter>
      <Routes>
        {/* Landing */}
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />

          <Route path="*" element={<NotFound />} />
        </Route>

        {/* Auth */}
        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />}  />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/about-you" element={<AboutYou />} />

          <Route path="*" element={<NotFound />} />
        </Route>

        {/* Dashboard */}
        <Route path="/dashboard/" element={<DashboardLayout />}>

          {/* Operator */}
          <Route path="manage-simulations" element={<ManageSimulations />} />
          <Route path="manage-simulations/transfer-simulation" element={<TransferSimulation />} />

          {isCentralAdmin ? (
            <Route path="parameters" element={<Parameters />} />
          ) : (
            <></>
          )}
          {isCentralAdmin || isOrganizationAdmin || isRegionAdmin ? (
            <>
              <Route path="users" element={<Users />} />
              <Route path="users/delete-user" element={<DeleteUser />} />
            </>
          ) : (
            <></>
          )}

          <Route path="clients" element={<Clients />} />

          {isCentralAdmin ? (
            <>
              <Route path="organizations" element={<Organizations />} />
              <Route path="organizations/organization-detail/:id" element={<OrganizationDetail />} />
            </>
          ) : isOrganizationAdmin ? (
            <Route path="regions" element={<Regions />} />
          ) : (
            <></>
          )}

          {isCentralAdmin || isOrganizationAdmin || isRegionAdmin? (
            <>
              <Route path="statistics" element={<Statistics />} />
              <Route path="logs" element={<Logs />} />
            </>
          ) : (
            <></>
          )}

          <Route path="*" element={<DashboardNotFound />} />
        </Route>

        {/* Create Simulation */}
        <Route path="/dashboard/create-simulation/" element={<NewSimulationLayout />}>
          {/* Project Details */}
          <Route path="step/general-information" element={<GeneralInformation />} />
          <Route path="step/location-details" element={<LocationDetails />} />

          {/* Input Parameters */}
          <Route path="step/hot-water-demand" element={<HotWaterDemand />} />
          <Route path="step/electricity-demand" element={<ElectricityDemand />} />
          <Route path="step/hot-water-system" element={<HotWaterSystem />} />
          <Route path="step/environmental-indicators" element={<EnviromentalIndicators />} />

          {/* System parameters */}
          <Route path="step/solar-thermal-field" element={<SolarThermalField />} />
          <Route path="step/solar-storage" element={<PanelSpecifications />} />
          <Route path="step/auxiliary-heating-system" element={<AuxiliaryHeatingSystem />} />
          <Route path="step/pv-field" element={<PVField />} />
          <Route path="step/inverter" element={<Inverter />} />
          <Route path="step/system-performance-aging" element={<SystemPerformanceAging />} />

          {/* Financial Parameters */}
          <Route path="step/energy-price" element={<EnergyPrice />} />
          <Route path="step/economic-parameters" element={<EconomicParameters />} />
          <Route path="step/subsidy-&-tax-benefits" element={<SubsidyTaxBenefits />} />
          <Route path="step/system-costing" element={<SystemCosting />} />
          <Route path="step/uncertainty" element={<Uncertainty />} />
          <Route path="step/ppa-financial-model" element={<PPAFinancialModel />} />

          {/* Finish Simulation */}
          <Route path="project-summary" element={<ProjectSummary />} />
          <Route path="running-simulation" element={<RunningSimulation />} />
          <Route path="running-complete" element={<RunningComplete />} />
          <Route path="generate-report" element={<GenerateReport />} />
          <Route path="upload-report" element={<UploadReport />} />

          <Route path="*" element={<DashboardNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
