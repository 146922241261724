import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { getCountries } from '../../api/organizations'
import useAppContext from '../../hooks/useAppContext'
import CheckModal from './CheckModal'
import Modal from './Modal'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Input from '../Input'
import SearchInput from '../SearchInput'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import LoadingDots from '../LoadingDots'
import { addUser } from '../../api/auth'
import PhoneNumberInput from '../PhoneNumberInput'
import { isValidPhoneNumber } from 'libphonenumber-js'

interface Props {
  closeModal: () => void;
  refetch: () => void;
}

const NewOrganizationModal = ({ closeModal, refetch }: Props) => {
  const { token } = useAppContext()
  const [step, setStep] = useState(1)
  const { data } = useQuery(['getCountries'], () => getCountries(token), {
    initialData: {
      user_countries: [],
      all_countries: [],
    },
    enabled: token !== '',
  })
  const countries = useMemo(
    () =>
      data.all_countries
        ? data.all_countries.map((country) => ({
          value: country.id,
          label: country.name,
        }))
        : [],
    [data]
  )
  const [serverError, setServerError] = useState<Array<string>>([])

  const formikOne = useFormik({
    initialValues: {
      email: '',
      user_type: 'organization_admin',
      organization_name: '',
      organization_country_residence: '',
      organization_address: '',
      organization_contact_email: '',
      organization_phone: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email').required('Required'),
      organization_name: Yup.string().required('Required'),
      organization_country_residence: Yup.number().required('Required'),
      organization_address: Yup.string().required('Required'),
      organization_contact_email: Yup.string()
        .email('Invalid email')
        .required('Required'),
      organization_phone: Yup.string()
        .required('Required')
        .test(
          'check if is valid phone',
          'Invalid phone',
          (value) => typeof value === 'string' && isValidPhoneNumber(value)
        ),
    }),
    onSubmit: async () => {
      setStep((prev) => prev + 1)
    },
  })

  const formikTwo = useFormik<{
    office_name: string;
    office_country: number | null;
    office_city: string;
    office_zip_code: string;
    address_line_1: string;
    address_line_2: string;
    office_phone: string;
    office_email: string;
  }>({
    initialValues: {
      office_name: '',
      office_country: null,
      office_city: '',
      office_zip_code: null,
      address_line_1: '',
      address_line_2: '',
      office_phone: '',
      office_email: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      office_name: Yup.string().required('Required'),
      office_country: Yup.number().nullable().required('Required'),
      office_city: Yup.string().required('Required'),
      office_zip_code: Yup.string().nullable().required('Required'),
      address_line_1: Yup.string().required('Required'),
      address_line_2: Yup.string(),
      office_phone: Yup.string()
        .required('Required')
        .test('check if is valid phone', 'Invalid phone', (value) =>
          isValidPhoneNumber(value)
        ),
      office_email: Yup.string().email('Invalid email').required('Required'),
    }),
    onSubmit: async ({
      office_name,
      office_country,
      office_city,
      office_zip_code,
      address_line_1,
      address_line_2,
      office_phone,
      office_email,
    }) => {
      const body: {
        email: string;
        user_type: string;
        organization_id?: number;
        region_id?: number;
        organization_name?: string;
        organization_country_residence?: string;
        organization_address?: string;
        organization_contact_email?: string;
        organization_phone?: string;
        office_name?: string;
        office_country?: number;
        office_city?: string;
        office_zip_code?: string;
        address_line_1?: string;
        address_line_2?: string;
        office_phone?: string;
        office_email?: string;
        region_name?: string;
        country_ids?: string;
      } = {
        email: formikOne.values.email,
        user_type: formikOne.values.user_type,
        organization_name: formikOne.values.organization_name,
        organization_country_residence:
          formikOne.values.organization_country_residence,
        organization_address: formikOne.values.organization_address,
        organization_contact_email: formikOne.values.organization_contact_email,
        organization_phone: formikOne.values.organization_phone,
        office_name,
        office_country,
        office_city,
        office_zip_code,
        address_line_1,
        office_phone,
        office_email,
      }
      if (address_line_2) body.address_line_2 = address_line_2

      const response = await addUser(token, body)

      if (
        response.email ||
        response.user_type ||
        response.organization_name ||
        response.organization_country_residence ||
        response.organization_address ||
        response.organization_contact_email ||
        response.organization_phone ||
        response.office_name ||
        response.office_country ||
        response.office_city ||
        response.office_zip_code ||
        response.address_line_1 ||
        response.address_line_2 ||
        response.office_phone ||
        response.office_email
      ) {
        const keys = [
          'email',
          'user_type',
          'organization_name',
          'organization_country_residence',
          'organization_address',
          'organization_contact_email',
          'organization_phone',
        ]

        if (
          response.email ||
          response.user_type ||
          response.organization_name ||
          response.organization_country_residence ||
          response.organization_address ||
          response.organization_contact_email ||
          response.organization_phone
        ) {
          setServerError([])
          Object.keys(response).forEach((key) =>
            keys.includes(key)
              ? setServerError((p) =>
                response[key] instanceof Array
                  ? [...p, ...response[key]]
                  : [...p, response[key]]
              )
              : null
          )
        }
        Object.keys(response).forEach((key) =>
          formikTwo.setFieldError(
            key,
            response[key] instanceof Array ? response[key][0] : response[key]
          )
        )
      } else if (response.error === 'User with this email already exists') {
        setStep(1)
        formikOne.setErrors({
          email: 'User with this email already exists',
        })
      } else if (response.error === 'Organization with this name already exists') {
        setStep(1)
        formikOne.setErrors({
          organization_name: 'Organization with this name already exists',
        })
      } else if (response.error) {
        setServerError(['Something went wrong'])
      } else if (response) {
        setStep((prev) => prev + 1)
        refetch()
      }
    },
  })

  switch (step) {
  case 1:
    return (
      <Modal title="Add new organization" closeModal={closeModal} notCloseOnBlur>
        <div className="Modal__Scroll-Content d-flex flex-column gap-3 pt-3">
          <Input
            label="Name"
            name="organization_name"
            placeholder="Name"
            value={formikOne.values.organization_name}
            onChange={formikOne.handleChange}
            error={formikOne.errors.organization_name}
          />
          <SearchInput
            label="Country"
            name="organization_country_residence"
            placeholder="Country"
            search={countries}
            value={Number(formikOne.values.organization_country_residence)}
            onChange={(id) =>
              formikOne.setFieldValue('organization_country_residence', id)
            }
            error={formikOne.errors.organization_country_residence}
          />
          <Input
            label="Address"
            name="organization_address"
            placeholder="Address"
            value={formikOne.values.organization_address}
            onChange={formikOne.handleChange}
            error={formikOne.errors.organization_address}
          />
          <Input
            label="Administrator account email <span class='ms-2 text-6 fw-light text-secondary'>An invite will be sent by email</span>"
            name="email"
            type="email"
            placeholder="someone@gmail.com"
            value={formikOne.values.email}
            onChange={formikOne.handleChange}
            error={formikOne.errors.email}
          />
          <Input
            label="Contact email <span class='ms-2 text-6 fw-light text-secondary'>Optional (if different from administrator account email)</span>"
            name="organization_contact_email"
            type="email"
            placeholder="someone@gmail.com"
            value={formikOne.values.organization_contact_email}
            onChange={formikOne.handleChange}
            error={formikOne.errors.organization_contact_email}
          />

          <PhoneNumberInput
            label="Phone number"
            name="organization_phone"
            value={formikOne.values.organization_phone}
            onChange={(value) =>
              formikOne.setFieldValue('organization_phone', value)
            }
            errors={formikOne.errors.organization_phone}
          />

          <div className="d-flex flex-column align-items-center gap-2">
            <SwitchTransition>
              <CSSTransition
                key={formikOne.isSubmitting}
                timeout={300}
                unmountOnExit
              >
                <button
                  className="button-blue gap-0 w-100"
                  type="submit"
                  onClick={() => formikOne.handleSubmit()}
                  disabled={formikOne.isSubmitting}
                >
                  {formikOne.isSubmitting ? (
                    <>
                      <span>Loading</span>
                      <LoadingDots className="enter-done" />
                    </>
                  ) : (
                    <span>Continue</span>
                  )}
                </button>
              </CSSTransition>
            </SwitchTransition>
          </div>
          <div className="AuthCard__Steps">
            <div className="Radio Selected"></div>
            <div className="Radio"></div>
          </div>
        </div>
      </Modal>
    )
  case 2:
    return (
      <Modal
        title={'Add new office'}
        prevStep={() => setStep((p) => p - 1)}
        closeModal={closeModal}
        notCloseOnBlur
      >
        <div className="Modal__Scroll-Content d-flex flex-column gap-3 pt-3">
          <Input
            label="Name"
            name="office_name"
            value={formikTwo.values.office_name}
            onChange={formikTwo.handleChange}
            error={formikTwo.errors.office_name}
          />
          <div className="d-flex gap-2">
            <SearchInput
              label="Country"
              name="office_country"
              placeholder="Country"
              search={countries}
              value={Number(formikTwo.values.office_country)}
              onChange={(id) => formikTwo.setFieldValue('office_country', id)}
              error={formikTwo.errors.office_country}
            />
            <Input
              label="City"
              name="office_city"
              value={formikTwo.values.office_city}
              onChange={formikTwo.handleChange}
              error={formikTwo.errors.office_city}
            />
            <Input
              label="Zip code"
              name="office_zip_code"
              value={formikTwo.values.office_zip_code}
              onChange={formikTwo.handleChange}
              error={formikTwo.errors.office_zip_code}
              containerProps={{ style: { maxWidth: '100px' } }}
            />
          </div>
          <Input
            label="Address line 1"
            name="address_line_1"
            value={formikTwo.values.address_line_1}
            onChange={formikTwo.handleChange}
            error={formikTwo.errors.address_line_1}
          />
          <Input
            label="Address line 2 <span class='ms-2 text-6 fw-light text-secondary'>Optional</span>"
            name="address_line_2"
            value={formikTwo.values.address_line_2}
            onChange={formikTwo.handleChange}
            error={formikTwo.errors.address_line_2}
          />

          <Input
            label="Contact email"
            name="office_email"
            type="email"
            placeholder="someone@gmail.com"
            value={formikTwo.values.office_email}
            onChange={formikTwo.handleChange}
            error={formikTwo.errors.office_email}
          />

          <PhoneNumberInput
            label="Phone number"
            name="office_phone"
            value={formikTwo.values.office_phone}
            onChange={(value) =>
              formikTwo.setFieldValue('office_phone', value)
            }
            errors={formikTwo.errors.office_phone}
          />

          <div className="d-flex flex-column gap-2 w-100">
            <p className="text-5 fw-medium">
              Mark this office as Head Quarter’s location?
            </p>
            <button className="d-flex gap-2 opacity-50">
              <div className="Checkbox Selected text-opacity-50" />
              <p className="text-6">Yes</p>
            </button>
          </div>

          {serverError.length !== 0 ? (
            <p className="text-red">{serverError[0]}</p>
          ) : (
            <></>
          )}

          <div className="d-flex flex-column align-items-center gap-2">
            <button
              className="button-blue gap-0 w-100"
              type="submit"
              onClick={() => formikTwo.handleSubmit()}
              disabled={formikTwo.isSubmitting}
            >
              {formikTwo.isSubmitting ? (
                <>
                  <span>Creating</span>
                  <LoadingDots className="enter-done" />
                </>
              ) : (
                <span>Create organization & add office</span>
              )}
            </button>
          </div>
          <div className="AuthCard__Steps">
            <div className="Radio Selected"></div>
            <div className="Radio Selected"></div>
          </div>
        </div>
      </Modal>
    )

  default:
    return (
      <CheckModal
        closeModal={closeModal}
        title="Add new organization & add new office"
        message="¡Organization & office added succesfully!"
      />
    )
  }
}

export default NewOrganizationModal
