import classNames from 'classnames'
import { Link, LinkProps } from 'react-router-dom'
import { ButtonSizes, ButtonVariants } from './Button'

interface Props extends LinkProps {
  variant?: ButtonVariants;
  size?: ButtonSizes;
  active?: boolean;
}

export default function ButtonLink({
  to,
  className,
  variant = 'blue',
  size = 'm',
  active,
  children,
  ...props
}: Props) {
  return (
    <Link
      to={to}
      className={classNames('Button', variant, size, { active }, className)}
      {...props}
    >
      {children}
    </Link>
  )
}
