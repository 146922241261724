import { useEffect, useState } from 'react'
import { useLocation, Outlet, useNavigate } from 'react-router'
import useAppContext from '../hooks/useAppContext'
import useNewSimulationContext from '../hooks/useNewSimulationContext'
import useModalsContext from '../hooks/useModalsContext'
import SimulationFooter from '../components/SimulationFooter'
import SimulationSteps from '../components/SimulationSteps'
import DashboardHeader from '../containers/DashboardHeader'
import NewClientModal from '../components/modals/NewClientModal'
import NewProfileModal from '../components/modals/NewProfileModal'
import ManageProfilesModal from '../components/modals/ManageProfilesModal'
import NewSystemModal from '../components/modals/NewSystemModal'
import ManageSystemModal from '../components/modals/ManageSystemModal'
import NewModelModal from '../components/modals/NewModelModal'
import SimulationModal from '../components/modals/SimulationModal'
import ManageModelsModal from '../components/modals/ManageModelsModal'
import ManagePVModelModal from '../components/modals/ManagePVModelModal'
import NewPVModelModal from '../components/modals/NewPVModelModal'
import ContinueSimulationModal from '../components/modals/ContinueSimulationModal'
import PaperIcon from '../assets/icons/paper.svg'
import SimulationStepsMobile from '../components/SimulationStepsMobile'
import Loader from '../components/Loader'

const NewSimulationLayout = () => {
  const {
    token,
    firstCharge,
    user,
    userIsLoading,
    showNewClientModal,
    showSimulationModal,
    toggleShowSimulationModal,
  } = useAppContext()
  const { isLoading, newSimulation, setSimulationId } = useNewSimulationContext()
  const {
    showContinueSimulationModal,
    showManageProfilesModal,
    showNewProfileModal,
    showManageSystemModal,
    showNewSystemModal,
    showManageModelsModal,
    showNewModelModal,
    showManagePVModelsModal,
    showNewPVModelModal,
  } = useModalsContext()
  const [ firstSimulationLoading, setFirstSimulationLoading ] = useState(true)
  const location = useLocation()
  const navigate = useNavigate()

  // Redirect to login if token is not present
  useEffect(() => {
    if (!firstCharge && !token) {
      navigate('/login')
    }

    // if (
    //   !isCentralAdmin &&
    //   !firstCharge &&
    //   token.length !== 0 &&
    //   user?.region?.name.length === 0
    // )
    //   navigate('/')
  }, [token, firstCharge, user])

  // Redirect depending on the simulation status
  useEffect(() => {
    if(!isLoading) {
      if(newSimulation.status === 'ready_to_run' && (!location.pathname.includes('/project-summary') && !location.pathname.includes('/running-simulation'))) {
        navigate('/dashboard/create-simulation/project-summary')
      }
      if(newSimulation.status === 'running') {
        navigate('/dashboard/create-simulation/running-simulation')
      }
      if(newSimulation.status === 'finished' && (!location.pathname.includes('/running-complete') && !location.pathname.includes('/upload-report') && !location.pathname.includes('/generate-report'))) {
        navigate('/dashboard/create-simulation/running-complete')
      }
    }
  }, [isLoading, location.pathname, newSimulation.status])

  // Redirect to current step
  useEffect(() => {
    if(firstSimulationLoading && !isLoading && location.pathname.includes('step') && newSimulation.client.id) {
      if(newSimulation.current_step) {
        navigate(`/dashboard/create-simulation/step/${newSimulation.current_step}`, {
          replace: true,
        })
      } else {
        navigate('/dashboard/create-simulation/step/general-information')
      }
      setFirstSimulationLoading(false)
    }
    // else if(!isLoading && !newSimulation.current_step) {
    //   navigate('/dashboard/create-simulation/step/general-information')
    // }

  }, [isLoading, newSimulation.client])

  // Scroll to top when page is loaded
  useEffect(() => {
    window.scrollTo(0, 0)

    const stepPath = location.pathname.split('/').pop()
    if (!stepPath) {
      navigate('/dashboard/create-simulation/step/general-information')
    }
  }, [location])


  if(firstCharge || (!user && userIsLoading)) return (
    <main className="d-flex flex-column justify-content-center align-items-center gap-4 w-100 h-screen">
      <Loader />
    </main>
  )

  if(!location.pathname.includes('step')) return (
    <>
      <DashboardHeader />

      <div className="finish-simulation-content">
        <Outlet />
      </div>
    </>
  )

  return (
    <>
      <DashboardHeader />
      {/* Outlet vendría a ser el contenido de la página */}
      <main className="create-simulation-main">
        <SimulationSteps />
        <SimulationStepsMobile />

        <section className="create-simulation-content">
          <Outlet />
        </section>

        <SimulationFooter />
      </main>

      {showContinueSimulationModal && <ContinueSimulationModal />}
      {showManageProfilesModal && <ManageProfilesModal />}
      {showNewClientModal && <NewClientModal />}
      {showNewProfileModal && <NewProfileModal />}
      {showManageSystemModal && <ManageSystemModal />}
      {showNewSystemModal && <NewSystemModal />}
      {showManageModelsModal && <ManageModelsModal />}
      {showNewModelModal && <NewModelModal />}
      {showManagePVModelsModal ? <ManagePVModelModal /> : <></>}
      {showNewPVModelModal && <NewPVModelModal />}
      {showSimulationModal && user?.id == 3 && <SimulationModal />}

      {user?.id == 3 && (
        <button
          className="SimulationModal__Button"
          onClick={toggleShowSimulationModal}
        >
          <img
            src={PaperIcon}
            alt="Subsidy & tax benefits"
            title="Subsidy & tax benefits"
          />
        </button>
      )}
    </>
  )
}

export default NewSimulationLayout
