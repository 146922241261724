import { Dispatch, SetStateAction } from 'react'
import { Page, PageSingle } from '../types/generateReport'
import { v4 as uuidv4 } from 'uuid'

type Props = {
  setPages: Dispatch<SetStateAction<Array<Page>>>;
  pageSelectedIndexs: {
    pageIndex: number;
    subPageIndex: number;
  };
  setIdSelected: Dispatch<SetStateAction<string>>;
};

const pageUpdater = ({
  pages,
  pageSelectedIndexs,
  pageHandler,
}: {
  pages: Array<Page>;
  pageSelectedIndexs: {
    pageIndex: number;
    subPageIndex: number;
  };
  pageHandler: (sections: PageSingle) => PageSingle;
}): Array<Page> => {
  const { pageIndex, subPageIndex } = pageSelectedIndexs

  const page = pages[pageIndex]

  if (!page) return pages

  if (page.type === 'single') {
    return [
      ...pages.slice(0, pageIndex),
      pageHandler(page),
      ...pages.slice(pageIndex + 1),
    ]
  }

  const subPage = page.subPages[subPageIndex]

  if (!subPage) return pages

  const newSubPages = [
    ...page.subPages.slice(0, subPageIndex),
    pageHandler(subPage),
    ...page.subPages.slice(subPageIndex + 1),
  ]

  return [
    ...pages.slice(0, pageIndex),
    {
      ...page,
      subPages: newSubPages,
    },
    ...pages.slice(pageIndex + 1),
  ]
}

export default function usePageHandlers({
  setPages,
  pageSelectedIndexs,
  setIdSelected,
}: Props) {
  const handleAddPage = () => {
    const newPageID = uuidv4()
    setPages((prev) => [
      ...prev,
      {
        id: newPageID,
        label: 'New page',
        title: 'New page',
        active: true,
        subPagesCount: 1,
        type: 'single',
        sections: [
          {
            id: uuidv4(),
            title: 'Add image',
            active: true,
            type: 'image_select',
            style: {
              width: '100%',
            },
            placeholder: 'Upload image',
            value: null,
          },
          {
            id: uuidv4(),
            title: 'Title',
            active: true,
            type: 'text_conf',
            placeholder: 'Write a title',
            value: '',
          },
          {
            id: uuidv4(),
            title: 'Text',
            active: true,
            type: 'text_conf',
            placeholder: 'Write a text',
            value: '',
          },
        ],
        removable: true,
      },
    ])
    setIdSelected(newPageID)
    setTimeout(() => {
      const element = document.getElementById('PageId:' + newPageID)
      element?.scrollIntoView({ behavior: 'smooth' })
    })
  }

  const handleDeletePage = (pageId: string) => {
    setPages((prev) => {
      const pageIndex = prev.findIndex((page) => page.id === pageId)
      if (pageIndex < 0) return prev

      const newPages = [
        ...prev.slice(0, pageIndex),
        ...prev.slice(pageIndex + 1),
      ]
      setIdSelected(newPages[0].id)
      return newPages
    })
  }

  const handleTogglePage = (id: string, subId?: string) => {
    setPages((pages) => {
      const index = pages.findIndex((page) => page.id === id)

      const page = pages[index]

      if (!page) return pages

      if (page.type === 'single' || !subId) {
        return [
          ...pages.slice(0, index),
          {
            ...page,
            active: !page.active,
          },
          ...pages.slice(index + 1),
        ]
      }

      const subPageIndex = page.subPages.findIndex(
        (subItem) => subItem.id === subId
      )

      const subPage = page.subPages[subPageIndex]

      if (!subPage) return pages

      return [
        ...pages.slice(0, index),
        {
          ...pages[index],
          subPages: [
            ...page.subPages.slice(0, subPageIndex),
            {
              ...subPage,
              active: !subPage.active,
            },
            ...page.subPages.slice(subPageIndex + 1),
          ],
        },
        ...pages.slice(index + 1),
      ]
    })
  }

  const updatePageTitle = (value: string) => {
    setPages((pages) => {
      return pageUpdater({
        pages,
        pageSelectedIndexs,
        pageHandler: (page) => ({
          ...page,
          title: value,
        }),
      })
    })
  }

  const updatePageImage = (value: string | null) => {
    setPages((pages) => {
      return pageUpdater({
        pages,
        pageSelectedIndexs,
        pageHandler: (page) => ({
          ...page,
          image: value,
        }),
      })
    })
  }

  const updatePageSubPagesCount = (value: number) => {
    setPages((pages) => {
      return pageUpdater({
        pages,
        pageSelectedIndexs,
        pageHandler: (page) => ({
          ...page,
          subPagesCount: value,
        }),
      })
    })
  }

  return {
    handleAddPage,
    handleDeletePage,
    handleTogglePage,
    updatePageTitle,
    updatePageImage,
    updatePageSubPagesCount,
  }
}
