import useReportContext from '../../hooks/useReportContext'
import { SectionExpirationDate } from '../../types/generateReport'
import { handleShowPicker } from '../../utils/handleShowPicker'
import InputDebounce from '../InputDebounce'

type Props = {
  section: SectionExpirationDate;
};

export default function ExpirationDateCard({ section }: Props) {
  const { handleUpdateExpirationDate } = useReportContext()

  return (
    <InputDebounce
      name={section.id}
      value={section.value}
      type="date"
      onClick={(e) => handleShowPicker(e)}
      onDebounce={(value) => handleUpdateExpirationDate(section.id, value)}
    />
  )
}
