import { deleteQuery, getQuery, patchQuery, postQuery } from '../../apiFunctions'

export const getPVField = async (token, simulation_id) => {
  return getQuery<any>({
    path: `/simulations/pv-field/${simulation_id}/`,
    token,
    callback: data => data.data
  })
}

export const addPVField = async (token, simulation_id, body) => {
  return postQuery<any>({
    path: `/simulations/pv-field/${simulation_id}/`,
    token,
    body
  })
}

export const updatePVField = async (token, simulation_id, body) => {
  return patchQuery<any>({
    path: `/simulations/pv-field/${simulation_id}/`,
    token,
    body
  })
}

// PV models
export const getPVModels = async (token: string, simulation_id?: number) => {
  return getQuery<any>({
    path: '/simulations/pv-model-panel-parameters/',
    token,
    params: {
      simulation_id
    },
    callback: data => data.data
  })
}

export const createPVModel = async (token, body) => {
  return postQuery<any>({
    path: '/simulations/pv-model-panel-parameters/',
    token,
    body
  })
}

export const getPVModelDetail = async (token, profile_id) => {
  return getQuery<any>({
    path: `/simulations/pv-model-panel-parameters/${profile_id}/`,
    token,
    callback: data => data.data
  })
}

export const updatePVModel = async (token, profile_id, body) => {
  return patchQuery<any>({
    path: `/simulations/pv-model-panel-parameters/${profile_id}/`,
    token,
    body
  })
}

export const deletePVModel = async (token, profile_id) => {
  return deleteQuery<any>({
    path: `/simulations/pv-model-panel-parameters/${profile_id}/`,
    token
  })
}