import { useEffect } from 'react'
import { useLocation, Outlet, useNavigate } from 'react-router'
import useAppContext from '../hooks/useAppContext'
import Header from '../containers/Header'
import NewClientModal from '../components/modals/NewClientModal'
import ErrorBoundary from './ErrorBoundary'
import Loader from '../components/Loader'

const Layout = () => {
  const { token, firstCharge, user, userIsLoading, showNewClientModal } =
    useAppContext()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!firstCharge && !token) navigate('/login')
  }, [token, firstCharge])

  useEffect(() => {
    if (!firstCharge && token && user?.error) navigate('/')
    if (!firstCharge && token && !user?.first_name && !userIsLoading) {
      navigate('/about-you')
    }
  }, [user])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <ErrorBoundary>
      {firstCharge || (!user && userIsLoading) ? (
        <main className="d-flex flex-column justify-content-center align-items-center gap-4 w-100 h-screen">
          <Loader />
        </main>
      ) : (
        <>
          <Header />
          {/* Outlet vendría a ser el contenido de la página */}
          <Outlet />

          {showNewClientModal && <NewClientModal />}
        </>
      )}
    </ErrorBoundary>
  )
}

export default Layout
