import { SVGProps } from 'react'
const SvgICalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.1 8c.292 0 .572.105.778.293A.956.956 0 0 1 15.2 9v1h17.6V9c0-.265.116-.52.322-.707.206-.188.486-.293.778-.293.292 0 .572.105.778.293A.956.956 0 0 1 35 9v1h2.2c1.167 0 2.286.421 3.111 1.172.825.75 1.289 1.767 1.289 2.828v22c0 1.06-.464 2.078-1.289 2.828S38.367 40 37.2 40H10.8c-1.167 0-2.286-.421-3.111-1.172C6.864 38.078 6.4 37.061 6.4 36V14c0-1.06.464-2.078 1.289-2.828S9.633 10 10.8 10H13V9c0-.265.116-.52.322-.707.207-.188.486-.293.778-.293Zm-5.5 8v20c0 .53.232 1.04.644 1.414.413.375.973.586 1.556.586h26.4c.583 0 1.143-.21 1.556-.586.412-.375.644-.884.644-1.414V16H8.6Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgICalendar
