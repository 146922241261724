import { useSortable } from '@dnd-kit/sortable'
import { Page } from '../../types/generateReport'
import { CSS } from '@dnd-kit/utilities'
import { IMove } from '../../iconComponents'
import useReportContext from '../../hooks/useReportContext'
import classNames from 'classnames'
import Switch from '../Switch'
import PagesSortSubOverlay from './PagesSortSubOverlay'

type Props = {
  page: Page;
};

export default function PagesSortOverlay({ page }: Props) {
  const { idSelected } = useReportContext()
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: page.id })

  const style: React.CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  }

  return (
    <div
      className="PagesSort__Item Overlay"
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <div
        className={classNames('PagesSort__Item_Header Overlay', {
          Active:
            page.id === idSelected ||
            (page.type === 'container' &&
              page.subPages.some((subpage) => subpage.id === idSelected)),
        })}
      >
        <div className="PagesSort__Item_Header_Icon" {...listeners}>
          <IMove width={24} height={24} />
        </div>
        <p className="text-5 PagesSort__Item_Header_text">{page.label}</p>
        <div className='PagesSort__Item_Header_Switch'>
          <Switch
            active={page.active}
          />
        </div>
      </div>
      {page.type === 'container' && page.subPages.map((subPage) => (
        <PagesSortSubOverlay
          key={subPage.id}
          subPage={subPage}
        />
      ))}
    </div>
  )
}
