import classNames from 'classnames'
import ErrorIcon from '../assets/icons/warning-red.svg'
import WarningIcon from '../assets/icons/warning-yellow.svg'

interface Props {
  label: string;
  subLabel?: string;
  options: Array<{ label: string; value: string | boolean | number }>;
  optionSelected: any;
  small?: boolean;
  handleSelect: (value: any) => void;
  error?: any;
  warning?: any;
  disabled?: boolean;
}

export default function Checkboxes({
  label,
  subLabel,
  options,
  optionSelected,
  small,
  handleSelect,
  error,
  warning,
  disabled,
}: Props) {
  return (
    <div className="Checkboxes__Container d-flex flex-column gap-2">
      <div className="d-flex align-items-center flex-wrap gap-2">
        {error && (
          <div className="Input__Error-Icon-Container">
            <img className="Input__Error-Icon" src={ErrorIcon} />
          </div>
        )}
        {warning && !error && (
          <div className="Input__Warning-Icon-Container">
            <img className="Input__Warning-Icon" src={WarningIcon} />
          </div>
        )}
        <h2 className="text-4 fw-medium">{label}</h2>
        {subLabel && (
          <h3 className="text-5 text-secondary fw-normal">{subLabel}</h3>
        )}
      </div>
      <div className={classNames('Checkboxes', { disabled, small })}>
        {options.map((option) => (
          <button
            key={`Checkboxes-Option-${option.value}`}
            className="Checkboxes__Option"
            onClick={() => !disabled && handleSelect(option.value)}
          >
            <div className="Checkboxes__Circle">
              <span
                className={classNames('Checkboxes__Check', {
                  active: option.value === optionSelected,
                })}
              />
            </div>
            <span className="text-5 fw-medium">{option.label}</span>
          </button>
        ))}
      </div>
      {error && <span className="Input__Error text-6">{error}</span>}

      {warning && !error && (
        <span className="Input__Warning text-6">{warning}</span>
      )}
    </div>
  )
}
