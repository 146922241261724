import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { addSystemCosting, updateSystemCosting } from '../../api/simulations/financial-parameters/system-costing'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import CreateSimulationContainer from '../../containers/CreateSimulationContainer'
import SimulationFormikHandler from '../../containers/SimulationFormikHandler'
import CreateSimulationTitle from '../../components/CreateSimulationTitle'
import InputSmall from '../../components/InputSmall'
import { submitStep } from '../../utils/submitStep'
import MoneyIcon from '../../assets/icons/money.svg'
import { normalizeNumberWithCommas, normalizePercentToSend, normalizePercentToShow, threeDecimalRegex } from '../../constants'

const SystemCosting = () => {
  const { token } = useAppContext()
  const { newSimulation, updateNewSimulation, setIsStepLoading } = useNewSimulationContext()

  const yupNumberValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('min', 'Min: 0', value => value >= 0)
    .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))

  const yupIntegerValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('min', 'Min: 0', value => value >= 0)
    .test('integer', 'Only integers', value => Number.isInteger(Number(value)))

  const yupPercentageValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('min', 'Min: 0%', value => value >= 0)
    // .test('max', 'Max: 100%', value => value <= 100)
    .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))

  const formik = useFormik({
    initialValues: {
      currency_conversion_factor: 0,
      total_PVT_system_cost: 0,
      total_thermal_system_cost: 0,
      total_PV_system_cost: 0,
      total_auxiliary_system_cost: 0,
      other_costs: 0,
      contingency_project: 0,
      contingency_civil: 0,
      margin_for_third_party: 0,
      discount: 0,
      O_and_M_PVT_cost: 0,
      O_and_M_thermal_cost: 0,
      O_and_M_PV_cost: 0,
      O_and_M_auxiliary_cost: 0,
      system_insurance: 0,
      monitoring_and_metering_charges: 0,
      year_of_solar_equipment_replacement: 0,
      replacement_PVT_system_cost: 0,
      replacement_thermal_system_cost: 0,
      replacement_PV_system_cost: 0,
      year_of_auxilairy_system_replacement: 0,
      replacement_auxiliary_heaying_system_cost: 0,
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      currency_conversion_factor: yupNumberValidation,
      total_PVT_system_cost: yupNumberValidation,
      total_thermal_system_cost: yupNumberValidation,
      total_PV_system_cost: yupNumberValidation,
      total_auxiliary_system_cost: yupNumberValidation,
      other_costs: yupNumberValidation,
      contingency_project: yupNumberValidation,
      contingency_civil: yupNumberValidation,
      margin_for_third_party: yupPercentageValidation,
      discount: yupPercentageValidation,
      O_and_M_PVT_cost: yupNumberValidation,
      O_and_M_thermal_cost: yupNumberValidation,
      O_and_M_PV_cost: yupNumberValidation,
      O_and_M_auxiliary_cost: yupNumberValidation,
      system_insurance: yupPercentageValidation,
      monitoring_and_metering_charges: yupNumberValidation,
      year_of_solar_equipment_replacement: yupIntegerValidation,
      replacement_PVT_system_cost: yupNumberValidation,
      replacement_thermal_system_cost: yupNumberValidation,
      replacement_PV_system_cost: yupNumberValidation,
      year_of_auxilairy_system_replacement: yupIntegerValidation,
      replacement_auxiliary_heaying_system_cost: yupNumberValidation,
    }),
    onSubmit: async (values) => {
      const newValues = {
        currency_conversion_factor: normalizeNumberWithCommas(values.currency_conversion_factor),
        total_PVT_system_cost: normalizeNumberWithCommas(values.total_PVT_system_cost),
        total_thermal_system_cost: normalizeNumberWithCommas(values.total_thermal_system_cost),
        total_PV_system_cost: normalizeNumberWithCommas(values.total_PV_system_cost),
        total_auxiliary_system_cost: normalizeNumberWithCommas(values.total_auxiliary_system_cost),
        other_costs: normalizeNumberWithCommas(values.other_costs),
        contingency_project: normalizeNumberWithCommas(values.contingency_project),
        contingency_civil: normalizeNumberWithCommas(values.contingency_civil),
        margin_for_third_party: normalizePercentToSend(normalizeNumberWithCommas(values.margin_for_third_party)),
        discount: normalizePercentToSend(normalizeNumberWithCommas(values.discount)),
        O_and_M_PVT_cost: normalizeNumberWithCommas(values.O_and_M_PVT_cost),
        O_and_M_thermal_cost: normalizeNumberWithCommas(values.O_and_M_thermal_cost),
        O_and_M_PV_cost: normalizeNumberWithCommas(values.O_and_M_PV_cost),
        O_and_M_auxiliary_cost: normalizeNumberWithCommas(values.O_and_M_auxiliary_cost),
        system_insurance: normalizePercentToSend(normalizeNumberWithCommas(values.system_insurance)),
        monitoring_and_metering_charges: normalizeNumberWithCommas(values.monitoring_and_metering_charges),
        year_of_solar_equipment_replacement: normalizeNumberWithCommas(values.year_of_solar_equipment_replacement),
        replacement_PVT_system_cost: normalizeNumberWithCommas(values.replacement_PVT_system_cost),
        replacement_thermal_system_cost: normalizeNumberWithCommas(values.replacement_thermal_system_cost),
        replacement_PV_system_cost: normalizeNumberWithCommas(values.replacement_PV_system_cost),
        year_of_auxilairy_system_replacement: normalizeNumberWithCommas(values.year_of_auxilairy_system_replacement),
        replacement_auxiliary_heaying_system_cost: normalizeNumberWithCommas(values.replacement_auxiliary_heaying_system_cost),
      }

      await submitStep({
        pathname: location.pathname,
        lastStep: newSimulation.last_step,
        addFunction: () => addSystemCosting(token, newSimulation.id, newValues),
        updateFunction: () => updateSystemCosting(token, newSimulation.id, newValues),
        updateNewSimulation,
        setIsStepLoading
      })
    }
  })

  // Show saved simulation step
  useEffect(() => {
    formik.setValues({
      currency_conversion_factor: newSimulation.steps.system_costing.currency_conversion_factor,
      total_PVT_system_cost: newSimulation.steps.system_costing.total_PVT_system_cost,
      total_thermal_system_cost: newSimulation.steps.system_costing.total_thermal_system_cost,
      total_PV_system_cost: newSimulation.steps.system_costing.total_PV_system_cost,
      total_auxiliary_system_cost: newSimulation.steps.system_costing.total_auxiliary_system_cost,
      other_costs: newSimulation.steps.system_costing.other_costs,
      contingency_project: newSimulation.steps.system_costing.contingency_project,
      contingency_civil: newSimulation.steps.system_costing.contingency_civil,
      margin_for_third_party: Number(normalizePercentToShow(newSimulation.steps.system_costing.margin_for_third_party)),
      discount: Number(normalizePercentToShow(newSimulation.steps.system_costing.discount)),
      O_and_M_PVT_cost: newSimulation.steps.system_costing.O_and_M_PVT_cost,
      O_and_M_thermal_cost: newSimulation.steps.system_costing.O_and_M_thermal_cost,
      O_and_M_PV_cost: newSimulation.steps.system_costing.O_and_M_PV_cost,
      O_and_M_auxiliary_cost: newSimulation.steps.system_costing.O_and_M_auxiliary_cost,
      system_insurance: Number(normalizePercentToShow(newSimulation.steps.system_costing.system_insurance)),
      monitoring_and_metering_charges: newSimulation.steps.system_costing.monitoring_and_metering_charges,
      year_of_solar_equipment_replacement: newSimulation.steps.system_costing.year_of_solar_equipment_replacement,
      replacement_PVT_system_cost: newSimulation.steps.system_costing.replacement_PVT_system_cost,
      replacement_thermal_system_cost: newSimulation.steps.system_costing.replacement_thermal_system_cost,
      replacement_PV_system_cost: newSimulation.steps.system_costing.replacement_PV_system_cost,
      year_of_auxilairy_system_replacement: newSimulation.steps.system_costing.year_of_auxilairy_system_replacement,
      replacement_auxiliary_heaying_system_cost: newSimulation.steps.system_costing.replacement_auxiliary_heaying_system_cost,
    })
  }, [newSimulation])

  return (
    <SimulationFormikHandler formik={formik}>
      <Helmet>
        <title>Create Simulation: System Costing</title>
      </Helmet>

      <CreateSimulationContainer>
        <header className="d-flex align-items-center flex-wrap gap-2">
          <img
            src={MoneyIcon}
            className="CreateSimulation__Title-Icon"
            alt='System costing'
            title='System costing'
          />

          <CreateSimulationTitle>System costing</CreateSimulationTitle>
          <p className='text-4 fw-normal'>All the field must be excluding vat</p>
        </header>

        <InputSmall
          topLabel='Currency conversor factor'
          name='currency_conversion_factor'
          value={formik.values.currency_conversion_factor}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.currency_conversion_factor}
        />

        <InputSmall
          topLabel='Total PVT system cost'
          rightLabel={newSimulation.steps.general_information.currency_symbol}
          name='total_PVT_system_cost'
          value={formik.values.total_PVT_system_cost}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.total_PVT_system_cost}
        />

        <InputSmall
          topLabel='Total solar thermal system cost'
          rightLabel={newSimulation.steps.general_information.currency_symbol}
          name='total_thermal_system_cost'
          value={formik.values.total_thermal_system_cost}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.total_thermal_system_cost}
        />

        <InputSmall
          topLabel='Total PV system cost'
          rightLabel={newSimulation.steps.general_information.currency_symbol}
          name='total_PV_system_cost'
          value={formik.values.total_PV_system_cost}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.total_PV_system_cost}
        />

        <InputSmall
          topLabel='Total auxiliary system cost'
          rightLabel={newSimulation.steps.general_information.currency_symbol}
          name='total_auxiliary_system_cost'
          value={formik.values.total_auxiliary_system_cost}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.total_auxiliary_system_cost}
        />

        <InputSmall
          topLabel='Other costs'
          rightLabel={newSimulation.steps.general_information.currency_symbol}
          name='other_costs'
          value={formik.values.other_costs}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.other_costs}
        />

        <InputSmall
          topLabel='Contingency included in the project'
          rightLabel={newSimulation.steps.general_information.currency_symbol}
          name='contingency_project'
          value={formik.values.contingency_project}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.contingency_project}
        />

        <InputSmall
          topLabel='Contingency for civil works & others'
          rightLabel={newSimulation.steps.general_information.currency_symbol}
          name='contingency_civil'
          value={formik.values.contingency_civil}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.contingency_civil}
        />

        <InputSmall
          topLabel='Margin for third party'
          rightLabel='% of total costs'
          name='margin_for_third_party'
          value={formik.values.margin_for_third_party}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.margin_for_third_party}
        />

        <InputSmall
          topLabel='Discount'
          rightLabel='% of total costs'
          name='discount'
          value={formik.values.discount}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.discount}
        />

        <InputSmall
          topLabel='O&M costs of PVT'
          rightLabel={newSimulation.steps.general_information.currency_symbol}
          name='O_and_M_PVT_cost'
          value={formik.values.O_and_M_PVT_cost}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.O_and_M_PVT_cost}
        />

        <InputSmall
          topLabel='O&M costs of solar thermal'
          rightLabel={newSimulation.steps.general_information.currency_symbol}
          name='O_and_M_thermal_cost'
          value={formik.values.O_and_M_thermal_cost}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.O_and_M_thermal_cost}
        />

        <InputSmall
          topLabel='O&M costs of PV'
          rightLabel={newSimulation.steps.general_information.currency_symbol}
          name='O_and_M_PV_cost'
          value={formik.values.O_and_M_PV_cost}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.O_and_M_PV_cost}
        />

        <InputSmall
          topLabel='O&M costs of auxiliary heating system'
          rightLabel={newSimulation.steps.general_information.currency_symbol}
          name='O_and_M_auxiliary_cost'
          value={formik.values.O_and_M_auxiliary_cost}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.O_and_M_auxiliary_cost}
        />

        <InputSmall
          topLabel='System insurance'
          rightLabel='% of CAPEX per year'
          name='system_insurance'
          value={formik.values.system_insurance}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.system_insurance}
        />

        <InputSmall
          topLabel='Monitoring & metering charges'
          rightLabel={newSimulation.steps.general_information.currency_symbol}
          name='monitoring_and_metering_charges'
          value={formik.values.monitoring_and_metering_charges}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.monitoring_and_metering_charges}
        />

        <InputSmall
          topLabel='Year of solar equipment replacement'
          rightLabel='years'
          name='year_of_solar_equipment_replacement'
          value={formik.values.year_of_solar_equipment_replacement}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.year_of_solar_equipment_replacement}
        />

        <InputSmall
          topLabel='Replacement costs of PVT system'
          rightLabel={newSimulation.steps.general_information.currency_symbol}
          name='replacement_PVT_system_cost'
          value={formik.values.replacement_PVT_system_cost}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.replacement_PVT_system_cost}
        />

        <InputSmall
          topLabel='Replacement costs of Solar Thermal system'
          rightLabel={newSimulation.steps.general_information.currency_symbol}
          name='replacement_thermal_system_cost'
          value={formik.values.replacement_thermal_system_cost}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.replacement_thermal_system_cost}
        />

        <InputSmall
          topLabel='Replacement costs of PV system'
          rightLabel={newSimulation.steps.general_information.currency_symbol}
          name='replacement_PV_system_cost'
          value={formik.values.replacement_PV_system_cost}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.replacement_PV_system_cost}
        />

        <InputSmall
          topLabel='Year of auxiliary system replacement'
          rightLabel='years'
          name='year_of_auxilairy_system_replacement'
          value={formik.values.year_of_auxilairy_system_replacement}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.year_of_auxilairy_system_replacement}
        />

        <InputSmall
          topLabel='Replacement costs of auxiliary heating system'
          rightLabel={newSimulation.steps.general_information.currency_symbol}
          name='replacement_auxiliary_heaying_system_cost'
          value={formik.values.replacement_auxiliary_heaying_system_cost}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.replacement_auxiliary_heaying_system_cost}
        />
      </CreateSimulationContainer>
    </SimulationFormikHandler>
  )
}

export default SystemCosting