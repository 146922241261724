import {
  createContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react'
import { getUser } from '../api/auth'
import { GetClients, getClients } from '../api/clients'
import { emptyClients } from '../constants'
import {
  Client,
  Simulation,
  User,
  UserComplete,
  UserProfile,
} from '../api/apiTypes'
import { useQuery } from 'react-query'

interface AppContextInterface {
  firstCharge: boolean;

  token: string;
  setToken: Dispatch<SetStateAction<string>>;

  user: User & UserProfile & { error?: string };
  refreshUser: () => void;
  userIsLoading: boolean;

  isCentralAdmin: boolean;
  isOrganizationAdmin: boolean;
  isRegionAdmin: boolean;

  clients: GetClients;
  refreshClients: () => Promise<void>;

  simulationsToTransfer: Simulation[];
  setsimulationsToTransfer: Dispatch<SetStateAction<Simulation[]>>;

  userToTransfer: UserComplete[];
  setUserToTransfer: Dispatch<SetStateAction<UserComplete[]>>;

  userToDelete: UserComplete[];
  setUserToDelete: Dispatch<SetStateAction<UserComplete[]>>;

  simulationActionSelected: string;
  setSimulationActionSelected: Dispatch<SetStateAction<string>>;
  userActionSelected: string;
  setUserActionSelected: Dispatch<SetStateAction<string>>;

  showNewClientModal: boolean;
  toggleShowNewClientModal: () => void;
  newClientCallback: (client: Client) => void | null;
  setNewClientCallback:  Dispatch<SetStateAction<(client: Client) => void | null>>;
  refetch: () => void;
  setRefetch: Dispatch<SetStateAction<() => void>>;

  showSimulationModal: boolean; /// DELETE
  toggleShowSimulationModal: () => void; /// DELETE
}

export const AppContext = createContext<AppContextInterface | null>(null)

const AppContextProvider = ({ children }) => {
  // State to avoid deleting token from local storage on page reload
  const [firstCharge, setFirstCharge] = useState(true)
  const [token, setToken] = useState('')
  const {
    data: user,
    remove: removeUser,
    refetch: refreshUser,
    isLoading: userIsLoading,
  } = useQuery(['user'], () => getUser(token) || ({} as User & UserProfile), {
    enabled: token !== '',
  })
  const isCentralAdmin = useMemo(
    () => user?.user_type === 'central_admin',
    [user?.user_type]
  )
  const isOrganizationAdmin = useMemo(
    () => user?.user_type === 'organization_admin',
    [user?.user_type]
  )
  const isRegionAdmin = useMemo(
    () => user?.user_type === 'region_admin',
    [user?.user_type]
  )

  const [simulationsToTransfer, setsimulationsToTransfer] = useState<
    Simulation[]
  >([])
  const [userToTransfer, setUserToTransfer] = useState<UserComplete[]>([])
  const [userToDelete, setUserToDelete] = useState<UserComplete[]>([])
  const [simulationActionSelected, setSimulationActionSelected] = useState('')
  const [userActionSelected, setUserActionSelected] = useState('')

  const [clients, setClients] = useState<GetClients>(emptyClients)

  // Modals
  const [showNewClientModal, setShowNewClientModal] = useState(false)
  const [newClientCallback, setNewClientCallback] = useState<(client: Client) => void | null>(null)
  const [refetch, setRefetch] = useState<() => void>(null)
  const [showSimulationModal, setShowSimulationModal] = useState(false)

  // Check token and get user data in first charge
  useEffect(() => {
    checkToken()
    checkSimulationsToTransfer()
    checkUserToTransfer()
    checkUserToDelete()
    checkSimulationActionSelected()
    checkUserActionSelected()

    setFirstCharge(false)
  }, [])

  // Save and remove token in local storage
  useEffect(() => {
    const checkData = async () => {
      if (token) {
        localStorage.setItem('token', token)
        checkToken()
        await refreshUser()
      } else {
        localStorage.removeItem('token')
        removeUser()
      }
    }

    if (!firstCharge) {
      checkData()
    }
  }, [token])

  // Catch local storage changes
  useEffect(() => {
    function storageEventHandler(event) {
      if (!event.isTrusted) {
        if (event.currentTarget.localStorage.token && !token) {
          setToken(event.currentTarget.localStorage.token)
        } else if (token) {
          setToken('')
        }
      } else {
        if (event.key === 'token') {
          setToken(event.newValue)
        }
      }
    }

    window.addEventListener('storage', storageEventHandler)
  }, [])

  // Check simulationsToTransfer, userToTransfer, and userToDelete
  useEffect(() => {
    const checkData = async () => {
      if (!firstCharge) {
        if (simulationsToTransfer.length !== 0) {
          sessionStorage.setItem(
            'simulationsToTransfer',
            JSON.stringify(simulationsToTransfer)
          )
        } else sessionStorage.removeItem('simulationsToTransfer')
      }
    }

    checkData()
  }, [simulationsToTransfer])

  useEffect(() => {
    const checkData = async () => {
      if (!firstCharge) {
        if (userToTransfer.length !== 0) {
          sessionStorage.setItem(
            'userToTransfer',
            JSON.stringify(userToTransfer)
          )
        } else sessionStorage.removeItem('userToTransfer')
      }
    }

    checkData()
  }, [userToTransfer])

  useEffect(() => {
    const checkData = async () => {
      if (!firstCharge) {
        if (userToDelete.length !== 0) {
          sessionStorage.setItem('userToDelete', JSON.stringify(userToDelete))
        } else sessionStorage.removeItem('userToDelete')
      }
    }

    checkData()
  }, [userToDelete])

  useEffect(() => {
    const checkData = async () => {
      if (!firstCharge) {
        if (simulationActionSelected.length !== 0) {
          sessionStorage.setItem(
            'simulationActionSelected',
            JSON.stringify(simulationActionSelected)
          )
        } else sessionStorage.removeItem('simulationActionSelected')
      }
    }

    checkData()
  }, [simulationActionSelected])

  useEffect(() => {
    const checkData = async () => {
      if (!firstCharge) {
        if (userActionSelected.length !== 0) {
          sessionStorage.setItem(
            'userActionSelected',
            JSON.stringify(userActionSelected)
          )
        } else sessionStorage.removeItem('userActionSelected')
      }
    }

    checkData()
  }, [userActionSelected])

  // Get clients
  useEffect(() => {
    const getData = async () => {
      await refreshClients()
    }

    if (token && user?.first_name) {
      getData()
    }
  }, [token, user?.first_name])

  const checkToken = async () => {
    const savedToken = localStorage.getItem('token')

    if (savedToken) {
      setToken(savedToken)
    } else {
      setToken('')
    }
  }

  const checkSimulationsToTransfer = async () => {
    const savedSimulationsToTransfer: Simulation[] = JSON.parse(
      sessionStorage.getItem('simulationsToTransfer')
    )

    if (savedSimulationsToTransfer) {
      setsimulationsToTransfer(savedSimulationsToTransfer)
    }
  }

  const checkUserToTransfer = async () => {
    const savedUserToTransfer: UserComplete[] = JSON.parse(
      sessionStorage.getItem('userToTransfer')
    )

    if (savedUserToTransfer) {
      setUserToTransfer(savedUserToTransfer)
    }
  }

  const checkUserToDelete = async () => {
    const savedUserToDelete: UserComplete[] = JSON.parse(
      sessionStorage.getItem('userToDelete')
    )

    if (savedUserToDelete) {
      setUserToDelete(savedUserToDelete)
    }
  }

  const checkSimulationActionSelected = async () => {
    const savedSimulationActionSelected: string = JSON.parse(
      sessionStorage.getItem('simulationActionSelected')
    )

    if (savedSimulationActionSelected) {
      setSimulationActionSelected(savedSimulationActionSelected)
    }
  }

  const checkUserActionSelected = async () => {
    const savedUserActionSelected: string = JSON.parse(
      sessionStorage.getItem('userActionSelected')
    )

    if (savedUserActionSelected) {
      setUserActionSelected(savedUserActionSelected)
    }
  }

  const refreshClients = async () => {
    const response = await getClients(token)

    if (response.error) setClients(emptyClients)
    else setClients(response)
  }

  const toggleShowNewClientModal = () => {
    setShowNewClientModal((prev) => {
      if (prev) {
        setNewClientCallback(null)
      }
      return !prev
    })
    
  }
  const toggleShowSimulationModal = () =>
    setShowSimulationModal((prev) => !prev)

  const values: AppContextInterface = {
    firstCharge,

    token,
    setToken,

    user,
    refreshUser,
    userIsLoading,

    isCentralAdmin,
    isOrganizationAdmin,
    isRegionAdmin,

    clients,
    refreshClients,

    simulationsToTransfer,
    setsimulationsToTransfer,

    userToTransfer,
    setUserToTransfer,
    userToDelete,
    setUserToDelete,

    simulationActionSelected,
    setSimulationActionSelected,
    userActionSelected,
    setUserActionSelected,

    showNewClientModal,
    toggleShowNewClientModal,
    newClientCallback, 
    setNewClientCallback,
    refetch, 
    setRefetch,

    showSimulationModal,
    toggleShowSimulationModal,
  }

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>
}

export default AppContextProvider
