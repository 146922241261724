import { useRef, memo } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import ErrorIcon from '../assets/icons/warning-red.svg'
import WarningIcon from '../assets/icons/warning-yellow.svg'

interface Props {
  topLabel?: string;
  rightLabel?: string;
  name: string;
  type?: string;
  placeholder?: string;
  value?: any;
  onChange?: any;
  error?: string | any;
  warning?: string | any;
  tip?: string;
  disabled?: boolean;
  edit?: string | any;
  inputMode?: 'text' | 'search' | 'email' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal';
}

const InputSmall = ({
  topLabel,
  rightLabel,
  name,
  type = 'text',
  placeholder,
  value,
  onChange,
  error,
  warning,
  tip,
  disabled,
  edit,
  inputMode = 'decimal',
}: Props) => {
  const inputRef = useRef(null)
  // const formatNumber = (number, maximumFractionDigits = 0) => {
  //   const parts = number?.toString()?.split('.')
  //   parts[0] = parts[0]?.replaceAll(',', '')
  //   console.log(Number(parts[0])?.toLocaleString('en-US', {maximumFractionDigits}))
  //   return Number(parts[0])?.toLocaleString('en-US', {maximumFractionDigits})
  // }

  const normalizeNumber = (value) => {
    if (value) {
      const parts = value?.toString()?.split('.')
      parts[0] = parts[0]?.replaceAll(',', '')
      parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return parts?.join('.')
    }
  }

  if (topLabel)
    return (
      <div className="InputSmall__All_Container d-flex flex-column gap-1">
        <div className="d-flex flex-wrap align-items-center gap-1">
          {error && (
            <div className="Input__Error-Icon-Container">
              <img className="Input__Error-Icon" src={ErrorIcon} />
            </div>
          )}
          {warning && !error && (
            <div className="Input__Warning-Icon-Container">
              <img className="Input__Warning-Icon" src={WarningIcon} />
            </div>
          )}

          {edit && !error && !warning && (
            <FontAwesomeIcon icon={faCircle} className="Input__Edit-Dot" />
          )}
          <h4 className="InputSmall__Label text-5 fw-medium">{topLabel}</h4>
        </div>

        <div className="d-flex align-items-center gap-2 position-relative">
          <div
            className={classNames('InputSmall__Container', {
              'min-w': rightLabel,
            })}
          >
            <input
              className={classNames('InputSmall text-6', {
                'InputSmall--Disabled': disabled,
              })}
              ref={inputRef}
              type={type}
              name={name}
              placeholder={placeholder}
              id={name}
              value={value === 0 ? 0 : normalizeNumber(value) || ''}
              onChange={onChange}
              onFocus={() => {
                // if(value === 0 || value === '0') {
                //   inputRef.current.value = ''
                // }
              }}
              onBlur={() => {
                if (
                  inputRef.current !== document.activeElement &&
                  inputRef.current.value === ''
                ) {
                  inputRef.current.value = 0
                }
              }}
              disabled={disabled}
              readOnly={disabled}
              inputMode={inputMode}
            />
            <div className="InputSmall__Detail"></div>
            {rightLabel ? (
              <label
                htmlFor={name}
                className="InputSmall__rightLabel text-6 fw-normal text-secondary"
              >
                {rightLabel}
              </label>
            ) : (
              <></>
            )}
          </div>
        </div>

        {error && <span className="Input__Error text-6">{error}</span>}

        {warning && !error && (
          <span className="Input__Warning text-6">{warning}</span>
        )}

        {edit && typeof edit === 'string' && !error && !warning && (
          <span className="Input__Edit text-6">{edit}</span>
        )}

        {tip && !error && !warning && !edit && (
          <span className="Input__Tip text-6">
            { tip }
          </span>
        )}
      </div>
    )

  return (
    <div className="InputSmall__All_Container d-flex flex-column gap-1">
      <div className="d-flex align-items-center gap-3 position-relative">
        <div
          className={classNames('InputSmall__Container', {
            'min-w': rightLabel,
          })}
        >
          <input
            className={classNames('InputSmall text-5', {
              'InputSmall--Disabled': disabled,
            })}
            type={type}
            name={name}
            placeholder={placeholder}
            id={name}
            value={value === 0 ? 0 : normalizeNumber(value) || ''}
            onChange={onChange}
            disabled={disabled}
            readOnly={disabled}
            inputMode={inputMode}
          />
          <div className="InputSmall__Detail"></div>
        </div>
        {rightLabel && (
          <label htmlFor={name} className="text-6 fw-normal text-secondary">
            {rightLabel}
          </label>
        )}
      </div>

      {error && <span className="Input__Error text-6">{error}</span>}

      {warning && !error && (
        <span className="Input__Warning text-6">{warning}</span>
      )}

      {edit && typeof edit === 'string' && !error && !warning && (
        <span className="Input__Edit text-6">{edit}</span>
      )}

      {tip && !error && !warning && !edit && (
        <span className="Input__Tip text-6">
          { tip }
        </span>
      )}
    </div>
  )
}

export default memo(InputSmall)
