import React from 'react'

interface Props {
  children: React.ReactNode;
}

const CreateSimulationTitle = ({ children }: Props) => {
  return (
    <h1 className='text-3 fw-semibold'>{ children }</h1>
  )
}

export default CreateSimulationTitle