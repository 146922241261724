import { getReportImagesGallery } from '../../api/newReport'
import useAppContext from '../../hooks/useAppContext'
import useReportContext from '../../hooks/useReportContext'
import { SectionImage } from '../../types/generateReport'
import { useMemo, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { getOrganizations } from '../../api/organizations'
import ImageLibrarySelector from './ImageLibrarySelector'
import InputDebounce from '../InputDebounce'

type Props = {
  section: SectionImage;
  footer?: boolean;
};

function ImageLibraryCard({ section, footer }: Props) {
  const { token, isCentralAdmin } = useAppContext()
  const { handleUpdateImage, handleUpdateImageText, handleUpdateFooterImage } =
    useReportContext()

  const {
    data: library,
    isFetching,
    refetch,
  } = useQuery(
    ['getReportImagesGallery', section.gallery_type],
    () =>
      getReportImagesGallery(token, {
        gallery_type: section.gallery_type ?? 'general',
      }),
    {
      initialData: {
        response: new Response(),
        data: {
          data: [],
        },
        ok: true,
      },
      enabled: token !== '',
      refetchOnWindowFocus: false,
    }
  )
  const initialData = { page: 1, total_items: 0, total_pages: 1, data: [] }
  const { data: organizatoins } = useQuery(
    ['getOrganizations'],
    () => getOrganizations(token, initialData),
    {
      initialData,
      enabled: token !== '' && isCentralAdmin,
      refetchOnWindowFocus: false,
    }
  )
  const organizatoinsForInput = useMemo(
    () =>
      organizatoins.data?.map((organizatoin) => ({
        value: organizatoin.id,
        label: organizatoin.name,
      })),
    [organizatoins]
  )

  const [error, seterror] = useState('')

  useEffect(() => {
    if (!error) return
    const clearError = setTimeout(() => seterror(''), 10000)

    return () => clearTimeout(clearError)
  }, [error])

  return (
    <>
      <ImageLibrarySelector
        organizations={organizatoinsForInput}
        id={section.id}
        value={section.value}
        placeholder={section.placeholder}
        gallery_type={section.gallery_type}
        library={library}
        isFetching={isFetching}
        refetch={refetch as any}
        onSelect={(value: string) =>
          !footer
            ? handleUpdateImage(section.id, value)
            : handleUpdateFooterImage(section.id, value)
        }
        onDelete={() =>
          !footer
            ? handleUpdateImage(section.id, null)
            : handleUpdateFooterImage(section.id, null)
        }
      />

      {'text' in section && (
        <InputDebounce
          label="Title"
          placeholder='Type a title'
          name="text"
          value={section.text}
          onDebounce={(value) => handleUpdateImageText(section.id, 'text', value)}
        />
      )}
      {'subtitle' in section && (
        <InputDebounce
          label="Subtitle"
          placeholder='Type a subtitle'
          name="subtitle"
          value={section.subtitle}
          onDebounce={(value) => handleUpdateImageText(section.id, 'subtitle', value)}
        />
      )}
      {'description' in section && (
        <InputDebounce
          label="Description"
          placeholder='Type a description'
          name="description"
          value={section.description}
          onDebounce={(value) => handleUpdateImageText(section.id, 'description', value)}
        />
      )}
    </>
  )
}

export default ImageLibraryCard
