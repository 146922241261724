import { deleteQuery, getQuery, patchQuery, postQuery } from './apiFunctions'
import {
  CountryDetail,
  Office,
  Organization,
  OrganizationDetail,
  Region,
} from './apiTypes'

// Organizations

export const getAllOrganizations = async (token: string) => {
  return await getQuery<any>({
    path: '/organizations/',
    token,
  })
}
interface GetOrganizations {
  page?: number;
  total_items?: number;
  total_pages?: number;
  data?: Organization[];
  error?: string;
}

export const getOrganizations = async (
  token: string,
  initialData: {
    page: number;
    total_items: number;
    total_pages: number;
    data: Organization[];
  },
  params?: {
    page?: number;
    per_page?: number;
    search?: string;
    order_by?: string;
  }
) => {
  if (params)
    params = Object.entries(params).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )

  return await getQuery<GetOrganizations>({
    path: '/organizations/',
    token,
    params,
    callback: (data: GetOrganizations) =>
      data.data instanceof Array ? data : { ...data, ...initialData },
  })
}

export const getOrganizationDetail = async (token: string, id: number) => {
  return await getQuery<OrganizationDetail>({
    path: `/organizations/detail/${id}`,
    token,
  })
}

interface CreateNewOrganization {
  mesage: string;
  error?: { [index: string]: string[] } | string;
}

export const createNewOrganization = async (
  token: string,
  body: {
    name: string;
    country_residence: number;
    address: string;
    admin_email: string;
    email: string;
    phone: string;
  }
) => {
  return await postQuery<CreateNewOrganization>({
    path: '/organizations/',
    token,
    body,
  })
}

interface UpdateOrganization {
  mesage: string;
  error?: { [index: string]: string[] } | string;
}

export const updateOrganization = async (
  token: string,
  body: {
    organization_id: number;
    name: string;
    country_residence: number;
    address: string;
    email: string;
    phone: string;
  }
) => {
  return await patchQuery<UpdateOrganization>({
    path: '/organizations/',
    token,
    body,
  })
}

export const deleteOrganization = async (
  token: string,
  body: { organization_id: number }
) => {
  return deleteQuery<any>({
    path: '/organizations/',
    token,
    body,
  })
}

// Regions

interface GetOrganizationRegions {
  data?: Array<Region>;
  error?: string;
}

export const getOrganizationRegions = async (
  token: string,
  organizationId,
  params?: { order_by?: string; search?: string }
) => {
  return await getQuery<GetOrganizationRegions>({
    path: `/organizations/regions/${organizationId}/`,
    token,
    params,
  })
}

export const addOrganizationRegion = async (
  token: string,
  body: {
    name: string;
    organization: number;
    countries: Array<number>;
  }
) => {
  return await postQuery<any>({
    path: '/organizations/regions/',
    token,
    body,
  })
}

export const editOrganizationRegion = async (
  token: string,
  body: {
    id: number;
    name: string;
    countries: Array<number>;
  }
) => {
  return await patchQuery<any>({
    path: '/organizations/regions/',
    token,
    body,
  })
}

export const deleteOrganizationRegion = async (
  token: string,
  body: {
    region_id: number;
    transfer_users?: string;
    transfer_users_to?: number;
    transfer_simulation?: string;
    transfer_simulations_to?: number;
  }
) => {
  return await deleteQuery<any>({
    path: '/organizations/regions/',
    token,
    body,
  })
}

// Countries
export const getCountriesByRegion = async (
  token: string,
  region_id: number
  // params: {
  //   search?: string;
  // }
) => {
  // params = Object.entries(params).reduce(
  //   (a, [k, v]) => (v ? ((a[k] = v), a) : a),
  //   {}
  // )
  return await getQuery<any>({
    path: `/organizations/regions/${region_id}/countries/`,
    token,
  })
}

// States
export const getStates = async (token, country_id) => {
  return await getQuery<any>({
    path: `/organizations/countries/${country_id}/states/`,
    token,
  })
}

// Countries

interface GetCountries {
  user_countries?: Array<CountryDetail>;
  all_countries?: Array<CountryDetail>;
  error?: string;
}

export const getCountries = async (
  token: string,
  params?: {
    search?: string;
  }
) => {
  if (params)
    params = Object.entries(params).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
  return await getQuery<GetCountries>({
    path: '/organizations/countries/',
    token,
    callback: (data) => data,
  })
}

// Oficces

interface GetOffices {
  data?: Array<Office>;
  error?: string;
}

export const getOffices = async (
  token: string,
  params: {
    organization_id: number;
  },
  optionalParams?: {
    search?: string;
    order_by?: string;
  }
) => {
  if (optionalParams)
    optionalParams = Object.entries(optionalParams).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )

  return await getQuery<GetOffices>({
    path: '/organizations/offices/',
    token,
    params: { ...params, ...optionalParams },
  })
}

export const addOffice = async (
  token: string,
  body: {
    name: string;
    country: number | null;
    city: string;
    zip_code: string;
    address_line_1: string;
    phone: string;
    email: string;
    HQ: boolean;
    organization: number;
  },
  bodyOptional: { address_line_2?: string }
) => {
  if (bodyOptional)
    bodyOptional = Object.entries(bodyOptional).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )

  return await postQuery<any>({
    path: '/organizations/offices/',
    token,
    body: { ...body, ...bodyOptional },
  })
}

export const updateOffice = async (
  token: string,
  officeId: number,
  body: {
    name: string;
    country: number | null;
    city: string;
    zip_code: string;
    phone: string;
    email: string;
    HQ: string;
    organization: number;
  },
  bodyOptional: { address_line_1?: string; address_line_2?: string }
) => {
  // if (bodyOptional)
  //   bodyOptional = Object.entries(bodyOptional).reduce(
  //     (a, [k, v]) => (v ? ((a[k] = v), a) : a),
  //     {}
  //   )

  return await patchQuery<any>({
    path: `/organizations/offices/${officeId}/`,
    token,
    body: { ...body, ...bodyOptional },
  })
}

export const deleteOffice = async (token: string, officeId: number) => {
  return await deleteQuery<any>({
    path: `/organizations/offices/${officeId}/`,
    token,
  })
}
