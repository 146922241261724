import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  getDailyProfileDetail,
  addHotWaterDemand,
  updateHotWaterDemand,
} from '../../api/simulations/input-parameters/hot-water-demand'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import useModalsContext from '../../hooks/useModalsContext'
import CreateSimulationContainer from '../../containers/CreateSimulationContainer'
import SimulationFormikHandler from '../../containers/SimulationFormikHandler'
import Checkboxes from '../../components/Checkboxes'
import CreateSimulationSelector from '../../components/CreateSimulationSelector'
import Input from '../../components/Input'
import InputSmall from '../../components/InputSmall'
import SearchInput from '../../components/SearchInput'
import WaterIcon from '../../assets/icons/water.svg'
import ErrorIcon from '../../assets/icons/warning-red.svg'
import {
  checkboxOptions,
  threeDecimalRegex,
  emptyProfile,
  normalizePercentToSend,
  normalizePercentToShow,
  normalizeNumberWithCommas,
} from '../../constants'
import { submitStep } from '../../utils/submitStep'
import { ListIcon } from '../../assets/icons'
import SimulationSwitch from '../../components/SimulationSwitch'

const HotWaterDemand = () => {
  const { token } = useAppContext()
  const {
    newSimulation,
    updateNewSimulation,
    waterProfiles,
    setIsStepLoading,
  } = useNewSimulationContext()
  const { toggleShowManageProfilesModal } = useModalsContext()
  const [profiles, setProfiles] = useState<any>([])
  const [profileSelected, setProfileSelected] = useState(emptyProfile)

  const yupNumberValidation = Yup.mixed()
    .required('Required')
    .transform((value) => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', (value) => !isNaN(value))
    .test(
      'decimals',
      'Max: 3 decimals',
      (value) => !threeDecimalRegex.test(value)
    )
    .test('min', 'Min: 100', (value) => value >= 100)

  const yupTemperatuValidation = Yup.mixed()
    .required('Required')
    .transform((value) => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', (value) => !isNaN(value))
    .test(
      'decimals',
      'Max: 3 decimals',
      (value) => !threeDecimalRegex.test(value)
    )
    .test('min', 'Min: 5°', (value) => value >= 5)
    .test('max', 'Max: 100°', (value) => value <= 100)

  const formik = useFormik({
    initialValues: {
      // General
      sector: '',
      daily_profile: null,
      // Extra config
      maximum_hot_water_demand: '',
      desired_hot_water_temperature: '',
      is_recirculation: false,
      water_temperature: '',
      // Weekly profile
      f_mon: 0,
      f_tue: 0,
      f_wed: 0,
      f_thu: 0,
      f_fri: 0,
      f_sat: 0,
      f_sun: 0,
      // Monthly profile
      f_jan: 0,
      f_feb: 0,
      f_mar: 0,
      f_apr: 0,
      f_may: 0,
      f_jun: 0,
      f_jul: 0,
      f_aug: 0,
      f_sep: 0,
      f_oct: 0,
      f_nov: 0,
      f_dec: 0,
      will_complete: false,
      // Errors
      weekly_error: '',
      monthly_error: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      sector: Yup.lazy(() => {
        if (formik.values.will_complete)
          return Yup.string().required('Required')
        else return Yup.string()
      }),
      daily_profile: Yup.lazy(() => {
        if (formik.values.will_complete)
          return Yup.number().nullable().required('Required')
        else return Yup.number().nullable()
      }),
      maximum_hot_water_demand: Yup.lazy(() => {
        if (formik.values.will_complete) return yupNumberValidation
        else return Yup.number().nullable()
      }),
      desired_hot_water_temperature: Yup.lazy(() => {
        if (formik.values.will_complete) return yupTemperatuValidation
        else return Yup.number().nullable()
      }),
      is_recirculation: Yup.boolean().required('Required'),
      water_temperature: Yup.lazy(() => {
        if (formik.values.will_complete && formik.values.is_recirculation)
          return yupTemperatuValidation
        else return Yup.number().nullable()
      }),
    }),
    validate: (values) => {
      const errors = {
        weekly_error: '',
        monthly_error: '',
      }

      const weeklyError = validateWeeklyProfile(values)
      const monthlyError = validateMonthlyProfile(values)

      if (weeklyError) errors.weekly_error = weeklyError
      if (monthlyError) errors.monthly_error = monthlyError

      if ((errors.weekly_error || errors.monthly_error) && values.will_complete)
        return errors
      else return
    },
    onSubmit: async (values) => {
      const weeklyValues = {
        f_mon: normalizePercentToSend(values.f_mon),
        f_tue: normalizePercentToSend(values.f_tue),
        f_wed: normalizePercentToSend(values.f_wed),
        f_thu: normalizePercentToSend(values.f_thu),
        f_fri: normalizePercentToSend(values.f_fri),
        f_sat: normalizePercentToSend(values.f_sat),
        f_sun: normalizePercentToSend(values.f_sun),
      }
      const monthlyValues = {
        f_jan: normalizePercentToSend(values.f_jan),
        f_feb: normalizePercentToSend(values.f_feb),
        f_mar: normalizePercentToSend(values.f_mar),
        f_apr: normalizePercentToSend(values.f_apr),
        f_may: normalizePercentToSend(values.f_may),
        f_jun: normalizePercentToSend(values.f_jun),
        f_jul: normalizePercentToSend(values.f_jul),
        f_aug: normalizePercentToSend(values.f_aug),
        f_sep: normalizePercentToSend(values.f_sep),
        f_oct: normalizePercentToSend(values.f_oct),
        f_nov: normalizePercentToSend(values.f_nov),
        f_dec: normalizePercentToSend(values.f_dec),
      }

      const newValues = {
        ...values,
        maximum_hot_water_demand: normalizeNumberWithCommas(
          values.maximum_hot_water_demand
        ),
        desired_hot_water_temperature: normalizeNumberWithCommas(
          values.desired_hot_water_temperature
        ),
        is_recirculation: String(values.is_recirculation),
        water_temperature: Number(values.water_temperature),
        will_complete: String(values.will_complete),
        ...weeklyValues,
        ...monthlyValues,
      }
      delete newValues.weekly_error
      delete newValues.monthly_error

      if (newValues.is_recirculation === 'false') {
        newValues.water_temperature = 0
      }

      await submitStep({
        pathname: location.pathname,
        lastStep: newSimulation.last_step,
        addFunction: () =>
          addHotWaterDemand(token, newSimulation.id, newValues),
        updateFunction: () =>
          updateHotWaterDemand(token, newSimulation.id, newValues),
        updateNewSimulation,
        setIsStepLoading,
      })
    },
  })

  const percentagesPerDay = [
    {
      label: 'Mon',
      name: 'f_mon',
      value: formik.values.f_mon,
      error: formik.errors.f_mon,
    },
    {
      label: 'Tue',
      name: 'f_tue',
      value: formik.values.f_tue,
      error: formik.errors.f_tue,
    },
    {
      label: 'Wed',
      name: 'f_wed',
      value: formik.values.f_wed,
      error: formik.errors.f_wed,
    },
    {
      label: 'Thu',
      name: 'f_thu',
      value: formik.values.f_thu,
      error: formik.errors.f_thu,
    },
    {
      label: 'Fri',
      name: 'f_fri',
      value: formik.values.f_fri,
      error: formik.errors.f_fri,
    },
    {
      label: 'Sat',
      name: 'f_sat',
      value: formik.values.f_sat,
      error: formik.errors.f_sat,
    },
    {
      label: 'Sun',
      name: 'f_sun',
      value: formik.values.f_sun,
      error: formik.errors.f_sun,
    },
  ]
  const percentagesPerMonth = [
    {
      label: 'Jan',
      name: 'f_jan',
      value: formik.values.f_jan,
      error: formik.errors.f_jan,
    },
    {
      label: 'Feb',
      name: 'f_feb',
      value: formik.values.f_feb,
      error: formik.errors.f_feb,
    },
    {
      label: 'Mar',
      name: 'f_mar',
      value: formik.values.f_mar,
      error: formik.errors.f_mar,
    },
    {
      label: 'Apr',
      name: 'f_apr',
      value: formik.values.f_apr,
      error: formik.errors.f_apr,
    },
    {
      label: 'May',
      name: 'f_may',
      value: formik.values.f_may,
      error: formik.errors.f_may,
    },
    {
      label: 'Jun',
      name: 'f_jun',
      value: formik.values.f_jun,
      error: formik.errors.f_jun,
    },
    {
      label: 'Jul',
      name: 'f_jul',
      value: formik.values.f_jul,
      error: formik.errors.f_jul,
    },
    {
      label: 'Aug',
      name: 'f_aug',
      value: formik.values.f_aug,
      error: formik.errors.f_aug,
    },
    {
      label: 'Sep',
      name: 'f_sep',
      value: formik.values.f_sep,
      error: formik.errors.f_sep,
    },
    {
      label: 'Oct',
      name: 'f_oct',
      value: formik.values.f_oct,
      error: formik.errors.f_oct,
    },
    {
      label: 'Nov',
      name: 'f_nov',
      value: formik.values.f_nov,
      error: formik.errors.f_nov,
    },
    {
      label: 'Dec',
      name: 'f_dec',
      value: formik.values.f_dec,
      error: formik.errors.f_dec,
    },
  ]
  const sectors = [
    {
      label: 'Residential',
      value: 'residential',
    },
    {
      label: 'Hotels',
      value: 'hotels',
    },
    {
      label: 'Hospitals',
      value: 'hospitals',
    },
    {
      label: 'Industries',
      value: 'industries',
    },
    {
      label: 'Care homes',
      value: 'care_homes',
    },
    {
      label: 'Gyms',
      value: 'gyms',
    },
    {
      label: 'Wellness centers',
      value: 'wellness_centers',
    },
    {
      label: 'Prisons',
      value: 'prisons',
    },
    {
      label: 'Camps',
      value: 'camps',
    },
  ]

  // Show saved simulation step
  useEffect(() => {
    formik.setValues({
      // General
      sector: newSimulation.steps.hot_water_demand.sector,
      daily_profile: newSimulation.steps.hot_water_demand.daily_profile,
      // Extra config
      maximum_hot_water_demand:
        newSimulation.steps.hot_water_demand.maximum_hot_water_demand,
      desired_hot_water_temperature:
        newSimulation.steps.hot_water_demand.desired_hot_water_temperature,
      is_recirculation: newSimulation.steps.hot_water_demand.is_recirculation,
      water_temperature: newSimulation.steps.hot_water_demand.water_temperature,
      // Weekly profile
      f_mon: normalizePercentToShow(newSimulation.steps.hot_water_demand.f_mon),
      f_tue: normalizePercentToShow(newSimulation.steps.hot_water_demand.f_tue),
      f_wed: normalizePercentToShow(newSimulation.steps.hot_water_demand.f_wed),
      f_thu: normalizePercentToShow(newSimulation.steps.hot_water_demand.f_thu),
      f_fri: normalizePercentToShow(newSimulation.steps.hot_water_demand.f_fri),
      f_sat: normalizePercentToShow(newSimulation.steps.hot_water_demand.f_sat),
      f_sun: normalizePercentToShow(newSimulation.steps.hot_water_demand.f_sun),
      // Monthly profile
      f_jan: normalizePercentToShow(newSimulation.steps.hot_water_demand.f_jan),
      f_feb: normalizePercentToShow(newSimulation.steps.hot_water_demand.f_feb),
      f_mar: normalizePercentToShow(newSimulation.steps.hot_water_demand.f_mar),
      f_apr: normalizePercentToShow(newSimulation.steps.hot_water_demand.f_apr),
      f_may: normalizePercentToShow(newSimulation.steps.hot_water_demand.f_may),
      f_jun: normalizePercentToShow(newSimulation.steps.hot_water_demand.f_jun),
      f_jul: normalizePercentToShow(newSimulation.steps.hot_water_demand.f_jul),
      f_aug: normalizePercentToShow(newSimulation.steps.hot_water_demand.f_aug),
      f_sep: normalizePercentToShow(newSimulation.steps.hot_water_demand.f_sep),
      f_oct: normalizePercentToShow(newSimulation.steps.hot_water_demand.f_oct),
      f_nov: normalizePercentToShow(newSimulation.steps.hot_water_demand.f_nov),
      f_dec: normalizePercentToShow(newSimulation.steps.hot_water_demand.f_dec),
      will_complete: newSimulation.steps.hot_water_demand.will_complete,
      // Errors
      weekly_error: '',
      monthly_error: '',
    })
  }, [newSimulation.steps.hot_water_demand])

  // Normalize profiles
  useEffect(() => {
    const getCurrentProfiles = async () => {
      const normalizeProfiles = waterProfiles.map((profile) => ({
        value: profile.id,
        label: profile.name,
      }))

      // Reset if profile was changed
      if (formik.values.daily_profile) {
        const existProfileModel = normalizeProfiles.find(
          (model) => model.value == formik.values.daily_profile
        )
        const profileSelected = profiles.find(
          (model) => model.value == formik.values.daily_profile
        )
        if (
          !existProfileModel ||
          existProfileModel?.label !== profileSelected?.label
        ) {
          formik.setFieldValue('daily_profile', '')
          formik.setStatus('reset')
          setTimeout(() => formik.setStatus(''), 150)
        }
      }

      setProfiles(normalizeProfiles)
    }

    if (token && waterProfiles.length > 0) {
      getCurrentProfiles()
    }
  }, [token, waterProfiles])

  // Get daily profile
  useEffect(() => {
    const getCurrentProfileDetail = async () => {
      const response = await getDailyProfileDetail(
        token,
        formik.values.daily_profile
      )

      if (response.error) {
        formik.setFieldValue('daily_profile', '')
        formik.setStatus('reset')
        setTimeout(() => formik.setStatus(''), 150)
        return
      } else {
        const newProfile = []
        for (let i = 0; i < 24; i++) {
          newProfile.push({
            label: `${i} - ${i + 1}`,
            value: Number(normalizePercentToShow(response[`f_h${i + 1}`])),
          })
        }

        setProfileSelected(newProfile)
      }
    }

    if (token && formik.values.daily_profile) {
      getCurrentProfileDetail()
    } else {
      const emptyProfile = []
      for (let i = 0; i < 24; i++) {
        emptyProfile.push({
          label: `${i} - ${i + 1}`,
          value: 0,
        })
      }
      setProfileSelected(emptyProfile)
    }
  }, [token, formik.values.daily_profile, waterProfiles])

  // Validate weekly profile on change
  useEffect(() => {
    const weeklyError = validateWeeklyProfile(formik.values)
    formik.setErrors({
      ...formik.errors,
      weekly_error: weeklyError,
    })
  }, [
    formik.values.f_mon,
    formik.values.f_tue,
    formik.values.f_wed,
    formik.values.f_thu,
    formik.values.f_fri,
    formik.values.f_sat,
    formik.values.f_sun,
  ])

  // Validate monthly profile on change
  useEffect(() => {
    const monthlyError = validateMonthlyProfile(formik.values)
    formik.setErrors({
      ...formik.errors,
      monthly_error: monthlyError,
    })
  }, [
    formik.values.f_jan,
    formik.values.f_feb,
    formik.values.f_mar,
    formik.values.f_apr,
    formik.values.f_may,
    formik.values.f_jun,
    formik.values.f_jul,
    formik.values.f_aug,
    formik.values.f_sep,
    formik.values.f_oct,
    formik.values.f_nov,
    formik.values.f_dec,
  ])

  const validateWeeklyProfile = (values) => {
    let weeklyError = ''

    const weeklyValues = {
      f_mon: values.f_mon && Number(values.f_mon) / 100,
      f_tue: values.f_tue && Number(values.f_tue) / 100,
      f_wed: values.f_wed && Number(values.f_wed) / 100,
      f_thu: values.f_thu && Number(values.f_thu) / 100,
      f_fri: values.f_fri && Number(values.f_fri) / 100,
      f_sat: values.f_sat && Number(values.f_sat) / 100,
      f_sun: values.f_sun && Number(values.f_sun) / 100,
    }

    const weeklyValuesArray = Object.values(weeklyValues)
    const hasWeeklyEmptyValues = weeklyValuesArray.some(
      (value) => typeof value === 'string' && value === ''
    )
    const hasWeeklyInvalidValues = weeklyValuesArray.some(
      (value) => typeof value !== 'number' || isNaN(value)
    )
    const hasWeeklyInvalidMinMaxValues = weeklyValuesArray.some(
      (value) => value < 0 || value > 1
    )
    // const hasWeeklyMaxDecimal = weeklyValuesArray.some((value) => threeDecimalRegex.test(String((value * 100).toFixed(3))))
    // const weeklyTotalValues = roundToDecimals(weeklyValuesArray, 3)

    if (hasWeeklyEmptyValues) {
      weeklyError = 'Complete all fields'
    } else if (hasWeeklyInvalidValues) {
      weeklyError = 'There are invalid values, only numbers are allowed'
    } else if (hasWeeklyInvalidMinMaxValues) {
      weeklyError = 'Invalid values: all values must be between 0 and 100'
    }
    // else if (hasWeeklyMaxDecimal) {
    //   weeklyError = 'Invalid values: all values must have a maximum of 3 decimals'
    // }

    if (weeklyError && values.will_complete) return weeklyError
  }

  const validateMonthlyProfile = (values) => {
    let monthlyError = ''

    const monthlyValues = {
      f_jan: values.f_jan && Number(values.f_jan) / 100,
      f_feb: values.f_feb && Number(values.f_feb) / 100,
      f_mar: values.f_mar && Number(values.f_mar) / 100,
      f_apr: values.f_apr && Number(values.f_apr) / 100,
      f_may: values.f_may && Number(values.f_may) / 100,
      f_jun: values.f_jun && Number(values.f_jun) / 100,
      f_jul: values.f_jul && Number(values.f_jul) / 100,
      f_aug: values.f_aug && Number(values.f_aug) / 100,
      f_sep: values.f_sep && Number(values.f_sep) / 100,
      f_oct: values.f_oct && Number(values.f_oct) / 100,
      f_nov: values.f_nov && Number(values.f_nov) / 100,
      f_dec: values.f_dec && Number(values.f_dec) / 100,
    }

    const monthlyValuesArray = Object.values(monthlyValues)
    const hasMonthlyEmptyValues = monthlyValuesArray.some(
      (value) => typeof value === 'string' && value === ''
    )
    const hasMonthlyInvalidValues = monthlyValuesArray.some(
      (value) => typeof value !== 'number' || isNaN(value)
    )
    const hasMonthlyInvalidMinMaxValues = monthlyValuesArray.some(
      (value) => value < 0 || value > 1
    )
    // const hasMonthlyMaxDecimal = monthlyValuesArray.some((value) => threeDecimalRegex.test(String((value * 100).toFixed(3))))
    // const monthlyTotalValues = roundToDecimals(monthlyValuesArray, 3)

    if (hasMonthlyEmptyValues) {
      monthlyError = 'Complete all fields'
    } else if (hasMonthlyInvalidValues) {
      monthlyError = 'There are invalid values, only numbers are allowed'
    } else if (hasMonthlyInvalidMinMaxValues) {
      monthlyError = 'Invalid values: all values must be between 0 and 100'
    }
    // else if (hasMonthlyMaxDecimal) {
    //   monthlyError = 'Invalid values: all values must have a maximum of 3 decimals'
    // }

    if (monthlyError && values.will_complete) return monthlyError
  }

  return (
    <SimulationFormikHandler formik={formik}>
      <Helmet>
        <title>Create Simulation: How Water Demand</title>
      </Helmet>

      {/* First Container */}
      <CreateSimulationSelector
        title="Hot water demand"
        alert="Important decision: The “yes” answer enables below content"
        subtitle="Is there a hot water demand?"
        options={checkboxOptions}
        optionSelected={formik.values.will_complete}
        handleSelect={(value) => formik.setFieldValue('will_complete', value)}
      />

      {/* Second Container */}
      <CreateSimulationContainer available={formik.values.will_complete}>
        <header className="d-flex align-items-center flex-wrap gap-2">
          <img
            src={WaterIcon}
            className="CreateSimulation__Title-Icon"
            alt="Water demand detail"
            title="Water demand detail"
          />

          <h2 className="text-4 fw-semibold">Water demand detail</h2>
        </header>

        <Input
          label="Sector"
          name="sector"
          list={sectors}
          value={
            formik.values.sector
              ? sectors.find((sector) => sector.value === formik.values.sector)
              : {
                label: '',
                value: '',
              }
          }
          placeholder="Select a sector"
          onChange={(name, item) => formik.setFieldValue(name, item.value)}
          error={formik.errors.sector}
          disabled={!formik.values.will_complete}
        />

        <SearchInput
          label="Daily profile"
          name="daily_profile"
          search={profiles}
          value={formik.values.daily_profile}
          placeholder="Select a daily profile"
          onChange={(id) => formik.setFieldValue('daily_profile', id)}
          error={formik.errors.daily_profile}
          reset={formik.status}
          disabled={!formik.values.will_complete || profiles?.length == 0}
          disabledPlaceholder={
            profiles?.length == 0 ? 'No profiles available' : 'Select a profile'
          }
          buttonLabel="Manage"
          buttonIcon={<ListIcon />}
          buttonOnClick={toggleShowManageProfilesModal}
        />

        <SimulationSwitch label='Show % per hour information'>
          <div className="HotWaterDemand__Percentages-Grid Hours">
            {profileSelected &&
                profileSelected.map((item, index) => (
                  <Input
                    key={`Input-Percentage-Per-Hour-${index}`}
                    label={item.label}
                    name={item.label}
                    value={item.value}
                    placeholder="0"
                    disabled={true}
                    small
                  />
                ))}

            <div className="HotWaterDemand__Percentages-Grid Hours_Extra">
              <span className="text-6 text-secondary pb-2">%</span>
              <span className="text-6 text-secondary pb-2">%</span>
              <span className="text-6 text-secondary pb-2">%</span>
              <span className="text-6 text-secondary pb-2">%</span>
              <span className="text-6 text-secondary pb-2 measure">%</span>
              <span className="text-6 text-secondary pb-2 measure">%</span>
            </div>
          </div> 
        </SimulationSwitch>

        <div className="d-flex flex-column gap-2">
          <div className="w-100 d-flex align-items-center gap-2">
            <h3 className="d-flex align-items-center gap-1 text-5 fw-medium">
              {formik.errors.weekly_error && (
                <div className="Input__Error-Icon-Container">
                  <img className="Input__Error-Icon" src={ErrorIcon} />
                </div>
              )}
              Weekly profile
            </h3>
            {formik.errors.weekly_error && (
              <span className="Input__Error text-6">
                {formik.errors.weekly_error}
              </span>
            )}
          </div>

          <div className="d-flex align-items-end gap-2">
            <div className="CreateSimulation__Grid Eight End">
              {percentagesPerDay.map((item, index) => (
                <Input
                  key={`Input-Percentage-Per-Day-${index}`}
                  label={item.label}
                  name={item.name}
                  value={item.value}
                  placeholder="0"
                  onChange={formik.handleChange}
                  error={item.error}
                  disabled={!formik.values.will_complete}
                  maxLength={3}
                  small
                />
              ))}
              <span className="text-6 text-secondary pb-2">%</span>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column gap-2">
          <div className="w-100 d-flex align-items-center gap-2">
            <h3 className="d-flex align-items-center gap-1 text-5 fw-medium">
              {formik.errors.monthly_error && (
                <div className="Input__Error-Icon-Container">
                  <img className="Input__Error-Icon" src={ErrorIcon} />
                </div>
              )}
              Monthly profile
            </h3>
            {formik.errors.monthly_error && (
              <span className="Input__Error text-6">
                {formik.errors.monthly_error}
              </span>
            )}
          </div>

          <div className="d-flex align-items-end gap-2">
            <div className="CreateSimulation__Grid Thirteen">
              {percentagesPerMonth.map((item, index) => (
                <Input
                  key={`Input-Percentage-Per-Day-${index}`}
                  label={item.label}
                  name={item.name}
                  value={item.value}
                  placeholder="0"
                  onChange={formik.handleChange}
                  error={item.error}
                  disabled={!formik.values.will_complete}
                  maxLength={3}
                  small
                />
              ))}

              <div className="CreateSimulation__Grid Thirteen_Extra">
                <span className="text-6 text-secondary pb-2">%</span>
                <span className="text-6 text-secondary pb-2">%</span>
                <span className="text-6 text-secondary pb-2 d-lg-none">%</span>
              </div>
            </div>
          </div>
        </div>

        <InputSmall
          topLabel="Maximum hot water demand"
          rightLabel="LPD"
          name="maximum_hot_water_demand"
          placeholder="0"
          value={formik.values.maximum_hot_water_demand}
          onChange={formik.handleChange}
          error={formik.errors.maximum_hot_water_demand}
          disabled={!formik.values.will_complete}
        />

        <InputSmall
          topLabel="Desired hot water temperature"
          rightLabel="°C"
          name="desired_hot_water_temperature"
          placeholder="0"
          value={formik.values.desired_hot_water_temperature}
          onChange={formik.handleChange}
          error={formik.errors.desired_hot_water_temperature}
          disabled={!formik.values.will_complete}
        />

        <Checkboxes
          label="Is it recirculation process?"
          subLabel='A recirculation process is a process in which hot water is not directly consumed. Heat is rather extracted from the hot water via a heat exchanger and as a result the water after losing heat returns back to the heat generator as a colder temperature.'
          options={checkboxOptions}
          optionSelected={formik.values.is_recirculation}
          small
          handleSelect={(e) => formik.setFieldValue('is_recirculation', e)}
          disabled={!formik.values.will_complete}
        />

        <InputSmall
          topLabel="Return water temperature"
          rightLabel="°C"
          name="water_temperature"
          placeholder="0"
          value={formik.values.water_temperature}
          onChange={formik.handleChange}
          error={formik.errors.water_temperature}
          disabled={
            !formik.values.will_complete || !formik.values.is_recirculation
          }
        />
      </CreateSimulationContainer>
    </SimulationFormikHandler>
  )
}

export default HotWaterDemand
