import { Image, StyleSheet, View } from '@react-pdf/renderer'
import { SectionImage } from '../../types/generateReport'

type Props = {
  logo: SectionImage;
};

const styles = StyleSheet.create({
  image: {
    // width: 96,
    height: 32,
    // borderTopLeftRadius: 4,
    // borderTopRightRadius: 4,
    // borderBottomRightRadius: 4,
    // borderBottomLeftRadius: 4,
    objectFit: 'cover',
  },
  emptyImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 96,
    height: 32,
    backgroundColor: '#D9D9D9',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
  },
})

export default function LogoRender({ logo }: Props) {
  if (logo.value) {
    return <Image src={logo.value} style={styles.image} />
  }
  return <View />
}
