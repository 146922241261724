import React, { useEffect } from 'react'
import SimulationLoadingModal from '../components/modals/SimulationLoadingModal'
import StepLoadingModal from '../components/modals/StepLoadingModal'
import useNewSimulationContext from '../hooks/useNewSimulationContext'

interface Props {
  formik: any,
  skip?: boolean,
  children: React.ReactNode
}

const SimulationFormikHandler = ({ formik, skip = false, children }: Props) => {
  const { isLoading, isStepLoading, setCurrentFormik, setSkip } = useNewSimulationContext()

  // Set simulation step
  useEffect(() => {
    setCurrentFormik(formik)
  }, [])

  // Set skip step
  useEffect(() => {
    setSkip(skip)
  }, [skip])

  return (
    <>
      { children }

      {isLoading && (
        <SimulationLoadingModal/>
      )}

      {isStepLoading && (
        <StepLoadingModal/>
      )}
    </>
  )
}

export default SimulationFormikHandler