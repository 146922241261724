import { Component, ErrorInfo, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import DashboardHeader from '../containers/DashboardHeader'

export class ErrorBoundary extends Component<{ children: ReactNode }, { hasError: boolean }> {
  state = {
    hasError: false
  }

  public static getDerivedStateFromError(_: Error) {
    console.log(_)
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  private resetError() {
    this.setState({ hasError: false })
  }

  render() {
    if (this.state.hasError)
      return (
        <>
          <DashboardHeader handleErrorBoundary={this.resetError} />
          <div className='flex flex-col justify-center items-center gap-4 w-100 h-screen'>
            <h2>Something went wrong.</h2>

            <Link to="/" onClick={this.resetError} className="button-blue">
            Return to home
            </Link>
          </div>
        </>
      )
    return this.props.children
  }
}

export default ErrorBoundary