import { useFormik } from 'formik'
import { useEffect } from 'react'
import * as Yup from 'yup'
import { threeDecimalRegex } from '../constants'
import CreateSimulationContainer from '../containers/CreateSimulationContainer'
import useNewSimulationContext from '../hooks/useNewSimulationContext'
import InputSmall from './InputSmall'

interface Props {
  title: string;
  periodName: string;
  periods: any;
  setPeriods: any;
  economicLife: number;
  available: boolean;
}

const PPAFinancialPeriod = ({ title, periodName, periods, setPeriods, economicLife, available }: Props) => {
  const { newSimulation } = useNewSimulationContext()

  const yupYearValidation = Yup.lazy(() => {
    if(available) return Yup
      .mixed()
      .required('Required')
      .transform(value => value?.toString()?.replace(/,/g, ''))
      .test('type', 'Only numbers', value => !isNaN(value))
      .test('min', 'Min: 0', value => value >= 0)
      .test('max', `Max: ${economicLife}`, value => value <= economicLife)
      .test('integer', 'Only integers', value => Number.isInteger(Number(value)))
    else return Yup.number().nullable()
  })

  const yupNumberValidation = Yup.lazy(() => {
    if(available) return Yup
      .mixed()
      .required('Required')
      .transform(value => value?.toString()?.replace(/,/g, ''))
      .test('type', 'Only numbers', value => !isNaN(value))
      .test('min', 'Min: 0', value => value >= 0)
      .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))
    else return Yup.number().nullable()
  })

  const yupPercentageValidation = Yup.lazy(() => {
    if(available) return Yup
      .mixed()
      .required('Required')
      .transform(value => value?.toString()?.replace(/,/g, ''))
      .test('type', 'Only numbers', value => !isNaN(value))
      .test('min', 'Min: 0%', value => value >= 0)
      // .test('max', 'Max: 100%', value => value <= 100)
      .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))
    else return Yup.number().nullable()
  })

  const formik = useFormik({
    initialValues: {
      model_id: '',
      number_of_years: '',
      electricity_price_per_kwh: '',
      electricity_escalation_rate: '',
      heat_price_per_kwh: '',
      heat_escalation_rate: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      number_of_years: yupYearValidation,
      electricity_price_per_kwh: yupNumberValidation,
      electricity_escalation_rate: yupPercentageValidation,
      heat_price_per_kwh: yupNumberValidation,
      heat_escalation_rate: yupPercentageValidation,
    }),
    onSubmit: () => {
      return
    }
  })

  useEffect(() => {
    const newPeriods = periods

    newPeriods[periodName] = formik

    setPeriods(newPeriods)
  }, [formik.isValidating])

  return (
    <CreateSimulationContainer available={available}>
      <header className='d-flex align-items-center gap-2'>
        <h2 className='text-4 fw-semibold'>{ title }</h2>
      </header>

      <InputSmall
        topLabel='Contract period'
        rightLabel='years'
        name='number_of_years'
        value={formik.values.number_of_years}
        placeholder='0'
        onChange={formik.handleChange}
        error={formik.errors.number_of_years}
        disabled={!available}
      />


      <InputSmall
        topLabel='Electricity price'
        rightLabel={`${newSimulation.steps.general_information.currency_symbol} per kWh`}
        name='electricity_price_per_kwh'
        value={formik.values.electricity_price_per_kwh}
        placeholder='0'
        onChange={formik.handleChange}
        error={formik.errors.electricity_price_per_kwh}
        disabled={!available}
      />

      <InputSmall
        topLabel='Electricity escalation rates'
        rightLabel='%'
        name='electricity_escalation_rate'
        value={formik.values.electricity_escalation_rate}
        placeholder='0'
        onChange={formik.handleChange}
        error={formik.errors.electricity_escalation_rate}
        disabled={!available}
      />
      <InputSmall
        topLabel='Heat price'
        rightLabel={`${newSimulation.steps.general_information.currency_symbol} per kWh`}
        name='heat_price_per_kwh'
        value={formik.values.heat_price_per_kwh}
        placeholder='0'
        onChange={formik.handleChange}
        error={formik.errors.heat_price_per_kwh}
        disabled={!available}
      />

      <InputSmall
        topLabel='Heat escalation rates'
        rightLabel='%'
        name='heat_escalation_rate'
        value={formik.values.heat_escalation_rate}
        placeholder='0'
        onChange={formik.handleChange}
        error={formik.errors.heat_escalation_rate}
        disabled={!available}
      />
    </CreateSimulationContainer>
  )
}

export default PPAFinancialPeriod