import { SVGProps } from 'react'

export default function CrossIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.31344 4.31295C4.5087 4.11769 4.82528 4.11769 5.02055 4.31295L8.00033 7.29273L10.9801 4.31295C11.1754 4.11769 11.492 4.11769 11.6872 4.31295C11.8825 4.50821 11.8825 4.8248 11.6872 5.02006L8.70743 7.99984L11.6872 10.9796C11.8825 11.1749 11.8825 11.4915 11.6872 11.6867C11.492 11.882 11.1754 11.882 10.9801 11.6867L8.00033 8.70694L5.02055 11.6867C4.82528 11.882 4.5087 11.882 4.31344 11.6867C4.11818 11.4915 4.11818 11.1749 4.31344 10.9796L7.29322 7.99984L4.31344 5.02006C4.11818 4.8248 4.11818 4.50821 4.31344 4.31295Z"
        fill="currentColor"
      />
    </svg>
  )
}
