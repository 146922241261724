import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { addAuxiliaryHeatingSystem, updateAuxiliaryHeatingSystem } from '../../api/simulations/system-parameters/auxiliary-heating-system'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import CreateSimulationContainer from '../../containers/CreateSimulationContainer'
import SimulationFormikHandler from '../../containers/SimulationFormikHandler'
import Checkboxes from '../../components/Checkboxes'
import CreateSimulationDisabled from '../../components/CreateSimulationDisabled'
import CreateSimulationSelector from '../../components/CreateSimulationSelector'
import HeatingSystem from '../../components/HeatingSystem'
import { checkboxOptions, normalizeNumberWithCommas } from '../../constants'
import { submitStep } from '../../utils/submitStep'

const AuxiliaryHeatingSystem = () => {
  const { token } = useAppContext()
  const { newSimulation, updateNewSimulation, flowDecisions, setIsStepLoading } = useNewSimulationContext()
  const [ available, setAvailable ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const [ auxiliaryHeatingSystems, setAuxiliaryHeatingSystems ] = useState<any>({
    system_one: '',
    system_two: '',
  })
  const secondSystemRef = useRef(null)

  const formik = useFormik({
    initialValues: {
      heating_system: '',
      solar_buffer_system: '',
      will_complete: false,
      another_system: false,
    },
    validateOnChange: false,
    validate: async (values) => {
      const errors = {
        system_one: '',
        system_two: '',
      }
      const firstFormik = auxiliaryHeatingSystems.system_one
      const secondFormik = auxiliaryHeatingSystems.system_two

      if(firstFormik) errors.system_one = await firstFormik.validateForm()
      if(secondFormik) errors.system_two = await secondFormik.validateForm()

      if(!values.will_complete) errors.system_one = ''
      if(!values.another_system) errors.system_two = ''

      const hasErrors = Object.keys(errors.system_one).length > 0 || Object.keys(errors.system_two).length > 0

      if(hasErrors) return errors
    },
    onSubmit: async (values) => {
      const newValues = {
        heating_system: {
          ...auxiliaryHeatingSystems.system_one.values,
          capacity: normalizeNumberWithCommas(auxiliaryHeatingSystems.system_one.values.capacity),
          size_of_the_buffer_connected_to_auxiliary_heater: normalizeNumberWithCommas(auxiliaryHeatingSystems.system_one.values.size_of_the_buffer_connected_to_auxiliary_heater),
        },
        solar_buffer_system: {
          ...auxiliaryHeatingSystems.system_two.values,
          capacity: normalizeNumberWithCommas(auxiliaryHeatingSystems.system_two.values.capacity),
          size_of_the_buffer_connected_to_auxiliary_heater: normalizeNumberWithCommas(auxiliaryHeatingSystems.system_two.values.size_of_the_buffer_connected_to_auxiliary_heater),
        },
      }

      if(!auxiliaryHeatingSystems.system_one.values || !values.will_complete) delete newValues.heating_system
      if(!auxiliaryHeatingSystems.system_two.values || !values.another_system) delete newValues.solar_buffer_system

      await submitStep({
        pathname: location.pathname,
        lastStep: newSimulation.last_step,
        addFunction: () => addAuxiliaryHeatingSystem(token, newSimulation.id, newValues),
        updateFunction: () => updateAuxiliaryHeatingSystem(token, newSimulation.id, newValues),
        updateNewSimulation,
        setIsStepLoading
      })
    },
    onReset: () => {
      const firstFormik = auxiliaryHeatingSystems.system_one
      const secondFormik = auxiliaryHeatingSystems.system_two

      if(firstFormik.resetForm) {
        firstFormik.resetForm({
          status: 'reset'
        })

        setTimeout(() => {
          firstFormik.setStatus('')
        }, 150)
      }
      if(secondFormik.resetForm) {
        secondFormik.resetForm({
          status: 'reset'
        })

        setTimeout(() => {
          secondFormik.setStatus('')
        }, 150)
      }
    }
  })

  // Set available
  useEffect(() => {
    if(flowDecisions?.hot_water_demand) {
      setAvailable(true)
    } else if(available) {
      setAvailable(false)
    }

    setLoading(false)
  }, [flowDecisions])

  // Show saved simulation step
  useEffect(() => {
    const savedSystemOne = newSimulation.steps.auxiliary_heating_system.system_one
    const savedSystemTwo = newSimulation.steps.auxiliary_heating_system.system_two

    formik.setValues({
      heating_system: '',
      solar_buffer_system: '',
      will_complete: newSimulation.steps.auxiliary_heating_system.will_complete,
      another_system: newSimulation.steps.auxiliary_heating_system.another_system,
    })

    if(savedSystemOne?.system && auxiliaryHeatingSystems.system_one && available) {
      auxiliaryHeatingSystems.system_one.setValues({
        system: savedSystemOne.system,
        name: savedSystemOne.name,
        capacity: savedSystemOne.capacity,
        size_of_the_buffer_connected_to_auxiliary_heater: '',
      })
    }

    if(savedSystemTwo?.system && auxiliaryHeatingSystems.system_two && available) {
      auxiliaryHeatingSystems.system_two.setValues({
        system: savedSystemTwo.system,
        name: savedSystemTwo.name,
        capacity: savedSystemTwo.capacity,
        size_of_the_buffer_connected_to_auxiliary_heater: savedSystemTwo.size_of_the_buffer_connected_to_auxiliary_heater,
      })
    }
  }, [newSimulation.steps.auxiliary_heating_system, auxiliaryHeatingSystems, available])

  // Scroll to second system
  useEffect(() => {
    if(!flowDecisions?.include_a_t_or_pvt && secondSystemRef.current) {
      secondSystemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [flowDecisions.include_a_t_or_pvt, secondSystemRef.current])

  if(loading) return null

  if(!available) return (
    <SimulationFormikHandler formik={formik} skip>
      <Helmet>
        <title>Create Simulation: Auxiliary Heating System</title>
      </Helmet>

      <CreateSimulationDisabled
        title='Auxiliary heating system'
        message={
          <span>
            If you want to complete this step, you must complete the <Link to='/dashboard/create-simulation/step/hot-water-demand' className='link'>Hot Water Demand</Link> step, or include a T or PVT field in the <Link to='/dashboard/create-simulation/step/solar-thermal-field' className='link'>Solar Thermal Field</Link> step and then complete the <Link to='/dashboard/create-simulation/step/solar-storage' className='link'>Solar Storage</Link> step
          </span>
        }
      />
    </SimulationFormikHandler>
  )

  return (
    <SimulationFormikHandler formik={formik}>
      <Helmet>
        <title>Create Simulation: Auxiliary Heating System</title>
      </Helmet>

      {/* First System */}
      {!flowDecisions?.include_a_t_or_pvt ? (
        <CreateSimulationDisabled
          title='Auxiliary heating system'
          message={
            <span>
              If you want to complete the first form you must include a T or PVT field in the <Link to='/dashboard/create-simulation/step/solar-thermal-field' className='link'>Solar Thermal Field</Link> step and then complete the <Link to='/dashboard/create-simulation/step/solar-storage' className='link'>Solar Storage</Link> step
            </span>
          }
        />
      ) : (
        <CreateSimulationSelector
          title='Auxiliary heating system'
          alert='Important decision: The “yes” answer enables below content'
          subtitle='Include another auxiliary heater in the solar storage?'
          options={checkboxOptions}
          optionSelected={formik.values.will_complete}
          handleSelect={(value) => formik.setFieldValue('will_complete', value)}
          available={flowDecisions?.include_a_t_or_pvt}
        />
      )}

      <HeatingSystem
        title='Heating system'
        auxiliaryHeatingSystemName='system_one'
        auxiliaryHeatingSystems={auxiliaryHeatingSystems}
        setAuxiliaryHeatingSystems={setAuxiliaryHeatingSystems}
        available={formik.values.will_complete}
      />


      {/* Second System */}
      <CreateSimulationContainer containerRef={secondSystemRef}>
        <Checkboxes
          label="Include auxiliary after heater after the solar buffer?"
          subLabel="Important decision: The “yes” answer enables below content"
          options={checkboxOptions}
          optionSelected={formik.values.another_system}
          small
          handleSelect={(value) => formik.setFieldValue('another_system', value)}
        />
      </CreateSimulationContainer>

      <HeatingSystem
        title='Auxiliary after heater after the solar buffer'
        isSecondForm
        auxiliaryHeatingSystemName='system_two'
        auxiliaryHeatingSystems={auxiliaryHeatingSystems}
        setAuxiliaryHeatingSystems={setAuxiliaryHeatingSystems}
        available={formik.values.another_system}
      />
    </SimulationFormikHandler>
  )
}

export default AuxiliaryHeatingSystem