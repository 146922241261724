import { useState, useEffect } from 'react'
import { uploadImages3 } from '../../api/newReport'
import useAppContext from '../../hooks/useAppContext'
import useReportContext from '../../hooks/useReportContext'
import { IDelete } from '../../iconComponents'
import { SectionImage } from '../../types/generateReport'
import { handleInputFile } from '../../utils/handleInputFile'
import InputFile from '../InputFile'

type Props = {
  section: SectionImage;
  footer?: boolean,
};

export default function ImageCard({ section, footer }: Props) {
  const { token } = useAppContext()
  const { handleUpdateImage, handleUpdateFooterImage } = useReportContext()
  const [error, seterror] = useState('')

  useEffect(() => {
    if (!error) return 
    const clearError = setTimeout(() => seterror(''), 10000)
  
    return () => clearTimeout(clearError)
  }, [error])
  
  return (
    <>
      {section.value === null ? (
        <InputFile
          name={'image_' + section.id}
          accept={['image/jpg', 'image/jpeg', 'image/png']}
          onChange={(event) =>
            handleInputFile({
              event,
              fileHandler: async (file: File) => {
                const formData = new FormData()
                formData.append('image', file)
                const { ok, data } = await uploadImages3(token, formData)

                if (!ok) {
                  console.error(data)
                } else if (!footer) {
                  handleUpdateImage(section.id, data.url)
                } else {
                  handleUpdateFooterImage(section.id, data.url)
                }
              },
              fileTypes: ['image/jpg', 'image/jpeg', 'image/png'],
              fileMaxSize: 5,
              errorHandler: (error) => seterror(error)
            })
          }
          error={error}
        >
          <span className="InputFile_Text">
            <span className="text-5 text-primary">
              {section.placeholder ?? 'Upload image'}
            </span>
            <span className="text-5 text-secondary">Max 5mb.</span>
          </span>
          <span className="Button xs blue">Upload</span>
        </InputFile>
      ) : (
        <div className="InputFile">
          <img src={section.value} className="InputFile_Image" />
          <div className="InputFile_Text">
            <p className="text-5 text-primary">
              {section.placeholder ?? 'Upload image'}
            </p>
            <span className="text-5 text-secondary">Max 5mb.</span>
          </div>
          <IDelete
            width={24}
            height={24}
            className="text-primary me-2"
            onClick={() => !footer ? handleUpdateImage(section.id, null) : handleUpdateFooterImage(section.id, null)}
          />
        </div>
      )}
    </>
  )
}
