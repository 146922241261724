import { useMemo, useState } from 'react'
import { deletePanelModel } from '../../api/simulations/system-parameters/solar-thermal-field'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import useModalsContext from '../../hooks/useModalsContext'
import Modal from './Modal'
import TableManage from '../tables/TableManage'

const ManageModelsModal = () => {
  const { token } = useAppContext()
  const { panelModels, getExtraData } = useNewSimulationContext()
  const { panelModelTypeSelected, setModalId, toggleShowManageModelsModal, openShowNewModelModal } = useModalsContext()
  const [showDeleteModel, setShowDeleteModel] = useState(false)
  const [modelToDelete, setModelToDelete] = useState(null)

  const panelModelsFilter = useMemo(
    () =>
      panelModels.filter(
        (model) =>
          model.panel_type ===
          panelModelTypeSelected[panelModelTypeSelected.panelSelected]
      ),
    [panelModels]
  )

  const handleEdit = (id: number) => {
    toggleShowManageModelsModal()
    setModalId(id)
    openShowNewModelModal()
  }

  const openshowDeleteModel = (model) => {
    setModelToDelete(model)
    setShowDeleteModel(true)
  }

  const handleDelete = async () => {
    const response = await deletePanelModel(token, modelToDelete.id)

    if (response.error) console.error(response.error)
    else if (response) getExtraData()

    setShowDeleteModel(false)
  }

  return (
    <Modal title="Manage models" closeModal={toggleShowManageModelsModal}>
      <div className="Modal__Scroll-Content d-flex flex-column align-items-center">
        {/* <button
          className="p-2 text-5 fw-normal button-transparent"
          onClick={() => {
            toggleShowManageModelsModal()
            openShowNewModelModal()
          }}
        >
          + Add new model
        </button>
        <div className="Modal__Divider mb-2" /> */}

        <TableManage
          name="models"
          data={panelModelsFilter}
          handleEdit={handleEdit}
          openDeleteModal={openshowDeleteModel}
        />
      </div>
      {showDeleteModel ? (
        <Modal
          title="Delete model"
          closeModal={() => setShowDeleteModel(false)}
        >
          <div className="d-flex flex-column justify-content-center align-items-center p-5">
            <p className="text-center">Are you sure you want to delete the <span className="fw-medium">{modelToDelete.model_name}</span> model?</p>
          </div>
          <button className="button-blue mx-auto w-100" onClick={handleDelete}>
            Delete
          </button>
        </Modal>
      ) : (
        <></>
      )}
    </Modal>
  )
}

export default ManageModelsModal
