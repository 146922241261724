import { useMemo } from 'react'
// import {
//   DndContext,
//   closestCorners,
//   KeyboardSensor,
//   PointerSensor,
//   useSensor,
//   useSensors,
//   DragEndEvent,
// } from '@dnd-kit/core'
// import {
//   arrayMove,
//   SortableContext,
//   sortableKeyboardCoordinates,
//   verticalListSortingStrategy,
// } from '@dnd-kit/sortable'
import PageInfoCard from './PageInfoCard'
// import {
//   PageContainer,
//   PageSingle,
// } from '../types/generateReport'
import useReportContext from '../hooks/useReportContext'
import PageInfoHeader from './GenerateReport/PageInfoHeader'

export default function PageInfo() {
  const { pageSelected } = useReportContext()
  const sections = useMemo(
    () => (pageSelected ? pageSelected.sections : []),
    [pageSelected]
  )

  const footer = useMemo(
    () => ('footer' in pageSelected ? pageSelected.footer : []),
    [pageSelected]
  )

  // const sensors = useSensors(
  //   useSensor(PointerSensor),
  //   useSensor(KeyboardSensor, {
  //     coordinateGetter: sortableKeyboardCoordinates,
  //   })
  // )

  // function handleDragEnd(event: DragEndEvent) {
  //   const { active, over } = event
  //   if (!active && !over) return
  //   if (active.id !== over.id) {
  //     setPages((pages) => {
  //       const { pageIndex, subPageIndex } = pageSelectedIndexs

  //       if (pageIndex < 0) return pages

  //       if (pages[pageIndex].type === 'single') {
  //         const sections = (pages[pageIndex] as PageSingle).sections

  //         const oldIndex = sections.findIndex((item) => item.id === active.id)
  //         const newIndex = sections.findIndex((item) => item.id === over.id)

  //         return [
  //           ...pages.slice(0, pageIndex),
  //           {
  //             ...pages[pageIndex],
  //             sections: arrayMove(sections, oldIndex, newIndex),
  //           },
  //           ...pages.slice(pageIndex + 1),
  //         ]
  //       }

  //       if (subPageIndex < 0) return pages

  //       const subPages = (pages[pageIndex] as PageContainer).subPages

  //       const oldIndex = subPages[subPageIndex].sections.findIndex(
  //         (item) => item.id === active.id
  //       )
  //       const newIndex = subPages[subPageIndex].sections.findIndex(
  //         (item) => item.id === over.id
  //       )

  //       return [
  //         ...pages.slice(0, pageIndex),
  //         {
  //           ...pages[pageIndex],
  //           subPages: [
  //             ...subPages.slice(0, subPageIndex),
  //             {
  //               ...subPages[subPageIndex],
  //               sections: arrayMove(
  //                 subPages[subPageIndex].sections,
  //                 oldIndex,
  //                 newIndex
  //               ),
  //             },
  //             ...subPages.slice(subPageIndex + 1),
  //           ],
  //         },
  //         ...pages.slice(pageIndex + 1),
  //       ]
  //     })
  //   }
  // }

  return (
    <div className="PageInfo">
      <PageInfoHeader />
      {/* {
        pageSelected.isSortEnabled ? (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={sections as Array<{ id: string }>} // I add this becouse the proyect isn't strict
              strategy={verticalListSortingStrategy}
            > 
              {sections.map((section) => (
                <PageInfoCard key={section.id} section={section} />
              ))}
            </SortableContext>
          </DndContext>
        ) : ( */}
      {sections.map((section) => (
        <PageInfoCard key={section.id} section={section} />
      ))}
      {/* )
      } */}
      {footer.map((section) => (
        <PageInfoCard key={section.id} section={section} footer />
      ))}
    </div>
  )
}
