const roundToDecimals = (arrayOfValues: (string | number)[], decimals?: number): number => {
  if(decimals > 0) {
    const totalValue = Number(arrayOfValues.reduce((acc, cur) => Number(acc) + Number(cur)))

    if(totalValue) return parseFloat(totalValue.toFixed(decimals))
    else return 0
  }
  else return Number(arrayOfValues.reduce((acc, cur) => Number(acc) + Number(cur))) || 0
}

export default roundToDecimals