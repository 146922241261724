import { useMemo, useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'
import { createPVModel, getPVModelDetail, updatePVModel } from '../../api/simulations/system-parameters/pv-field'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import useModalsContext from '../../hooks/useModalsContext'
import Input from '../Input'
import InputSmall from '../InputSmall'
import Modal from './Modal'
import CheckModal from './CheckModal'
import LoadingDots from '../LoadingDots'

const NewPVModelModal = () => {
  const { token } = useAppContext()
  const { getExtraData } = useNewSimulationContext()
  const { pvModelTypeSelected, modalId, closeShowNewPVModelModal, toggleShowManagePVModelsModal } = useModalsContext()
  const [step, setStep] = useState(1)
  const [serverError, setServerError] = useState(false)

  const modelType = useMemo(() => {
    if (pvModelTypeSelected.panelSelected == 'firstPanel') {
      return pvModelTypeSelected.firstPanel
    } else if (pvModelTypeSelected.panelSelected == 'secondPanel') {
      return pvModelTypeSelected.secondPanel
    }
  }, [pvModelTypeSelected])

  const yupNumberValidation = Yup.number()
    .required('Required')
    .typeError('Only numbers')

  const formik = useFormik({
    initialValues: {
      name: '',
      panel_type: modelType || '',
      ag: '',
      h_col: '',
      w_col: '',
      ppvt: '',
      vmpp: '',
      impp: '',
      voc: '',
      isc: '',
      ref_eff: '',
      gamma_Pmpp: '',
      gamma_Voc: '',
      gamma_Isc: '',
      t_NOCT: '',
      n_cell_series: '',
      is_showed: false,
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      panel_type: Yup.string().required('Required'),
      ag: yupNumberValidation,
      h_col: yupNumberValidation,
      w_col: yupNumberValidation,
      ppvt: yupNumberValidation,
      vmpp: yupNumberValidation,
      impp: yupNumberValidation,
      voc: yupNumberValidation,
      isc: yupNumberValidation,
      ref_eff: yupNumberValidation,
      gamma_Pmpp: yupNumberValidation,
      gamma_Voc: yupNumberValidation,
      gamma_Isc: yupNumberValidation,
      t_NOCT: yupNumberValidation,
      n_cell_series: yupNumberValidation,
    }),
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        model: formik.values.name,
      }

      let response

      if (!modalId) response = await createPVModel(token, newValues)
      else {
        delete newValues.is_showed
        response = await updatePVModel(token, modalId, newValues)
      }

      const nonFieldErrors = response?.error?.non_field_errors

      if ((nonFieldErrors?.length && nonFieldErrors[0] == 'A panel model with the same name already exists' || response?.error == 'Model with this name already exists') ) {
        formik.setFieldError('name', 'This name is already in use')
      }

      if (response.data) {
        getExtraData()
        setStep(2)
      }
    },
  })

  useEffect(() => {
    if (!modalId) return
    const getInfo = async () => {
      const response = await getPVModelDetail(token, modalId)

      if (response.error) setServerError(true)
      else if (response) {
        formik.setFieldValue('name', response.name)
        formik.setFieldValue('panel_type', response.panel_type)
        formik.setFieldValue('ag', response.ag)
        formik.setFieldValue('h_col', response.h_col)
        formik.setFieldValue('w_col', response.w_col)
        formik.setFieldValue('ppvt', response.ppvt)
        formik.setFieldValue('vmpp', response.vmpp)
        formik.setFieldValue('impp', response.impp)
        formik.setFieldValue('voc', response.voc)
        formik.setFieldValue('isc', response.isc)
        formik.setFieldValue('ref_eff', response.ref_eff)
        formik.setFieldValue('gamma_Pmpp', response.gamma_Pmpp)
        formik.setFieldValue('gamma_Voc', response.gamma_Voc)
        formik.setFieldValue('gamma_Isc', response.gamma_Isc)
        formik.setFieldValue('t_NOCT', response.t_NOCT)
        formik.setFieldValue('n_cell_series', response.n_cell_series)
      }
    }
    getInfo()
  }, [modalId])

  const toggleSave = () => formik.setFieldValue('is_showed', !formik.values.is_showed)

  if (step == 1)
    return (
      <Modal
        title={!modalId ? '+ Add new PV model' : 'Edit PV model'}
        closeModal={closeShowNewPVModelModal}
        prevStep={() => {toggleShowManagePVModelsModal(); closeShowNewPVModelModal()}}
        notCloseOnBlur
      >
        <div
          className="flex flex-col justify-center items-center gap-4 h-100 py-4 mx-auto"
          style={{ maxHeight: 'calc(90dvh - 160px)' }}
        >
          <div className="Columns Two">
            <Input
              label="Type of PV"
              name="panel_type"
              value={modelType}
              placeholder="Type of PV"
              disabled
            />

            <Input
              label="Name of model"
              name="name"
              value={formik.values.name}
              placeholder="Enter model name"
              onChange={formik.handleChange}
              error={formik.errors.name}
            />
          </div>

          <div className="Modal__Divider" />

          <div className="Columns Two Modal__Scroll-Content">
            <div className="d-flex flex-column gap-3">
              <InputSmall
                topLabel="Ag"
                name="ag"
                value={formik.values.ag}
                placeholder="0"
                onChange={formik.handleChange}
                error={formik.errors.ag}
              />

              <InputSmall
                topLabel="H_col"
                name="h_col"
                value={formik.values.h_col}
                placeholder="0"
                onChange={formik.handleChange}
                error={formik.errors.h_col}
              />

              <InputSmall
                topLabel="W_col"
                name="w_col"
                value={formik.values.w_col}
                placeholder="0"
                onChange={formik.handleChange}
                error={formik.errors.w_col}
              />

              <InputSmall
                topLabel="Ppvt"
                name="ppvt"
                value={formik.values.ppvt}
                placeholder="0"
                onChange={formik.handleChange}
                error={formik.errors.ppvt}
              />

              <InputSmall
                topLabel="Vmpp"
                name="vmpp"
                value={formik.values.vmpp}
                placeholder="0"
                onChange={formik.handleChange}
                error={formik.errors.vmpp}
              />

              <InputSmall
                topLabel="Impp"
                name="impp"
                value={formik.values.impp}
                placeholder="0"
                onChange={formik.handleChange}
                error={formik.errors.impp}
              />

              <InputSmall
                topLabel="Voc"
                name="voc"
                value={formik.values.voc}
                placeholder="0"
                onChange={formik.handleChange}
                error={formik.errors.voc}
              />
            </div>
            <div className="d-flex flex-column gap-3">
              <InputSmall
                topLabel="Isc"
                name="isc"
                value={formik.values.isc}
                placeholder="0"
                onChange={formik.handleChange}
                error={formik.errors.isc}
              />

              <InputSmall
                topLabel="ref_eff"
                name="ref_eff"
                value={formik.values.ref_eff}
                placeholder="0"
                onChange={formik.handleChange}
                error={formik.errors.ref_eff}
              />

              <InputSmall
                topLabel="gamma_Pmpp"
                name="gamma_Pmpp"
                value={formik.values.gamma_Pmpp}
                placeholder="0"
                onChange={formik.handleChange}
                error={formik.errors.gamma_Pmpp}
              />

              <InputSmall
                topLabel="gamma_Voc"
                name="gamma_Voc"
                value={formik.values.gamma_Voc}
                placeholder="0"
                onChange={formik.handleChange}
                error={formik.errors.gamma_Voc}
              />

              <InputSmall
                topLabel="gamma_Isc"
                name="gamma_Isc"
                value={formik.values.gamma_Isc}
                placeholder="0"
                onChange={formik.handleChange}
                error={formik.errors.gamma_Isc}
              />

              <InputSmall
                topLabel="T_NOCT"
                name="t_NOCT"
                value={formik.values.t_NOCT}
                placeholder="0"
                onChange={formik.handleChange}
                error={formik.errors.t_NOCT}
              />

              <InputSmall
                topLabel="Ncell_series"
                name="n_cell_series"
                value={formik.values.n_cell_series}
                placeholder="0"
                onChange={formik.handleChange}
                error={formik.errors.n_cell_series}
              />
            </div>
          </div>

          {!modalId ? (
            <>
              <div className="Modal__Divider" />

              <div className="d-flex flex-column gap-2 w-100">
                <p className="text-5 fw-medium">
                  Save system for future simulations?
                </p>
                <button className="d-flex gap-2" onClick={toggleSave}>
                  <div
                    className={classNames('Checkbox', {
                      Selected: formik.values.is_showed,
                    })}
                  />
                  <p className="text-6">Yes</p>
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        {serverError ? <p className='text-red'>Something went wrong</p> : <></>}

        <button
          className={classNames('button-blue gap-0 mx-auto w-100', { 'mt-2': modalId })}
          type="submit"
          onClick={() => formik.handleSubmit()}
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? (
            <>
              <span>{!modalId ? 'Creating' : 'Saving'}</span>
              <LoadingDots className='enter-done' />
            </>
          ) : (
            <span>{!modalId ? 'Create' : 'Save'}</span>
          )}
        </button>
      </Modal>
    )

  if (step == 2)
    return (
      <CheckModal
        closeModal={closeShowNewPVModelModal}
        title={!modalId ? '+ Add new PV model' : 'Edit PV model'}
        message={
          !modalId
            ? 'The new PV model has been added successfully, now you can select it in the simulation'
            : 'PV model has been edited successfully'
        }
      />
    )
}

export default NewPVModelModal
