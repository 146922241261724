// import classNames from 'classnames'
import { memo } from 'react'

interface Props {
  title: string;
  value: string;
  caption?: string;
  onlyValue?: boolean;
}

const SummaryField = ({
  title,
  value,
  caption,
  onlyValue = false,
}: Props) => {
  if(onlyValue) return (
    <div className='SummaryField onlyValue' >
      <div className="SummaryField__Data" title={title}>
        <span className="text-4 text-secondary">{value}</span>
        {caption && (
          <span className="text-5 text-secondary ms-2">{caption}</span>
        )}
      </div>
    </div>
  )
  else return (
    <div className="SummaryField Half" >
      <div className="SummaryField__Title" title={title}>
        <p className="text-5 fw-medium">{title}</p>
      </div>
      <div className="SummaryField__Data">
        <p className="text-4 text-secondary">{value}</p>
        {caption && (
          <span className="text-5 text-secondary ms-2">{caption}</span>
        )}
      </div>
    </div>
  )
}

export default memo(SummaryField)
