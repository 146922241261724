import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { getCountries, updateOrganization } from '../../api/organizations'
import useAppContext from '../../hooks/useAppContext'
import CheckModal from './CheckModal'
import Modal from './Modal'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Input from '../Input'
import SearchInput from '../SearchInput'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import LoadingDots from '../LoadingDots'
import { Organization } from '../../api/apiTypes'
import 'react-phone-number-input/style.css'
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneNumberInput from '../PhoneNumberInput'

interface Props {
  closeModal: () => void;
  organization: Organization;
  refetch: () => void;
}

const EditOrganizationModal = ({
  organization,
  refetch,
  closeModal,
}: Props) => {
  const { token } = useAppContext()
  const [step, setStep] = useState(1)
  const { data } = useQuery(['getCountries'], () => getCountries(token), {
    initialData: {
      user_countries: [],
      all_countries: [],
    },
    enabled: token !== '',
  })
  const countries = useMemo(
    () =>
      data.all_countries
        ? data.all_countries.map((country) => ({
          value: country.id,
          label: country.name,
        }))
        : [],
    [data]
  )
  const [serverError, setServerError] = useState(false)

  const formik = useFormik<{
    name: string;
    country_residence: number | null;
    address: string;
    email: string;
    phone: string;
  }>({
    initialValues: {
      name: organization.name ?? '',
      country_residence: organization.country_residence.id ?? null,
      address: organization.address ?? '',
      email: organization.email ?? '',
      phone: organization.phone ?? '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      country_residence: Yup.number().required('Required'),
      address: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email').required('Required'),
      phone: Yup.string()
        .required('Required')
        .test((value) => isValidPhoneNumber(value)),
    }),
    onSubmit: async (data) => {
      const response = await updateOrganization(token, {
        organization_id: organization.id,
        ...data,
      })

      if (response.error) {
        if (typeof response.error === 'object' && response.error !== null)
          Object.keys(response.error).forEach((key) =>
            formik.setFieldError(key, response.error[key][0])
          )
        else setServerError(true)
      } else if (response) {
        setStep((prev) => prev + 1)
        refetch()
      }
    },
  })

  switch (step) {
  case 1:
    return (
      <Modal title="Organization info." closeModal={closeModal}>
        <div className="Modal__Scroll-Content d-flex flex-column gap-3 py-3">
          <Input
            label="Name"
            name="name"
            placeholder="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.errors.name}
          />
          <SearchInput
            label="Country"
            name="country_residence"
            placeholder="Country"
            search={countries}
            value={Number(formik.values.country_residence)}
            onChange={(id) => formik.setFieldValue('country_residence', id)}
            error={formik.errors.country_residence}
          />
          <Input
            label="Address"
            name="address"
            placeholder="Address"
            value={formik.values.address}
            onChange={formik.handleChange}
            error={formik.errors.address}
          />
          <Input
            label="Contact email"
            name="email"
            type="email"
            placeholder="someone@gmail.com"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email}
          />

          <PhoneNumberInput
            label="Phone number"
            name="phone"
            value={formik.values.phone}
            onChange={(value) => formik.setFieldValue('phone', value)}
            errors={formik.errors.phone}
          />

          {serverError ? (
            <p className="text-red">Something went wrong</p>
          ) : (
            <></>
          )}

          <div className="d-flex flex-column align-items-center gap-2">
            <SwitchTransition>
              <CSSTransition
                key={formik.isSubmitting}
                timeout={300}
                unmountOnExit
              >
                <button
                  className="button-blue gap-0 w-100"
                  type="submit"
                  onClick={() => formik.handleSubmit()}
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? (
                    <>
                      <span>Saving</span>
                      <LoadingDots className="enter-done" />
                    </>
                  ) : (
                    <span>Save changes</span>
                  )}
                </button>
              </CSSTransition>
            </SwitchTransition>
          </div>
        </div>
      </Modal>
    )
  default:
    return (
      <CheckModal
        closeModal={closeModal}
        title="Organization info."
        message="¡Organization edited succesfully!"
      />
    )
  }
}

export default EditOrganizationModal
