import { useMemo } from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { Page } from '../../types/generateReport'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 8,
    width: '100%',
  },
  text: {
    flexShrink: 0,
    paddingRight: 4,
    fontSize: 12,
  },
  dots: {
    flexGrow: 1,
    borderBottomWidth: 2,
    borderBottomStyle: 'dotted',
    borderBottomColor: ' #252525',
    fontSize: 12,
  },
  number: {
    flexShrink: 0,
    paddingLeft: 4,
    fontSize: 12,
  },
})

type Props = {
  pages: Array<Page>;
};

export default function TableOfContentsRender({ pages }: Props) {
  const pagesListed = useMemo(() => {
    let pageNumber = 1
    const pagesArray: Array<{ id: string; title: string; index: number }> = []

    for (const page of pages) {
      if (page.type === 'single' && !page.hiddenInTableOfContents) {
        pagesArray.push({ id: page.id, title: page.title, index: pageNumber })
        pageNumber += page.subPagesCount ?? 1
      } else if (page.type === 'single') {
        pageNumber += page.subPagesCount ?? 1
      } else if (page.type === 'container') {
        for (const subPage of page.subPages) {
          pagesArray.push({
            id: subPage.id,
            title: subPage.title,
            index: pageNumber,
          })
          pageNumber += subPage.subPagesCount ?? 1
        }
      }
    }

    return pagesArray
  }, [pages])

  return (
    <View
      style={styles.container}
      render={() =>
        pagesListed.map((page) => (
          <View key={page.id} style={styles.item}>
            <Text style={styles.text}>{page.title}</Text>
            {/* <Text style={styles.dots}>
          ......................................................................................
        </Text> */}
            <View style={styles.dots} />
            <Text style={styles.number}>{page.index}</Text>
          </View>
        ))
      }
    />
  )
}
