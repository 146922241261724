import { useState } from 'react'
import { Organization } from '../../api/apiTypes'
import Modal from './Modal'
import CheckModal from './CheckModal'
import useAppContext from '../../hooks/useAppContext'
import { IAdmin, IAlert, ILocation, IMail, IPhone, IWorld } from '../../iconComponents'
import LoadingDots from '../LoadingDots'
import { deleteOrganization, getOrganizationDetail } from '../../api/organizations'
import { useQuery } from 'react-query'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { formatPhoneNumberIntl } from 'react-phone-number-input'

interface Props {
  closeModal: () => void;
  organizationInfo: Organization;
  refetch: () => void;
}

const DeleteOrganizationModal = ({organizationInfo, refetch, closeModal}: Props) => {
  const { token } = useAppContext()
  const [step, setStep] = useState(1)
  const [buttonModalIsLoading, setButtonModalIsLoading] = useState(false)
  const [serverError, setServerError] = useState(false)

  const { data: organizationDetail } = useQuery(
    ['getOrganizationDetail', organizationInfo.id],
    () => getOrganizationDetail(token, organizationInfo.id),
    {
      initialData: {
        id: 0,
        name: '',
        country_residence: {id: 0, country_name: ''},
        phone: '',
        admin_email: '',
        email: '',
        address: '',
        operating_countries: 0,
        admins: [],
        regions: [],
        users: 0,
      },
      enabled: token !== '',
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    }
  )

  const handleDelete = async () => {
    if (buttonModalIsLoading) return
    setButtonModalIsLoading(true)

    const response = await deleteOrganization(token, {
      organization_id: organizationInfo.id,
    })

    if (response.error) setServerError(true)
    else if (response) {
      refetch()
      setStep(2)
    }

    setButtonModalIsLoading(false)
  }

  if (step === 1)
    return (
      <Modal title="Delete organization" closeModal={closeModal}>
        <div className="Modal__Scroll-Content d-flex flex-column gap-3 p-3">
          <IAlert
            width={60}
            height={60}
            className="flex-shrink-0 text-yellow d-block mx-auto"
          />
          <p className="text-3 text-center fw-medium">
          Are you sure you want to delete “{organizationInfo.name}”?
          </p>

          <div className='d-flex align-items-center gap-3 m-auto' style={{maxWidth: '400px'}}>
            <IAlert className='flex-shrink-0' />
            <p className='text-5'>All the information, users, simulations & clients from this organization will be permanetly deleted</p>
          </div>
          
          <div className="Modal__Divider" />

          <p className="text-center text-4">{organizationInfo.name}</p>

          <div className="Modal__Divider" />

          <div className="DeleteSimulationModal__Grid my-4">
            <div className="DeleteSimulationModal__Grid_Item">
              <div className="DeleteSimulationModal__Grid_Item_Title">
                <ILocation className='DeleteSimulationModal__Grid_Item_Title_Icon' />
                
                <p className="text-5 text-secondary">Location</p>
              </div>
              <div className="DeleteSimulationModal__Grid_Item_Info text-5">
                {organizationInfo.address || '-'}
              </div>
            </div>
            <div className="DeleteSimulationModal__Grid_Item">
              <div className="DeleteSimulationModal__Grid_Item_Title">
                <IAdmin className='DeleteSimulationModal__Grid_Item_Title_Icon' />
                
                <p className="text-5 text-secondary">Admin</p>
              </div>
              <div className="DeleteSimulationModal__Grid_Item_Info text-5">
                {organizationInfo.admins.length !== 0 && typeof organizationInfo.admins[0].first_name
                  ? organizationInfo.admins
                    .reduce(
                      (acc, cur) =>
                        acc +
                    `${
                      cur.first_name
                        ? `${cur.first_name} ${cur.last_name}`
                        : 'Invitation sent'
                    }, `,
                      ''
                    )
                    .slice(0, -2)
                  : '-'}
              </div>
            </div>
            <div className="DeleteSimulationModal__Grid_Item">
              <div className="DeleteSimulationModal__Grid_Item_Title">
                <IWorld className='DeleteSimulationModal__Grid_Item_Title_Icon'/>
               
                <p className="text-5 text-secondary">Countries</p>
              </div>
              <div className="DeleteSimulationModal__Grid_Item_Info text-5">
                {organizationInfo.operating_countries ?? 0}
              </div>
            </div>
            <div className="DeleteSimulationModal__Grid_Item">
              <div className="DeleteSimulationModal__Grid_Item_Title">
                <IMail className='DeleteSimulationModal__Grid_Item_Title_Icon' />
                
                <p className="text-5 text-secondary">Email</p>
              </div>
              <div className="DeleteSimulationModal__Grid_Item_Info text-5">
                {organizationInfo.email || '-'}
              </div>
            </div>
            <div className="DeleteSimulationModal__Grid_Item">
              <div className="DeleteSimulationModal__Grid_Item_Title">
                <ILocation className='DeleteSimulationModal__Grid_Item_Title_Icon' />
                <p className="text-5 text-secondary">Regions</p>
              </div>
              <div className="DeleteSimulationModal__Grid_Item_Info text-5">
                {organizationDetail.regions.length ?? 0}
              </div>
            </div>
            <div className="DeleteSimulationModal__Grid_Item">
              <div className="DeleteSimulationModal__Grid_Item_Title">
                <IPhone className='DeleteSimulationModal__Grid_Item_Title_Icon' />
                <p className="text-5 text-secondary">Phone  number</p>
              </div>
              <div className="DeleteSimulationModal__Grid_Item_Info text-5">
                {isValidPhoneNumber(organizationInfo.phone) ? formatPhoneNumberIntl(organizationInfo.phone) : organizationInfo.phone || '-'}
              </div>
            </div>
          </div>

          {serverError ? <p className="text-red">Something went wrong</p> : <></>}

          <div className="d-flex align-items-center gap-2">
            <button
              className="button_transparent_red button_defult w-100 text-4 gap-0"
              type='submit'
              onClick={handleDelete}
              disabled={buttonModalIsLoading}
            >
              {buttonModalIsLoading ? (
                <>
                  <span>Deleting</span>
                  <LoadingDots className="enter-done" />
                </>
              ) : (
                <span className='text-red'>Yes, delete organization</span>
              )}
              
            </button>
            <button
              className="button_transparent_blue w-100 text-4"
              onClick={closeModal}
            >
              No, do not delete
            </button>
          </div>
        </div>
      </Modal>
    )

  return (
    <CheckModal
      closeModal={closeModal}
      title="Delete organization"
      message="¡Organization deleted successfully!"
    />
  )
}

export default DeleteOrganizationModal