import classNames from 'classnames'

export type ButtonVariants =
  | 'blue'
  | 'blue10'
  | 'transparent'
  | 'transparent_blue'
  | 'transparent_red'
  | 'white_blue'
  | 'green'
  | 'red';
export type ButtonSizes = 'xs' | 's' | 'm' | 'l';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: ButtonVariants;
  size?: ButtonSizes;
  active?: boolean;
  hidden?: boolean;
}

export default function Button({
  className,
  variant = 'blue',
  size = 'm',
  active,
  hidden,
  children,
  ...props
}: Props) {
  return (
    <button
      className={classNames('Button', variant, size, { active, hidden }, className)}
      {...props}
    >
      {children}
    </button>
  )
}
