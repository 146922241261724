import ReactPDF, { Path, Svg } from '@react-pdf/renderer'

export default function ImageSvg({...props}: ReactPDF.SVGProps) {
  return (
    <Svg viewBox="0 0 32 32" {...props}>
      <Path
        d="M26 5.00031H6C4.34315 5.00031 3 6.34353 3 8.00047V24.0014C3 25.6583 4.34315 27.0015 6 27.0015H26C27.6569 27.0015 29 25.6583 29 24.0014V8.00047C29 6.34353 27.6569 5.00031 26 5.00031Z"
        stroke="#696969"
        strokeWidth="1.5"
      />
      <Path
        d="M21 13.0008C22.1046 13.0008 23 12.1053 23 11.0007C23 9.89603 22.1046 9.00055 21 9.00055C19.8954 9.00055 19 9.89603 19 11.0007C19 12.1053 19.8954 13.0008 21 13.0008Z"
        stroke="#696969"
        strokeWidth="1.5"
      />
      <Path
        d="M19 20.9881L13.3337 15.3321C12.9732 14.9716 12.4886 14.7624 11.9789 14.7473C11.4693 14.7322 10.9731 14.9123 10.5919 15.2509L3 22.0012M14 27.0015L21.7087 19.2923C22.0613 18.9391 22.5332 18.7304 23.0317 18.7073C23.5302 18.6841 24.0194 18.8482 24.4031 19.1673L29 23.0013"
        stroke="#696969"
        strokeWidth="1.5"
      />
    </Svg>
  )
}
