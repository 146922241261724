import { z } from 'zod'
import { deleteQuery, getQuery, patchQuery, postQuery } from './apiQueries'
import { GalleryType } from '../types/generateReport'
import { SimulationDetails, SimulationResults } from '../types/simulation'

// galery
export const getReportImagesGallery = async (
  token: string,
  params?: {
    gallery_type?: GalleryType;
  }
) => {
  return await getQuery({
    path: '/api/organizations/report-image-gallery/',
    token,
    params,
    successSchema: z.object({
      data: z.array(
        z.object({
          id: z.number().int(),
          image: z.string(),
          name: z.nullable(z.string()),
          gallery_type: z.string(),
          organization:z.nullable(z.number().int()),
          owner: z.nullable(z.number().int()),
        })
      ),
    }),
    errorSchema: z.string(),
  })
}

export const  addReportImageToGallery = async (token:string, body: FormData) => {
  return await postQuery({
    path: '/api/organizations/report-image-gallery/',
    token,
    body,
    httpHeaders: {},
    successSchema: z.object({
      id: z.number().int(),
      image: z.string(),
      gallery_type: z.string(),
      organization: z.nullable(z.number().int()),
      owner: z.nullable(z.number().int()),
    }),
    errorSchema: z.string(),
  })
}

export const deleteReportImageFromGallery = async (token: string, body: { image_id: number }) => {
  return await deleteQuery({
    path: '/api/organizations/report-image-gallery/',
    token,
    body:  JSON.stringify(body),
    successSchema: z.null(),
    errorSchema: z.string(),
  }) 
}

export const uploadImages3 = async (token: string, body: FormData) => {
  return await postQuery({
    path: '/api/organizations/upload-image-s3/',
    token,
    body,
    httpHeaders: {},
    successSchema: z.object({
      url: z.string().url(),
    }),
    errorSchema: z.string(),
  })
}

export const getSimulationDetails = async (
  token: string,
  simulation_id: number
) => {
  return getQuery<{ data: SimulationDetails }, string>({
    path: `/api/simulations/${simulation_id}/`,
    token,
  })
}

export const getSimulationResults = async (token: string, simulation_id: number) => {
  return getQuery<{data: SimulationResults }, string>({
    path: `/api/simulations/generate-report/${simulation_id}/`,
    token,
  })
}

export const getSimulationTable = async (path) => {
  return getQuery<string, string>({
    path,
    httpHeaders: {
      'Content-Type': 'text/plain'
    },
  })
}

export const getSimulationGraphs = async (
  token: string,
  simulation_id: number,
  params?: { report_lenguaje: 'en' | 'du' }
) => {
  return await getQuery({
    path: `/api/simulations/simulation-graphs/${simulation_id}/`,
    token,
    params,
    successSchema: z.object({
      data: z.object({
        CAPEX_graph: z.string().url(),
        energy_plot_report: z.string().url(),
        PPA_graph_1: z.string().url(),
        PPA_graph_2: z.string().url(),
        PPA_graph_3: z.string().url(),
      }),
    }),
    errorSchema: z.string(),
  })
}

export const uploadReportInfo = async (
  token: string,
  body: { 
    simulation_id: number; 
    report_information_en?: string
    report_information_du?: string
  }
) => {
  return await patchQuery({
    path: '/api/simulations/',
    token,
    body: JSON.stringify(body),
    errorSchema: z.string(),
  })
}
