import { memo } from 'react'
import classNames from 'classnames'

interface Props<T> {
  data: T;
  unselectable?: boolean;
  selected?: boolean;
  handleSelectItem: (data: T) => void;
  children: React.ReactNode;
  className?: string;
}

function TableItem<T>({
  data,
  unselectable,
  selected = false,
  handleSelectItem,
  children,
  className
}: Props<T> ) {
  const handleDisabled = () => {
    if (unselectable) return null
    handleSelectItem(data)
  }

  return (
    <div
      className={classNames(
        'TableItem',
        { Selected: selected },
        { Unselectable: unselectable },
        'text-5',
        className
      )}
      onClick={handleDisabled}
    >
      {children}
    </div>
  )
}

export default memo(TableItem)
