import { useFormik } from 'formik'
import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import * as Yup from 'yup'
import {
  getCountries,
  getOrganizationRegions,
  getOrganizations,
} from '../../api/organizations'
import { userTypes } from '../../constants'
import useAppContext from '../../hooks/useAppContext'
import { IDelete } from '../../iconComponents'
import Input from '../Input'
import SearchInput from '../SearchInput'
import CheckModal from './CheckModal'
import Modal from './Modal'
import { addUser } from '../../api/auth'
import LoadingDots from '../LoadingDots'
import classNames from 'classnames'
import { isValidPhoneNumber } from 'libphonenumber-js'
import PhoneNumberInput from '../PhoneNumberInput'

interface Props {
  closeModal: () => void;
  refetch: () => void;
}

const CreateUserModal = ({ closeModal, refetch }: Props) => {
  const { token, user, isCentralAdmin, isOrganizationAdmin } = useAppContext()
  const [step, setStep] = useState(1)
  const [serverError, setServerError] = useState(false)
  const [officeError, setOfficeError] = useState<Array<string>>([])
  const [createOrganization, setCreateOrganization] = useState(false)
  const [createRegion, setCreateRegion] = useState(false)
  const initialData = { page: 1, total_items: 0, total_pages: 1, data: [] }
  const { data: organizatoins } = useQuery(
    ['getOrganizations'],
    () => getOrganizations(token, initialData),
    {
      initialData,
      enabled: token !== '' && isCentralAdmin,
    }
  )
  const organizatoinsForInput = useMemo(
    () =>
      organizatoins.data?.map((organizatoin) => ({
        value: organizatoin.id,
        label: organizatoin.name,
      })),
    [organizatoins]
  )
  const [countryList, setCountryList] = useState<Array<number>>([])
  const [countryError, setCountryError] = useState('')
  const [resetInput, setResetInput] = useState(false)
  const { data } = useQuery(['getCountries'], () => getCountries(token), {
    initialData: {
      user_countries: [],
      all_countries: [],
    },
    enabled: token !== '',
  })
  const countries = useMemo(
    () =>
      data.all_countries
        ? data.all_countries.map((country) => ({
          value: country.id,
          label: country.name,
        }))
        : [],
    [data]
  )
  const countriesFiltered = useMemo(
    () => countries.filter((country) => !countryList.includes(country.value)),
    [countries, countryList]
  )

  const formik = useFormik({
    initialValues: {
      email: '',
      user_type: isCentralAdmin
        ? 'organization_admin'
        : isOrganizationAdmin
          ? 'region_admin'
          : 'operator',

      organization_id: isCentralAdmin ? '' : user.organization.id,
      region_id: '',

      organization_name: '',
      organization_country_residence: '',
      organization_address: '',
      organization_contact_email: '',
      organization_phone: '',

      region_name: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      email: Yup.string().email('invalid mail').required('Required'),
      user_type: Yup.string().required('Required'),

      organization_id: Yup.number().test(
        'check if is required',
        'Required',
        (value) =>
          (!createOrganization && typeof value === 'number') ||
          createOrganization
      ),
      region_id: Yup.number().test(
        'check if is required',
        'Required',
        (value) =>
          (formik.values.user_type !== 'organization_admin' &&
            !createRegion &&
            typeof value === 'number') ||
          createRegion ||
          formik.values.user_type === 'organization_admin'
      ),

      organization_name: Yup.string().test(
        'check if is required',
        'Required',
        (value) =>
          (createOrganization && typeof value === 'string') ||
          !createOrganization
      ),
      organization_country_residence: Yup.string().test(
        'check if is required',
        'Required',
        (value) =>
          (createOrganization && typeof value === 'string') ||
          !createOrganization
      ),
      organization_address: Yup.string().test(
        'check if is required',
        'Required',
        (value) =>
          (createOrganization && typeof value === 'string') ||
          !createOrganization
      ),
      organization_contact_email: Yup.string()
        .email('invalid mail')
        .test(
          'check if is required',
          'Required',
          (value) =>
            (createOrganization && typeof value === 'string') ||
            !createOrganization
        ),
      organization_phone: Yup.string()
        .test(
          'check if is required',
          'Required',
          (value) =>
            (createOrganization && typeof value === 'string' && isValidPhoneNumber(value)) ||
            !createOrganization
        ),
      region_name: Yup.string().test(
        'check if is required',
        'Required',
        (value) => (createRegion && typeof value === 'string') || !createRegion
      ),
    }),
    onSubmit: async ({
      email,
      user_type,
      organization_id,
      region_id,
      // organization_name,
      // organization_country_residence,
      // organization_address,
      // organization_contact_email,
      // organization_phone,
      region_name,
    }) => {
      if (
        user_type === 'region_admin' &&
        createRegion &&
        countryList.length === 0
      )
        return setCountryError('at least one country is required')
      if (user_type === 'organization_admin' && createOrganization)
        return setStep(2)

      const body: {
        email: string;
        user_type: string;
        organization_id?: number;
        region_id?: number;
        organization_name?: string;
        organization_country_residence?: string;
        organization_address?: string;
        organization_contact_email?: string;
        organization_phone?: string;
        region_name?: string;
        country_ids?: string;
      } = { email, user_type }
      if (user_type === 'organization_admin')
        body.organization_id = Number(organization_id)
      else if (user_type === 'region_admin' && createRegion) {
        body.organization_id = Number(organization_id)
        body.region_name = region_name
        body.country_ids = countryList
          .filter((countryId) => countryId !== 0)
          .reduce((acc, cur) => acc + `${cur},`, '')
          .slice(0, -1)
      } else {
        body.organization_id = Number(organization_id)
        body.region_id = Number(region_id)
      }

      const response = await addUser(token, body)

      if (response.error === 'User with this email already exists') {
        formik.setErrors({
          email: 'User with this email already exists',
        })
      } else if (
        response.error === 'Organization with this name already exists'
      ) {
        formik.setErrors({
          organization_name: 'Organization with this name already exists',
        })
      } else if (
        response.error === 'Region already exists in this organization'
      ) {
        formik.setErrors({
          region_name: 'Region already exists in this organization',
        })
      } else if (response.error) {
        setServerError(true)
      } else if (response.detail === 'Invitation email sent') {
        refetch()
        setStep(3)
      }
    },
  })

  const formikTwo = useFormik<{
    office_name: string;
    office_country: number | null;
    office_city: string;
    office_zip_code: string;
    address_line_1: string;
    address_line_2: string;
    office_phone: string;
    office_email: string;
  }>({
    initialValues: {
      office_name: '',
      office_country: null,
      office_city: '',
      office_zip_code: null,
      address_line_1: '',
      address_line_2: '',
      office_phone: '',
      office_email: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      office_name: Yup.string().required('Required'),
      office_country: Yup.number().required('Required'),
      office_city: Yup.string().required('Required'),
      office_zip_code: Yup.string().required('Required'),
      address_line_1: Yup.string().required('Required'),
      address_line_2: Yup.string(),
      office_phone: Yup.string()
        .required('Required')
        .test((value) => isValidPhoneNumber(value)),
      office_email: Yup.string().email('Invalid email').required('Required'),
    }),
    onSubmit: async ({
      office_name,
      office_country,
      office_city,
      office_zip_code,
      address_line_1,
      address_line_2,
      office_phone,
      office_email,
    }) => {
      const body: {
        email: string;
        user_type: string;
        organization_id?: number;
        region_id?: number;
        organization_name?: string;
        organization_country_residence?: string;
        organization_address?: string;
        organization_contact_email?: string;
        organization_phone?: string;
        office_name?: string;
        office_country?: number;
        office_city?: string;
        office_zip_code?: string;
        address_line_1?: string;
        address_line_2?: string;
        office_phone?: string;
        office_email?: string;
        region_name?: string;
        country_ids?: string;
      } = {
        email: formik.values.email,
        user_type: formik.values.user_type,
        organization_name: formik.values.organization_name,
        organization_country_residence:
          formik.values.organization_country_residence,
        organization_address: formik.values.organization_address,
        organization_contact_email: formik.values.organization_contact_email,
        organization_phone: formik.values.organization_phone,
        office_name,
        office_country,
        office_city,
        office_zip_code,
        address_line_1,
        office_phone,
        office_email,
      }
      if (address_line_2) body.address_line_2 = address_line_2

      const response = await addUser(token, body)

      if (
        response.email ||
        response.user_type ||
        response.organization_name ||
        response.organization_country_residence ||
        response.organization_address ||
        response.organization_contact_email ||
        response.organization_phone ||
        response.office_name ||
        response.office_country ||
        response.office_city ||
        response.office_zip_code ||
        response.address_line_1 ||
        response.address_line_2 ||
        response.office_phone ||
        response.office_email
      ) {
        const keys = [
          'email',
          'user_type',
          'organization_name',
          'organization_country_residence',
          'organization_address',
          'organization_contact_email',
          'organization_phone',
        ]

        if (
          response.email ||
          response.user_type ||
          response.organization_name ||
          response.organization_country_residence ||
          response.organization_address ||
          response.organization_contact_email ||
          response.organization_phone
        ) {
          setOfficeError([])
          Object.keys(response).forEach((key) =>
            keys.includes(key)
              ? setOfficeError((p) =>
                response[key] instanceof Array
                  ? [...p, ...response[key]]
                  : [...p, response[key]]
              )
              : null
          )
        }
        Object.keys(response).forEach((key) =>
          formikTwo.setFieldError(
            key,
            response[key] instanceof Array ? response[key][0] : response[key]
          )
        )
      } else if (response.error === 'User with this email already exists') {
        setOfficeError(['User with this email already exists'])
        formik.setErrors({
          email: 'User with this email already exists',
        })
      } else if (
        response.error === 'Organization with this name already exists'
      ) {
        setOfficeError(['User with this email already exists'])
        formik.setErrors({
          organization_name: 'User with this email already exists',
        })
      } else if (response.error) {
        setOfficeError(['Something went wrong'])
      } else if (response) {
        setStep((prev) => prev + 1)
        refetch()
      }
    },
  })

  const { data: regions, isFetching: isRegionsFetching } = useQuery(
    ['getOrganizationRegions'],
    () => getOrganizationRegions(token, formik.values.organization_id),
    {
      initialData: { data: [] },
      enabled:
        token !== '' &&
        (isCentralAdmin || isOrganizationAdmin) &&
        formik.values.user_type !== 'organization_admin' &&
        Boolean(formik.values.organization_id),
    }
  )
  const regionsForInput = useMemo(
    () =>
      regions.data.map((region) => ({
        value: region.id,
        label: region.name,
      })),
    [regions]
  )

  const usertypesObject = Object.keys(userTypes)
    .slice(isCentralAdmin ? 1 : isOrganizationAdmin ? 2 : 3)
    .map((value) => ({ value, label: userTypes[value] }))

  const selectCreateOrganization = () => {
    setCreateOrganization(true)
  }

  const selectChooseOrganization = () => {
    setCreateOrganization(false)
  }

  const selectCreateRegion = () => {
    setCreateRegion(true)
  }

  const selectChooseRegion = () => {
    setCreateRegion(false)
  }

  const handleSearch = (value: string) => {
    if (!countryList.includes(Number(value)))
      setCountryList((prev) => [...prev, Number(value)])

    setResetInput(true)
    setTimeout(() => {
      setResetInput(false)
    })
  }

  const handleRemoveCountry = (value: number) => {
    setCountryList((prev) => {
      const contryIndex = prev.findIndex((country) => country === value)
      return [...prev.slice(0, contryIndex), ...prev.slice(contryIndex + 1)]
    })
  }

  switch (step) {
  case 1:
    return (
      <Modal title="Create user" closeModal={closeModal} notCloseOnBlur>
        <div className="Modal__Scroll-Content d-flex flex-column gap-3 p-3">
          <Input
            label="Email"
            name="email"
            type="email"
            placeholder="someone@gmail.com"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email}
            autoComplete="nope"
          />

          {isCentralAdmin || isOrganizationAdmin ? (
            <Input
              label="User type"
              name="user_type"
              placeholder="User type"
              list={usertypesObject}
              value={usertypesObject.find(
                (option) => option.value === formik.values.user_type
              )}
              onChange={(name, value) => {
                setCreateOrganization(false)
                setCreateRegion(false)
                formik.setFieldValue(name, value.value)
              }}
              error={formik.errors.user_type}
            />
          ) : (
            <Input
              label="User type"
              name="user_type"
              value={formik.values.user_type}
              disabled
            />
          )}

          {isCentralAdmin ? (
            formik.values.user_type === 'organization_admin' &&
              createOrganization ? (
                <>
                  <div className="d-flex align-items-center gap-1 w-100">
                    <p className="d-inline fw-medium">
                      Organization information{' '}
                    </p>
                    <button
                      className="Input__Button ms-auto text-6 fw-light"
                      onClick={selectChooseOrganization}
                    >
                      Choose organization
                    </button>
                  </div>
                  <Input
                    label="Name"
                    name="organization_name"
                    placeholder="Name"
                    value={formik.values.organization_name}
                    onChange={formik.handleChange}
                    error={formik.errors.organization_name}
                  />
                  <SearchInput
                    label="Country"
                    name="organization_country_residence"
                    placeholder="Country"
                    search={countries}
                    value={Number(formik.values.organization_country_residence)}
                    onChange={(id) =>
                      formik.setFieldValue('organization_country_residence', id)
                    }
                    error={formik.errors.organization_country_residence}
                  />
                  <Input
                    label="Address"
                    name="organization_address"
                    placeholder="Address"
                    value={formik.values.organization_address}
                    onChange={formik.handleChange}
                    error={formik.errors.organization_address}
                  />
                  <Input
                    label="Contact email"
                    name="organization_contact_email"
                    type='email'
                    placeholder="someone@gmail.com"
                    value={formik.values.organization_contact_email}
                    onChange={formik.handleChange}
                    error={formik.errors.organization_contact_email}
                  />
                  <PhoneNumberInput
                    label="Phone number"
                    name="organization_phone"
                    value={formik.values.organization_phone}
                    onChange={(value) =>
                      formik.setFieldValue('organization_phone', value)
                    }
                    errors={formik.errors.organization_phone}
                  />
                </>
              ) : (
                <SearchInput
                  label="Organization"
                  name="organization_id"
                  placeholder="Choose organization"
                  search={
                    isCentralAdmin
                      ? organizatoinsForInput
                      : [
                        {
                          value: user.organization.id,
                          label: user.organization.name,
                        },
                      ]
                  }
                  value={Number(formik.values.organization_id)}
                  onChange={(id) => formik.setFieldValue('organization_id', id)}
                  error={formik.errors.organization_id}
                  button={
                    formik.values.user_type === 'organization_admin' ? (
                      <button
                        className="Input__Button ms-auto text-6 fw-light"
                        onClick={selectCreateOrganization}
                      >
                        + add new organization & add new office
                      </button>
                    ) : (
                      <></>
                    )
                  }
                />
              )
          ) : (
            <Input
              label="Organization"
              name="organization_id"
              value={user?.organization?.name}
              disabled
            />
          )}

          {isCentralAdmin || isOrganizationAdmin ? (
            formik.values.user_type === 'region_admin' && createRegion ? (
              <>
                <div className="d-flex align-items-center gap-1 w-100">
                  <p className="d-inline fw-medium">Region information </p>
                  <button
                    className="Input__Button ms-auto text-6 fw-light"
                    onClick={selectChooseRegion}
                  >
                      Choose region
                  </button>
                </div>
                <Input
                  label="Name"
                  name="region_name"
                  placeholder="name"
                  value={formik.values.region_name}
                  onChange={formik.handleChange}
                  error={formik.errors.region_name}
                />
                <SearchInput
                  label="Country"
                  name="Country"
                  search={countriesFiltered}
                  onChange={handleSearch}
                  reset={resetInput}
                  error={countryError}
                />
                <div className="d-flex flex-wrap gap-3">
                  {countryList.map((item) => {
                    const countrySelected = countries.find(
                      (country) => country.value === item
                    )
                    if (countrySelected)
                      return (
                        <button
                          key={item}
                          className="button-pill d-flex align-items-center gap-2 text-6"
                          onClick={() => handleRemoveCountry(item)}
                        >
                          <span>{countrySelected.label}</span>
                          <IDelete width={12} height={12} />
                        </button>
                      )
                  })}
                </div>
              </>
            ) : formik.values.user_type === 'region_admin' ||
                formik.values.user_type === 'operator' ? (
                <SearchInput
                  label="Region"
                  name="region_id"
                  placeholder="Choose region"
                  search={regionsForInput}
                  value={Number(formik.values.region_id)}
                  onChange={(id) => formik.setFieldValue('region_id', id)}
                  error={
                    formik.errors.region_id ||
                    (formik.values.organization_id &&
                      !isRegionsFetching &&
                      regionsForInput.length === 0)
                      ? 'no regions'
                      : ''
                  }
                  button={
                    formik.values.user_type === 'region_admin' ? (
                      <button
                        className="Input__Button ms-auto text-6 fw-light"
                        onClick={selectCreateRegion}
                      >
                        + add new region
                      </button>
                    ) : (
                      <></>
                    )
                  }
                  disabled={
                    formik.values.organization_id
                      ? regionsForInput.length === 0
                      : true
                  }
                />
              ) : (
                <></>
              )
          ) : (
            <Input
              label="Region"
              name="region_id"
              value={user?.region?.name}
              disabled
            />
          )}

          {serverError ? (
            <p className="text-red">Something went wrong</p>
          ) : (
            <></>
          )}

          <div
            className={classNames(
              'd-flex flex-column align-items-center gap-2',
              {
                'py-4': !(
                  formik.values.user_type === 'organization_admin' &&
                  createOrganization
                ),
              }
            )}
          >
            <button
              className="button-blue gap-0 text-4 w-100"
              type="submit"
              onClick={() => formik.handleSubmit()}
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? (
                <>
                  <span>Creating</span>
                  <LoadingDots className="enter-done" />
                </>
              ) : formik.values.user_type === 'organization_admin' &&
                createOrganization ? (
                  <span>Create account</span>
                ) : (
                  <span>Continue</span>
                )}
            </button>
          </div>
          {formik.values.user_type === 'organization_admin' &&
          createOrganization ? (
              <div className="AuthCard__Steps mt-2">
                <div className="Radio Selected"></div>
                <div className="Radio"></div>
              </div>
            ) : (
              <></>
            )}
        </div>
      </Modal>
    )

  case 2:
    return (
      <Modal
        title={'Add new office'}
        prevStep={() => setStep((p) => p - 1)}
        closeModal={closeModal}
      >
        <div className="Modal__Scroll-Content d-flex flex-column gap-3 py-3">
          <Input
            label="Name"
            name="office_name"
            value={formikTwo.values.office_name}
            onChange={formikTwo.handleChange}
            error={formikTwo.errors.office_name}
          />
          <div className="d-flex gap-2">
            <SearchInput
              label="Country"
              name="office_country"
              placeholder="Country"
              search={countries}
              value={Number(formikTwo.values.office_country)}
              onChange={(id) => formikTwo.setFieldValue('office_country', id)}
              error={formikTwo.errors.office_country}
            />
            <Input
              label="City"
              name="office_city"
              value={formikTwo.values.office_city}
              onChange={formikTwo.handleChange}
              error={formikTwo.errors.office_city}
            />
            <Input
              label="Zip code"
              name="office_zip_code"
              value={formikTwo.values.office_zip_code}
              onChange={formikTwo.handleChange}
              error={formikTwo.errors.office_zip_code}
              containerProps={{ style: { maxWidth: '100px' } }}
            />
          </div>
          <Input
            label="address line 1"
            name="address_line_1"
            value={formikTwo.values.address_line_1}
            onChange={formikTwo.handleChange}
            error={formikTwo.errors.address_line_1}
          />
          <Input
            label="address line 2 <span class='ms-2 text-6 fw-light text-secondary'>Optional</span>"
            name="address_line_2"
            value={formikTwo.values.address_line_2}
            onChange={formikTwo.handleChange}
            error={formikTwo.errors.address_line_2}
          />

          <Input
            label="Contact email"
            name="office_email"
            type="email"
            placeholder="someone@gmail.com"
            value={formikTwo.values.office_email}
            onChange={formikTwo.handleChange}
            error={formikTwo.errors.office_email}
          />

          <PhoneNumberInput
            label="Phone number"
            name="office_phone"
            value={formikTwo.values.office_phone}
            onChange={(value) =>
              formikTwo.setFieldValue('office_phone', value)
            }
            errors={formikTwo.errors.office_phone}
          />

          <div className="d-flex flex-column gap-2 w-100">
            <p className="text-5 fw-medium">
                Mark this office as Head Quarter’s location?
            </p>
            <button className="d-flex gap-2 opacity-50">
              <div className="Checkbox Selected text-opacity-50" />
              <p className="text-6">Yes</p>
            </button>
          </div>

          {officeError.length !== 0 ? (
            <p className="text-red">{officeError[0]}</p>
          ) : (
            <></>
          )}

          <div className="d-flex flex-column align-items-center gap-2">
            <button
              className="button-blue gap-0 w-100"
              type="submit"
              onClick={() => formikTwo.handleSubmit()}
              disabled={formikTwo.isSubmitting}
            >
              {formikTwo.isSubmitting ? (
                <>
                  <span>Creating</span>
                  <LoadingDots className="enter-done" />
                </>
              ) : (
                <span>create organization & add office</span>
              )}
            </button>
          </div>
          <div className="AuthCard__Steps mt-2">
            <div className="Radio Selected"></div>
            <div className="Radio Selected"></div>
          </div>
        </div>
      </Modal>
    )
  default:
    return (
      <CheckModal
        closeModal={closeModal}
        title="Create user"
        message="¡Account was succesully created!"
        info={`A sign up link was sent by email to the ${
          formik.values.user_type === 'organization_admin'
            ? 'organization administrator'
            : formik.values.user_type === 'region_admin'
              ? 'region administrator'
              : 'operator'
        }`}
      />
    )
  }
}

export default CreateUserModal
