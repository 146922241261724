import SolarusLogo from '../assets/images/solarus-logo.png'

const SolarusBanner = () => {
  return (
    <div className="SolarusBanner h-100 p-3">
      <img
        className="SolarusBanner__Logo"
        src={SolarusLogo}
        alt="Solarus - Smart Energy Solutions"
        title="Solarus - Smart Energy Solutions"
      />
    </div>
  )
}

export default SolarusBanner
