import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown,
  faAngleUp,
} from '@fortawesome/free-solid-svg-icons'
import Input from './Input'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import { Option } from '../types'

interface prop {
  maxItems?: number;
  name: string;
  filterIndex: number;
  search?: string;
  options: Option[];
  optionSelected: Option;
  onSelectOption: (filterIndex: number, option: Option) => void;
  onSearch: (filterIndex: number, value: string) => void;
}

const DropdownSearch = ({
  maxItems = 8,
  name,
  filterIndex,
  search,
  optionSelected,
  options,
  onSelectOption,
  onSearch,
}: prop) => {
  const [showOptions, setShowOptions] = useState(false)
  const [isPrevent, setIsPrevent] = useState(false)
  const [optionsFiltered, setOptionsFiltered] = useState(options)

  useEffect(() => {
    if(typeof search !== 'string') return

    if(search.length !== 0)
      setOptionsFiltered(
        options.filter(item => item.label.toLowerCase().includes(search.toLowerCase()))
      )
    else 
      setOptionsFiltered(options)
  }, [search])
  
  useEffect(() => {
    if(typeof search === 'string' && showOptions)
      onSearch(filterIndex, typeof optionSelected.value === 'string' ? optionSelected.value : '')
  }, [showOptions])

  useEffect(() => {
    setOptionsFiltered(options)
  }, [JSON.stringify(options)])

  const toggleShowOptions = () => {
    setShowOptions((prev) => !prev)
  }

  const closeShowOptions = () => {
    if (isPrevent) return
    setShowOptions(false)
  }

  const selectOption = (
    e,
    option: Option,
  ) => {
    e.stopPropagation()
    if (onSelectOption) {
      onSelectOption(filterIndex, option)
    }

    // setShowOptions(false)
  }

  const preventClose = () => {
    setIsPrevent(true)
    setTimeout(() => setIsPrevent(false))
  }

  return (
    <button
      className={classNames(
        'DropdownSearch',
        { Active: showOptions },
        'text-3 d-none d-md-flex'
      )}
      onBlur={closeShowOptions}
    >
      <div
        className="DropdownSearch__Title-Container"
        onClick={toggleShowOptions}
      >
        <div className="flex justify-between">
          <p className="DropdownSearch__Title text-5 text-secondary">{name}</p>
          <FontAwesomeIcon
            icon={faAngleDown}
            className="DropdownSearch__Icon text-4"
          />
        </div>
        <p className="DropdownSearch__Title text-5">{optionSelected.label}</p>
      </div>

      <CSSTransition in={showOptions} timeout={300} unmountOnExit>
        <div
          className="DropdownSearch__Show-Container"
          style={{
            maxHeight: `${maxItems * 37}px`,
          }}
          onClick={preventClose}
        >
          <div
            className="DropdownSearch__Show-Title-Container"
            onClick={toggleShowOptions}
          >
            <div className="flex justify-between">
              <p className="DropdownSearch__Title text-5 text-secondary">{name}</p>
              <FontAwesomeIcon
                icon={faAngleUp}
                className="DropdownSearch__Icon text-4"
              />
            </div>
            <p className="DropdownSearch__Title text-5">{optionSelected.label}</p>
          </div>
          {typeof search === 'string' ? (
            <div className="DropdownSearch__Search">
              <Input
                name="Search"
                placeholder={name}
                value={search}
                onChange={(e) => onSearch(filterIndex, e.target.value)}
                onMouseDown={preventClose}
              />
            </div>
          ) : (
            <></>
          )}
          {optionsFiltered.map((item, index) => (
            <div
              key={index}
              className={classNames(
                'DropdownSearch__Item',
                { Active: JSON.stringify(item.value) === JSON.stringify(optionSelected.value)},
                'text-5'
              )}
              onClick={(e) => selectOption(e, item)}
            >
              {item.label}
            </div>
          ))}
        </div>
      </CSSTransition>
    </button>
  )
}

export default DropdownSearch
