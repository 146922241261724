import { Dispatch, SetStateAction } from 'react'
import {
  Page,
  PageContainer,
  PageSingle,
  Section,
  SectionImage,
} from '../types/generateReport'

type Props = {
  setPages: Dispatch<SetStateAction<Array<Page>>>;
  pageSelectedIndexs: {
    pageIndex: number;
    subPageIndex: number;
  };
};

const footersUpdater = ({
  pages,
  pageSelectedIndexs,
  footersHandler,
}: {
  pages: Array<Page>;
  pageSelectedIndexs: {
    pageIndex: number;
    subPageIndex: number;
  };
  footersHandler: (footers: Array<Section>) => Array<Section>;
}): Array<Page> => {
  const { pageIndex, subPageIndex } = pageSelectedIndexs

  if (pageIndex < 0) return pages

  if (pages[pageIndex].type === 'single') {
    const footers = (pages[pageIndex] as PageSingle).footer

    if (!footers) return pages

    const newPageSingle = {
      ...pages[pageIndex],
      footer: footersHandler(footers),
    } as PageSingle

    return [
      ...pages.slice(0, pageIndex),
      newPageSingle,
      ...pages.slice(pageIndex + 1),
    ]
  }

  const subPages = (pages[pageIndex] as PageContainer).subPages

  if (!subPages) return pages

  const subPage = subPages[subPageIndex]

  if (!subPage) return pages

  const footers = subPages[subPageIndex].footer

  const newSubPages = [
    ...subPages.slice(0, subPageIndex),
    {
      ...subPages[subPageIndex],
      footer: footersHandler(footers),
    },
    ...subPages.slice(subPageIndex + 1),
  ]

  const newPageContainer = {
    ...pages[pageIndex],
    subPages: newSubPages,
  } as PageContainer

  return [
    ...pages.slice(0, pageIndex),
    newPageContainer,
    ...pages.slice(pageIndex + 1),
  ]
}

const footerUpdater = ({
  pages,
  pageSelectedIndexs,
  footerId,
  footerHandler,
}: {
  pages: Array<Page>;
  pageSelectedIndexs: {
    pageIndex: number;
    subPageIndex: number;
  };
  footerId: string;
  footerHandler: (footer: Section) => Section;
}): Array<Page> => {
  return footersUpdater({
    pages,
    pageSelectedIndexs,
    footersHandler: (footers: Array<Section>) => {
      const sectionIndex = footers.findIndex(
        (footer) => footer.id === footerId
      )
    
      if (sectionIndex < 0) return footers
    
      const footer = footers[sectionIndex]
    
      return [
        ...footers.slice(0, sectionIndex),
        footerHandler(footer),
        ...footers.slice(sectionIndex + 1),
      ]
    },
  })
  
}

export default function useFooterHandlers({
  setPages,
  pageSelectedIndexs,
}: Props) {

  const handleUpdateFooterImage = (footerId: string, value: SectionImage['value']) => { 
    setPages((pages) => {
      return footerUpdater({
        pages,
        pageSelectedIndexs,
        footerId,
        footerHandler: (footer: Section) => {
          if (
            footer.type !== 'image' &&
            footer.type !== 'image_select' &&
            footer.type !== 'image_logo'
          )
            return footer

          return {
            ...footer,
            value,
          }
        }
      })
    })
  }

  return {
    handleUpdateFooterImage
  }
}