import { Region } from '../../api/apiTypes'
import { IAdmin, IEdit, IWorld } from '../../iconComponents'
import { preventDivClick } from '../../utils/preventClick'
import TableItem from './TableItem'
import TableItemMenu from './TableItemMenu'

interface Props {
  data: Region;
  action?: (data: Region) => void;
  unselectable?: boolean;
  selected?: boolean;
  handleSelectItem?: (data: Region) => void;
  isMobile?: boolean;
}

const TableRegions = ({
  data,
  action,
  unselectable = false,
  selected = false,
  handleSelectItem,
  isMobile,
}: Props) => {
  const actions = [
    {
      id: 1,
      label: 'edit',
      callback: () => action(data),
    },
  ]

  if (isMobile)
    return (
      <TableItem
        data={data}
        unselectable={unselectable}
        selected={selected}
        handleSelectItem={handleSelectItem}
      >
        <div className="TableItem__Cell">
          <p className="text-4 fw-medium">{data.name || '-'}</p>
        </div>

        <div className="TableItem__Cell of-visible justify-content-end">
          <TableItemMenu actions={actions} />
        </div>
        <div className="TableItem__Cell TableItem__Cell--Mb">
          <IAdmin className="TableItem__Cell_Icon text-secondary" />
          <p>
            {data.admins.length !== 0
              ? data.admins
                .reduce(
                  (acc, cur) =>
                    acc +
                      (cur.user.first_name
                        ? `${cur.user.first_name} ${cur.user.last_name}`
                        : 'Invitation sent') +
                      ', ',
                  ''
                )
                .slice(0, -2)
              : '-'}
          </p>
        </div>

        <div className="TableItem__Cell TableItem__Cell--Mb">
          <IWorld className="TableItem__Cell_Icon text-secondary" />
          <p className="text-5 text-secondary">{data.countries.length}</p>
        </div>
      </TableItem>
    )

  return (
    <TableItem
      data={data}
      unselectable={unselectable}
      selected={selected}
      handleSelectItem={handleSelectItem}
      className="h-auto"
    >
      <div className="TableItem__Cell--center">
        <div className="Checkbox" />
      </div>
      <div
        className="TableItem__Cell cursor-pointer"
        onClick={(e) => preventDivClick(e, () => action(data))}
        title={data.name}
      >
        <p>{data.name || '-'}</p>
      </div>
      <div
        className="TableItem__Cell cursor-pointer"
        onClick={(e) => preventDivClick(e, () => action(data))}
        title={
          data.admins.length !== 0
            ? data.admins
              .reduce(
                (acc, cur) =>
                  acc +
                    (cur.user.first_name
                      ? `${cur.user.first_name} ${cur.user.last_name}`
                      : 'Invitation sent') +
                    ', ',
                ''
              )
              .slice(0, -2)
            : ''
        }
      >
        <p>
          {data.admins.length !== 0
            ? data.admins
              .reduce(
                (acc, cur) =>
                  acc +
                    (cur.user.first_name
                      ? `${cur.user.first_name} ${cur.user.last_name}`
                      : 'Invitation sent') +
                    ', ',
                ''
              )
              .slice(0, -2)
            : '-'}
        </p>
      </div>
      <div
        className="TableItem__Cell flex-wrap gap-2 h-auto py-2 cursor-pointer"
        onClick={(e) => preventDivClick(e, () => action(data))}
      >
        {data.countries.length !== 0
          ? data.countries.map((country) => (
            <p key={country.id} className="TableItem__Pill">
              {country.country_name}
            </p>
          ))
          : '-'}
      </div>
      <div
        className="TableItem__Cell gap-2 cursor-pointer"
        onClick={(e) => preventDivClick(e, () => action(data))}
      >
        <IEdit className="text-blue_primary" />
      </div>
    </TableItem>
  )
}

export default TableRegions
