import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { addSolarThermalField, updateSolarThermalField } from '../../api/simulations/system-parameters/solar-thermal-field'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import CreateSimulationContainer from '../../containers/CreateSimulationContainer'
import SimulationFormikHandler from '../../containers/SimulationFormikHandler'
import Checkboxes from '../../components/Checkboxes'
import CreateSimulationSelector from '../../components/CreateSimulationSelector'
import PVData from '../../components/SolarThermalFieldForm'
import { checkboxOptions, normalizeNumberWithCommas, normalizePercentToSend, normalizePercentToShow, threeDecimalRegex } from '../../constants'
import { submitStep } from '../../utils/submitStep'
import PanelIcon from '../../assets/icons/panel.svg'
import CreateSimulationDisabled from '../../components/CreateSimulationDisabled'
import { Link } from 'react-router-dom'
import InputSmall from '../../components/InputSmall'

const SolarThermalField = () => {
  const { token } = useAppContext()
  const { newSimulation, updateNewSimulation, flowDecisions, setIsStepLoading } = useNewSimulationContext()
  const [ available, setAvailable ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const [ panels, setPanels ] = useState<any>({
    panel_one: '',
    panel_two: ''
  })

  const formik = useFormik({
    initialValues: {
      panel_one: '',
      panel_two: '',
      antifreeze_composition: null,
      include_thermal_system_loss: false,
      will_complete: false,
      another_panel: false
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      antifreeze_composition: Yup.lazy(() => {
        if(formik.values.will_complete) return Yup
          .mixed()
          .required('Required')
          .transform(value => value?.toString()?.replace(/,/g, ''))
          .test('type', 'Only numbers', value => !isNaN(value))
          .test('min', 'Min: 0%', value => value >= 0)
          .test('max', 'Max: 50%', value => value <= 50)
          .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))
        else return Yup.number().nullable().typeError('Only numbers').min(0, 'Min: 0%')
      })
    }),
    validate: async (values) => {
      const errors = {
        panel_one: '',
        panel_two: '',
      }
      const firstFormik = panels.panel_one
      const secondFormik = panels.panel_two

      if(firstFormik && values.will_complete) errors.panel_one = await firstFormik.validateForm()
      if(secondFormik && values.will_complete && values.another_panel) errors.panel_two = await secondFormik.validateForm()

      const hasErrors = Object.keys(errors.panel_one).length > 0 || Object.keys(errors.panel_two).length > 0

      if(hasErrors) return errors
    },
    onSubmit: async (values) => {
      const newPanels = []

      if(panels.panel_one?.values && values.will_complete) newPanels.push({
        ...panels.panel_one.values,
        number_of_panles: panels.panel_one.values.number_of_panles && normalizeNumberWithCommas(panels.panel_one.values.number_of_panles),
        panel_orientation: panels.panel_one.values.panel_orientation && normalizeNumberWithCommas(panels.panel_one.values.panel_orientation),
        panel_tilt: panels.panel_one.values.panel_tilt && normalizeNumberWithCommas(panels.panel_one.values.panel_tilt),
        number_of_panels_per_row: panels.panel_one.values.number_of_panels_per_row && normalizeNumberWithCommas(panels.panel_one.values.number_of_panels_per_row),
        distance_between_rows: panels.panel_one.values.distance_between_rows && normalizeNumberWithCommas(panels.panel_one.values.distance_between_rows),
        lenght_of_piping_between_solar_field_and_pump: panels.panel_one.values.lenght_of_piping_between_solar_field_and_pump && normalizeNumberWithCommas(panels.panel_one.values.lenght_of_piping_between_solar_field_and_pump),
      })
      if(panels.panel_two?.values && values.will_complete && values.another_panel) newPanels.push({
        ...panels.panel_two.values,
        number_of_panles: panels.panel_two.values.number_of_panles && normalizeNumberWithCommas(panels.panel_two.values.number_of_panles),
        panel_orientation: panels.panel_two.values.panel_orientation && normalizeNumberWithCommas(panels.panel_two.values.panel_orientation),
        panel_tilt: panels.panel_two.values.panel_tilt && normalizeNumberWithCommas(panels.panel_two.values.panel_tilt),
        number_of_panels_per_row: panels.panel_two.values.number_of_panels_per_row && normalizeNumberWithCommas(panels.panel_two.values.number_of_panels_per_row),
        distance_between_rows: panels.panel_two.values.distance_between_rows && normalizeNumberWithCommas(panels.panel_two.values.distance_between_rows),
        lenght_of_piping_between_solar_field_and_pump: panels.panel_two.values.lenght_of_piping_between_solar_field_and_pump && normalizeNumberWithCommas(panels.panel_two.values.lenght_of_piping_between_solar_field_and_pump),
      })

      const newValues = {
        panels: newPanels,
        antifreeze_composition: normalizePercentToSend(values.antifreeze_composition),
        include_thermal_system_loss: values.include_thermal_system_loss,
        will_complete: String(values.will_complete)
      }

      if(newPanels.length == 0) delete newValues.panels

      await submitStep({
        pathname: location.pathname,
        lastStep: newSimulation.last_step,
        addFunction: () => addSolarThermalField(token, newSimulation.id, newValues),
        updateFunction: () => updateSolarThermalField(token, newSimulation.id, newValues),
        updateNewSimulation,
        setIsStepLoading
      })
    },
    onReset: () => {
      if(panels.panel_one.resetForm) {
        panels.panel_one.resetForm({
          status: 'reset'
        })

        setTimeout(() => {
          panels.panel_one.setStatus('')
        }, 150)
      }
      if(panels.panel_two.resetForm) {
        panels.panel_two.resetForm({
          status: 'reset'
        })

        setTimeout(() => {
          panels.panel_two.setStatus('')
        }, 150)
      }
    }
  })

  // Check if exist hot water demand
  useEffect(() => {
    if(flowDecisions?.hot_water_demand) {
      setAvailable(true)
    } else if(available) {
      setAvailable(false)
    }

    setLoading(false)
  }, [flowDecisions])

  // Show saved simulation step
  useEffect(() => {
    formik.setValues({
      panel_one: '',
      panel_two: '',
      antifreeze_composition: normalizePercentToShow(newSimulation.steps.solar_thermal_field.antifreeze_composition),
      include_thermal_system_loss: newSimulation.steps.solar_thermal_field.include_thermal_system_loss,
      will_complete: newSimulation.steps.solar_thermal_field.will_complete,
      another_panel: newSimulation.steps.solar_thermal_field.another_panel
    })
  }, [newSimulation.steps.solar_thermal_field])

  // Set saved panels data
  useEffect(() => {
    const savedPanelOne = newSimulation.steps.solar_thermal_field.panel_one
    const savedPanelTwo = newSimulation.steps.solar_thermal_field.panel_two

    if(savedPanelOne?.id && panels.panel_one && available) {
      panels.panel_one.setValues({
        name: savedPanelOne?.name,
        general_panel_parameters: savedPanelOne?.general_panel_parameters?.id,
        panel_parameters: savedPanelOne?.id,
        panel_type: savedPanelOne?.model_panel_parameters?.panel_type,
        panel_model: savedPanelOne?.model_panel_parameters?.id,
        number_of_panels: savedPanelOne?.general_panel_parameters?.number_of_panels,
        type_of_roof: savedPanelOne?.general_panel_parameters?.type_of_roof,
        panel_orientation: savedPanelOne?.general_panel_parameters?.panel_orientation,
        panel_tilt: savedPanelOne?.general_panel_parameters?.panel_tilt,
        number_of_panels_per_row: savedPanelOne?.general_panel_parameters?.number_of_panels_per_row,
        distance_between_rows: savedPanelOne?.general_panel_parameters?.distance_between_rows,
        lenght_of_piping_between_solar_field_and_pump: savedPanelOne?.general_panel_parameters?.lenght_of_piping_between_solar_field_and_pump,
        positional_parameters: savedPanelOne?.positional_fields?.id,
      })
    }

    if(savedPanelTwo?.id && panels.panel_two && available) {
      panels.panel_two.setValues({
        name: savedPanelTwo?.name,
        general_panel_parameters: savedPanelTwo?.general_panel_parameters.id,
        panel_parameters: savedPanelTwo?.id,
        panel_type: savedPanelTwo?.model_panel_parameters?.panel_type,
        panel_model: savedPanelTwo?.model_panel_parameters?.id,
        number_of_panels: savedPanelTwo?.general_panel_parameters?.number_of_panels,
        type_of_roof: savedPanelTwo?.general_panel_parameters?.type_of_roof,
        panel_orientation: savedPanelTwo?.general_panel_parameters?.panel_orientation,
        panel_tilt: savedPanelTwo?.general_panel_parameters?.panel_tilt,
        number_of_panels_per_row: savedPanelTwo?.general_panel_parameters?.number_of_panels_per_row,
        distance_between_rows: savedPanelTwo?.general_panel_parameters?.distance_between_rows,
        lenght_of_piping_between_solar_field_and_pump: savedPanelTwo?.general_panel_parameters?.lenght_of_piping_between_solar_field_and_pump,
        positional_parameters: savedPanelTwo?.positional_fields?.id,
      })
    }
  }, [newSimulation.steps.solar_thermal_field, panels, available])

  if(loading) return null

  if(!available) return (
    <SimulationFormikHandler formik={formik} skip>
      <Helmet>
        <title>Create Simulation: Solar Thermal Field</title>
      </Helmet>

      <CreateSimulationDisabled
        title='Parameters for system modeling'
        message={
          <span>
            If you want to complete this step, there must be a <Link to='/dashboard/create-simulation/step/hot-water-demand' className='link'>demand for hot water</Link>
          </span>
        }
      />
    </SimulationFormikHandler>
  )

  return (
    <SimulationFormikHandler formik={formik}>
      <Helmet>
        <title>Create Simulation: Solar Thermal Field</title>
      </Helmet>

      {/* First Container */}
      <CreateSimulationSelector
        title='Parameters for system modeling'
        alert='Important decision: The “yes” answer enables below content'
        subtitle='Include a T or a PVT field?'
        options={checkboxOptions}
        optionSelected={formik.values.will_complete}
        handleSelect={(value) => {
          formik.setFieldValue('will_complete', value)
          if(!value) formik.setFieldValue('another_panel', false)
        }}
      />

      {/* Second Container */}
      <PVData
        available={formik.values.will_complete}
        isSecondForm={false}
        checkboxSelected={formik.values.another_panel}
        handleSelect={(value) => formik.setFieldValue('another_panel', value)}
        panelName='panel_one'
        panels={panels}
        setPanels={setPanels}
      />

      {/* Third Container */}
      <PVData
        available={formik.values.another_panel}
        isSecondForm={true}
        panelName='panel_two'
        panels={panels}
        setPanels={setPanels}
      />

      {/* Fourth Container */}
      <CreateSimulationContainer available={formik.values.will_complete}>
        <header className='d-flex align-items-center gap-2'>
          <img
            src={PanelIcon}
            className="CreateSimulation__Title-Icon"
            alt='Solar (PV) thermal field'
            title='Solar (PV) thermal field'
          />

          <h2 className='text-4 fw-semibold'>Solar (PV) thermal field</h2>
        </header>

        <InputSmall
          topLabel='Antifreeze composition'
          rightLabel='%'
          name='antifreeze_composition'
          type='number'
          value={formik.values.antifreeze_composition}
          placeholder='Enter antifreeze composition'
          onChange={formik.handleChange}
          error={formik.errors.antifreeze_composition}
          disabled={!formik.values.will_complete}
        />

        <Checkboxes
          label='Include thermal system loss?'
          options={checkboxOptions}
          optionSelected={formik.values.include_thermal_system_loss}
          small
          handleSelect={(value) => formik.setFieldValue('include_thermal_system_loss', value)}
          disabled={!formik.values.will_complete}
        />
      </CreateSimulationContainer>
    </SimulationFormikHandler>
  )
}

export default SolarThermalField