import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { changePassword, updateUser } from '../../api/auth'
import UserIcon from '../../assets/icons/user-gray.svg'
import RegionIcon from '../../assets/icons/region-gray.svg'
import OrganizationIcon from '../../assets/icons/organization-gray.svg'
import Input from '../Input'
import Modal from './Modal'
import CheckModal from './CheckModal'
import { userTypeObject } from '../../utils/userTypes'
import useAppContext from '../../hooks/useAppContext'
import 'react-phone-number-input/style.css'
import { isValidPhoneNumber } from 'react-phone-number-input'
import LoadingDots from '../LoadingDots'
import PhoneNumberInput from '../PhoneNumberInput'

interface Props {
  closeModal: () => void;
}

const AccountInfoModal = ({ closeModal }: Props) => {
  const { token, user, refreshUser, isCentralAdmin, isOrganizationAdmin } =
    useAppContext()
  const [step, setStep] = useState(1)
  const [serverError, setServerError] = useState(false)
  const [serverError2, setServerError2] = useState(false)

  const formikUserInfo = useFormik({
    initialValues: {
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
      phone: user?.phone || '',
      rol: user?.rol || '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required('Required'),
      last_name: Yup.string().required('Required'),
      phone: Yup.string()
        .required('Required')
        .test(
          (value) => typeof value === 'string' && isValidPhoneNumber(value)
        ),
      rol: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      const response = await updateUser(token, values)

      if (response.error) setServerError(true)
      else if (response.user && response.user_profile) {
        refreshUser()
        setStep(4)
      }
    },
  })

  const formikChangePassword = useFormik({
    initialValues: {
      old_password: '',
      new_password1: '',
      new_password2: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      old_password: Yup.string().required('Required'),
      new_password1: Yup.string()
        .required('Required')
        .min(8, 'Password must be at least 8 characters'),
      new_password2: Yup.string()
        .required('Required')
        .min(8, 'Password must be at least 8 characters'),
    }),
    onSubmit: async ({ old_password, new_password1, new_password2 }) => {
      if (new_password1 !== new_password2) {
        formikChangePassword.setErrors({
          new_password1: 'Passwords do not match',
          new_password2: 'Passwords do not match',
        })

        return
      }

      const response = await changePassword(token, {
        old_password,
        new_password1,
        new_password2,
      })

      if (
        response.error ||
        response.new_password2 ||
        response.new_password1 ||
        response.old_password
      ) {
        if (
          response.new_password1 &&
          response.new_password1.includes(
            'New password must be different from old password.'
          )
        ) {
          formikChangePassword.setErrors({
            new_password1: 'New password must be different from old password.',
            new_password2: 'New password must be different from old password.',
          })
        } else if (
          response.new_password2 &&
          (response.new_password2.includes('This password is too common.') ||
            response.new_password2.includes(
              'The password is too similar to the username.'
            ) ||
            response.new_password2.includes(
              'The password is too similar to the first name.'
            ) ||
            response.new_password2.includes(
              'The password is too similar to the last name.'
            ))
        ) {
          formikChangePassword.setErrors({
            new_password1: 'This password is very insecure',
            new_password2: 'This password is very insecure',
          })
        } else if (
          response.new_password2 &&
          (response.new_password2?.includes(
            'Password must contain at least one uppercase letter.'
          ) ||
            response.new_password2?.includes(
              'Password must contain at least one symbol: !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~.'
            ) ||
            response.new_password2?.includes(
              'Password must contain at least one number.'
            ))
        ) {
          formikChangePassword.setErrors({
            new_password1:
              'Your password must contain a number, a symbol, and a capital letter',
            new_password2:
              'Your password must contain a number, a symbol, and a capital letter',
          })
        } else setServerError2(true)
      } else if (response.detail) {
        setStep(3)
      }
    },
  })

  if (step === 1)
    return (
      <Modal title="User information" closeModal={closeModal}>
        <div className='Modal__Scroll-Content'>
          <div className="UserInfoModal__Data">
            <div className="text-5 d-flex align-items-center gap-2 text-capitalize text-secondary">
              <img
                className="UserInfoModal__Icon"
                src={UserIcon}
                alt={user?.user_type}
                title={user?.user_type}
              />
              <p>{user?.user_type ? userTypeObject[user.user_type] : '-'}</p>
            </div>

            {!isCentralAdmin ? (
              <div className="text-5 d-flex align-items-center gap-2 text-capitalize text-secondary">
                <img
                  className="UserInfoModal__Icon"
                  src={OrganizationIcon}
                  alt={`${user?.organization?.name}`}
                  title={`${user?.organization?.name}`}
                />
                <p>{user?.organization?.name || 'Organization'}</p>
              </div>
            ) : (
              <></>
            )}

            {!isCentralAdmin && !isOrganizationAdmin ? (
              <div className="text-5 d-flex align-items-center gap-2 text-capitalize text-secondary">
                <img
                  className="UserInfoModal__Icon"
                  src={RegionIcon}
                  alt={user?.region?.name}
                  title={user?.region?.name}
                />
                <p>{user?.region?.name || 'Region'}</p>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="d-flex flex-column gap-3 py-3">
            <div className="d-flex gap-2">
              <Input
                label="Name"
                type="text"
                name="first_name"
                placeholder="Name"
                initialValue={user?.first_name || ''}
                value={formikUserInfo.values.first_name}
                onChange={formikUserInfo.handleChange}
                error={formikUserInfo.errors.first_name}
              />
              <Input
                label="Last name"
                type="text"
                name="last_name"
                placeholder="Last name"
                initialValue={user?.last_name || ''}
                value={formikUserInfo.values.last_name}
                onChange={formikUserInfo.handleChange}
                error={formikUserInfo.errors.last_name}
              />
            </div>

            <Input
              label="Email"
              name="email"
              type="email"
              value={user?.email}
              disabled
            />

            <Input
              label="Role"
              name="rol"
              type="text"
              placeholder="Role description"
              initialValue={user?.phone || ''}
              value={formikUserInfo.values.rol}
              onChange={formikUserInfo.handleChange}
              error={formikUserInfo.errors.rol}
            />

            <PhoneNumberInput
              label="Phone number"
              name="phone"
              initialValue={user?.rol || ''}
              value={formikUserInfo.values.phone}
              onChange={(value) => formikUserInfo.setFieldValue('phone', value)}
              errors={formikUserInfo.errors.phone}
            />
          </div>

          {serverError ? <p className="text-red">Something went wrong</p> : <></>}

          <div className="d-flex flex-column align-items-center gap-2">
            <button
              className="button-transparent text-4 w-100"
              type="button"
              onClick={() => setStep(2)}
            >
            Change password
            </button>
            <button
              className="button-blue gap-0 text-4 w-100"
              type="submit"
              onClick={() => formikUserInfo.handleSubmit()}
              disabled={formikUserInfo.isSubmitting}
            >
              {formikUserInfo.isSubmitting ? (
                <>
                  <span>Saving</span>
                  <LoadingDots className="enter-done" />
                </>
              ) : (
                <span>Save changes</span>
              )}
            </button>
          </div>
        </div>
      </Modal>
    )

  if (step === 2)
    return (
      <Modal
        title="Change password"
        closeModal={closeModal}
        prevStep={() => setStep(1)}
      >
        <div className="d-flex flex-column gap-3 py-5">
          <Input
            label="Old password"
            name="old_password"
            type="password"
            value={formikChangePassword.values.old_password}
            onChange={formikChangePassword.handleChange}
            error={formikChangePassword.errors.old_password}
          />
          <Input
            label="New password"
            name="new_password1"
            type="password"
            value={formikChangePassword.values.new_password1}
            onChange={formikChangePassword.handleChange}
            error={formikChangePassword.errors.new_password1}
            tip="Your password must contain a number, a symbol, and a capital letter"
          />
          <Input
            label="Confirm new password"
            name="new_password2"
            type="password"
            value={formikChangePassword.values.new_password2}
            onChange={formikChangePassword.handleChange}
            error={formikChangePassword.errors.new_password2}
          />
        </div>

        {serverError2 ? (
          <p className="text-red">Something went wrong</p>
        ) : (
          <></>
        )}

        <div className="d-flex flex-column align-items-center gap-2">
          <button
            className="button-blue gap-0"
            type="submit"
            onClick={() => formikChangePassword.handleSubmit()}
            disabled={formikChangePassword.isSubmitting}
          >
            {formikChangePassword.isSubmitting ? (
              <>
                <span>Saving</span>
                <LoadingDots className="enter-done" />
              </>
            ) : (
              <span>Save changes</span>
            )}
          </button>
        </div>
      </Modal>
    )

  if (step === 3)
    return (
      <CheckModal
        closeModal={closeModal}
        title="Change password"
        message="¡Password changed!"
      />
    )

  return (
    <CheckModal
      closeModal={closeModal}
      title="User information"
      message="¡Changes saved!"
    />
  )
}

export default AccountInfoModal
