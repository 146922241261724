import {
  Image,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import { PageSingle } from '../../types/generateReport'
import ImageSvg from './ImageSvg'
import headerBackground from './headerBackground'

const styles = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    height: 64,
    backgroundColor: '#0070CC',
    color: '#FFFFFF',
    marginBottom: '32pt',
  },
  headerBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    width: '100%',
    height: 64,
  },
  title: {
    padding: '0 32pt',
    fontSize: 20,
  },
})

const specialStyles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    height: 334.845,
    marginBottom: '32pt',
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    objectFit: 'cover', 
  },
  emptyImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: '#D9D9D9',
  },
  textContainer: {
    position: 'absolute',
    bottom: 0,
    paddingBottom: 32,
    borderTopLeftRadius: 22,
    borderTopRightRadius: 22,
    borderBottomRightRadius: 22,
    borderBottomLeftRadius: 22,
  },
  textBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    width: '100%',
    height: '100%',
    borderTopLeftRadius: 22,
    borderTopRightRadius: 22,
    borderBottomRightRadius: 22,
    borderBottomLeftRadius: 22,
    objectFit: 'cover',
  },
  title: {
    padding: '8pt 24pt',
    fontSize: 20,
    maxWidth: '70%',
    textAlign: 'center',
    color: '#FFFFFF',
  }
})


type Props = {
  pageSelected: PageSingle | null;
};

export default function HeaderRender({ pageSelected }: Props) {
  if (!pageSelected) return null

  if (pageSelected.type === 'single' && pageSelected.image !== undefined) {
    return (
      <View style={specialStyles.container} fixed>
        {typeof pageSelected.image === 'string' ? (
          <Image src={pageSelected.image} style={specialStyles.image} />
        ) : (
          <View style={specialStyles.emptyImage}>
            <ImageSvg width={32} height={32} />
          </View>
        )}
        <View style={specialStyles.textContainer} >
          <Image style={specialStyles.textBackground} src={headerBackground} />
          <Text style={specialStyles.title} render={() => pageSelected.title}  />
        </View>
      </View>
    )
  }

  return (
    <View style={styles.header} fixed>
      <Image style={styles.headerBackground} src={headerBackground} />
      <Text style={styles.title} render={() => pageSelected.title} />
    </View>
  )
}
