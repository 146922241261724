'use client'
import classNames from 'classnames'
import { IAlert, ILoading } from '../iconComponents'
import { useState, useEffect, ChangeEventHandler } from 'react'

interface Props<T extends string | number | readonly string[]>
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  containerProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  label?: string;
  labelActions?: React.ReactNode;
  labelContainerProps?: React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >;
  innerLeft?: React.ReactNode;
  outerLeft?: React.ReactNode;
  innerRight?: React.ReactNode;
  outerRight?: React.ReactNode;
  name: string;
  error?: string;
  debounce?: number;
  value: T;
  onDebounce: (value: T) => void;
}

export default function InputDebounce<
  T extends string | number
>({
  containerProps: { className: containerClassName, ...containerRestProps } = {},
  label = '',
  labelActions,
  innerLeft,
  outerLeft,
  innerRight,
  outerRight,
  name,
  disabled,
  error,
  className,
  onDebounce,
  value,
  debounce = 1000,
  ...restProps
}: Props<T>) {
  const [innerValue, setInnerValue] = useState(value)
  const [isDebouncing, setIsDebouncing] = useState(false)

  useEffect(() => {
    setInnerValue(value)
  }, [value])

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      onDebounce(innerValue)
      setIsDebouncing(false)
    }, debounce)
    return () => clearTimeout(delayInputTimeoutId)
  }, [innerValue, debounce])

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setInnerValue(event.target.value as T)
    setIsDebouncing(true)
  }

  return (
    <div
      className={classNames('Input__Container', containerClassName)}
      {...containerRestProps}
    >
      {label || labelActions ? (
        <div className="Input__Label_Container">
          <label
            htmlFor={name}
            className="Input__Label text-5 font-medium"
            dangerouslySetInnerHTML={{ __html: label }}
          />
          {labelActions ?? <></>}
        </div>
      ) : (
        <></>
      )}

      <div className="w-100 d-flex align-items-center gap-1 position-relative">
        <div className="w-100 position-relative">
          {outerLeft ?? <></>}
          <label
            className={classNames(
              'Input Special',
              {
                Disabled: disabled,
              },
              'text-6'
            )}
            htmlFor={name}
          >
            {innerLeft ?? <></>}
            <input
              className={classNames(className)}
              name={name}
              id={name}
              disabled={disabled}
              {...restProps}
              value={innerValue}
              onChange={handleOnChange}
            />
            {innerRight ?? <></>}
            {isDebouncing && (
              <ILoading width={24} height={24} className="Input_Icon_Loading" />
            )}
          </label>
          {outerRight ?? <></>}
        </div>
      </div>

      {error ? (
        <p className={classNames('Input__Error text-6')}>
          <IAlert width="1.25rem" height="1.25rem" className="text-red" />
          <span dangerouslySetInnerHTML={{ __html: error }} />
        </p>
      ) : (
        <></>
      )}
    </div>
  )
}
