import { useState, useMemo } from 'react'
import { IMove } from '../iconComponents'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Switch from './Switch'
import PagesSortISubtem from './PagesSortISubtem'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  UniqueIdentifier,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { Page, PageContainer } from '../types/generateReport'
import classNames from 'classnames'
import useReportContext from '../hooks/useReportContext'
import PagesSortSubOverlay from './GenerateReport/PagesSortSubOverlay'

type Props = {
  page: Page;
  index: number;
};

export default function PagesSortItem({ page, index }: Props) {
  const { idSelected, setPages, updateIdSelected, handleTogglePage } =
    useReportContext()
  const [activeSubPageId, setActiveSubPageId] =
    useState<UniqueIdentifier | null>(null)
  const activeSubPage = useMemo(
    () =>
      page.type === 'container'
        ? page.subPages.find((subPage) => subPage.id === activeSubPageId)
        : null,
    [page, activeSubPageId]
  )

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({ id: page.id })

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const style: React.CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  }

  function handleDragStart(event: DragStartEvent) {
    setActiveSubPageId(event.active.id)
  }

  function handleDragEnd(event: DragEndEvent) {
    setActiveSubPageId(null)
    const { active, over } = event
    if (!active && !over) return
    if (active.id !== over.id) {
      setPages((pages) => {
        if (pages[index].type === 'single') return pages

        const subPages = (pages[index] as PageContainer).subPages

        const oldIndex = subPages.findIndex((item) => item.id === active.id)
        const newIndex = subPages.findIndex((item) => item.id === over.id)

        return [
          ...pages.slice(0, index),
          {
            ...pages[index],
            subPages: arrayMove(subPages, oldIndex, newIndex),
          },
          ...pages.slice(index + 1),
        ]
      })
    }
  }

  const handleDragCancel = () => setActiveSubPageId(null)

  return (
    <div
      id={'PageId:' + page.id}
      className="PagesSort__Item"
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <div
        className={classNames('PagesSort__Item_Header', {
          Active:
            page.id === idSelected ||
            (page.type === 'container' &&
              page.subPages.some((subpage) => subpage.id === idSelected)),
        })}
      >
        <div className="PagesSort__Item_Header_Icon" {...listeners}>
          <IMove width={24} height={24} />
        </div>
        <p
          className="text-5 PagesSort__Item_Header_text"
          onClick={() =>
            page.type === 'single'
              ? updateIdSelected(page.id)
              : updateIdSelected(page.subPages.at(0).id ?? '')
          }
        >
          {page.label}
        </p>
        <div
          className="PagesSort__Item_Header_Switch"
          onClick={() => handleTogglePage(page.id)}
        >
          <Switch active={page.active} />
        </div>
      </div>
      {page.type === 'container' && page.subPages ? (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <SortableContext
            items={page.subPages as Array<{ id: string }>} // I add this becouse the proyect isn't strict
            strategy={verticalListSortingStrategy}
          >
            {page.subPages.map((subPage) => (
              <PagesSortISubtem
                subPage={subPage}
                key={subPage.id}
                parentId={page.id}
              />
            ))}
          </SortableContext>
          <DragOverlay>
            {activeSubPageId ? (
              <PagesSortSubOverlay subPage={activeSubPage} />
            ) : null}
          </DragOverlay>
        </DndContext>
      ) : (
        <></>
      )}
    </div>
  )
}
