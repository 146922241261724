import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { getHotWaterSystemDetails } from '../api/simulations/input-parameters/existing-hot-water-system-details'
import useAppContext from '../hooks/useAppContext'
import useModalsContext from '../hooks/useModalsContext'
import useNewSimulationContext from '../hooks/useNewSimulationContext'
import CreateSimulationContainer from '../containers/CreateSimulationContainer'
import SearchInput from './SearchInput'
import Input from './Input'
import InputSmall from './InputSmall'
import HeatIcon from '../assets/icons/heat.svg'
import { normalizePercentToShow, threeDecimalRegex } from '../constants'
import { ListIcon } from '../assets/icons'
import SimulationSwitch from './SimulationSwitch'

interface Props {
  title: string;
  isSecondForm?: boolean;
  auxiliaryHeatingSystemName: string;
  auxiliaryHeatingSystems: any;
  setAuxiliaryHeatingSystems: any;
  available: boolean;
}

const HeatingSystem = ({
  title,
  isSecondForm = false,
  auxiliaryHeatingSystemName,
  auxiliaryHeatingSystems,
  setAuxiliaryHeatingSystems,
  available,
}: Props) => {
  const { token } = useAppContext()
  const { newSimulation, waterSystems } = useNewSimulationContext()
  const { toggleShowManageSystemModal } = useModalsContext()
  const [systems, setSystems] = useState([])
  const [systemSelected, setSystemSelected] = useState({
    system_name: '',
    energy_source: '',
    CO2_Fuel_txt: '',
    CO2_Fuel: null,
    kWH_CF_Fuel_txt: '',
    kWh_CF_Fuel: null,
    n_ex_syst: null,
  })

  const yupCapacityValidation = Yup.mixed()
    .required('Required')
    .transform((value) => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', (value) => !isNaN(value))
    .test(
      'decimals',
      'Max: 3 decimals',
      (value) => !threeDecimalRegex.test(value)
    )
    .test('min', 'This field must be greater than 0', (value) => value > 0)

  const yupVolumeValidation = Yup.mixed()
    .required('Required')
    .transform((value) => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', (value) => !isNaN(value))
    .test(
      'decimals',
      'Max: 3 decimals',
      (value) => !threeDecimalRegex.test(value)
    )

  const formik = useFormik({
    initialValues: {
      system: null,
      name: '',
      capacity: '',
      size_of_the_buffer_connected_to_auxiliary_heater: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      system: Yup.lazy(() => {
        if (available) return Yup.number().nullable().required('Required')
        else return Yup.number().nullable()
      }),
      name: Yup.lazy(() => {
        if (available) return Yup.string().required('Required')
        else return Yup.string()
      }),
      capacity: Yup.lazy(() => {
        if (available) return yupCapacityValidation
        else return Yup.number().nullable()
      }),
      size_of_the_buffer_connected_to_auxiliary_heater: Yup.lazy(() => {
        if (available && auxiliaryHeatingSystemName === 'system_two') {
          const newValue = Number(
            formik.values.capacity?.toString()?.replace(/,/g, '')
          )
          if (!isNaN(newValue)) {
            return yupVolumeValidation.test(
              'min',
              `Min: ${20 * newValue}`,
              (value) => value >= 20 * newValue
            )
          } else {
            return Yup.mixed().test(
              'resolve',
              'Resolve capacity errors',
              () => false
            )
          }
        } else return Yup.number().nullable()
      }),
    }),
    onSubmit: () => {
      return
    },
  })

  // Set formik system
  useEffect(() => {
    const newSystem = auxiliaryHeatingSystems

    newSystem[auxiliaryHeatingSystemName] = formik

    setAuxiliaryHeatingSystems(newSystem)
  }, [formik.isValidating])

  // Normalize water systems
  useEffect(() => {
    const getCurrentSystems = async () => {
      if (waterSystems?.length > 0) {
        const normalizeSystems = waterSystems.map((water_system) => ({
          value: water_system.id,
          label: water_system.system_name,
        }))

        setSystems(normalizeSystems)
      }
    }

    if (newSimulation.id) {
      getCurrentSystems()
    }
  }, [newSimulation.id, waterSystems])

  // Get water system details
  useEffect(() => {
    const getCurrentSystemDetail = async (system: string) => {
      const response = await getHotWaterSystemDetails(token, system)

      const {
        system_name,
        energy_source,
        CO2_Fuel_txt,
        CO2_Fuel,
        kWH_CF_Fuel_txt,
        kWh_CF_Fuel,
        n_ex_syst,
      } = response

      if (response.error) {
        setSystemSelected({
          system_name: '',
          energy_source: '',
          CO2_Fuel_txt: '',
          CO2_Fuel: '',
          kWH_CF_Fuel_txt: '',
          kWh_CF_Fuel: '',
          n_ex_syst: '',
        })
        formik.setFieldValue('system', '')
        formik.setStatus('reset')
        setTimeout(() => formik.setStatus(''), 150)
        return
      } else {
        setSystemSelected({
          system_name,
          energy_source,
          CO2_Fuel_txt,
          CO2_Fuel,
          kWH_CF_Fuel_txt,
          kWh_CF_Fuel,
          n_ex_syst: normalizePercentToShow(n_ex_syst),
        })
      }
    }

    if (token && formik.values.system) {
      getCurrentSystemDetail(formik.values.system)
    } else {
      setSystemSelected({
        system_name: '',
        energy_source: '',
        CO2_Fuel_txt: '',
        CO2_Fuel: '',
        kWH_CF_Fuel_txt: '',
        kWh_CF_Fuel: '',
        n_ex_syst: '',
      })
    }
  }, [token, formik.values.system, waterSystems])

  return (
    <CreateSimulationContainer available={available}>
      <header className="d-flex align-items-center flex-wrap gap-2">
        <img src={HeatIcon} alt={title} title={title} />

        <h2 className="text-4 fw-semibold">{title}</h2>
      </header>

      <SearchInput
        label="Type of auxiliary system"
        name="system"
        search={systems}
        value={formik.values.system}
        placeholder="Select an existing hot water system"
        onChange={(id) => formik.setFieldValue('system', id)}
        error={formik.errors.system}
        reset={formik.status}
        disabled={!available || systems?.length == 0}
        disabledPlaceholder={
          systems?.length == 0
            ? 'No system available'
            : 'Select an existing hot water system'
        }
        buttonLabel="Manage"
        buttonIcon={<ListIcon />}
        buttonOnClick={toggleShowManageSystemModal}
      />

      <Input
        label="Energy source"
        name="energy_source"
        value={systemSelected.energy_source}
        placeholder="Energy source"
        disabled
      />

      <SimulationSwitch label="Show energy source information">
        <Input
          label="CO2 factor text"
          name="CO2_Fuel_txt"
          value={systemSelected.CO2_Fuel_txt}
          placeholder="CO2 factor text"
          disabled
        />

        <Input
          label="CO2 factor"
          name="CO2_Fuel"
          value={systemSelected.CO2_Fuel}
          placeholder="0"
          disabled
        />

        <Input
          label="Energy factor text"
          name="kWH_CF_Fuel_txt"
          value={systemSelected.kWH_CF_Fuel_txt}
          placeholder="Energy factor text"
          disabled
        />

        <Input
          label="Energy factor"
          name="kWH_CF_Fuel"
          value={systemSelected.kWh_CF_Fuel}
          placeholder="0"
          disabled
        />

        <Input
          label="Thermal efficiency / COP"
          name="n_ex_syst"
          value={systemSelected.n_ex_syst && `${systemSelected.n_ex_syst}%`}
          placeholder="0%"
          disabled
        />
      </SimulationSwitch>

      <Input
        label="Name of the auxiliary heater"
        name="name"
        value={formik.values.name}
        placeholder="Enter the name of the auxiliary heater"
        onChange={formik.handleChange}
        error={formik.errors.name}
        disabled={!available}
      />

      <InputSmall
        topLabel="Capacity of auxiliary heater"
        rightLabel="kW"
        name="capacity"
        value={formik.values.capacity}
        placeholder="0"
        onChange={formik.handleChange}
        error={formik.errors.capacity}
        disabled={!available}
      />

      {isSecondForm && (
        <InputSmall
          topLabel="Size of the buffer connected to this auxiliary heater"
          rightLabel="L"
          name="size_of_the_buffer_connected_to_auxiliary_heater"
          value={formik.values.size_of_the_buffer_connected_to_auxiliary_heater}
          placeholder="0"
          onChange={formik.handleChange}
          error={formik.errors.size_of_the_buffer_connected_to_auxiliary_heater}
          disabled={!available}
        />
      )}
    </CreateSimulationContainer>
  )
}

export default HeatingSystem
