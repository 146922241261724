import { useState, useMemo } from 'react'

const useOrderby = (value: string, bool = true) => {
  const [sort, setSort] = useState({
    value,
    bool,
  })

  const orderBy = useMemo(
    () =>
      sort.value.length !== 0
        ? sort.bool === true
          ? `${sort.value}`
          : `-${sort.value}`
        : '',
    [sort]
  )

  const handleSort = (value: string) => {
    setSort((prev) => {
      if (prev.value !== value)
        return {
          value: value,
          bool: true,
        }

      return {
        value: value,
        bool: !prev.bool,
      }
    })
  }

  return {
    orderBy,
    sort,
    handleSort,
  }
}

export default useOrderby
