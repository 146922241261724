import { API_URL } from '../constants'
import { z } from 'zod'

interface GetQuery<TData, EData> {
  path: string;
  token?: string;
  params?: 
    | string
    | URLSearchParams
    | Record<string, string>
    | string[][]
    | undefined;
  httpHeaders?: RequestInit['headers'];
  requestInit?: RequestInit | undefined;
  successSchema?: z.Schema<TData>;
  errorSchema?: z.Schema<EData>;
}

interface GeneralQuery<TData, EData> {
  path: string;
  token?: string;
  body?: RequestInit['body'];
  httpHeaders?: RequestInit['headers'];
  requestInit?: RequestInit | undefined;
  successSchema?: z.Schema<TData>;
  errorSchema?: z.Schema<EData>;
}

export const getQuery = async <T, E>({
  path,
  token,
  params,
  httpHeaders = { 'Content-Type': 'application/json' },
  requestInit,
  successSchema,
  errorSchema,
}: GetQuery<T, E>): Promise<
  | { response: Response; ok: true; data: T }
  | { response: Response; ok: false; data: E | string }
> => {
  const urlPath = new URL(path, API_URL)
  if (params) urlPath.search = new URLSearchParams(params).toString()

  const headers = new Headers(httpHeaders)
  if (token) {
    headers.append('Authorization', `Token ${token}`)
  }

  try {
    const response: Response = await fetch(urlPath.toString(), {
      headers,
      ...requestInit,
    })

    if (response.status === 401) deleteInvalidToken()
    
    let data = null
    if (response.headers.get('content-type').startsWith('text/plain')) {
      data = await response.text()
    } else {
      data = await response.json()
    }

    if (!response.ok) {
      return {
        response,
        ok: false,
        data: errorSchema?.parse(data) ?? (data as E | string)
      }
    } else {
      return {
        response,
        ok: response.ok,
        data: successSchema?.parse(data) ?? (data as T)
      }
    }
  } catch (error) {
    return handleError(error, headers, undefined)
  }
}

export const postQuery = async <T, E>({
  path,
  token,
  body,
  httpHeaders = { 'Content-Type': 'application/json' },
  requestInit,
  successSchema,
  errorSchema,
}: GeneralQuery<T, E>): Promise<
  | { response: Response; ok: true; data: T; body?: RequestInit['body'] }
  | {
      response: Response;
      ok: false;
      data: E | string;
      body?: RequestInit['body'];
    }
> => {
  const urlPath = new URL(path, API_URL)

  const headers = new Headers(httpHeaders)
  if (token) {
    headers.append('Authorization', `Token ${token}`)
  }

  try {
    const response: Response = await fetch(urlPath.toString(), {
      method: 'POST',
      headers,
      body,
      ...requestInit,
    })

    if (response.status === 401) deleteInvalidToken()
    const data = await response.json()

    if (!response.ok) {
      return {
        response,
        ok: false,
        data: errorSchema?.parse(data) ?? (data as E | string),
      }
    } else {
      return {
        response,
        ok: response.ok,
        data: successSchema?.parse(data) ?? (data as T),
      }
    }
  } catch (error) {
    return handleError(error, headers, body)
  }
}

export const deleteQuery = async <T, E>({
  path,
  token,
  body,
  httpHeaders = { 'Content-Type': 'application/json' },
  requestInit,
  successSchema,
  errorSchema,
}: GeneralQuery<T, E>): Promise<
  | { response: Response; ok: true; data: T }
  | { response: Response; ok: false; data: E | string }
> => {
  const urlPath = new URL(path, API_URL)

  const headers = new Headers(httpHeaders)
  if (token) {
    headers.append('Authorization', `Token ${token}`)
  }

  try {
    const response: Response = await fetch(urlPath.toString(), {
      method: 'DELETE',
      headers,
      body,
      ...requestInit,
    })

    let data = null
    if (response.status === 401) deleteInvalidToken()
    if (response.status !== 204) data = await response.json()

    if (!response.ok) {
      return {
        response,
        ok: false,
        data: errorSchema?.parse(data) ?? (data as E | string),
      }
    } else {
      return {
        response,
        ok: response.ok,
        data: successSchema?.parse(data) ?? (data as T),
      }
    }
  } catch (error) {
    return handleError(error, headers, body)
  }
}

export const putQuery = async <T, E>({
  path,
  token,
  body,
  httpHeaders = { 'Content-Type': 'application/json' },
  requestInit,
  successSchema,
  errorSchema,
}: GeneralQuery<T, E>): Promise<
  | { response: Response; ok: true; data: T }
  | { response: Response; ok: false; data: E | string }
> => {
  const urlPath = new URL(path, API_URL)

  const headers = new Headers(httpHeaders)
  if (token) {
    headers.append('Authorization', `Token ${token}`)
  }

  try {
    const response: Response = await fetch(urlPath.toString(), {
      method: 'PUT',
      headers,
      body,
      ...requestInit,
    })

    if (response.status === 401) deleteInvalidToken()

    const data = await response.json()

    if (!response.ok) {
      return {
        response,
        ok: false,
        data: errorSchema?.parse(data) ?? (data as E | string),
      }
    } else {
      return {
        response,
        ok: response.ok,
        data: successSchema?.parse(data) ?? (data as T),
      }
    }
  } catch (error) {
    return handleError(error, headers, body)
  }
}

export const patchQuery = async <T, E>({
  path,
  token,
  body,
  httpHeaders = { 'Content-Type': 'application/json' },
  requestInit,
  successSchema,
  errorSchema,
}: GeneralQuery<T, E>): Promise<
  | { response: Response; ok: true; data: T }
  | { response: Response; ok: false; data: E | string }
> => {
  const urlPath = new URL(path, API_URL)

  const headers = new Headers(httpHeaders)
  if (token) {
    headers.append('Authorization', `Token ${token}`)
  }

  try {
    const response: Response = await fetch(urlPath.toString(), {
      method: 'PATCH',
      headers,
      body,
      ...requestInit,
    })

    if (response.status === 401) deleteInvalidToken()

    const data = await response.json()

    if (!response.ok) {
      return {
        response,
        ok: false,
        data: errorSchema?.parse(data) ?? (data as E | string),
      }
    } else {
      return {
        response,
        ok: response.ok,
        data: successSchema?.parse(data) ?? (data as T),
      }
    }
  } catch (error) {
    return handleError(error, headers, body)
  }
}

const deleteInvalidToken = () => {
  localStorage.removeItem('token')
  window.dispatchEvent(new Event('storage'))
}

const handleError = (error: unknown, headers: Headers, body: RequestInit['body'] ): {
  response: Response;
  ok: false;
  data: string;
  body?: RequestInit['body']
} => {
  const response = new Response('Something went wrong', {
    status: 500,
    headers,
  })

  if (error instanceof z.ZodError) {
    return {
      response,
      ok: false,
      data: `ZodError ${JSON.stringify(error.format())}`,
      body
    }
  }

  return {
    response,
    ok: false,
    data: `Something went wrong, error ${JSON.stringify(error)}`,
    body
  }
}
