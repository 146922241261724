import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { steps } from '../constants'
import useNewSimulationContext from '../hooks/useNewSimulationContext'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useMatchMedia from '../hooks/useMatchMedia'
import Button from './buttons/Button'
import { AngleLeftIcon } from '../assets/icons'

const SimulationFooter = () => {
  const {
    newSimulation,
    setNewSimulation,
    currentFormik,
    skip,
    setSkip,
    asideSteps,
    isStepLoading,
  } = useNewSimulationContext()
  const [currentStep, setCurrentStep] = useState(1)
  const [disabled, setDisabled] = useState(false)
  const [error, setError] = useState(false)
  const isMobile = useMatchMedia()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  // Set current step
  useEffect(() => {
    const stepPath = pathname.split('/').pop()

    asideSteps.find(
      (item) => item.path === stepPath && setCurrentStep(item.value)
    )
  }, [pathname])

  const finishStep = steps.length - 2

  const submitFormik = async () => {
    if (skip) {
      goToNextStep()
      setSkip(false)
    } else {
      setDisabled(true)
      try {
        const errors = await currentFormik.validateForm()

        const hasErrors = Object.keys(errors).length > 0
        if (hasErrors) {
          errorButtonAnimation()
        } else {
          await currentFormik.submitForm()
          currentFormik.setSubmitting(false)
          goToNextStep()
        }
      } catch (error) {
        console.log(error)
        console.log(JSON.parse(error.message))
      }
      setDisabled(false)
    }
  }

  const resetForm = async () => {
    try {
      await currentFormik.resetForm({
        status: 'reset',
      })

      setTimeout(() => {
        currentFormik.setStatus('')
      }, 150)
    } catch (error) {
      console.log(error)
      console.log(JSON.parse(error.message))
    }
  }

  const goToNextStep = () => {
    const isFinishStep = currentStep === steps[steps.length - 1].value

    if (isFinishStep && !skip) {
      navigate('/dashboard/create-simulation/project-summary')
    } else {
      const { path: currentPath } = asideSteps.find(
        (item) => item.value === currentStep
      )
      const { path: nextPath } = asideSteps.find(
        (item) => item.value === currentStep + 1
      )

      if (nextPath) {
        navigate(`/dashboard/create-simulation/step/${nextPath}`)
      }
      if (skip && currentPath == newSimulation.last_step) {
        setNewSimulation({
          ...newSimulation,
          current_step: nextPath,
          last_step: nextPath,
        })
      }
    }
  }

  const handleBackStep = () => {
    if (currentStep === 1) return
    // const { path: currentPath } = asideSteps.find(
    //   (item) => item.value === currentStep
    // )
    const { path: prevPath } = asideSteps.find(
      (item) => item.value === currentStep - 1
    )
    navigate(`/dashboard/create-simulation/step/${prevPath}`)
  }

  const errorButtonAnimation = () => {
    // Scroll if the content has an input with class ".Input__Error"
    const simulationContainer = document.querySelector(
      '.create-simulation-content'
    )
    const inputWithError = simulationContainer.querySelector(
      '.Input__Error'
    ) as HTMLElement

    if (inputWithError) {
      inputWithError.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }

    // Error animation

    setError(true)

    setTimeout(() => {
      setError(false)
    }, 2500)
  }

  return (
    <footer className="SimulationFooter">
      <Button
        variant="white_blue"
        className="d-md-none"
        style={{ width: 86 }}
        hidden={currentStep === 1}
        onClick={handleBackStep}
      >
        <AngleLeftIcon />
        <span>Back</span>
      </Button>

      <Button variant="transparent" className="d-md-none">
        <FontAwesomeIcon icon={faEllipsisH} />
      </Button>

      <Button
        variant={error ? 'red' : 'blue'}
        className="d-md-none"
        style={{ width: 86 }}
        type="submit"
        onClick={submitFormik}
        disabled={currentFormik.isSubmitting || disabled || isStepLoading}
      >
        {error ? <span>Error</span> : (
          <>
            <span>Next</span>
            <AngleLeftIcon className="rotate_180" />
          </>
        )}
      </Button>

      <Button
        variant="transparent"
        className="d-none d-md-block"
        onClick={resetForm}
        disabled={currentFormik.isSubmitting || disabled}
      >
        Reset this screen
      </Button>

      <div className="SimulationFooter__Divisor d-none d-md-block" />

      <Button
        variant={error ? 'red' : 'blue'}
        className="d-none d-md-block"
        style={{ width: 120 }}
        type="submit"
        onClick={submitFormik}
        disabled={currentFormik.isSubmitting || disabled || isStepLoading}
      >
        {error ? 'Error' : currentStep === finishStep ? 'Finish' : 'Continue'}
      </Button>
    </footer>
  )
}

export default SimulationFooter
