import { createContext, useState, Dispatch, SetStateAction } from 'react'

interface CreateSimulationContextInterface {
  panelModelTypeSelected: {
    panelSelected: string;
    firstPanel: string;
    secondPanel: string;
  },
  setPanelModelTypeSelected: Dispatch<SetStateAction<{
    panelSelected: string;
    firstPanel: string;
    secondPanel: string;
  }>>,
  pvModelTypeSelected: {
    panelSelected: string;
    firstPanel: string;
    secondPanel: string;
  },
  setPVModelTypeSelected: Dispatch<SetStateAction<{
    panelSelected: string;
    firstPanel: string;
    secondPanel: string;
  }>>,

  modalId: number;
  setModalId: Dispatch<SetStateAction<number>>;

  showContinueSimulationModal: boolean;
  setShowContinueSimulationModal: Dispatch<SetStateAction<boolean>>;
  toggleContinueSimulationModal: () => void;

  showManageProfilesModal: boolean;
  toggleShowManageProfilesModal: () => void;

  showNewProfileModal: boolean;
  openShowNewProfileModal: () => void;
  closeShowNewProfileModal: () => void;

  showManageSystemModal: boolean;
  toggleShowManageSystemModal: () => void;

  showNewSystemModal: boolean;
  openShowNewSystemModal: () => void;
  closeShowNewSystemModal: () => void;

  showManageModelsModal: boolean;
  toggleShowManageModelsModal: () => void;

  showNewModelModal: boolean;
  openShowNewModelModal: () => void;
  closeShowNewModelModal: () => void;

  showManagePVModelsModal: boolean;
  toggleShowManagePVModelsModal: () => void;

  showNewPVModelModal: boolean;
  openShowNewPVModelModal: () => void;
  closeShowNewPVModelModal: () => void;
}

export const ModalsContext = createContext<CreateSimulationContextInterface>(null)

const ModalsContextProvider = ({ children }) => {
  const [ panelModelTypeSelected, setPanelModelTypeSelected ] = useState({
    panelSelected: '',
    firstPanel: '',
    secondPanel: '',
  })
  const [ pvModelTypeSelected, setPVModelTypeSelected ] = useState({
    panelSelected: '',
    firstPanel: '',
    secondPanel: '',
  })
  const [modalId, setModalId] = useState<number>(null)
  const [showContinueSimulationModal, setShowContinueSimulationModal] = useState(false)
  const [showManageProfilesModal, setShowManageProfilesModal] = useState(false)
  const [showNewProfileModal, setShowNewProfileModal] = useState(false)
  const [showManageSystemModal, setShowManageSystemModal] = useState(false)
  const [showNewSystemModal, setShowNewSystemModal] = useState(false)
  const [showManageModelsModal, setShowManageModelsModal] = useState(false)
  const [showNewModelModal, setShowNewModelModal] = useState(false)
  const [showManagePVModelsModal, setShowManagePVModelsModal] = useState(false)
  const [showNewPVModelModal, setShowNewPVModelModal] = useState(false)

  // Modals handlers
  const toggleContinueSimulationModal = () => setShowContinueSimulationModal((prev) => !prev)
  const openShowNewProfileModal = () => setShowNewProfileModal(true)
  const closeShowNewProfileModal = () => {
    setShowNewProfileModal(false)
    setModalId(null)
  }
  const toggleShowManageProfilesModal = () => setShowManageProfilesModal((prev) => !prev)
  const openShowNewSystemModal = () => setShowNewSystemModal(true)
  const closeShowNewSystemModal = () => {
    setShowNewSystemModal(false)
    setModalId(null)
  }
  const toggleShowManageSystemModal = () => setShowManageSystemModal((prev) => !prev)
  const toggleShowManageModelsModal = () => setShowManageModelsModal((prev) => !prev)
  const openShowNewModelModal = () => setShowNewModelModal(true)
  const closeShowNewModelModal = () => {
    setShowNewModelModal(false)
    setModalId(null)
  }
  const toggleShowManagePVModelsModal = () => setShowManagePVModelsModal((prev) => !prev)
  const openShowNewPVModelModal = () => setShowNewPVModelModal(true)
  const closeShowNewPVModelModal = () => {
    setShowNewPVModelModal(false)
    setModalId(null)
  }

  const values = {
    panelModelTypeSelected,
    setPanelModelTypeSelected,
    pvModelTypeSelected,
    setPVModelTypeSelected,
    modalId,
    setModalId,

    showContinueSimulationModal,
    setShowContinueSimulationModal,
    toggleContinueSimulationModal,

    showManageProfilesModal,
    toggleShowManageProfilesModal,

    showNewProfileModal,
    openShowNewProfileModal,
    closeShowNewProfileModal,

    showManageSystemModal,
    toggleShowManageSystemModal,

    showNewSystemModal,
    openShowNewSystemModal,
    closeShowNewSystemModal,

    showManageModelsModal,
    toggleShowManageModelsModal,

    showNewModelModal,
    openShowNewModelModal,
    closeShowNewModelModal,

    showManagePVModelsModal,
    toggleShowManagePVModelsModal,

    showNewPVModelModal,
    openShowNewPVModelModal,
    closeShowNewPVModelModal,
  }

  return <ModalsContext.Provider value={values}>{ children }</ModalsContext.Provider>
}

export default ModalsContextProvider