// import { useEffect, useState } from 'react'
import Loader from '../Loader'

const StepLoadingModal = () => {
  // const [ showError, setShowError ] = useState(false)

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setShowError(true)
  //   }, 10000)

  //   return 
  // }, [])

  return (
    <div
      className='step-loading-modal SimulationLoading'
    >
      <Loader/>
    </div>
  )
}

export default StepLoadingModal