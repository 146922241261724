import { useState } from 'react'
import CheckModal from './CheckModal'
import Modal from './Modal'
import useAppContext from '../../hooks/useAppContext'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import LoadingDots from '../LoadingDots'
import { Simulation } from '../../api/apiTypes'
import { deleteSimulation } from '../../api/simulations/general-simulation'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { IAdmin, IAlert, ICalendar, ILocation, IOrganization, IUser } from '../../iconComponents'

dayjs.extend(customParseFormat)

interface Props {
  closeModal: () => void;
  simulationToDelete: Simulation;
  refetch: () => void,
}

const DeleteSimulationModal = ({ closeModal, simulationToDelete, refetch }: Props) => {
  const { token } = useAppContext()
  const { resetNewSimulation } = useNewSimulationContext()
  const [step, setStep] = useState(1)
  const [buttonModalIsLoading, setButtonModalIsLoading] = useState(false)
  const [serverError, setServerError] = useState(false)

  const handleDelete = async () => {
    if (buttonModalIsLoading) return
    setButtonModalIsLoading(true)

    const response = await deleteSimulation(token, {
      simulation_id: simulationToDelete.id,
    })

    if (response.error) setServerError(true)
    else if (response.message) {
      refetch()
      resetNewSimulation()
      setStep(2)
    }

    setButtonModalIsLoading(false)
  }

  switch (step) {
  case 1:
    return (
      <Modal closeModal={closeModal} title="Delete simulation">
        <div className="d-flex flex-column align-items-center gap-3 my-4">
          <IAlert className='DeleteSimulationModal__warning_Icon text-yellow' />
          <p className="text-center text-3 fw-semibold">
            Are you sure you want to delete the following simulation?
          </p>
        </div>

        <div className="Modal__Divider" />

        <p className="text-center text-4 my-2">{simulationToDelete.project_name}</p>

        <div className="Modal__Divider" />

        <div className="DeleteSimulationModal__Grid my-4">
          <div className="DeleteSimulationModal__Grid_Item">
            <div className="DeleteSimulationModal__Grid_Item_Title">
              <ICalendar className='DeleteSimulationModal__Grid_Item_Title_Icon' />
              <p className="text-5 text-secondary">Date</p>
            </div>
            <div className="DeleteSimulationModal__Grid_Item_Info text-5">
              {simulationToDelete.updated_at
                ? dayjs(simulationToDelete.updated_at, 'YYYY-MM-DD').format(
                  'DD/MM/YY'
                )
                : '-'}
            </div>
          </div>
          <div className="DeleteSimulationModal__Grid_Item">
            <div className="DeleteSimulationModal__Grid_Item_Title">
              <ILocation className='DeleteSimulationModal__Grid_Item_Title_Icon' />
              <p className="text-5 text-secondary">Country</p>
            </div>
            <div className="DeleteSimulationModal__Grid_Item_Info text-5">
              {simulationToDelete.location_country_name || '-'}
            </div>
          </div>
          <div className="DeleteSimulationModal__Grid_Item">
            <div className="DeleteSimulationModal__Grid_Item_Title">
              <IUser className='DeleteSimulationModal__Grid_Item_Title_Icon' />
              <p className="text-5 text-secondary">Created by</p>
            </div>
            <div className="DeleteSimulationModal__Grid_Item_Info text-5">
              {simulationToDelete.creator_name || '-'}
            </div>
          </div>
          <div className="DeleteSimulationModal__Grid_Item">
            <div className="DeleteSimulationModal__Grid_Item_Title">
              <IOrganization className='DeleteSimulationModal__Grid_Item_Title_Icon' />
              <p className="text-5 text-secondary">Organization</p>
            </div>
            <div className="DeleteSimulationModal__Grid_Item_Info text-5">
              {simulationToDelete.organization_name || '-'}
            </div>
          </div>
          <div className="DeleteSimulationModal__Grid_Item">
            <div className="DeleteSimulationModal__Grid_Item_Title">
              <IAdmin className='DeleteSimulationModal__Grid_Item_Title_Icon' />
              <p className="text-5 text-secondary">Client</p>
            </div>
            <div className="DeleteSimulationModal__Grid_Item_Info text-5">
              {simulationToDelete.client_name || '-'}
            </div>
          </div>
        </div>

        {serverError ? <p className='text-red'>Something went wrong</p> : <></>}

        <div className="d-flex align-items-center gap-2">
          <SwitchTransition>
            <CSSTransition key={setButtonModalIsLoading} timeout={300} unmountOnExit>
              <button className="button_transparent_red button_defult w-100 text-4 gap-0" onClick={handleDelete} disabled={buttonModalIsLoading}>
                {buttonModalIsLoading ? (
                  <>
                    <span>Deleting</span>
                    <LoadingDots className='enter-done' />
                  </>
                ) : (
                  <span className='text-red'>Yes, delete simulation</span>
                )}
              </button>
            </CSSTransition>
          </SwitchTransition>
          <button
            className="button_transparent_blue w-100 text-4"
            onClick={closeModal}
          >
              No, do not delete
          </button>
        </div>
      </Modal>
    )

  default:
    return (
      <CheckModal
        closeModal={closeModal}
        title="Delete simulation"
        message="¡Simulation deleted successfully!"
      />
    )
  }
}

export default DeleteSimulationModal
