import classNames from 'classnames'
import { SectionGrid } from '../../types/generateReport'
import useReportContext from '../../hooks/useReportContext'
import { Fragment } from 'react'
import InputDebounce from '../InputDebounce'
import { normalizeNumberinString } from '../../constants'

type Props = {
  section: SectionGrid;
};

export default function GridCard({ section }: Props) {
  const { handleToggleGridBoolean, handleUpdateGridText, numbersType } = useReportContext()

  return (
    <>
      {section.value.map((item) => {
        if (!item.editTitle) {
          return (
            <InputDebounce
              key={item.id}
              label={item.title}
              labelActions={
                <>
                  <div
                    className={classNames('Checkbox Small Color-blue', {
                      Selected: item.highlight,
                    })}
                    onClick={() =>
                      handleToggleGridBoolean(section.id, item.id, 'highlight')
                    }
                  />
                  <p
                    className="text-6 text-blue cursor-pointer"
                    onClick={() =>
                      handleToggleGridBoolean(section.id, item.id, 'highlight')
                    }
                  >
                    Highlight
                  </p>
                  <div
                    className={classNames('Checkbox Small Color-secondary', {
                      Selected: item.show,
                    })}
                    onClick={() =>
                      handleToggleGridBoolean(section.id, item.id, 'show')
                    }
                  />
                  <p
                    className="text-6 text-secondary cursor-pointer"
                    onClick={() =>
                      handleToggleGridBoolean(section.id, item.id, 'show')
                    }
                  >
                    Show
                  </p>
                </>
              }
              name={item.id}
              placeholder={item.placeholder}
              value={item.value}
              onDebounce={(value) =>
                handleUpdateGridText(section.id, item.id, 'value', typeof value === 'string' ? normalizeNumberinString(value, 3, numbersType) : value)
              }
            />
          )
        }
        return (
          <Fragment key={item.id}>
            <div className="SectionCard_Line" />
            <InputDebounce
              label="Title"
              labelActions={
                <>
                  <div
                    className={classNames('Checkbox Small Color-blue', {
                      Selected: item.highlight,
                    })}
                    onClick={() =>
                      handleToggleGridBoolean(section.id, item.id, 'highlight')
                    }
                  />
                  <p
                    className="text-6 text-blue cursor-pointer"
                    onClick={() =>
                      handleToggleGridBoolean(section.id, item.id, 'highlight')
                    }
                  >
                    Highlight
                  </p>
                  <div
                    className={classNames('Checkbox Small Color-secondary', {
                      Selected: item.show,
                    })}
                    onClick={() =>
                      handleToggleGridBoolean(section.id, item.id, 'show')
                    }
                  />
                  <p
                    className="text-6 text-secondary cursor-pointer"
                    onClick={() =>
                      handleToggleGridBoolean(section.id, item.id, 'show')
                    }
                  >
                    Show
                  </p>
                </>
              }
              name={'Title' + item.id}
              value={item.title}
              onDebounce={(value) =>
                handleUpdateGridText(section.id, item.id, 'title', value)
              }
            />
            <InputDebounce
              label="Value"
              name={'value' + item.id}
              placeholder={item.placeholder}
              value={item.value}
              onDebounce={(value) =>
                handleUpdateGridText(section.id, item.id, 'value', value)
              }
            />
          </Fragment>
        )
      })}
    </>
  )
}
