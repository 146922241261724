import classNames from 'classnames'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useState, useEffect } from 'react'
import { ILoading } from '../iconComponents'

// const toolbarOptions = [
//   ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
//   ['blockquote', 'code-block'],

//   [{ 'header': 1 }, { 'header': 2 }],               // custom button values
//   [{ 'list': 'ordered'}, { 'list': 'bullet' }],
//   [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
//   [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
//   [{ 'direction': 'rtl' }],                         // text direction

//   [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
//   [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

//   [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
//   [{ 'font': [] }],
//   [{ 'align': [] }],

//   ['clean']                                         // remove formatting button
// ]

interface Props extends ReactQuill.ReactQuillProps {
  value: string
  debounce?: number;
  onDebounce: (value: string) => void;
}

export default function InputTextDebounce({
  className,
  value,
  onDebounce,
  debounce = 1000,
  ...props
}: Props) {
  const [innerValue, setInnerValue] = useState(value)
  const [isDebouncing, setIsDebouncing] = useState(false)

  useEffect(() => {
    setInnerValue(value)
  }, [value])

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      onDebounce(innerValue)
      setIsDebouncing(false)
    }, debounce)
    return () => clearTimeout(delayInputTimeoutId)
  }, [innerValue, debounce])

  const handleOnChange= (value: string) => {
    setInnerValue(value)
    setIsDebouncing(true)
  }

  const modules = {
    toolbar: {
      container: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', { color: [] }],
        [{ align: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        ['clean'],
      ],
    },
  }

  const formats = [
    'font',
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'color',
    'align',
    'list',
    'indent',
  ]

  return (
    <div style={{position: 'relative'}}>
      <ReactQuill
        theme="snow"
        className={classNames('InputText', className)}
        modules={modules}
        formats={formats}
        {...props}
        value={innerValue}
        onChange={handleOnChange}
      />
      {isDebouncing && (
        <ILoading width={24} height={24} className="Input_Icon_Loading" style={{position: 'absolute', right: 4, bottom: 72}} />
      )}
    </div>
  )
}
