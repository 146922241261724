import { useEffect } from 'react'
import { useLocation, Outlet } from 'react-router'
import { useNavigate } from 'react-router'
import useAppContext from '../hooks/useAppContext'
import DashboardHeader from '../containers/DashboardHeader'
import NewClientModal from '../components/modals/NewClientModal'
import ErrorBoundary from './ErrorBoundary'
import Loader from '../components/Loader'
import useNewSimulationContext from '../hooks/useNewSimulationContext'

const DashboardLayout = () => {
  const {
    token,
    firstCharge,
    user,
    userIsLoading,
    showNewClientModal,
    toggleShowNewClientModal,
  } = useAppContext()
  const {
    setIsLoading,
    setIsStepLoading,
  } = useNewSimulationContext()
  const location = useLocation()
  const navigate = useNavigate()

  // Redirect to login if token is not present
  useEffect(() => {
    if (!firstCharge && !token) navigate('/login')

    // if (
    //   !isCentralAdmin &&
    //   !firstCharge &&
    //   token.length !== 0 &&
    //   user?.region?.name.length === 0
    // )
    //   navigate('/')
  }, [token, firstCharge, user])

  // Redirect to about you if user is not complete
  useEffect(() => {
    if (!firstCharge && token && user?.error) navigate('/')
    if (!firstCharge && token && !user?.first_name && !userIsLoading) {
      navigate('/about-you')
    }
  }, [user, firstCharge, userIsLoading, token])

  // Scroll to top when page is loaded
  useEffect(() => {
    window.scrollTo(0, 0)

    if (showNewClientModal) toggleShowNewClientModal()
  }, [location])

  // Hide loading modals when user isn't in create simulation flow
  useEffect(() => {
    if(!location.pathname.includes('create-simulation')) {
      setIsLoading(false)
      setIsStepLoading(false)
    }
  }, [location])

  return (
    <ErrorBoundary>
      {firstCharge || (!user && userIsLoading) ? (
        <main className="d-flex flex-column justify-content-center align-items-center gap-4 w-100 h-screen">
          <Loader />
        </main>
      ) : (
        <>
          <DashboardHeader />
          {/* Outlet vendría a ser el contenido de la página */}
          <main className="dashboard-main">
            <Outlet />
          </main>

          {showNewClientModal ? <NewClientModal /> : <></>}
        </>
      )}
    </ErrorBoundary>
  )
}

export default DashboardLayout
