import classNames from 'classnames'
import React from 'react'

interface Props
  extends React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  > {
  label?: string ;
  name: string;
  error?: string ;
  accept?: Array<string>;
  containerProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  multiple?: boolean
}

export default function InputFile({
  className,
  children,
  name,
  accept = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'],
  label,
  error,
  multiple,
  containerProps: { className: containerClassName, ...containerRestProps } = {},
  ...props
}: Props) {
  return (
    <div
      className={classNames('InputFile_Container', containerClassName)}
      {...containerRestProps}
    >
      {label ? (
        <label
          htmlFor={name}
          className="InputFile_Label"
          dangerouslySetInnerHTML={{ __html: label }}
        />
      ) : (
        <></>
      )}

      <label
        className={classNames('InputFile', className)}
        tabIndex={0}
        {...props}
      >
        {children}
        <input
          id={name}
          name={name}
          type="file"
          accept={accept.reduce((acc, cur, index) => acc + (index === 0 ? cur : `, ${cur}`), '')}
          style={{ display: 'none' }}
          multiple={multiple}
        />
      </label>

      {error ? (
        <div
          className="Input__Error"
          style={{ height: 18, opacity: 1 }}
          dangerouslySetInnerHTML={{ __html: error }}
        />
      ) : (
        <></>
      )}
    </div>
  )
}
