import useAppContext from '../../../hooks/useAppContext'
import { IAlert, IArrowRight } from '../../../iconComponents'

const ConfirmData = () => {
  const {
    user,
    userToDelete,
    simulationActionSelected,
    userActionSelected,
    simulationsToTransfer,
    userToTransfer,
  } = useAppContext()

  return (
    <>
      {simulationActionSelected === 'choose' || simulationActionSelected === 'all' ? (
        <div className="Dashboard__Table_path_info_Simulation_Count">
          <p className="text-4 fw-medium">{simulationActionSelected === 'choose' ? simulationsToTransfer.length : userToDelete[0].simulations}</p>
          <p className="text-5">
            {simulationsToTransfer.length == 1 ? 'Simulation' : 'Simulations'}{' '}
          selected
          </p>
        </div>
      )
        : <></>
      }
      
      <div className="Dashboard__Table_path_info_Confirm_Data">
        <div>
          <p className="text-4 text-primary fw-medium">From</p>
          <p className="text-5 text-secondary fw-medium">
            {`${userToDelete[0]?.first_name} ${userToDelete[0]?.last_name}`}
          </p>
        </div>
        <IArrowRight
          width={16}
          height={16}
          className=" text-blue_primary align-self-center"
        />
        <div>
          <p className="text-4 text-primary fw-medium">To</p>
          <p className="text-5 text-secondary fw-medium">
            {userActionSelected === 'to other' ? `${userToTransfer[0]?.first_name} ${userToTransfer[0]?.last_name}` : `${user.first_name} ${user.last_name}` }
          </p>
        </div>
      </div>

      {simulationActionSelected === 'choose' ? (
        <div className="d-flex justify-content-center align-items-center gap-2 p-2">
          <IAlert width={16} height={16} />
          <p className="text-4 fw-normal">All the simulations that were not selected will be permanently deleted</p>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default ConfirmData
