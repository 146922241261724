import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { getSimulationSummary } from '../../api/simulations/general-simulation'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import SummaryField from '../../components/SummaryField'
import Loader from '../../components/Loader'
import LocationIcon from '../../assets/icons/location-dot-gray.svg'
import SectorIcon from '../../assets/icons/sector.svg'
import MoneyIcon from '../../assets/icons/money-gray.svg'
import ListIcon from '../../assets/icons/list-gray.svg'
import PaperIcon from '../../assets/icons/paper.svg'
import { normalizeNumber } from '../../constants'
import useMatchMedia from '../../hooks/useMatchMedia'
import Button from '../../components/buttons/Button'

const ProjectSummary = () => {
  const { token } = useAppContext()
  const { newSimulation, setNewSimulation, isLoading } =
    useNewSimulationContext()
  const { data: summary, isFetched } = useQuery(
    ['simulationSummary'],
    () => getSimulationSummary(token, Number(localStorage.getItem('simulation_id'))),
    {
      enabled:
        token !== '' &&
        newSimulation.id !== '' &&
        newSimulation.status === 'ready_to_run',
      refetchOnWindowFocus: false,
    }
  )
  const isMobile = useMatchMedia()
  const navigate = useNavigate()

  const solutionDetails = useMemo(() => {
    if (summary && !summary?.error)
      return [
        {
          title: 'PVT field',
          value: summary.PVT_1_quantity
            ? `${normalizeNumber(summary.PVT_1_quantity, 3)} panels`
            : '',
          caption: summary.PVT_1_surface_area
            ? `${normalizeNumber(
              summary.PVT_1_surface_area,
              3
            )} m2 surface area`
            : '',
        },
        {
          title: 'PVT field thermal capacity',
          value: summary.PVT_1_thermal_capacity
            ? `${normalizeNumber(summary.PVT_1_thermal_capacity, 3)} kW`
            : '',
        },
        {
          title: 'PVT field electrical capacity',
          value: summary.PVT_1_electrical_capacity
            ? `${normalizeNumber(summary.PVT_1_electrical_capacity, 3)} kW`
            : '',
        },
        {
          title: 'Total solar buffer capacity',
          value: summary.total_solar_buffer_capacity
            ? `${normalizeNumber(
              summary.total_solar_buffer_capacity,
              3
            )} liters`
            : '',
          caption: summary.number_of_buffers
            ? `${normalizeNumber(summary.number_of_buffers, 3)} buffers`
            : '',
        },
        {
          title: 'Thermal field',
          value: summary.PVT_2_quantity
            ? `${normalizeNumber(summary.PVT_2_quantity, 3)} panels`
            : '',
          caption: summary.PVT_2_surface_area
            ? `${normalizeNumber(
              summary.PVT_2_surface_area,
              3
            )} m2 surface area`
            : '',
        },
        {
          title: 'Thermal field thermal capacity',
          value: summary.PVT_2_thermal_capacity
            ? `${normalizeNumber(summary.PVT_2_thermal_capacity, 3)} kW`
            : '',
        },
        {
          title: 'PV East field',
          value: summary.PV_field_1_quantity
            ? `${normalizeNumber(summary.PV_field_1_quantity, 3)} panels`
            : '',
          caption: summary.PV_field_1_surface_area
            ? `${normalizeNumber(
              summary.PV_field_1_surface_area,
              3
            )} m2 surface area`
            : '',
        },
        {
          title: 'PV East field electrical capacity',
          value: summary.PV_field_1_electrical_capacity
            ? `${normalizeNumber(summary.PV_field_1_electrical_capacity, 3)} kW`
            : '',
        },
        {
          title: 'PV West field',
          value: summary.PV_field_2_quantity
            ? `${normalizeNumber(summary.PV_field_2_quantity, 3)} panels`
            : '',
          caption: summary.PV_field_2_surface_area
            ? `${normalizeNumber(
              summary.PV_field_2_surface_area,
              3
            )} m2 surface area`
            : '',
        },
        {
          title: 'PV West field electrical capacity',
          value: summary.PV_field_2_electrical_capacity
            ? `${normalizeNumber(summary.PV_field_2_electrical_capacity, 3)} kW`
            : '',
        },
        {
          title: 'In store electric heater heat capacity',
          value: summary.elec_heater_capacity
            ? `${normalizeNumber(summary.elec_heater_capacity, 3)} kW`
            : '',
        },
        {
          title: 'In store indirect gas boiler heat capacity',
          value: summary.aux_heater_1_capacity
            ? `${normalizeNumber(summary.aux_heater_1_capacity, 3)} kW`
            : '',
        },
        {
          title: 'Daikin Heat pump heat capacity',
          value: summary.aux_heater_2_capacity
            ? `${normalizeNumber(summary.aux_heater_2_capacity, 3)} kW`
            : '',
        },
        {
          title: 'Auxiliary buffer capacity',
          value: summary.aux_buffer_capacity
            ? `${normalizeNumber(summary.aux_buffer_capacity, 3)} liters`
            : '',
        },
      ]
    else return []
  }, [summary])

  const systemCosting = useMemo(() => {
    if (summary && !summary?.error)
      return [
        {
          title: 'System cost',
          value: summary.system_cost
            ? `${normalizeNumber(summary.system_cost)} ${
              newSimulation.steps.general_information.currency_symbol
            }`
            : '',
        },
        {
          title: 'Subsidy',
          value: summary.subsidy
            ? `${normalizeNumber(summary.subsidy)} ${
              newSimulation.steps.general_information.currency_symbol
            }`
            : '',
        },
        {
          title: 'Net system cost',
          value: summary.net_system_cost
            ? `${normalizeNumber(summary.net_system_cost)} ${
              newSimulation.steps.general_information.currency_symbol
            }`
            : '',
        },
      ]
    else return []
  }, [summary])

  const currency = useMemo(() => {
    const currency =
      newSimulation.steps.general_information?.currency_selected?.name
    const symbol = newSimulation.steps.general_information.currency_symbol
    return `${currency} (${symbol})`
  }, [newSimulation])

  const country = useMemo(() => {
    return newSimulation.client.country?.name
  }, [newSimulation])

  const goBackToSimualtion = () => {
    setNewSimulation({
      ...newSimulation,
      status: 'creating',
    })
    navigate('/dashboard/create-simulation/step/general-information', {
      replace: true,
    })
  }

  const goToRunSimulation = () => {
    navigate('/dashboard/create-simulation/running-simulation')
  }

  const solutionDetailsFiltered = useMemo(
    () => solutionDetails?.filter((item) => item.value),
    [solutionDetails]
  )
  const systemCostingFiltered = useMemo(
    () => systemCosting?.filter((item) => item.value),
    [systemCosting]
  )

  return (
    <div className="ProjectSummary">
      <Helmet>
        <title>Project Summary</title>
      </Helmet>

      {/* <header className="ProjectSummary__Title">
        <h1 className="text-3">Project Summary</h1>
      </header> */}

      <div className="ProjectSummary__Content Scroll_blue">
        {isLoading || !isFetched ? (
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <Loader />
          </div>
        ) : (
          <>
            <div className="ProjectSummary__Container">
              <div className="ProjectSummary__Fields-Container">
                <div className="d-flex align-items-center gap-2">
                  <img
                    src={PaperIcon}
                    alt="Solution details"
                    title="Solution details"
                  />
                  <h2 className="text-4 fw-medium">Projecr summary</h2>
                </div>
                <div className="ProjectSummary__General_Data">
                  <div className="ProjectSummary__General_Data_Item">
                    <img src={MoneyIcon} alt="Currency" title="Currency" />
                    <h3 className="text-5 text-secondary fw-normal">
                      Currency
                    </h3>
                    <div></div>
                    <h2 className="text-5 fw-normal">{currency}</h2>
                  </div>

                  <div className="ProjectSummary__General_Data_Item">
                    <img
                      src={ListIcon}
                      alt="Weather file"
                      title="Weather file"
                    />
                    <h3 className="text-5 text-secondary fw-normal">
                      Weather file
                    </h3>
                    <div></div>
                    <h2 className="text-5 fw-normal">
                      {newSimulation.steps.location_details.weather_file_name}
                    </h2>
                  </div>

                  <div className="ProjectSummary__General_Data_Item">
                    <img src={LocationIcon} alt="Location" title="Location" />
                    <h3 className="text-5 text-secondary fw-normal">
                      Location
                    </h3>
                    <div></div>
                    <h2 className="text-5 fw-normal">
                      {country}, {newSimulation.steps.location_details.city}
                    </h2>
                  </div>

                  <div className="ProjectSummary__General_Data_Item">
                    <img
                      src={SectorIcon}
                      alt="Sector name"
                      title="Sector name"
                    />
                    <h3 className="text-5 text-secondary fw-normal">
                      Sector name
                    </h3>
                    <div></div>
                    <h2 className="text-5 fw-normal">
                      {newSimulation.steps.hot_water_demand.sector[0]?.toUpperCase() +
                        newSimulation.steps.hot_water_demand.sector.substring(
                          1
                        ) || '-'}
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            <div className="ProjectSummary__Container">
              <div className="ProjectSummary__Fields-Container">
                <div className="d-flex align-items-center gap-2">
                  <img
                    src={PaperIcon}
                    alt="Solution details"
                    title="Solution details"
                  />
                  <h2 className="text-4 fw-medium">Solution details</h2>
                </div>
                <div className="ProjectSummary__Fields Solution">
                  {solutionDetailsFiltered.map((item, index) => (
                    <SummaryField
                      key={`Summary-Field-One-${index}`}
                      title={item.title}
                      value={item.value}
                      caption={item.caption}
                    />
                  ))}
                </div>
              </div>
            </div>

            {systemCostingFiltered.length > 0 && (
              <div className="ProjectSummary__Container">
                <div className="ProjectSummary__Fields-Container">
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src={PaperIcon}
                      alt="Solution details"
                      title="Solution details"
                    />
                    <h2 className="text-4 fw-medium">System costing</h2>
                  </div>
                  <div className="ProjectSummary__Fields System">
                    {systemCostingFiltered?.map((item, index) => (
                      <SummaryField
                        key={`Summary-Field-Two-${index}`}
                        title={item.title}
                        value={item.value}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {isLoading || !isFetched ? (
        <></>
      ) : (
        <footer className="ProjectSummary__Footer">
          <span className="text-5 d-md-none text-center">
            Once started, the simulation can&apos;t be canceled
          </span>

          <div className="ProjectSummary__Footer_Content">
            <span className="text-5 d-none d-md-block">
              Once started, the simulation can&apos;t be canceled
            </span>

            <Button onClick={goBackToSimualtion}>
              {isMobile ? 'Go back' : 'Go back to simulation'}
            </Button>

            <Button variant='green' onClick={goToRunSimulation}>
              Run simulation
            </Button>
          </div>
        </footer>
      )}
    </div>
  )
}

export default ProjectSummary
