import { SVGProps } from 'react'

export default function ListIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.7 11.5C5.7 11.3674 5.74214 11.2402 5.81716 11.1464C5.89217 11.0527 5.99391 11 6.1 11H14.1C14.2061 11 14.3078 11.0527 14.3828 11.1464C14.4579 11.2402 14.5 11.3674 14.5 11.5C14.5 11.6326 14.4579 11.7598 14.3828 11.8536C14.3078 11.9473 14.2061 12 14.1 12H6.1C5.99391 12 5.89217 11.9473 5.81716 11.8536C5.74214 11.7598 5.7 11.6326 5.7 11.5ZM4.1 7.5C4.1 7.36739 4.14214 7.24021 4.21716 7.14645C4.29217 7.05268 4.39391 7 4.5 7H12.5C12.6061 7 12.7078 7.05268 12.7828 7.14645C12.8579 7.24021 12.9 7.36739 12.9 7.5C12.9 7.63261 12.8579 7.75979 12.7828 7.85355C12.7078 7.94732 12.6061 8 12.5 8H4.5C4.39391 8 4.29217 7.94732 4.21716 7.85355C4.14214 7.75979 4.1 7.63261 4.1 7.5ZM2.5 3.5C2.5 3.36739 2.54214 3.24021 2.61716 3.14645C2.69217 3.05268 2.79391 3 2.9 3H10.9C11.0061 3 11.1078 3.05268 11.1828 3.14645C11.2579 3.24021 11.3 3.36739 11.3 3.5C11.3 3.63261 11.2579 3.75979 11.1828 3.85355C11.1078 3.94732 11.0061 4 10.9 4H2.9C2.79391 4 2.69217 3.94732 2.61716 3.85355C2.54214 3.75979 2.5 3.63261 2.5 3.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
