import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import { addPPAFinancialModel, updatePPAFinancialModel } from '../../api/simulations/financial-parameters/ppa-financial-model'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import SimulationFormikHandler from '../../containers/SimulationFormikHandler'
import CreateSimulationSelector from '../../components/CreateSimulationSelector'
import PPAFinancialPeriod from '../../components/PPAFinancialPeriod'
import { checkboxOptions, normalizeNumberWithCommas, normalizePercentToSend, normalizePercentToShow } from '../../constants'
import { submitStep } from '../../utils/submitStep'

const PPAFinancialModel = () => {
  const { token } = useAppContext()
  const { newSimulation, updateNewSimulation, setIsStepLoading } = useNewSimulationContext()
  const [ periods, setPeriods ] = useState<any>({
    period_one: '',
    period_two: '',
    period_three: ''
  })

  const formik = useFormik({
    initialValues: {
      will_complete: false,
    },
    validateOnChange: false,
    validate: async () => {
      const errors = {
        period_one: '',
        period_two: '',
        period_three: ''
      }
      const firstFormik = periods.period_one
      const secondFormik = periods.period_two
      const thirdFormik = periods.period_three

      if(firstFormik) errors.period_one = await firstFormik.validateForm()
      if(secondFormik) errors.period_two = await secondFormik.validateForm()
      if(thirdFormik) errors.period_three = await thirdFormik.validateForm()

      const hasErrors = Object.keys(errors.period_one).length > 0 || Object.keys(errors.period_two).length > 0 || Object.keys(errors.period_three).length > 0

      if(hasErrors) return errors
    },
    onSubmit: async () => {
      const newValues = {
        model: [
          {
            ...periods.period_one?.values,
            number_of_years: periods.period_one?.values?.number_of_years ?  normalizeNumberWithCommas(periods.period_one?.values?.number_of_years) : '',
            electricity_price_per_kwh: periods.period_one?.values?.electricity_price_per_kwh ?  normalizeNumberWithCommas(periods.period_one?.values?.electricity_price_per_kwh) : '',
            electricity_escalation_rate: periods.period_one?.values?.electricity_escalation_rate ?  normalizePercentToSend(normalizeNumberWithCommas(periods.period_one?.values?.electricity_escalation_rate)) : '',
            heat_price_per_kwh: periods.period_one?.values?.heat_price_per_kwh ?  normalizeNumberWithCommas(periods.period_one?.values?.heat_price_per_kwh) : '',
            heat_escalation_rate: periods.period_one?.values?.heat_escalation_rate ?  normalizePercentToSend(normalizeNumberWithCommas(periods.period_one?.values?.heat_escalation_rate)) : ''
          }, {
            ...periods.period_two?.values,
            number_of_years: periods.period_two?.values?.number_of_years ?  normalizeNumberWithCommas(periods.period_two?.values?.number_of_years) : '',
            electricity_price_per_kwh: periods.period_two?.values?.electricity_price_per_kwh ?  normalizeNumberWithCommas(periods.period_two?.values?.electricity_price_per_kwh) : '',
            electricity_escalation_rate: periods.period_two?.values?.electricity_escalation_rate ?  normalizePercentToSend(normalizeNumberWithCommas(periods.period_two?.values?.electricity_escalation_rate)) : '',
            heat_price_per_kwh: periods.period_two?.values?.heat_price_per_kwh ?  normalizeNumberWithCommas(periods.period_two?.values?.heat_price_per_kwh) : '',
            heat_escalation_rate: periods.period_two?.values?.heat_escalation_rate ?  normalizePercentToSend(normalizeNumberWithCommas(periods.period_two?.values?.heat_escalation_rate)) : ''
          }, {
            ...periods.period_three?.values,
            number_of_years: periods.period_three?.values?.number_of_years ?  normalizeNumberWithCommas(periods.period_three?.values?.number_of_years) : '',
            electricity_price_per_kwh: periods.period_three?.values?.electricity_price_per_kwh ?  normalizeNumberWithCommas(periods.period_three?.values?.electricity_price_per_kwh) : '',
            electricity_escalation_rate: periods.period_three?.values?.electricity_escalation_rate ?  normalizePercentToSend(normalizeNumberWithCommas(periods.period_three?.values?.electricity_escalation_rate)) : '',
            heat_price_per_kwh: periods.period_three?.values?.heat_price_per_kwh ?  normalizeNumberWithCommas(periods.period_three?.values?.heat_price_per_kwh) : '',
            heat_escalation_rate: periods.period_three?.values?.heat_escalation_rate ?  normalizePercentToSend(normalizeNumberWithCommas(periods.period_three?.values?.heat_escalation_rate)) : ''
          }
        ],
        will_complete: String(formik.values.will_complete)
      }

      if(!formik.values.will_complete) delete newValues.model

      await submitStep({
        pathname: location.pathname,
        lastStep: newSimulation.last_step,
        addFunction: () => addPPAFinancialModel(token, newSimulation.id, newValues),
        updateFunction: () => updatePPAFinancialModel(token, newSimulation.id, newValues),
        updateNewSimulation,
        setIsStepLoading
      })
    },
    onReset: () => {
      const firstFormik = periods.period_one
      const secondFormik = periods.period_two
      const threeFormik = periods.period_three

      if(firstFormik.resetForm) {
        firstFormik.resetForm({
          status: 'reset'
        })

        setTimeout(() => {
          firstFormik.setStatus('')
        }, 150)
      }
      if(secondFormik.resetForm) {
        secondFormik.resetForm({
          status: 'reset'
        })

        setTimeout(() => {
          secondFormik.setStatus('')
        }, 150)
      }
      if(threeFormik.resetForm) {
        threeFormik.resetForm({
          status: 'reset'
        })

        setTimeout(() => {
          threeFormik.setStatus('')
        }, 150)
      }
    }
  })

  // Set saved data
  useEffect(() => {
    if(newSimulation.steps.ppa_financial_model.will_complete) formik.setFieldValue('will_complete', true)

    if(periods.period_one) {
      periods.period_one.setValues({
        model_id: newSimulation.steps.ppa_financial_model.period_1.id,
        number_of_years: newSimulation.steps.ppa_financial_model.period_1.number_of_years,
        electricity_price_per_kwh: newSimulation.steps.ppa_financial_model.period_1.electricity_price_per_kwh,
        electricity_escalation_rate: normalizePercentToShow(newSimulation.steps.ppa_financial_model.period_1.electricity_escalation_rate),
        heat_price_per_kwh: newSimulation.steps.ppa_financial_model.period_1.heat_price_per_kwh,
        heat_escalation_rate: normalizePercentToShow(newSimulation.steps.ppa_financial_model.period_1.heat_escalation_rate),
      })
    }

    if(periods.period_two) {
      periods.period_two.setValues({
        model_id: newSimulation.steps.ppa_financial_model.period_2.id,
        number_of_years: newSimulation.steps.ppa_financial_model.period_2.number_of_years,
        electricity_price_per_kwh: newSimulation.steps.ppa_financial_model.period_2.electricity_price_per_kwh,
        electricity_escalation_rate: normalizePercentToShow(newSimulation.steps.ppa_financial_model.period_2.electricity_escalation_rate),
        heat_price_per_kwh: newSimulation.steps.ppa_financial_model.period_2.heat_price_per_kwh,
        heat_escalation_rate: normalizePercentToShow(newSimulation.steps.ppa_financial_model.period_2.heat_escalation_rate),
      })
    }

    if(periods.period_three) {
      periods.period_three.setValues({
        model_id: newSimulation.steps.ppa_financial_model.period_3.id,
        number_of_years: newSimulation.steps.ppa_financial_model.period_3.number_of_years,
        electricity_price_per_kwh: newSimulation.steps.ppa_financial_model.period_3.electricity_price_per_kwh,
        electricity_escalation_rate: normalizePercentToShow(newSimulation.steps.ppa_financial_model.period_3.electricity_escalation_rate),
        heat_price_per_kwh: newSimulation.steps.ppa_financial_model.period_3.heat_price_per_kwh,
        heat_escalation_rate: normalizePercentToShow(newSimulation.steps.ppa_financial_model.period_3.heat_escalation_rate),
      })
    }
  }, [newSimulation.steps.ppa_financial_model])

  // Set saved data
  useEffect(() => {
    const ppaFinancialModel = newSimulation.steps.ppa_financial_model
    const periodOne = periods.period_one
    const periodTwo = periods.period_two
    const periodThree = periods.period_three

    if(periodOne && ppaFinancialModel.period_1.id) periodOne.setFieldValue('model_id', ppaFinancialModel.period_1.id)
    if(periodTwo && ppaFinancialModel.period_2.id) periodTwo.setFieldValue('model_id', ppaFinancialModel.period_2.id)
    if(periodThree && ppaFinancialModel.period_3.id) periodThree.setFieldValue('model_id', ppaFinancialModel.period_3.id)
  }, [newSimulation.steps.ppa_financial_model, formik.values])

  return (
    <SimulationFormikHandler
      formik={formik}
    >
      <Helmet>
        <title>Create Simulation: PPA Financial Model</title>
      </Helmet>

      {/* First Container */}
      <CreateSimulationSelector
        title='PPA financial model'
        alert='Important decision: The “yes” answer enables below content'
        subtitle='PPA option?'
        options={checkboxOptions}
        optionSelected={formik.values.will_complete}
        handleSelect={(value) => formik.setFieldValue('will_complete', value)}
      />

      {/* Second Container */}
      <PPAFinancialPeriod
        title='Period 1'
        periodName='period_one'
        periods={periods}
        setPeriods={setPeriods}
        economicLife={newSimulation.steps.economic_parameters.project_economic_lifetime}
        available={formik.values.will_complete}
      />

      {/* Third Container */}
      <PPAFinancialPeriod
        title='Period 2'
        periodName='period_two'
        periods={periods}
        setPeriods={setPeriods}
        economicLife={newSimulation.steps.economic_parameters.project_economic_lifetime}
        available={formik.values.will_complete}
      />

      {/* Fourth Container */}
      <PPAFinancialPeriod
        title='Period 3'
        periodName='period_three'
        periods={periods}
        setPeriods={setPeriods}
        economicLife={newSimulation.steps.economic_parameters.project_economic_lifetime}
        available={formik.values.will_complete}
      />
    </SimulationFormikHandler>
  )
}

export default PPAFinancialModel