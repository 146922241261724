import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import { addPVField, updatePVField } from '../../api/simulations/system-parameters/pv-field'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import SimulationFormikHandler from '../../containers/SimulationFormikHandler'
import CreateSimulationDisabled from '../../components/CreateSimulationDisabled'
import CreateSimulationSelector from '../../components/CreateSimulationSelector'
import PVForm from '../../components/PVForm'
import { checkboxOptions, normalizeNumberWithCommas } from '../../constants'
import { submitStep } from '../../utils/submitStep'
import { Link } from 'react-router-dom'

const PVField = () => {
  const { token } = useAppContext()
  const { newSimulation, updateNewSimulation, flowDecisions, setIsStepLoading } = useNewSimulationContext()
  const [ available, setAvailable ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const [ fields, setFields ] = useState<any>({
    field_one: '',
    field_two: ''
  })

  const formik = useFormik({
    initialValues: {
      pv_field: '',
      will_complete: false,
      another_field: false
    },
    validateOnChange: false,
    validate: async (values) => {
      const errors = {
        field_one: '',
        field_two: '',
      }
      const firstFormik = fields.field_one
      const secondFormik = fields.field_two

      if(firstFormik && values.will_complete) errors.field_one = await firstFormik.validateForm()
      if(secondFormik && values.another_field) errors.field_two = await secondFormik.validateForm()

      const hasErrors = Object.keys(errors.field_one).length > 0 || Object.keys(errors.field_two).length > 0

      if(hasErrors) return errors
    },
    onSubmit: async (values) => {
      const newFields = []

      if(fields.field_one.values && values.will_complete) newFields.push({
        ...fields.field_one.values,
        number_of_panels: fields.field_one.values.number_of_panels && normalizeNumberWithCommas(fields.field_one.values.number_of_panels),
        panel_orientation: fields.field_one.values.panel_orientation && normalizeNumberWithCommas(fields.field_one.values.panel_orientation),
        panel_tilt: fields.field_one.values.panel_tilt && normalizeNumberWithCommas(fields.field_one.values.panel_tilt),
        number_of_panels_per_row: fields.field_one.values.number_of_panels_per_row && normalizeNumberWithCommas(fields.field_one.values.number_of_panels_per_row),
        distance_between_rows: fields.field_one.values.distance_between_rows && normalizeNumberWithCommas(fields.field_one.values.distance_between_rows),
      })
      if(fields.field_two.values && values.will_complete && values.another_field) newFields.push({
        ...fields.field_two.values,
        number_of_panels: fields.field_two.values.number_of_panels && normalizeNumberWithCommas(fields.field_two.values.number_of_panels),
        panel_orientation: fields.field_two.values.panel_orientation && normalizeNumberWithCommas(fields.field_two.values.panel_orientation),
        panel_tilt: fields.field_two.values.panel_tilt && normalizeNumberWithCommas(fields.field_two.values.panel_tilt),
        number_of_panels_per_row: fields.field_two.values.number_of_panels_per_row && normalizeNumberWithCommas(fields.field_two.values.number_of_panels_per_row),
        distance_between_rows: fields.field_two.values.distance_between_rows && normalizeNumberWithCommas(fields.field_two.values.distance_between_rows),
      })

      const newValues = {
        pv_field: newFields,
        will_complete: String(values.will_complete),
      }

      if(newFields.length == 0) delete newValues.pv_field

      await submitStep({
        pathname: location.pathname,
        lastStep: newSimulation.last_step,
        addFunction: () => addPVField(token, newSimulation.id, newValues),
        updateFunction: () => updatePVField(token, newSimulation.id, newValues),
        updateNewSimulation,
        setIsStepLoading
      })
    },
    onReset: () => {
      const firstFormik = fields.field_one
      const secondFormik = fields.field_two

      if(firstFormik.resetForm) {
        firstFormik.resetForm({
          status: 'reset'
        })

        setTimeout(() => {
          firstFormik.setStatus('')
        }, 150)
      }
      if(secondFormik.resetForm) {
        secondFormik.resetForm({
          status: 'reset'
        })

        setTimeout(() => {
          secondFormik.setStatus('')
        }, 150)
      }
    }
  })

  useEffect(() => {
    if(flowDecisions?.electricity_demand) {
      setAvailable(true)
    } else if(available) {
      setAvailable(false)
    }

    setLoading(false)
  }, [flowDecisions])

  // Show saved simulation step
  useEffect(() => {
    formik.setValues({
      pv_field: '',
      will_complete: newSimulation.steps.pv_field.will_complete,
      another_field: newSimulation.steps.pv_field.another_field
    })
  }, [newSimulation.steps.pv_field])

  // Set saved panels data
  useEffect(() => {
    if(newSimulation.steps.pv_field.first_panel?.id && fields.field_one) {
      fields.field_one.setValues({
        pv_field: newSimulation.steps.pv_field.first_panel.id,
        name: newSimulation.steps.pv_field.first_panel.name,
        pv_type: newSimulation.steps.pv_field.first_panel.pv_type,
        model: newSimulation.steps.pv_field.first_panel.model,
        number_of_panels: newSimulation.steps.pv_field.first_panel.number_of_panels,
        type_of_roof: newSimulation.steps.pv_field.first_panel.type_of_roof,
        panel_orientation: newSimulation.steps.pv_field.first_panel.panel_orientation,
        panel_tilt: newSimulation.steps.pv_field.first_panel.panel_tilt,
        number_of_panels_per_row: newSimulation.steps.pv_field.first_panel.number_of_panels_per_row,
        distance_between_rows: newSimulation.steps.pv_field.first_panel.distance_between_rows,
      })
    }

    if(newSimulation.steps.pv_field.second_panel?.id && fields.field_two) {
      fields.field_two.setValues({
        pv_field: newSimulation.steps.pv_field.second_panel.id,
        name: newSimulation.steps.pv_field.second_panel.name,
        pv_type: newSimulation.steps.pv_field.second_panel.pv_type,
        model: newSimulation.steps.pv_field.second_panel.model,
        number_of_panels: newSimulation.steps.pv_field.second_panel.number_of_panels,
        type_of_roof: newSimulation.steps.pv_field.second_panel.type_of_roof,
        panel_orientation: newSimulation.steps.pv_field.second_panel.panel_orientation,
        panel_tilt: newSimulation.steps.pv_field.second_panel.panel_tilt,
        number_of_panels_per_row: newSimulation.steps.pv_field.second_panel.number_of_panels_per_row,
        distance_between_rows: newSimulation.steps.pv_field.second_panel.distance_between_rows,
      })
    }
  }, [newSimulation.steps.pv_field, fields, available])

  if(loading) return null

  if(!available) return (
    <SimulationFormikHandler formik={formik} skip>
      <Helmet>
        <title>Create Simulation: PV Field</title>
      </Helmet>

      <CreateSimulationDisabled
        title='PV field'
        message={
          <span>
            If you want to complete this step, there must be <Link to='/dashboard/create-simulation/step/electricity-demand' className='link'>demand for electricity</Link>
          </span>
        }
      />
    </SimulationFormikHandler>
  )

  return (
    <SimulationFormikHandler formik={formik}>
      <Helmet>
        <title>Create Simulation: PV Field</title>
      </Helmet>

      {/* First Container */}
      <CreateSimulationSelector
        title='PV field'
        alert='Important decision: The “yes” answer enables below content'
        subtitle='Include a PV field?'
        options={checkboxOptions}
        optionSelected={formik.values.will_complete}
        handleSelect={(value) => {
          if(!value) {
            formik.setFieldValue('will_complete', value)
            formik.setFieldValue('another_field', value)
          } else {
            formik.setFieldValue('will_complete', value)
            formik.setFieldValue('another_field', newSimulation.steps.pv_field.another_field)
          }
        }}
      />

      {/* Second Container */}
      <PVForm
        available={formik.values.will_complete}
        checkboxSelected={formik.values.another_field}
        handleSelect={(value) => formik.setFieldValue('another_field', value)}
        fieldName='field_one'
        fields={fields}
        setFields={setFields}
      />

      {/* Third Container */}
      <PVForm
        isSecondForm={true}
        available={formik.values.another_field}
        fieldName='field_two'
        fields={fields}
        setFields={setFields}
      />
    </SimulationFormikHandler>
  )
}

export default PVField