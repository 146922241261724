import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  addSystemPerformanceAging,
  updateSystemPerformanceAging,
} from '../../api/simulations/system-parameters/system-performance-aging'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import CreateSimulationContainer from '../../containers/CreateSimulationContainer'
import SimulationFormikHandler from '../../containers/SimulationFormikHandler'
import CreateSimulationTitle from '../../components/CreateSimulationTitle'
import InputSmall from '../../components/InputSmall'
import { submitStep } from '../../utils/submitStep'
import WatchIcon from '../../assets/icons/watch.svg'
import {
  normalizeNumberWithCommas,
  normalizePercentToSend,
  normalizePercentToShow,
  threeDecimalRegex,
} from '../../constants'

const SystemPerformanceAging = () => {
  const { token } = useAppContext()
  const { newSimulation, updateNewSimulation, setIsStepLoading } =
    useNewSimulationContext()

  const yupNumberValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))
    .test('min', 'Min: 0%', value => value >= 0)
    .test('max', 'Max: 10%', value => value <= 10)

  const formik = useFormik({
    initialValues: {
      PV_electrical_out_degradation: null,
      solar_thermal_out_degradation: null,
      auxiliary_heating_degradation: null,
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      PV_electrical_out_degradation: yupNumberValidation,
      solar_thermal_out_degradation: yupNumberValidation,
      auxiliary_heating_degradation: yupNumberValidation,
    }),
    onSubmit: async (values) => {
      const newValues = {
        PV_electrical_out_degradation: values.PV_electrical_out_degradation ? normalizePercentToSend(
          normalizeNumberWithCommas(values.PV_electrical_out_degradation)
        ) : '',
        solar_thermal_out_degradation: values.solar_thermal_out_degradation ? normalizePercentToSend(
          normalizeNumberWithCommas(values.solar_thermal_out_degradation)
        ) : '',
        auxiliary_heating_degradation: values.auxiliary_heating_degradation ? normalizePercentToSend(
          normalizeNumberWithCommas(values.auxiliary_heating_degradation)
        ) : '',
      }

      await submitStep({
        pathname: location.pathname,
        lastStep: newSimulation.last_step,
        addFunction: () =>
          addSystemPerformanceAging(token, newSimulation.id, newValues),
        updateFunction: () =>
          updateSystemPerformanceAging(token, newSimulation.id, newValues),
        updateNewSimulation,
        setIsStepLoading,
      })
    },
  })

  // Show saved simulation step
  useEffect(() => {
    formik.setValues({
      PV_electrical_out_degradation: normalizePercentToShow(
        newSimulation.steps.system_performance_aging
          .PV_electrical_out_degradation
      ),
      solar_thermal_out_degradation: normalizePercentToShow(
        newSimulation.steps.system_performance_aging
          .solar_thermal_out_degradation
      ),
      auxiliary_heating_degradation: normalizePercentToShow(
        newSimulation.steps.system_performance_aging
          .auxiliary_heating_degradation
      ),
    })
  }, [newSimulation])

  return (
    <SimulationFormikHandler formik={formik}>
      <Helmet>
        <title>Create Simulation: System Performance Aging</title>
      </Helmet>

      <CreateSimulationContainer>
        <header className="d-flex align-items-center flex-wrap gap-2">
          <img
            src={WatchIcon}
            className="CreateSimulation__Title-Icon"
            alt="System performance aging"
            title="System performance aging"
          />

          <CreateSimulationTitle>
            System performance aging
          </CreateSimulationTitle>
        </header>

        <InputSmall
          topLabel="PV electrical output degradation"
          rightLabel="%"
          name="PV_electrical_out_degradation"
          placeholder="0"
          value={formik.values.PV_electrical_out_degradation}
          onChange={formik.handleChange}
          error={formik.errors.PV_electrical_out_degradation}
        />

        <InputSmall
          topLabel="Solar thermal output degradation"
          rightLabel="%"
          name="solar_thermal_out_degradation"
          placeholder="0"
          value={formik.values.solar_thermal_out_degradation}
          onChange={formik.handleChange}
          error={formik.errors.solar_thermal_out_degradation}
        />

        <InputSmall
          topLabel="Auxiliary heating system degradation"
          rightLabel="%"
          name="auxiliary_heating_degradation"
          placeholder="0"
          value={formik.values.auxiliary_heating_degradation}
          onChange={formik.handleChange}
          error={formik.errors.auxiliary_heating_degradation}
        />
      </CreateSimulationContainer>
    </SimulationFormikHandler>
  )
}

export default SystemPerformanceAging
