import { getQuery, patchQuery, postQuery } from '../../apiFunctions'

export const getSolarStorage = async (token, simulation_id) => {
  return getQuery<any>({
    path: `/simulations/solar-storage/${simulation_id}/`,
    token,
    callback: (data) => data.data
  })
}

export const addSolarStorage = async (token, simulation_id, body) => {
  return postQuery<any>({
    path: `/simulations/solar-storage/${simulation_id}/`,
    token,
    body
  })
}

export const updateSolarStorage = async (token, simulation_id, body) => {
  return patchQuery<any>({
    path: `/simulations/solar-storage/${simulation_id}/`,
    token,
    body
  })
}