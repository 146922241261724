import { ChangeEventHandler, useState } from 'react'
import { Office, OrganizationDetail } from '../api/apiTypes'
import { ISearch } from '../iconComponents'
import { TableHeaders } from '../types'
import Input from './Input'
import Table from './tables/Table'
import TableTitle from './TableTitle'
import useOrderby from '../hooks/useOrderby'
import { useQuery } from 'react-query'
import { getOffices } from '../api/organizations'
import useAppContext from '../hooks/useAppContext'
import TableFiltersMobile from './TableFiltersMobile'
import useMatchMedia from '../hooks/useMatchMedia'
import useTable from '../hooks/useTable'
import TableOffices from './tables/TableOffices'
import NewOfficeModal from './modals/NewOfficeModal'
import EditOfficeModal from './modals/EditOfficeModal'
import DeleteOfficeModal from './modals/DeleteOfficeModal'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  organizationId: number;
  organization: OrganizationDetail | null;
}

const ShowOffices = ({ organizationId, organization, ...restProps }: Props) => {
  const { token } = useAppContext()
  const [search, setSearch] = useState('')
  const { orderBy, sort, handleSort } = useOrderby('')
  const { data, isFetching, refetch } = useQuery(
    ['getOffices', search, orderBy, organizationId],
    () => getOffices(token, { organization_id: organizationId }),
    {
      initialData: { data: [] },
      enabled: token !== '',
      refetchOnWindowFocus: false,
    }
  )
  const { selectedItems, handleSelectItem, handleSelectAll } = useTable({
    tableData: data.data ? data.data : [],
  })
  const [showNewOfficeModal, setShowNewOfficeModal] = useState(false)
  const [showEditOfficeModal, setShowEditOfficeModal] = useState(false)
  const [showDeleteOfficeModal, setShowDeleteOfficeModal] = useState(false)
  const [officeInfo, setOfficeInfo] = useState<Office>(null)
  const isMobile = useMatchMedia()

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearch(event.target.value)
  }

  const openShowNewOfficeModal = () => setShowNewOfficeModal(true)

  const closeShowNewOfficeModal = () => setShowNewOfficeModal(false)

  const openShowEditOfficeModal = (region: Office) => {
    setOfficeInfo(region)
    setShowEditOfficeModal(true)
  }

  const closeShowEditOfficeModal = () => setShowEditOfficeModal(false)

  const openShowDeleteOfficeModal = () => {
    setOfficeInfo(selectedItems[0])
    setShowDeleteOfficeModal(true)
  }

  const closeShowDeleteOfficeModal = () => setShowDeleteOfficeModal(false)

  const tableHeaders: TableHeaders[] = [
    {
      id: 0,
      label: 'Office',
      value: '',
    },
    {
      id: 1,
      label: 'Location',
      value: '',
    },
    {
      id: 2,
      label: 'Zip code & Address',
      value: '',
    },
    {
      id: 3,
      label: 'Email',
      value: '',
    },
    {
      id: 4,
      label: 'Phone number',
      value: '',
    },
    {
      id: 5,
      label: 'Edit',
      value: '',
    },
  ]

  return (
    <div {...restProps}>
      <div>
        <Input
          name="Search"
          placeholder="Search"
          value={search}
          onChange={handleSearch}
          icon={<ISearch className="Input__Icon" />}
        />
      </div>

      <div className="Dashboard__Filters_Container">
        <p className="text-4 fw-medium">Offices</p>
        <div className="Dashboard__Filters_Container_Mobile d-flex justify-between flex-wrap d-md-none pr-1 w-100">
          <TableFiltersMobile
            headers={tableHeaders}
            sort={sort}
            handleSort={handleSort}
          />
          <TableTitle
            totalItems={data.data?.length}
            itemsSelected={selectedItems.length}
            mobile
          />
          <button
            className="button_red text-5"
            onClick={openShowDeleteOfficeModal}
            disabled={selectedItems.length !== 1}
          >
            Delete
          </button>
          <button
            className="button-yellow ms-auto text-5"
            onClick={openShowNewOfficeModal}
          >
            New office
          </button>
        </div>
        {/* <TableFilters
          filtersSelected={filtersSelected}
          setFiltersSelected={setFiltersSelected}
          filtersGroup={filtersGroup}
        /> */}
        <TableTitle totalItems={data.data?.length} />
        <button
          className="button_red text-5 d-none d-md-flex"
          onClick={openShowDeleteOfficeModal}
          disabled={selectedItems.length !== 1}
        >
          Delete
        </button>
        <button
          className="button-yellow ms-auto d-none d-md-flex"
          onClick={openShowNewOfficeModal}
        >
          New office
        </button>
      </div>

      <Table
        type="Offices"
        handleSelectAll={handleSelectAll}
        selectedItemsLength={selectedItems.length}
        headers={tableHeaders}
        dataLength={data.data?.length}
        loading={isFetching || token === ''}
        sort={sort}
        handleSort={handleSort}
      >
        {data.data?.map((item) => (
          <TableOffices
            key={item.id}
            data={item}
            selected={selectedItems.some(
              (itemSelect) => itemSelect?.id === item.id
            )}
            handleSelectItem={handleSelectItem}
            action={openShowEditOfficeModal}
            isMobile={isMobile}
          />
        ))}
      </Table>

      {showNewOfficeModal ? (
        <NewOfficeModal
          organization={organization}
          refetch={refetch}
          closeModal={closeShowNewOfficeModal}
        />
      ) : (
        <></>
      )}

      {showEditOfficeModal ? (
        <EditOfficeModal
          organizationId={organizationId}
          officeInfo={officeInfo}
          refetch={refetch}
          closeModal={closeShowEditOfficeModal}
        />
      ) : (
        <></>
      )}

      {showDeleteOfficeModal ? (
        <DeleteOfficeModal
          officeInfo={officeInfo}
          refetch={refetch}
          closeModal={closeShowDeleteOfficeModal}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default ShowOffices
