import Checkboxes from './Checkboxes'
import CreateSimulationTitle from './CreateSimulationTitle'
import WarningIcon from '../assets/icons/warning-black.svg'
import classNames from 'classnames'

interface Props {
  title: string;
  alert?: string;
  subtitle: string;
  subtitleAlert?: string;
  options: Array<{ label: string, value: string | boolean }>;
  optionSelected: any;
  handleSelect: (value: boolean) => void;
  available?: boolean;
}

const CreateSimulationSelector = ({ title, alert, subtitle, subtitleAlert, options, optionSelected, handleSelect, available = true }: Props) => {
  return (
    <div className={classNames(
      'CreateSimulation__Container',
      { 'CreateSimulation__Container--Disabled': !available }
    )}>
      <header className="d-flex align-items-center flex-wrap gap-2">
        <img
          src={WarningIcon}
          className="CreateSimulation__Title-Icon"
          alt={ alert }
          title={ alert }
        />

        <CreateSimulationTitle>{ title }</CreateSimulationTitle>

        <p className='text-4 fw-normal'>{ alert }</p>
      </header>

      <Checkboxes
        label={subtitle}
        subLabel={subtitleAlert}
        options={options}
        optionSelected={optionSelected}
        handleSelect={handleSelect}
        disabled={!available}
      />
    </div>
  )
}

export default CreateSimulationSelector