import useAppContext from '../../../hooks/useAppContext'
import { IMail, IOrganization, IPhone, IUser } from '../../../iconComponents'
import { userTypeObject } from '../../../utils/userTypes'
import { TableUsers } from '../../../components/tables/TableUsers'
import { UserComplete } from '../../../api/apiTypes'
import { useState } from 'react'
import UserInfoModal from '../../../components/modals/UserInfoModal'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { formatPhoneNumberIntl } from 'react-phone-number-input'

const Info = () => {
  const { userToDelete } = useAppContext()
  const [userInfo, setUserInfo] = useState<UserComplete>(null)
  const [showUserInfoModal, setShowUserInfoModal] = useState(false)

  const openShowUserInfoModal = (info: UserComplete) => {
    setUserInfo(info)
    setShowUserInfoModal(true)
  }

  const closeShowUserInfoModal = () => {
    setShowUserInfoModal(false)
  }


  return (
    <>
      <div className="Dashboard__Table_path_info_Info_content d-none d-md-flex">
        <div>
          <p className="text-5 fw-medium text-secondary">User information</p>
          <p className="text-4 fw-medium text-primary mt-2">{`${userToDelete[0]?.first_name} ${userToDelete[0]?.last_name}`}</p>
        </div>

        <div className="Modal__Divider" />

        <div className='pt-3'>
          <div className="flex align-items-center gap-1">
            <IUser width={16} height={16} className="text-secondary" />
            <p className="text-5 text-secondary">Type of user</p>
          </div>
          <p className='mt-1 ms-3 ps-1 text-5 text-primary'>
            {userToDelete[0]?.user_profile.user_type
              ? userTypeObject[userToDelete[0]?.user_profile.user_type]
              : '-'}
          </p>
        </div>
        <div>
          <div className="flex align-items-center gap-1">
            <IMail width={16} height={16} className="text-secondary" />
            <p className="text-5 text-secondary">Email</p>
          </div>
          <p className='mt-1 ms-3 ps-1 text-5 text-primary'>
            {userToDelete[0]?.email || '-'}
          </p>
        </div>
        <div>
          <div className="flex align-items-center gap-1">
            <IPhone width={16} height={16} className="text-secondary" />
            <p className="text-5 text-secondary">Phone</p>
          </div>
          <p className='mt-1 ms-3 ps-1 text-5 text-primary'>
            {isValidPhoneNumber(userToDelete[0]?.user_profile.phone) ? formatPhoneNumberIntl(userToDelete[0]?.user_profile.phone) : userToDelete[0]?.user_profile.phone || '-'}
          </p>
        </div>
        <div>
          <div className="flex align-items-center gap-1">
            <IOrganization width={16} height={16} className="text-secondary" />
            <p className="text-5 text-secondary">Organization</p>
          </div>
          <p className='mt-1 ms-3 ps-1 text-5 text-primary'>
            {userToDelete[0]?.user_profile.organization?.name || '-'}
          </p>
        </div>
      </div>
      <div className="TransferSimulation__Simulation_Info_content d-flex d-md-none">
        <p className="text-5 fw-light text-primary">User information</p>
        <div className='Dashboard__Table_path_info_Info_mobile'>
          {userToDelete.map(user => (
            <TableUsers key={user.id} data={user} isMobile unselectable details={openShowUserInfoModal} />
          ))}
        </div>
      </div>
      {showUserInfoModal ? (
        <UserInfoModal data={userInfo} closeModal={closeShowUserInfoModal} />
      ) : (
        <></>
      )}
    </>
  )
}

export default Info
