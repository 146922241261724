import { useState } from 'react'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import { uploadReportInfo } from '../../api/newReport'
import useReportContext from '../../hooks/useReportContext'
import CheckModal from '../modals/CheckModal'
import Modal from '../modals/Modal'
import { IAlert } from '../../iconComponents'
import Button from '../buttons/Button'

export default function UploadReport() {
  const { token } = useAppContext()
  const { simulationId, refetchSimulationDetail } = useNewSimulationContext()
  const { pages, reportLanguage } = useReportContext()
  const [isUploading, setIsUploading] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)

  const handleuploadReport = async () => {
    if (isUploading) return null
    setIsUploading(true)

    const body: Parameters<Awaited<typeof uploadReportInfo>>[1] = {
      simulation_id: simulationId,
    }
    if (reportLanguage === 'en')
      body.report_information_en = JSON.stringify(pages)
    else 
      body.report_information_du = JSON.stringify(pages)

    const { ok, data } = await uploadReportInfo(token, body)

    if (!ok) {
      console.error(data)
      setShowErrorModal(true)
    } else {
      setShowSuccessModal(true)
      await refetchSimulationDetail()
    }
    setIsUploading(false)
  }

  const handleCloseSuccessModal = () => setShowSuccessModal(false) 
  const handleCloseErrorModal = () => setShowErrorModal(false) 

  return (
    <>
      <Button
        style={{ minWidth: 192 }}
        onClick={handleuploadReport}
      >
        {
          !isUploading ? 'Save report' : 'Uploading report'
        }
      </Button>
      {showErrorModal && (
        <Modal
          title="Save report"
          closeModal={handleCloseErrorModal}
        >
          <div className="CheckModal text-center">
            <IAlert className='text-red' width={50}  height={50} />
            <h4 className='text-4 fw-medium'>We could not save your report.</h4>
          </div>
        </Modal>
      )}
      {showSuccessModal && 
        <CheckModal
          closeModal={handleCloseSuccessModal}
          title="Save report"
          message="¡Report saved successfully!"
        />
      }
    </>
  )
}
