import { isValidPhoneNumber } from 'libphonenumber-js'
import { Client } from '../../api/apiTypes'
import useAppContext from '../../hooks/useAppContext'
import { IEdit, IList, ILocation, IOrganization, IPhone, ITrash } from '../../iconComponents'
import TableItem from './TableItem'
import TableItemMenu from './TableItemMenu'
import { formatPhoneNumberIntl } from 'react-phone-number-input'

interface Props {
  data: Client;
  openInfo: (data: Client) => void;
  openEdit: (data: Client) => void;
  openDelete: (data: Client) => void;
  unselectable?: boolean;
  selected?: boolean;
  handleSelectItem?: (data: Client) => void;
  isMobile: boolean;
}

export const TableClients = ({
  data,
  openInfo,
  openEdit,
  openDelete,
  unselectable,
  selected = false,
  handleSelectItem,
  isMobile,
}: Props) => {
  const { isCentralAdmin } = useAppContext()

  const actions = [
    {
      id: 0,
      label: 'View details',
      callback: () => openInfo(data),
    },
    {
      id: 1,
      label: 'Edit',
      callback: () => openEdit(data),
    },
    {
      id: 2,
      label: 'Delete',
      callback: () => openDelete(data),
    },
  ]

  if (isMobile)
    return (
      <TableItem
        data={data}
        unselectable={unselectable}
        selected={selected}
        handleSelectItem={handleSelectItem}
      >
        <div className="TableItem__Cell">
          <p className="text-4 fw-medium">{data.name || '-'}</p>
        </div>
        <div className="TableItem__Cell of-visible justify-content-end">
          <TableItemMenu actions={actions} />
        </div>
        <div className="TableItem__Cell">
          <ILocation className="TableItem__Cell_Icon text-secondary" />
          <p className="text-5 text-secondary">
            {data.country_name || '-'}, {data.address || '-'}
          </p>
        </div>
        <div className="TableItem__Cell">
          {isCentralAdmin ? (
            <>
              <IOrganization className="TableItem__Cell_Icon text-secondary" />
              <p className="text-5 text-secondary">{data.organization_name}</p>
            </>
          ) : (
            <>
              <IPhone className="TableItem__Cell_Icon text-secondary" />
              <p className="text-5 text-secondary">{isValidPhoneNumber(data.phone) ? formatPhoneNumberIntl(data.phone) : data.phone || '-'}</p>
            </>
          )}
        </div>
        <div className="TableItem__Cell--center TableItem__Cell--Doble_columns">
          <IList className="TableItem__Cell_Icon text-primary" />
          <p className="text-5">{data.simulation_count ?? '-'}</p>
        </div>
      </TableItem>
    )

  return (
    <TableItem
      data={data}
      unselectable={unselectable}
      selected={selected}
      handleSelectItem={handleSelectItem}
    >
      <div className="TableItem__Cell" />
      <div className="TableItem__Cell" title={data.name}>
        <p>{data.name || '-'}</p>
      </div>
      {isCentralAdmin ? (
        <div className="TableItem__Cell" title={data.organization_name}>
          <p>{data.organization_name ?? ''}</p>
        </div>
      ) : (
        <></>
      )}
      <div className="TableItem__Cell" title={data.country_name}>
        <p>{data.country_name || '-'}</p>
      </div>
      <div className="TableItem__Cell">
        <p>{data.simulation_count ?? '-'}</p>
      </div>
      <div className="TableItem__Cell" title={data.email}>
        <p>{data.email || '-'}</p>
      </div>
      <div className="TableItem__Cell">
        <p>{isValidPhoneNumber(data.phone) ? formatPhoneNumberIntl(data.phone) : data.phone || '-'}</p>
      </div>
      <div className="TableItem__Cell" title={data.address}>
        <p className="text-nowrap">{data.address || '-'}</p>
      </div>
      <div className="TableItem__Cell">
        <div className='d-flex justify-content-center align-items-center p-2 cursor-pointer' title='Edit' onClick={() => openEdit(data)}>
          <IEdit />
        </div>
        <div className='d-flex justify-content-center align-items-center p-2 cursor-pointer' title='Delete' onClick={() => openDelete(data)}>
          <ITrash />
        </div>
      </div>
    </TableItem>
  )
}
