import { SVGProps } from 'react'
const SvgIWorld = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 4.8c-5.3 0-9.825 1.875-13.575 5.625S4.8 18.7 4.8 24c0 5.3 1.875 9.825 5.625 13.575S18.7 43.2 24 43.2c5.3 0 9.825-1.875 13.575-5.625S43.2 29.3 43.2 24c0-5.3-1.875-9.825-5.625-13.575S29.3 4.8 24 4.8ZM12.975 29.625c1.05 2.65 1.575 5.05 1.575 7.2-2-1.4-3.588-3.237-4.763-5.512C8.612 29.038 8.025 26.6 8.025 24c0-5.15 2.025-9.3 6.075-12.45.9.7 1.725 1.612 2.475 2.737s1.1 2.188 1.05 3.188c-.2 1.55-1.225 3.075-3.075 4.575a7.622 7.622 0 0 0-1.913 3.563 6.319 6.319 0 0 0 .338 4.012Zm27-5.625c0 4.4-1.563 8.163-4.688 11.288C32.163 38.413 28.4 39.975 24 39.975c-2.2 0-4.275-.425-6.225-1.275.25-3.25-.4-6.65-1.95-10.2-.55-1.7-.225-3.05.975-4.05 2.5-2.5 3.825-4.725 3.975-6.675.3-2.7-.975-5.425-3.825-8.175 1.95-1.05 4.3-1.575 7.05-1.575 1.3 0 2.525.15 3.675.45L24.45 11.7l-1.125 1.125 4.65 4.65 11.25 1.575c.5 1.75.75 3.4.75 4.95Zm-10.35-9.45-1.8-1.725L31.05 9.6c2.75 1.3 4.925 3.325 6.525 6.075l-7.95-1.125Zm-4.65 6.75L23.7 23.175c-1 1.6-1.125 3.2-.375 4.8l.675 1.5 1.125 5.4c.15 1.15.85 1.9 2.1 2.25.2.1.45.15.75.15.75 0 1.45-.275 2.1-.825l1.8-1.725.15-.3c.75-2.8 1.6-4.675 2.55-5.625 1.35-.95 1.725-2.65 1.125-5.1-.35-1.35-1.275-2.325-2.775-2.925L29.1 19.2h-.3c-1.75 0-3.025.7-3.825 2.1Zm1.425 3.675 1.275-1.95c.2-.4.525-.6.975-.6l3.225 1.125c.4.2.6.4.6.6.1.35.15.95.15 1.8-1.35 1-2.575 3.275-3.675 6.825l-.825.825-.9-5.1-.975-1.95c-.3-.45-.25-.975.15-1.575Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgIWorld
