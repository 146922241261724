import React, { useState } from 'react'
import Switch from './Switch'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'

type Props = {
  label: string;
  children: React.ReactNode
}

export default function SimulationSwitch({ label, children }: Props) {
  const [showContent, setShowContent] = useState(false)
  return (
    <div className={classNames('SimulationSwitch', { active: showContent})}>
      <button className='SimulationSwitch__Button' onClick={() => setShowContent(p => !p)}>
        <p className='text-5 fw-medium'>{label}</p>
        <Switch active={showContent} />
      </button>
      <CSSTransition in={showContent} timeout={{ enter: 0, exit: 300 }} unmountOnExit>
        <div className='SimulationSwitch__Content'>
          <div className='d-flex flex-column gap-3 p-2'>
            {children}
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}
