import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import useAppContext from '../../hooks/useAppContext'
import Loader from '../../components/Loader'

const AdminNotFound = () => {
  const { firstCharge, user  } = useAppContext()

  return (
    <>
      <Helmet>
        <title>{(firstCharge || !user) ? 'loading...' : 'Error: 404'}</title>
      </Helmet>

      <main className="d-flex flex-column justify-content-center align-items-center gap-4 w-100 h-100">
        {(firstCharge || !user) ? (
          <Loader />
        ) : (
          <>
            <h1>404</h1>
            <Link to="/dashboard">
              Go back to init
            </Link>
          </>
        )}
      </main>
    </>
  )
}

export default AdminNotFound