import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { addSolarStorage, updateSolarStorage } from '../../api/simulations/system-parameters/solar-storage'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import CreateSimulationContainer from '../../containers/CreateSimulationContainer'
import SimulationFormikHandler from '../../containers/SimulationFormikHandler'
import Checkboxes from '../../components/Checkboxes'
import CreateSimulationDisabled from '../../components/CreateSimulationDisabled'
import CreateSimulationTitle from '../../components/CreateSimulationTitle'
import Input from '../../components/Input'
import InputSmall from '../../components/InputSmall'
import { checkboxOptions, normalizeNumberWithCommas, threeDecimalRegex } from '../../constants'
import { submitStep } from '../../utils/submitStep'
import PanelIcon from '../../assets/icons/panel.svg'
import { Link } from 'react-router-dom'

const SolarStorage = () => {
  const { token } = useAppContext()
  const { newSimulation, updateNewSimulation, flowDecisions, setIsStepLoading } = useNewSimulationContext()
  const [ available, setAvailable ] = useState(false)
  const [ loading, setLoading ] = useState(true)

  const numberOfPanelsOne = newSimulation.steps.solar_thermal_field.panel_one?.general_panel_parameters?.number_of_panels || 0
  const numberOfPanelsTwo = newSimulation.steps.solar_thermal_field.panel_two?.general_panel_parameters?.number_of_panels || 0
  const minVolume = Number((20 * (numberOfPanelsOne + numberOfPanelsTwo)).toFixed(2))

  const yupStorageVolumeValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('min', `Min: ${minVolume}`, value => value >= minVolume)
    .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))

  const yupElectricCapacityValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('min', 'This field must be greater than 0', value => value > 0)
    .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))

  const yupIntegerValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('integer', 'Only integers', value => Number.isInteger(Number(value)))
    .test('min', 'Min: 1', value => value >= 1)
    .test('max', 'Max: 5', value => value <= 5)

  const formik = useFormik({
    initialValues: {
      total_solar_storage_volume: '',
      total_number_buffers: '',
      immersed_electric_heater_in_the_buffer: false,
      name_of_the_electrical_heater: '',
      capacity_of_the_electrical_heater: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      total_solar_storage_volume: yupStorageVolumeValidation,
      total_number_buffers: yupIntegerValidation,
      name_of_the_electrical_heater: Yup.string().when('immersed_electric_heater_in_the_buffer', {
        is: true,
        then: Yup.string().required('Required'),
      }),
      capacity_of_the_electrical_heater: Yup.mixed().when('immersed_electric_heater_in_the_buffer', {
        is: true,
        then: yupElectricCapacityValidation,
      }),
    }),
    onSubmit: async (values) => {
      const newValues = {
        total_solar_storage_volume: normalizeNumberWithCommas(values.total_solar_storage_volume),
        total_number_buffers: normalizeNumberWithCommas(values.total_number_buffers),
        immersed_electric_heater_in_the_buffer: String(values.immersed_electric_heater_in_the_buffer),
        name_of_the_electrical_heater: values.name_of_the_electrical_heater,
        capacity_of_the_electrical_heater: normalizeNumberWithCommas(values.capacity_of_the_electrical_heater),
      }

      await submitStep({
        pathname: location.pathname,
        lastStep: newSimulation.last_step,
        addFunction: () => addSolarStorage(token, newSimulation.id, newValues),
        updateFunction: () => updateSolarStorage(token, newSimulation.id, newValues),
        updateNewSimulation,
        setIsStepLoading
      })
    }
  })

  // Check if the step is available
  useEffect(() => {
    if(flowDecisions?.include_a_t_or_pvt) {
      setAvailable(true)
    } else if(available) {
      setAvailable(false)
    }

    setLoading(false)
  }, [flowDecisions])

  // Show saved simulation step
  useEffect(() => {
    if(available) {
      formik.setValues({
        total_solar_storage_volume: newSimulation.steps.solar_storage.total_solar_storage_volume,
        total_number_buffers: newSimulation.steps.solar_storage.total_number_buffers,
        immersed_electric_heater_in_the_buffer: newSimulation.steps.solar_storage.immersed_electric_heater_in_the_buffer,
        name_of_the_electrical_heater: newSimulation.steps.solar_storage.name_of_the_electrical_heater,
        capacity_of_the_electrical_heater: newSimulation.steps.solar_storage.capacity_of_the_electrical_heater,
      })
    }
  }, [newSimulation.steps.solar_storage, available])

  //
  useEffect(() => {
    if(!formik.values.immersed_electric_heater_in_the_buffer) {
      formik.setFieldValue('name_of_the_electrical_heater', '')
      formik.setFieldValue('capacity_of_the_electrical_heater', '')
    }
  }, [formik.values.immersed_electric_heater_in_the_buffer])

  if(loading) return null

  if(!available) return (
    <SimulationFormikHandler formik={formik} skip>
      <Helmet>
        <title>Create Simulation: Solar storage</title>
      </Helmet>

      <CreateSimulationDisabled
        title='Solar storage'
        message={
          <span>
            If you want to complete this step, you must include a T or PVT field in the <Link to='/dashboard/create-simulation/step/solar-thermal-field' className='link'>Solar Thermal Field</Link> step
          </span>
        }
      />
    </SimulationFormikHandler>
  )

  return (
    <SimulationFormikHandler formik={formik}>
      <Helmet>
        <title>Create Simulation: Solar Storage</title>
      </Helmet>

      {/* First Container */}
      <CreateSimulationContainer>
        <header className="d-flex align-items-center flex-wrap gap-2">
          <img
            src={PanelIcon}
            className="CreateSimulation__Title-Icon"
            alt='Solar Storage'
            title='Solar Storage'
          />

          <CreateSimulationTitle>Solar storage</CreateSimulationTitle>
        </header>

        <InputSmall
          topLabel='Total solar storage volume'
          rightLabel="L"
          name='total_solar_storage_volume'
          value={formik.values.total_solar_storage_volume}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.total_solar_storage_volume}
        />

        <InputSmall
          topLabel='Total number of buffers'
          rightLabel="n°"
          name='total_number_buffers'
          value={formik.values.total_number_buffers}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.total_number_buffers}
        />

        <Checkboxes
          label='Immersed electric heater in the buffer?'
          options={checkboxOptions}
          optionSelected={formik.values.immersed_electric_heater_in_the_buffer}
          small
          handleSelect={(value) => formik.setFieldValue('immersed_electric_heater_in_the_buffer', value)}
        />

        <Input
          label='Name of the electrical heater'
          name='name_of_the_electrical_heater'
          value={formik.values.name_of_the_electrical_heater}
          placeholder='Name of the electrical heater'
          onChange={formik.handleChange}
          error={formik.errors.name_of_the_electrical_heater}
          disabled={!formik.values.immersed_electric_heater_in_the_buffer}
        />

        <InputSmall
          topLabel='Capacity of the electric heater'
          rightLabel="kW"
          name='capacity_of_the_electrical_heater'
          value={formik.values.capacity_of_the_electrical_heater}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.capacity_of_the_electrical_heater}
          disabled={!formik.values.immersed_electric_heater_in_the_buffer}
        />
      </CreateSimulationContainer>
    </SimulationFormikHandler>
  )
}

export default SolarStorage