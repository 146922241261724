'use client'
import classNames from 'classnames'
import { IAlert } from '../iconComponents'

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  containerProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
  label?: string
  labelActions?: React.ReactNode
  labelContainerProps?: React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >
  innerLeft?: React.ReactNode
  outerLeft?: React.ReactNode
  innerRight?:React.ReactNode
  outerRight?:React.ReactNode
  name: string
  button?: JSX.Element
  tip?: string
  error?: string
}

export default function Input({
  containerProps: { className: containerClassName, ...containerRestProps } = {},
  label = '',
  labelActions,
  innerLeft,
  outerLeft,
  innerRight,
  outerRight,
  name,
  disabled,
  error,
  className,
  ...restProps
}: Props) {
  return (
    <div
      className={classNames('Input__Container', containerClassName)}
      {...containerRestProps}
    >
      {label || labelActions ? (
        <div className="Input__Label_Container">
          <label
            htmlFor={name}
            className="Input__Label text-5 font-medium"
            dangerouslySetInnerHTML={{ __html: label }}
          />
          {labelActions ?? <></>}
        </div>
      ) : (
        <></>
      )}

      <div className="w-100 d-flex align-items-center gap-1 position-relative">
        <div className="w-100 position-relative">
          {outerLeft ?? <></>}
          <label
            className={classNames(
              'Input Special',
              {
                Disabled: disabled,
              },
              'text-6'
            )}
            htmlFor={name}
          >
            {innerLeft ?? <></>}
            <input
              className={classNames(className)}
              name={name}
              id={name}
              disabled={disabled}
              {...restProps}
            />
            {innerRight ?? <></>}
          </label>
          {outerRight ?? <></>}
        </div>
      </div>

      {error ? (
        <p className={classNames('Input__Error text-6')}>
          <IAlert width="1.25rem" height="1.25rem" className='text-red' />
          <span dangerouslySetInnerHTML={{ __html: error }} />
        </p>
      ) : (
        <></>
      )}
    </div>
  )
}
