import { useQuery } from 'react-query'
import { getReportImagesGallery } from '../../api/newReport'
import useAppContext from '../../hooks/useAppContext'
import useReportContext from '../../hooks/useReportContext'
import { ITrash } from '../../iconComponents'
import { SectionGoal } from '../../types/generateReport'
import InputDebounce from '../InputDebounce'
import { useState, useEffect, useMemo } from 'react'
import { getOrganizations } from '../../api/organizations'
import ImageLibrarySelector from './ImageLibrarySelector'

type Props = {
  section: SectionGoal;
};

export default function GoalCard({ section }: Props) {
  const { token, isCentralAdmin } = useAppContext()
  const {
    handleUpdateGoalLabel,
    handleUpdateGoalItemText,
    handleUpdateGoalItemImage,
    handleAddGoalItem,
    handleRemoveGoalItem,
  } = useReportContext()
  const [error, seterror] = useState('')
  
  useEffect(() => {
    if (!error) return 
    const clearError = setTimeout(() => seterror(''), 10000)
  
    return () => clearTimeout(clearError)
  }, [error])

  const {
    data: library,
    isFetching,
    refetch,
  } = useQuery(
    ['getReportImagesGallery'],
    () => getReportImagesGallery(token, { gallery_type: section.gallery_type ?? 'general' }),
    {
      initialData: {
        response: new Response(),
        data: {
          data: [],
        },
        ok: true,
      },
      enabled: token !== '' && section.withImage,
      refetchOnWindowFocus: false,
    }
  )
  const initialData = { page: 1, total_items: 0, total_pages: 1, data: [] }
  const { data: organizatoins } = useQuery(
    ['getOrganizations'],
    () => getOrganizations(token, initialData),
    {
      initialData,
      enabled: token !== '' && isCentralAdmin,
      refetchOnWindowFocus: false,
    }
  )
  const organizatoinsForInput = useMemo(
    () =>
      organizatoins.data?.map((organizatoin) => ({
        value: organizatoin.id,
        label: organizatoin.name,
      })),
    [organizatoins]
  )

  return (
    <>
      <InputDebounce
        label={section.label.label}
        name={'title_' + section.id}
        placeholder={section.label.placeholder}
        value={section.label.value}
        onDebounce={(value) =>
          handleUpdateGoalLabel(
            section.id,
            value
          )
        }
      />

      {section.value.map((goal, index) => (
        <div className="PageInfo__Card" key={goal.id}>
          <div className="PageInfo__Card_Header">
            <p className="PageInfo__Card_Header_Text">Item {index + 1}</p>
            <ITrash
              className="PageInfo__Card_Header_Icon text-red"
              onClick={() => handleRemoveGoalItem(section.id, goal.id)}
            />
          </div>
          <InputDebounce
            label={goal.subtitle.label}
            name={'subtitle_' + goal.id}
            placeholder={goal.subtitle.placeholder}
            value={goal.subtitle.value}
            onDebounce={(value) =>
              handleUpdateGoalItemText(section.id, goal.id, 'subtitle', value)
            }
          />
          <InputDebounce
            label={goal.description.label}
            name={'description_' + goal.id}
            placeholder={goal.description.placeholder}
            value={goal.description.value}
            onDebounce={(value) =>
              handleUpdateGoalItemText(section.id, goal.id, 'description', value)
            }
          />
          {goal.image !== undefined && (
            <ImageLibrarySelector 
              organizations={organizatoinsForInput}

              id={goal.id}
              value={goal.image.value}
              placeholder={goal.image.placeholder}
              gallery_type={section.gallery_type}
              
              library={library}
              isFetching={isFetching}
              refetch={refetch as any}

              onSelect={(value: string) => handleUpdateGoalItemImage(
                section.id,
                goal.id,
                'image',
                {
                  placeholder: goal.image.placeholder,
                  value,
                }
              )}
              onDelete={() => handleUpdateGoalItemImage(section.id, goal.id, 'image', {
                placeholder: goal.image.placeholder,
                value: null,
              })}
            />
          )}
        </div>
      ))}
      <button
        className="text-5 text-blue text-start"
        onClick={() => handleAddGoalItem(section.id, section.withImage)}
      >
        + Add new goal
      </button>
    </>
  )
}
