import { Helmet } from 'react-helmet'
import { ChangeEventHandler, useState, useEffect, useMemo } from 'react'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Input from '../../components/Input'
import TableFilters from '../../components/TableFilters'
import TableTitle from '../../components/TableTitle'
import Table from '../../components/tables/Table'
import useTable from '../../hooks/useTable'
import classNames from 'classnames'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { useNavigate } from 'react-router-dom'
import TableFiltersMobile from '../../components/TableFiltersMobile'
import useAppContext from '../../hooks/useAppContext'
import { FiltersGroup, FiltersSelected, TableHeaders } from '../../types'
import { usersList } from '../../api/users'
import { useQuery } from 'react-query'
import SimulationInfo from '../../components/SimulationInfo'
import { transferSimulation } from '../../api/simulations/general-simulation'
import { User, UserComplete } from '../../api/apiTypes'
import { TableUsers } from '../../components/tables/TableUsers'
import Path from '../../components/Path'
import EndStep from './DeleteUser/EndStep'
import NavigateSteps from '../../components/NavigateSteps'
import UserInfoModal from '../../components/modals/UserInfoModal'
import useOrderby from '../../hooks/useOrderby'
import { ISearch } from '../../iconComponents'
import useMatchMedia from '../../hooks/useMatchMedia'

const TransferSimulation = () => {
  const {
    firstCharge,
    token,
    user,
    isCentralAdmin,
    isOrganizationAdmin,
    isRegionAdmin,
    simulationsToTransfer,
    setsimulationsToTransfer,
    userToTransfer,
    setUserToTransfer,
  } = useAppContext()
  const [step, setStep] = useState(1)
  const totalSteps = 3

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const [search, setSearch] = useState('')
  const { orderBy, sort, handleSort } = useOrderby('first_name')
  const [filtersSelected, setFiltersSelected] = useState<FiltersSelected[]>([
    {
      label: 'N° of Simulations',
      defaultOption: {
        label: 'All',
        value: { sim_lower: null, sim_upper: null },
      },
      option: {
        label: 'All',
        value: { sim_lower: null, sim_upper: null },
      },
    },
    // {
    //   label: 'Usertype',
    //   defaultOption: {
    //     label: 'All',
    //     value: '',
    //   },
    //   option: {
    //     label: 'All',
    //     value: '',
    //   },
    // },
  ])
  const params = {
    page,
    per_page: perPage,
    search,
    order_by: orderBy,
    sim_lower:
      typeof filtersSelected[0].option.value === 'object'
        ? filtersSelected[0].option.value.sim_lower
        : null,
    sim_upper:
      typeof filtersSelected[0].option.value === 'object'
        ? filtersSelected[0].option.value.sim_upper
        : null,
  }
  const { data, isFetching } = useQuery(
    ['usersList', page, perPage, search, filtersSelected, orderBy],
    () => usersList(token, params),
    {
      initialData: {
        page: 1,
        total_items: 0,
        total_pages: 1,
        data: [],
      },
      enabled: token !== '',
      refetchOnWindowFocus: false,
    }
  )
  const usersToTransfer = useMemo(
    () =>
      isCentralAdmin || isOrganizationAdmin || isRegionAdmin
        ? data.data
        : data.data.filter((item) => item.id !== user.id),
    [data.data, user]
  )
  const { selectedItems, handleSelectItem } = useTable({
    tableData: usersToTransfer,
    oneSelection: true,
  })
  const [buttonIsLoading, setButtonIsLoading] = useState(false)
  const [userInfo, setUserInfo] = useState<UserComplete>(null)
  const [showUserInfoModal, setShowUserInfoModal] = useState(false)

  const isMobile = useMatchMedia()
  const navigate = useNavigate()

  useEffect(() => {
    if (data.total_items) setPerPage(data.total_items)
  }, [data])

  useEffect(() => {
    if (!firstCharge && simulationsToTransfer.length === 0 && step !== 3)
      navigate('/dashboard/manage-simulations')
  }, [simulationsToTransfer])

  useEffect(() => {
    if (userToTransfer.length !== 0) setStep(2)
  }, [firstCharge])

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    setPage(1)
    setSearch(event.target.value)
  }

  const openShowUserInfoModal = (info: UserComplete) => {
    setUserInfo(info)
    setShowUserInfoModal(true)
  }

  const closeShowUserInfoModal = () => {
    setShowUserInfoModal(false)
  }

  const filtersGroup: FiltersGroup[] = [
    {
      label: 'N° of Simulations',
      options: [
        {
          label: '0 - 10',
          value: { sim_lower: 0, sim_upper: 10 },
        },
        {
          label: '10 - 30',
          value: { sim_lower: 10, sim_upper: 30 },
        },
        {
          label: '30 - 50',
          value: { sim_lower: 30, sim_upper: 50 },
        },
        {
          label: '50 +',
          value: { sim_lower: 50, sim_upper: 999999999 },
        },
      ],
    },
    // {
    //   label: 'Usertype',
    //   options: [
    //     {
    //       label: 'Region admin',
    //       value: 'region_admin',
    //     },
    //     {
    //       label: 'Operator',
    //       value: 'operator',
    //     },
    //   ],
    // },
  ]

  const tableHeaders: TableHeaders[] = [
    {
      id: 0,
      label: 'Name',
      value: 'first_name',
    },
    {
      id: 1,
      label: 'Email',
      value: 'email',
    },
    {
      id: 3,
      label: 'User type',
      value: 'user_profile__user_type',
    },
    {
      id: 6,
      label: 'Simulations',
      value: 'simulations',
    },
  ]

  if (isCentralAdmin) {
    tableHeaders.splice(2, 0, {
      id: 2,
      label: 'Organization',
      value: 'user_profile__organization__name',
    })
  }

  if (isOrganizationAdmin) {
    tableHeaders.splice(2, 0, {
      id: 2,
      label: 'Region',
      value: '', // TODO
    })
  }

  if (isCentralAdmin || isOrganizationAdmin || isRegionAdmin) {
    tableHeaders.splice(
      isCentralAdmin || isOrganizationAdmin ? 4 : 3,
      0,
      {
        id: 4,
        label: 'Permissions',
        value: 'permissions',
      },
      {
        id: 5,
        label: 'Created by',
        value: 'created_by__first_name',
      }
    )
    tableHeaders.push({
      id: 7,
      label: 'Last active',
      value: 'last_active',
    })
  }

  const handleBack = () => {
    if (step === 1 || step === totalSteps) return
    setUserToTransfer([])
    setStep((prev) => prev - 1)
  }

  const handleNext = async () => {
    if (step === 1) {
      setUserToTransfer(selectedItems)
      setStep((prev) => prev + 1)
      return
    }
    if (step === totalSteps) return goBackToSimulations()
    if (buttonIsLoading) return
    setButtonIsLoading(true)

    const simulationsFiltered = simulationsToTransfer.filter(
      (simulation) => simulation.owner_id !== selectedItems[0].id
    )

    if (simulationsFiltered.length === 0) {
      setStep((prev) => prev + 1)
      setsimulationsToTransfer([])
      setUserToTransfer([])

      setButtonIsLoading(false)
      return
    }

    const body = {
      simulation_ids: simulationsFiltered.map((simulation) => simulation.id),
      new_owner_id: selectedItems[0].id,
    }

    const response = await transferSimulation(token, body)

    if (response.error) console.error(response.error)
    else if (response) {
      setStep((prev) => prev + 1)
      setsimulationsToTransfer([])
      setUserToTransfer([])
    }

    setButtonIsLoading(false)
  }

  const listOfUsers = () => {
    const listUsers: User[] = []

    simulationsToTransfer.forEach((simulation) => {
      if (!listUsers.some((user) => user.id === simulation.owner_id))
        listUsers.push({ id: simulation.owner_id, first_name: simulation.owner_first_name, last_name: simulation.owner_last_name})
    })

    return listUsers
      .sort((a, b) => {
        const nameA = `${a.first_name} ${a.last_name}`.toUpperCase()
        const nameB = `${b.first_name} ${b.last_name}`.toUpperCase()

        if (nameA < nameB) return -1
        else if (nameA > nameB) return 1
        else return 0
      })
      .map((user) => (
        <p key={user.id} className="text-5 text-secondary fw-medium">
          {`${user.first_name} ${user.last_name}`}
        </p>
      ))
  }

  const goBackToSimulations = () => {
    setsimulationsToTransfer([])
    setUserToTransfer([])
    navigate('/dashboard/manage-simulations')
  }

  return (
    <>
      <Helmet>
        <title>Solarus: Transfer simulations</title>
      </Helmet>
      <section
        className={classNames('Dashboard__Table_path_info', {
          Info_5Row: !isCentralAdmin && !isOrganizationAdmin && !isRegionAdmin,
          Info_6Row: isCentralAdmin || isOrganizationAdmin || isRegionAdmin,
          No_Info: (step >= 2 && !isMobile) || (step === 3 && isMobile),
        })}
      >
        <Path
          handleBack={goBackToSimulations}
          parentPath="Manage simulations"
          path="Transfer simulations"
          currentStepName={classNames(
            { 'Choose user': step === 1 },
            { 'Confirm data': step === 2 }
          )}
          disable={step === 3}
        />
        <SimulationInfo />
        <SwitchTransition>
          <CSSTransition key={step} timeout={200}>
            <div className="Dashboard__Table_path_info_Content Animation_Opacity">
              {step === 1 && (
                <>
                  <p className="text-5 fw-medium text-secondary_black d-none d-md-block">
                    Search a user to transfer selected simulations
                  </p>
                  <p className="text-5 fw-medium text-primary d-block d-md-none">
                    Select destination user
                  </p>
                  <div className="TransferSimulation__Content_Filters_Container">
                    <div className="flex-grow-1">
                      <Input
                        name="Search"
                        placeholder="Search"
                        value={search}
                        onChange={handleSearch}
                        icon={<ISearch className="Input__Icon" />}
                      />
                    </div>

                    <div className="Dashboard__Filters_Container_Mobile d-flex justify-between gap-3 d-md-none pr-1">
                      <TableFiltersMobile
                        filtersSelected={filtersSelected}
                        setFiltersSelected={setFiltersSelected}
                        filtersGroup={filtersGroup}
                        headers={tableHeaders}
                        sort={sort}
                        handleSort={handleSort}
                      />

                      <TableTitle
                        totalItems={usersToTransfer.length}
                        itemsSelected={selectedItems.length}
                        mobile
                      />
                    </div>

                    <TableFilters
                      filtersSelected={filtersSelected}
                      setFiltersSelected={setFiltersSelected}
                      filtersGroup={filtersGroup}
                    />
                    <TableTitle
                      totalItems={usersToTransfer.length}
                      itemsSelected={selectedItems.length}
                    />
                  </div>

                  <Table
                    type={
                      isCentralAdmin || isOrganizationAdmin
                        ? 'Users_Extended_Plus'
                        : isRegionAdmin
                          ? 'Users_Extended'
                          : 'Users'
                    }
                    headers={tableHeaders}
                    dataLength={usersToTransfer.length}
                    loading={isFetching || token === ''}
                    sort={sort}
                    handleSort={handleSort}
                    page={page}
                    setPage={setPage}
                    perPage={data.total_items}
                    totalPages={data.total_pages}
                    totalItems={data.total_items}
                  >
                    {usersToTransfer.map((item) => (
                      <TableUsers
                        key={item.id}
                        data={item}
                        selected={selectedItems.some(
                          (itemSelect) => itemSelect?.id === item.id
                        )}
                        handleSelectItem={handleSelectItem}
                        isMobile={isMobile}
                        details={openShowUserInfoModal}
                        oneSelection
                      />
                    ))}
                  </Table>
                  {showUserInfoModal ? (
                    <UserInfoModal
                      data={userInfo}
                      closeModal={closeShowUserInfoModal}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
              {step === 2 && (
                <>
                  <div className="TransferSimulation__Simulation_Info_Title">
                    <p className="text-4 fw-medium">
                      {simulationsToTransfer.length}
                    </p>
                    <p className="text-5">
                      {simulationsToTransfer.length == 1
                        ? 'Simulation'
                        : 'Simulations'}{' '}
                      selected
                    </p>
                  </div>

                  <div className="TransferSimulation__Simulation_Info_Users">
                    <div className="TransferSimulation__Simulation_Info_Users_User">
                      <p className="text-4 text-primary fw-medium">From</p>
                      {isCentralAdmin ||
                      isOrganizationAdmin ||
                      isRegionAdmin ? (
                          listOfUsers()
                        ) : (
                          <p className="text-5 text-secondary fw-medium">
                            {user.first_name
                              ? `${user.first_name} ${user.last_name}`
                              : 'Invitation sent'}
                          </p>
                        )}
                    </div>
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="TransferSimulation__Simulation_Info_Users_Arrow text-blue_primary"
                    />
                    <div className="TransferSimulation__Simulation_Info_Users_User">
                      <p className="text-4 text-primary fw-medium">To</p>
                      <p className="text-5 text-secondary fw-medium">
                        {userToTransfer[0].first_name
                          ? `${userToTransfer[0].first_name} ${userToTransfer[0].last_name}`
                          : 'Invitation sent'}
                      </p>
                    </div>
                  </div>
                </>
              )}
              {step === 3 && (
                <EndStep title="¡Simulations transferred successfully!" />
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
        <NavigateSteps
          step={step}
          totalSteps={totalSteps}
          handleBack={handleBack}
          handleNext={handleNext}
          nextDisabled={selectedItems.length !== 1}
          nextIsLoading={buttonIsLoading}
        />
      </section>
    </>
  )
}

export default TransferSimulation
