import React, { RefObject } from 'react'
import classNames from 'classnames'

interface Props {
  containerRef?: RefObject<HTMLDivElement>;
  available?: boolean;
  hide?: boolean;
  children: React.ReactNode;
}

const CreateSimulationContainer = ({ containerRef, available = true, hide = false, children }: Props) => {
  return (
    <div
      ref={containerRef}
      className={classNames(
        'CreateSimulation__Container',
        { 'CreateSimulation__Container--Disabled': !available },
        { 'd-none': hide }
      )}
    >
      { children }
    </div>
  )
}

export default CreateSimulationContainer