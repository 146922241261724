import { deleteQuery, getQuery, patchQuery, postQuery } from '../../apiFunctions'

export const getExistingHotWaterSystem = async (token, simulation_id) => {
  return getQuery<any>({
    path: `/simulations/existing-hot-water-system/${simulation_id}/`,
    token,
    callback: data => data.data
  })
}

export const addExistingHotWaterSystem = async (token, simulation_id, body) => {
  return postQuery<any>({
    path: `/simulations/existing-hot-water-system/${simulation_id}/`,
    token,
    body
  })
}

export const updateExistingHotWaterSystem = async (token, simulation_id, body) => {
  return patchQuery<any>({
    path: `/simulations/existing-hot-water-system/${simulation_id}/`,
    token,
    body
  })
}

// Water System
// interface GetWaterSystems {
//   data?: any[];
//   error?: string;
// }

export const getHotWaterSystems = async (
  token: string,
) => {
  return await getQuery<any>({
    path: '/simulations/water-systems/',
    token,
    callback: data => data.data
  })
}

interface CreateWaterSystems {
  data?: {
    id: number,
    system_name: string,
    energy_source: string,
    is_admin_creation: boolean,
    CO2_Fuel_txt: string,
    CO2_Fuel: number,
    kWH_CF_Fuel_txt: string,
    kWh_CF_Fuel: number,
    n_ex_syst: number,
    is_showed: string,
    deleted: boolean,
    owner: number
  };
  error?: string;
  non_field_errors?: string[];
}

export const createHotWaterSystem = async (
  token: string, body: {
    system_name: string,
    energy_source: string,
    CO2_Fuel_txt: string,
    CO2_Fuel: number | string,
    kWH_CF_Fuel_txt: string,
    kWh_CF_Fuel: number | string,
    n_ex_syst: number | string,
    is_showed: string,
  }
) => {
  return await postQuery<CreateWaterSystems>({
    path: '/simulations/water-systems/',
    token,
    body,
  })
}

export const getHotWaterSystemDetails = async (token, system_id) => {
  return getQuery<any>({
    path: `/simulations/water-systems/${system_id}/`,
    token,
    callback: data => data.data
  })
}

export const updateHotWaterSystem = async (token, simulation_id, body) => {
  return patchQuery<any>({
    path: `/simulations/water-systems/${simulation_id}/`,
    token,
    body
  })
}

export const deleteHotWaterSystem = async (token, simulation_id) => {
  return deleteQuery<any>({
    path: `/simulations/water-systems/${simulation_id}/`,
    token,
  })
}