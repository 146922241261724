import { isValidPhoneNumber } from 'libphonenumber-js'
import { Organization, OrganizationDetail } from '../../api/apiTypes'
import {
  IAdmin,
  IEdit,
  ILocation,
  ITrash,
  IUser,
  IWorld,
} from '../../iconComponents'
import TableItem from './TableItem'
import TableItemMenu from './TableItemMenu'
import { formatPhoneNumberIntl } from 'react-phone-number-input'

interface Props {
  data: Organization | OrganizationDetail;
  openEdit?: (organization: Organization) => void;
  openDelete?: (organization: Organization) => void;
  unselectable?: boolean;
  selected?: boolean;
  handleSelectItem?: (data: Organization) => void;
  isMobile: boolean;
}

const TableOrganizations = ({
  data,
  openEdit,
  openDelete,
  unselectable,
  selected = false,
  handleSelectItem,
  isMobile,
}: Props) => {
  const preventClick = (
    event: React.MouseEvent<HTMLDivElement>,
    callback: () => void
  ) => {
    event.stopPropagation()
    callback()
  }

  const actions: {
    id: number;
    label: string;
    callback: () => void;
  }[] = []
  if (openEdit)
    actions.push({
      id: 1,
      label: 'Edit',
      callback: () => openEdit(data),
    })
  if (openDelete)
    actions.push({
      id: 2,
      label: 'Delete',
      callback: () => openDelete(data),
    })

  if (isMobile)
    return (
      <TableItem
        data={data}
        unselectable={unselectable}
        selected={selected}
        handleSelectItem={handleSelectItem}
      >
        <div className={'TableItem__Cell'}>
          <p className="text-4 fw-medium">{data.name || '-'}</p>
        </div>
        <div className="TableItem__Cell of-visible justify-content-end">
          {openEdit || openDelete ? <TableItemMenu actions={actions} /> : <></>}
        </div>
        <div className="TableItem__Cell">
          <ILocation className="TableItem__Cell_Icon text-secondary flex-shrink-0" />
          <p className="text-5 text-secondary">{data.address || '-'}</p>
        </div>
        <div className="TableItem__Cell">
          <IAdmin className="TableItem__Cell_Icon text-secondary flex-shrink-0" />
          <p className="text-5 text-secondary">
            {data.admins.length !== 0 && typeof data.admins[0].first_name
              ? data.admins
                .reduce(
                  (acc, cur) =>
                    acc +
                      `${
                        cur.first_name
                          ? `${cur.first_name} ${cur.last_name}`
                          : 'Invitation sent'
                      }, `,
                  ''
                )
                .slice(0, -2)
              : '-'}
          </p>
        </div>
        <div className="TableItem__Cell">
          <IWorld className="TableItem__Cell_Icon text-secondary flex-shrink-0" />
          <p className="text-5 text-secondary">
            {data.operating_countries ?? 0}
          </p>
        </div>
        <div className="TableItem__Cell">
          <IUser className="TableItem__Cell_Icon text-secondary flex-shrink-0" />
          <p className="text-5 text-secondary">{data.users ?? 0}</p>
        </div>
        <div className="TableItem__Cell--center TableItem__Cell--Doble_columns">
          <p className="text-5">{data.email || '-'}</p>
        </div>
      </TableItem>
    )

  return (
    <TableItem
      data={data}
      unselectable={unselectable}
      selected={selected}
      handleSelectItem={handleSelectItem}
    >
      <div className="TableItem__Cell" title={data.name}>
        <p>{data.name || '-'}</p>
      </div>
      <div className="TableItem__Cell" title={data.address}>
        <p>{data.address || '-'}</p>
      </div>
      <div
        className="TableItem__Cell"
        title={data.operating_countries ? `${data.operating_countries}` : '0'}
      >
        <p>{data.operating_countries ?? 0}</p>
      </div>
      <div className="TableItem__Cell">
        <p>{data.users ?? 0}</p>
      </div>
      <div
        className="TableItem__Cell"
        title={
          data.admins.length !== 0 && typeof data.admins[0].first_name
            ? data.admins
              .reduce(
                (acc, cur) =>
                  acc +
                    `${
                      cur.first_name
                        ? `${cur.first_name} ${cur.last_name}`
                        : 'Invitation sent'
                    }, `,
                ''
              )
              .slice(0, -2)
            : ''
        }
      >
        <p className="text-nowrap">
          {data.admins.length !== 0 && typeof data.admins[0].first_name
            ? data.admins
              .reduce(
                (acc, cur) =>
                  acc +
                    `${
                      cur.first_name
                        ? `${cur.first_name} ${cur.last_name}`
                        : 'Invitation sent'
                    }, `,
                ''
              )
              .slice(0, -2)
            : '-'}
        </p>
      </div>
      <div className="TableItem__Cell">
        <p className="text-nowrap">
          {isValidPhoneNumber(data.phone)
            ? formatPhoneNumberIntl(data.phone)
            : data.phone || '-'}
        </p>
      </div>
      <div className="TableItem__Cell" title={data.email}>
        <p className="text-nowrap">{data.email || '-'}</p>
      </div>
      <div className="TableItem__Cell">
        <div
          className="d-flex justify-content-center align-items-center p-2 cursor-pointer"
          title="Edit"
          onClick={(e) => preventClick(e, () => openEdit(data))}
        >
          <IEdit />
        </div>
        <div
          className="d-flex justify-content-center align-items-center p-2 cursor-pointer"
          title="Delete"
          onClick={(e) => preventClick(e, () => openDelete(data))}
        >
          <ITrash />
        </div>
      </div>
    </TableItem>
  )
}

export default TableOrganizations
