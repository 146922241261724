import { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { addUncertainty, updateUncertainty } from '../../api/simulations/financial-parameters/uncertainty'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import CreateSimulationContainer from '../../containers/CreateSimulationContainer'
import SimulationFormikHandler from '../../containers/SimulationFormikHandler'
import CreateSimulationTitle from '../../components/CreateSimulationTitle'
import InputSmall from '../../components/InputSmall'
import Input from '../../components/Input'
import { submitStep } from '../../utils/submitStep'
import ErrorIcon from '../../assets/icons/warning-red.svg'
import UncertaintyIcon from '../../assets/icons/uncertainty.svg'
import UpIcon from '../../assets/icons/up-black.svg'
import { normalizeNumberWithCommas, normalizePercentToSend, normalizePercentToShow, threeDecimalRegex } from '../../constants'

const Uncertainty = () => {
  const { token } = useAppContext()
  const { newSimulation, updateNewSimulation, flowDecisions, setIsStepLoading } = useNewSimulationContext()

  const yupPercentageValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('min', 'Min: 0%', value => value >= 0)
    // .test('max', 'Max: 100%', value => value <= 100)
    .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))

  const yupPercentageValidationNegative = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
  // .test('min', 'Min: 0%', value => value >= 0)
  // .test('max', 'Max: 100%', value => value <= 100)
    .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))

  const formik = useFormik({
    initialValues: {
      cost_uncertainty: 0,
      heat_production_uncertainty: 0,
      electricity_production_uncertainty: 0,
      cash_flow_year_1: 0,
      cash_flow_year_2: 0,
      cash_flow_year_3: 0,
      cash_flow_year_4: 0,
      cash_flow_year_5: 0,
      cash_flow_year_6: 0,
      cash_flow_year_7: 0,
      cash_flow_year_8: 0,
      cash_flow_year_9: 0,
      cash_flow_year_10: 0,
      cash_flow_year_11: 0,
      cash_flow_year_12: 0,
      cash_flow_year_13: 0,
      cash_flow_year_14: 0,
      cash_flow_year_15: 0,
      cash_flow_year_16: 0,
      cash_flow_year_17: 0,
      cash_flow_year_18: 0,
      cash_flow_year_19: 0,
      cash_flow_year_20: 0,
      cash_flow_year_21: 0,
      cash_flow_year_22: 0,
      cash_flow_year_23: 0,
      cash_flow_year_24: 0,
      cash_flow_year_25: 0,
      cash_flow_error: ''
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      cost_uncertainty: yupPercentageValidation,
      heat_production_uncertainty: yupPercentageValidationNegative,
      electricity_production_uncertainty: yupPercentageValidationNegative,
    }),
    validate: values => {
      const errors = {
        cash_flow_error: ''
      }

      const cashFlowValues = {
        cash_flow_year_1: values.cash_flow_year_1 && normalizeNumberWithCommas(values.cash_flow_year_1),
        cash_flow_year_2: values.cash_flow_year_2 && normalizeNumberWithCommas(values.cash_flow_year_2),
        cash_flow_year_3: values.cash_flow_year_3 && normalizeNumberWithCommas(values.cash_flow_year_3),
        cash_flow_year_4: values.cash_flow_year_4 && normalizeNumberWithCommas(values.cash_flow_year_4),
        cash_flow_year_5: values.cash_flow_year_5 && normalizeNumberWithCommas(values.cash_flow_year_5),
        cash_flow_year_6: values.cash_flow_year_6 && normalizeNumberWithCommas(values.cash_flow_year_6),
        cash_flow_year_7: values.cash_flow_year_7 && normalizeNumberWithCommas(values.cash_flow_year_7),
        cash_flow_year_8: values.cash_flow_year_8 && normalizeNumberWithCommas(values.cash_flow_year_8),
        cash_flow_year_9: values.cash_flow_year_9 && normalizeNumberWithCommas(values.cash_flow_year_9),
        cash_flow_year_10: values.cash_flow_year_10 && normalizeNumberWithCommas(values.cash_flow_year_10),
        cash_flow_year_11: values.cash_flow_year_11 && normalizeNumberWithCommas(values.cash_flow_year_11),
        cash_flow_year_12: values.cash_flow_year_12 && normalizeNumberWithCommas(values.cash_flow_year_12),
        cash_flow_year_13: values.cash_flow_year_13 && normalizeNumberWithCommas(values.cash_flow_year_13),
        cash_flow_year_14: values.cash_flow_year_14 && normalizeNumberWithCommas(values.cash_flow_year_14),
        cash_flow_year_15: values.cash_flow_year_15 && normalizeNumberWithCommas(values.cash_flow_year_15),
        cash_flow_year_16: values.cash_flow_year_16 && normalizeNumberWithCommas(values.cash_flow_year_16),
        cash_flow_year_17: values.cash_flow_year_17 && normalizeNumberWithCommas(values.cash_flow_year_17),
        cash_flow_year_18: values.cash_flow_year_18 && normalizeNumberWithCommas(values.cash_flow_year_18),
        cash_flow_year_19: values.cash_flow_year_19 && normalizeNumberWithCommas(values.cash_flow_year_19),
        cash_flow_year_20: values.cash_flow_year_20 && normalizeNumberWithCommas(values.cash_flow_year_20),
        cash_flow_year_21: values.cash_flow_year_21 && normalizeNumberWithCommas(values.cash_flow_year_21),
        cash_flow_year_22: values.cash_flow_year_22 && normalizeNumberWithCommas(values.cash_flow_year_22),
        cash_flow_year_23: values.cash_flow_year_23 && normalizeNumberWithCommas(values.cash_flow_year_23),
        cash_flow_year_24: values.cash_flow_year_24 && normalizeNumberWithCommas(values.cash_flow_year_24),
        cash_flow_year_25: values.cash_flow_year_25 && normalizeNumberWithCommas(values.cash_flow_year_25),
      }

      const cashFlowValuesArray = Object.values(cashFlowValues).splice(0, flowDecisions?.economic_lifetime)

      const hasEmptyValues = cashFlowValuesArray.some(value => typeof value === 'string' && value === '')
      const hasInvalidValues = cashFlowValuesArray.some(value => typeof value !== 'number' || isNaN(value))
      const hasMaxDecimals = cashFlowValuesArray.some((value) => threeDecimalRegex.test(String(value * 100)))
      // const hasInvalidMinMaxValues = cashFlowValuesArray.some((value) => value < 0)

      if(hasEmptyValues) {
        errors.cash_flow_error = 'Complete all fields'
      } else if(hasInvalidValues){
        errors.cash_flow_error = 'There are invalid values, only numbers are allowed'
      } else if(hasMaxDecimals) {
        errors.cash_flow_error = 'Invalid values: all values must have a maximum of 3 decimals'
      }
      // else if(hasInvalidMinMaxValues) {
      //   errors.cash_flow_error = 'Invalid values: all values must be greater than 0'
      // }

      if(errors.cash_flow_error) return errors
      else return
    },
    onSubmit: async (values) => {
      const newValues = {
        cost_uncertainty: normalizePercentToSend(normalizeNumberWithCommas(values.cost_uncertainty)),
        heat_production_uncertainty: normalizePercentToSend(normalizeNumberWithCommas(values.heat_production_uncertainty)),
        electricity_production_uncertainty: normalizePercentToSend(normalizeNumberWithCommas(values.electricity_production_uncertainty)),
        cash_flow_year_1: normalizeNumberWithCommas(values.cash_flow_year_1),
        cash_flow_year_2: normalizeNumberWithCommas(values.cash_flow_year_2), 
        cash_flow_year_3: normalizeNumberWithCommas(values.cash_flow_year_3),
        cash_flow_year_4: normalizeNumberWithCommas(values.cash_flow_year_4),
        cash_flow_year_5: normalizeNumberWithCommas(values.cash_flow_year_5),
        cash_flow_year_6: normalizeNumberWithCommas(values.cash_flow_year_6),
        cash_flow_year_7: normalizeNumberWithCommas(values.cash_flow_year_7),
        cash_flow_year_8: normalizeNumberWithCommas(values.cash_flow_year_8),
        cash_flow_year_9: normalizeNumberWithCommas(values.cash_flow_year_9),
        cash_flow_year_10: normalizeNumberWithCommas(values.cash_flow_year_10),
        cash_flow_year_11: normalizeNumberWithCommas(values.cash_flow_year_11),
        cash_flow_year_12: normalizeNumberWithCommas(values.cash_flow_year_12),
        cash_flow_year_13: normalizeNumberWithCommas(values.cash_flow_year_13),
        cash_flow_year_14: normalizeNumberWithCommas(values.cash_flow_year_14),
        cash_flow_year_15: normalizeNumberWithCommas(values.cash_flow_year_15),
        cash_flow_year_16: normalizeNumberWithCommas(values.cash_flow_year_16),
        cash_flow_year_17: normalizeNumberWithCommas(values.cash_flow_year_17),
        cash_flow_year_18: normalizeNumberWithCommas(values.cash_flow_year_18),
        cash_flow_year_19: normalizeNumberWithCommas(values.cash_flow_year_19),
        cash_flow_year_20: normalizeNumberWithCommas(values.cash_flow_year_20),
        cash_flow_year_21: normalizeNumberWithCommas(values.cash_flow_year_21),
        cash_flow_year_22: normalizeNumberWithCommas(values.cash_flow_year_22),
        cash_flow_year_23: normalizeNumberWithCommas(values.cash_flow_year_23),
        cash_flow_year_24: normalizeNumberWithCommas(values.cash_flow_year_24),
        cash_flow_year_25: normalizeNumberWithCommas(values.cash_flow_year_25),
      }

      await submitStep({
        pathname: location.pathname,
        lastStep: newSimulation.last_step,
        addFunction: () => addUncertainty(token, newSimulation.id, newValues),
        updateFunction: () => updateUncertainty(token, newSimulation.id, newValues),
        updateNewSimulation,
        setIsStepLoading
      })
    }
  })

  // Show saved simulation step
  useEffect(() => {
    formik.setValues({
      cost_uncertainty: Number(normalizePercentToShow(newSimulation.steps.uncertainty.cost_uncertainty)),
      heat_production_uncertainty: Number(normalizePercentToShow(newSimulation.steps.uncertainty.heat_production_uncertainty)),
      electricity_production_uncertainty: Number(normalizePercentToShow(newSimulation.steps.uncertainty.electricity_production_uncertainty)),
      cash_flow_year_1: newSimulation.steps.uncertainty.cash_flow_year_1,
      cash_flow_year_2: newSimulation.steps.uncertainty.cash_flow_year_2,
      cash_flow_year_3: newSimulation.steps.uncertainty.cash_flow_year_3,
      cash_flow_year_4: newSimulation.steps.uncertainty.cash_flow_year_4,
      cash_flow_year_5: newSimulation.steps.uncertainty.cash_flow_year_5,
      cash_flow_year_6: newSimulation.steps.uncertainty.cash_flow_year_6,
      cash_flow_year_7: newSimulation.steps.uncertainty.cash_flow_year_7,
      cash_flow_year_8: newSimulation.steps.uncertainty.cash_flow_year_8,
      cash_flow_year_9: newSimulation.steps.uncertainty.cash_flow_year_9,
      cash_flow_year_10: newSimulation.steps.uncertainty.cash_flow_year_10,
      cash_flow_year_11: newSimulation.steps.uncertainty.cash_flow_year_11,
      cash_flow_year_12: newSimulation.steps.uncertainty.cash_flow_year_12,
      cash_flow_year_13: newSimulation.steps.uncertainty.cash_flow_year_13,
      cash_flow_year_14: newSimulation.steps.uncertainty.cash_flow_year_14,
      cash_flow_year_15: newSimulation.steps.uncertainty.cash_flow_year_15,
      cash_flow_year_16: newSimulation.steps.uncertainty.cash_flow_year_16,
      cash_flow_year_17: newSimulation.steps.uncertainty.cash_flow_year_17,
      cash_flow_year_18: newSimulation.steps.uncertainty.cash_flow_year_18,
      cash_flow_year_19: newSimulation.steps.uncertainty.cash_flow_year_19,
      cash_flow_year_20: newSimulation.steps.uncertainty.cash_flow_year_20,
      cash_flow_year_21: newSimulation.steps.uncertainty.cash_flow_year_21,
      cash_flow_year_22: newSimulation.steps.uncertainty.cash_flow_year_22,
      cash_flow_year_23: newSimulation.steps.uncertainty.cash_flow_year_23,
      cash_flow_year_24: newSimulation.steps.uncertainty.cash_flow_year_24,
      cash_flow_year_25: newSimulation.steps.uncertainty.cash_flow_year_25,
      cash_flow_error: ''
    })
  }, [newSimulation])

  const pricesPerYear = useMemo(() => {
    const years = []

    for (let i = 0; i < 25; i++) {
      years.push({
        label: `Year ${i + 1}`,
        name: `cash_flow_year_${i + 1}`,
        value: formik.values[`cash_flow_year_${i + 1}`],
        error: formik.errors[`cash_flow_year_${i + 1}`],
        disabled: i >= Number(flowDecisions?.economic_lifetime),
      })
    }

    return years
  }, [formik.values, flowDecisions?.economic_lifetime])

  return (
    <SimulationFormikHandler formik={formik}>
      <Helmet>
        <title>Create Simulation: Uncertainty</title>
      </Helmet>

      <CreateSimulationContainer>
        <header className="d-flex align-items-center flex-wrap gap-2">
          <img
            src={UncertaintyIcon}
            className="CreateSimulation__Title-Icon"
            alt='Uncertainty'
            title='Uncertainty'
          />

          <CreateSimulationTitle>Uncertainty</CreateSimulationTitle>
        </header>

        <InputSmall
          topLabel='Cost uncertainty'
          rightLabel='%'
          name='cost_uncertainty'
          value={formik.values.cost_uncertainty}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.cost_uncertainty}
        />

        <InputSmall
          topLabel='Heat production uncertainty'
          rightLabel='%'
          name='heat_production_uncertainty'
          value={formik.values.heat_production_uncertainty}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.heat_production_uncertainty}
        />

        <InputSmall
          topLabel='Electricity production uncertainty'
          rightLabel='%'
          name='electricity_production_uncertainty'
          value={formik.values.electricity_production_uncertainty}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.electricity_production_uncertainty}
        />
      </CreateSimulationContainer>

      <CreateSimulationContainer>
        <header className="d-flex align-items-center flex-wrap gap-2">
          <img
            src={UpIcon}
            className="CreateSimulation__Title-Icon"
            alt='Cashflow'
            title='Cashflow'
          />

          <CreateSimulationTitle>Uncertainty Cashflow</CreateSimulationTitle>
        </header>

        <div className="d-flex flex-column gap-2">
          <div className="w-100 d-flex align-items-center gap-2">
            <h3 className='d-flex align-items-center gap-1 text-4 fw-medium'>
              {formik.errors.cash_flow_error && (
                <div className='Input__Error-Icon-Container'>
                  <img className='Input__Error-Icon' src={ErrorIcon} />
                </div>
              )}
                Years
            </h3>
            {formik.errors.cash_flow_error && (
              <span className='Input__Error text-6'>{ formik.errors.cash_flow_error }</span>
            )}
          </div>

          <div className="d-flex align-items-end gap-2">
            <div className="SubsidyTaxBenefits__Percentages-Grid">
              {pricesPerYear.map((item, index) => (
                <Input
                  key={`Input-Prices-Per-Year-${index}`}
                  label={item.label}
                  name={item.name}
                  value={item.value}
                  placeholder='0'
                  onChange={formik.handleChange}
                  error={item.error}
                  small
                  type='number'
                  disabled={item.disabled}
                />
              ))}
            </div>

            <div className="SubsidyTaxBenefits__Percentages-Grid-Extra">
              <span className="text-6 text-secondary pb-1">{newSimulation.steps.general_information.currency_symbol}</span>
              <span className="text-6 text-secondary pb-1">{newSimulation.steps.general_information.currency_symbol}</span>
              <span className="text-6 text-secondary pb-1">{newSimulation.steps.general_information.currency_symbol}</span>
              <span className="text-6 text-secondary pb-1">{newSimulation.steps.general_information.currency_symbol}</span>
              <span className="text-6 text-secondary pb-1">{newSimulation.steps.general_information.currency_symbol}</span>
            </div>
          </div>
        </div>
      </CreateSimulationContainer>

    </SimulationFormikHandler>
  )
}

export default Uncertainty