import { useMemo, useState } from 'react'
import Modal from './Modal'
import { IAlert, IArrowRight } from '../../iconComponents'
import useAppContext from '../../hooks/useAppContext'
import LoadingDots from '../LoadingDots'
import CheckModal from './CheckModal'
import { Region } from '../../api/apiTypes'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'
import SearchInput from '../SearchInput'
import { useQuery } from 'react-query'
import { deleteOrganizationRegion, getOrganizationRegions } from '../../api/organizations'

interface Props {
  closeModal: () => void;
  regionInfo: Region;
  refetch: () => void;
}

const DeleteRegionModal = ({
  closeModal,
  regionInfo,
  refetch,
}:
Props) => {
  const { token } = useAppContext()
  const [step, setStep] = useState(1)
  const [serverError, setServerError] = useState(false)
  const [transferUsers, setTransferUsers] = useState(true)
  const [transferSimulations, setTransferSimulations] = useState(true)
  const [buttonModalIsLoading, setButtonModalIsLoading] = useState(false)

  const { data: regions, isFetching: isRegionsFetching } = useQuery(
    ['getOrganizationRegions'],
    () => getOrganizationRegions(token, regionInfo.organization),
    {
      initialData: { data: [] },
      enabled: token !== '',
    }
  )
  const regionsForInput = useMemo(
    () =>
      regions.data.filter(region => region.id !== regionInfo.id).map((region) => ({
        value: region.id,
        label: region.name,
      })),
    [regions]
  )

  const formik = useFormik<{
    transfer_simulations_to: number;
  }>({
    initialValues: {
      transfer_simulations_to: 0,
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      transfer_simulations_to: Yup.number().test(
        'check if is required',
        'Required',
        (value) =>  ((transferUsers || transferSimulations) && value !== 0 && Boolean(value)) || (!transferUsers && !transferSimulations)
      ),
    }),
    onSubmit: async () => {
      setStep(2)
    },
  })

  const handleDeleteRegion = async () => {
    if (buttonModalIsLoading) return
    setButtonModalIsLoading(true)

    const body: {
      region_id: number;
      transfer_users: string;
      transfer_users_to?: number;
      transfer_simulation: string;
      transfer_simulations_to?: number;
    } = {
      region_id: regionInfo.id,
      transfer_users: 'false',
      transfer_simulation: 'false',
    }
    if (
      transferUsers
    ){
      body.transfer_users = 'true'
      body.transfer_users_to = Number(formik.values.transfer_simulations_to)
      body.transfer_simulation = 'true'
      body.transfer_simulations_to = Number(formik.values.transfer_simulations_to)
    }
    else if (transferSimulations) {
      body.transfer_simulation = 'true'
      body.transfer_simulations_to = Number(formik.values.transfer_simulations_to)
    }

    const response = await deleteOrganizationRegion(token, body)

    if (response.error) setServerError(true)
    else if (response) {
      setStep((prev) => prev + 1)
      refetch()
    }

    setButtonModalIsLoading(false)
  }

  if (step === 1)
    return (
      <Modal title="Delete region" closeModal={closeModal}>
        <div className="Modal__Scroll-Content d-flex flex-column gap-3 p-3">
          <IAlert
            width={60}
            height={60}
            className="flex-shrink-0 text-yellow d-block mx-auto"
          />
          <p className="text-center fw-medium">
          Before deleting “{regionInfo.name}”...
          </p>

          <div className="DeleteRegionModal__Option_Container d-flex flex-column gap-1">
            <p className="text-4 fw-medium">Transfer users to another region?</p>
            <div className="d-flex align-items-center gap-3">
              <IAlert className="text-4" />{' '}
              <p className="text-5">
              All users and their simulations will be deleted if they are not
              tranferred to another region.
              </p>
            </div>
            <div className="d-flex align-items-center gap-3">
              <IAlert className="text-4" />{' '}
              <p className="text-5">
              If you chose to transfer users, both users and their simulations
              will be transfered to the selected region.
              </p>
            </div>
            <div className="d-flex gap-2">
              <button
                className={classNames(
                  'Dashboard__Table_path_info_Content_Action py-1 text-start',
                  {
                    'cursor-pointer': !transferUsers,
                  }
                )}
                onClick={() => setTransferUsers(true)}
              >
                <span
                  className={classNames(
                    'Radio',
                    {
                      Selected: transferUsers,
                    },
                    'blue'
                  )}
                />
                <span className="text-5 fw-normal me-auto me-md-0">
                Transfer users
                </span>
              </button>
              <button
                className={classNames(
                  'Dashboard__Table_path_info_Content_Action py-1 text-start',
                  {
                    'cursor-pointer': transferUsers,
                  }
                )}
                onClick={() => setTransferUsers(false)}
              >
                <span
                  className={classNames(
                    'Radio',
                    {
                      Selected: !transferUsers,
                    },
                    'blue'
                  )}
                />
                <span className="text-5 fw-normal me-auto me-md-0">
                Delete users
                </span>
              </button>
            </div>
          </div>
          <div
            className={classNames(
              'DeleteRegionModal__Option_Container d-flex flex-column gap-1',
              { Disabled: transferUsers }
            )}
          >
            <p className="text-4 fw-medium">
            Transfer users’s simulations to another region?
            </p>
            <div className="d-flex align-items-center gap-3">
              <IAlert className="text-4" />{' '}
              <p className="text-5">
              If you chose to delete users, you can still transfer their
              simulations to another region to keep them.
              </p>
            </div>
            <div className="d-flex gap-2">
              <button
                className={classNames(
                  'Dashboard__Table_path_info_Content_Action py-1 text-start',
                  {
                    'cursor-pointer': !transferUsers && !transferSimulations,
                  }
                )}
                onClick={() =>
                  !transferUsers ? setTransferSimulations(true) : null
                }
              >
                <span
                  className={classNames(
                    'Radio',
                    {
                      Selected: transferSimulations,
                    },
                    'blue'
                  )}
                />
                <span className="text-5 fw-normal me-auto me-md-0">
                Transfer simulations
                </span>
              </button>
              <button
                className={classNames(
                  'Dashboard__Table_path_info_Content_Action py-1 text-start',
                  {
                    'cursor-pointer': !transferUsers && transferSimulations,
                  }
                )}
                onClick={() =>
                  !transferUsers ? setTransferSimulations(false) : null
                }
              >
                <span
                  className={classNames(
                    'Radio',
                    {
                      Selected: !transferSimulations,
                    },
                    'blue'
                  )}
                />
                <span className="text-5 fw-normal me-auto me-md-0">
                Delete simulations
                </span>
              </button>
            </div>
          </div>

          <SearchInput
            label="Transfer to"
            name="transfer_simulations_to"
            search={regionsForInput}
            value={Number(formik.values.transfer_simulations_to)}
            onChange={(id) => formik.setFieldValue('transfer_simulations_to', id)}
            error={
              formik.errors.transfer_simulations_to ||
            (!isRegionsFetching && regionsForInput.length === 0
              ? 'no regions'
              : '')
            }
            disabled={!transferUsers && !transferSimulations}
          />

          <div className="d-flex align-items-center gap-2">
            <button
              className="button-blue button_defult w-100 text-4 gap-0"
              type='submit'
              onClick={() => formik.handleSubmit()}
              disabled={formik.isSubmitting}
            >
              <span>Countinue</span>
            </button>
            <button
              className="button_transparent_blue w-100 text-4"
              onClick={closeModal}
            >
            Cancel
            </button>
          </div>
        </div>
      </Modal>
    )

  if (step === 2)
    return (
      <Modal title="Delete region" closeModal={closeModal} prevStep={() => setStep(p => p - 1)}>
        <div className="Modal__Scroll-Content d-flex flex-column gap-3 p-3">
          <IAlert
            width={60}
            height={60}
            className="flex-shrink-0 text-yellow d-block mx-auto mt-5"
          />
          <p className="text-center fw-medium mb-5">
          Are you sure you want to delete “{regionInfo.name}”?
          </p>

          <div className='Dashboard__Table_path_info_Simulation_Count'>
            <p className='text-5'>Users will be {transferUsers ? 'transferred' : 'deleted'}</p>
          </div>
          <div className='Dashboard__Table_path_info_Simulation_Count'>
            <p className='text-5'>Simulations will be {transferUsers ? 'transferred' : transferSimulations ? 'transferred' : 'deleted'}</p>
          </div>

          {transferUsers || transferSimulations ? <div className='d-flex justify-content-center align-items-center gap-4 mt-3 mb-4'>
            <div>
              <p className="text-4 text-primary fw-medium">From</p>
              <p className="text-5 text-secondary fw-medium">
                {regionInfo.name}
              </p>
            </div>
            <IArrowRight
              width={16}
              height={16}
              className=" text-blue_primary align-self-center"
            />
            <div>
              <p className="text-4 text-primary fw-medium">To</p>
              <p className="text-5 text-secondary fw-medium">
                {regionsForInput.find(region => region.value === formik.values.transfer_simulations_to).label}
              </p>
            </div>
          </div> : <></>}

          {serverError ? <p className="text-red">Something went wrong</p> : <></>}

          <div className="d-flex align-items-center gap-2">
            <button
              className="button_transparent_red button_defult w-100 text-4 gap-0"
              type='submit'
              onClick={handleDeleteRegion}
              disabled={buttonModalIsLoading}
            >
              {buttonModalIsLoading ? (
                <>
                  <span>Deleting</span>
                  <LoadingDots className="enter-done" />
                </>
              ) : (
                <span className='text-red'>Yes, delete region</span>
              )}
            </button>
            <button
              className="button_transparent_blue w-100 text-4"
              onClick={closeModal}
            >
            No, do not delete
            </button>
          </div>
        </div>
      </Modal>
    )

  return (
    <CheckModal
      closeModal={closeModal}
      title="Delete region"
      message="¡Region deleted successfully!"
    />
  )
}

export default DeleteRegionModal
