import { Document, PDFViewer } from '@react-pdf/renderer'
import useReportContext from '../../hooks/useReportContext'
import PageRender from '../PageRender'

export default function PreviewPDF() {
  const { pages, pageSelected, showReport, dateFormat, reportLanguage } = useReportContext()

  if (!pageSelected) return null

  return (
    <PDFViewer className="PageRender" style={showReport ? { display: 'flex' } :null} showToolbar={true}>
      <Document
        title={pageSelected.label}
        pageMode="useOutlines"
        pageLayout="twoPageLeft"
      >
        <PageRender pages={pages} pageSelected={pageSelected} dateFormat={dateFormat} language={reportLanguage} />
      </Document>
    </PDFViewer>
  )
}
