import { getQuery, patchQuery, postQuery } from '../../apiFunctions'

export const getRoofAndPlant = async (token, simulation_id) => {
  return getQuery<any>({
    path: `/simulations/roof-and-plant/${simulation_id}/`,
    token,
    callback: data => data.data
  })
}

export const addRoofAndPlant = async (token, simulation_id, body) => {
  return postQuery<any>({
    path: `/simulations/roof-and-plant/${simulation_id}/`,
    token,
    body
  })
}

export const updateRoofAndPlant = async (token, simulation_id, body) => {
  return patchQuery<any>({
    path: `/simulations/roof-and-plant/${simulation_id}/`,
    token,
    body
  })
}