import classNames from 'classnames'
import useReportContext from '../../hooks/useReportContext'
import { SectionTable } from '../../types/generateReport'

type Props = {
  section: SectionTable;
};

export default function TableCard({ section }: Props) {
  const { handleToggleTableColumn } = useReportContext()
  return (
    <>
      {section.value.map((column) => (
        <div key={column.id} className="inputTable">
          <p
            className="inputTable_text text-5 cursor-pointer"
            onClick={() => handleToggleTableColumn(section.id, column.id)}
          >
            {column.title}
          </p>
          <div
            className={classNames('Checkbox Small Color-secondary', {
              Selected: column.show,
            })}
            onClick={() => handleToggleTableColumn(section.id, column.id)}
          />
          <p
            className="text-6 text-secondary cursor-pointer"
            onClick={() => handleToggleTableColumn(section.id, column.id)}
          >
            Show
          </p>
        </div>
      ))}
    </>
  )
}
