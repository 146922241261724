import {
  Client,
  CountryDetail,
  CountryResidence,
  EnergyPerformance,
  EnviromentalIndicators,
  FinancialIndicators,
  Location,
  Organization,
  Results,
  SimulationDetails,
} from '../types/simulation'

export const URL = process.env.REACT_APP_URL_BACK
const API_URL = `${URL}/api`
const defaultToken = ''

const simulationStatus = {
  creating: 'Creating',
  ready_to_run: 'Ready to run',
  waiting: 'Waiting',
  running: 'Running',
  error_running: 'Error running',
  edited: 'Edited',
  finished: 'Finished',
}

const userTypes = {
  central_admin: 'Central Admin',
  organization_admin: 'Organization Admin',
  region_admin: 'Region Admin',
  operator: 'Operator',
}

export const emptyCountryDetail: CountryDetail = {
  id: 0,
  currencies: [],
  name: '',
  name_ascii: '',
  slug: '',
  geoname_id: 0,
  alternate_names: '',
  code2: '',
  code3: '',
  continent: '',
  tld: '',
  phone: '',
  name_en: '',
  is_active: false,
}

export const emptyCountryResidence: CountryResidence = {
  id: 0,
  country_name: '',
}

export const emptyOrganization: Organization = {
  id: 0,
  country_residence: emptyCountryResidence,
  name: '',
  address: '',
  email: '',
  phone: '',
  users: 0,
  admins: [],
  regions: [],
  operative_countries: 0,
}

export const emptyClient: Client = {
  id: 0,
  creator: '',
  simulation_count: 0,
  organization: emptyOrganization,
  country: emptyCountryDetail,
  name: '',
  address: '',
  email: '',
  phone: '',
  is_active: false,
  company: '',
  role: '',
  created_at: '',
  updated_at: '',
  last_updater: '',
}

export const emptyLocation: Location = {
  id: 0,
  city: '',
  address: '',
  postal_code: '',
  simulation: 0,
  country: emptyCountryDetail,
  state: 0,
  weather_file: {
    id: 0,
    file_name: '',
    file_path: '',
  },
}

export const emptyEnergyPerformance: EnergyPerformance = {
  id: 0,
  total_heat_demand: null,
  total_heat_supplied: null,
  heat_produced_first_PVT: null,
  thermal_efficiency_first_PVT: null,
  heat_produced_second_PVT: null,
  thermal_efficiency_second_PVT: null,
  heat_supplied_by_existing_hot_water: null,
  existing_hot_water_efficiency: null,
  heat_supplied_by_first_new_hot_water: null,
  first_new_hot_water_cop: null,
  heat_supplied_by_second_new_hot_water: null,
  second_new_hot_water_cop: null,
  solar_fraction: null,
  renewable_fraction: null,
  total_ac_electricity: null,
  total_renewable_electricity: null,
  dc_electricity_first_PVT: null,
  first_PVT_electrical_efficiency: null,
  dc_electricity_second_PVT: null,
  second_PVT_electrical_efficiency: null,
  dc_electricity_first_PV: null,
  first_PV_electrical_efficiency: null,
  dc_electricity_second_PV: null,
  second_PV_electrical_efficiency: null,
  electricity_consumed_solar_pumps: null,
  electricity_consumed_existing_hot_water: null,
  electricity_consumed_first_new_hot_water: null,
  electricity_consumed_second_new_hot_water: null,
  result: 0,
}

export const emptyEnviromentalIndicators: EnviromentalIndicators = {
  id: 0,
  annual_co2_savings: null,
  equivalent_trees: null,
  equivalent_kms_cars: null,
  equivalent_kms_flight: null,
  result: 0,
}

export const emptyFinancialIndicators: FinancialIndicators = {
  id: 0,
  energy_savings: null,
  payback_period: null,
  internal_rate_of_return: null,
  roi: null,
  total_savings_life_span: null,
  net_present_value: null,
  tco: null,
  system_cost: null,
  investment_subsidy: null,
  net_system_cost: null,
  result: 0,
}

export const emptyResults: Results = {
  id: 0,
  error_message: '',
  trnsys_error: '',
  capex_graph: '',
  capex_table_report: '',
  energy_plot_report: '',
  ppa_graph: '',
  report_doc: '',
  updated_report_doc: null,
  updated_at: '',
  simulation: 0,
  updated_by: {
    first_name: '',
    last_name: '',
  },
  energy_performance_results: emptyEnergyPerformance,
  enviromental_indicators_results: emptyEnviromentalIndicators,
  financial_indicators_results: emptyFinancialIndicators,
  financial_indicators_ppa_results: [],
}

export const emptySimulationDetails: SimulationDetails = {
  id: 0,
  name: '',
  status: '',
  step_status: '',
  last_step_done: '',
  id_number: '',
  convert_ratio: '',
  location: emptyLocation,
  currency_selected: null,
  region: null,
  office: null,
  creator: null,
  owner: null,
  client: emptyClient,
  demand_detail: null,
  electricity_demand: null,
  hot_water_system: null,
  roof_and_plant_detail: null,
  environmental_indicators: null,
  solar_thermal_field: null,
  solar_storage: null,
  new_auxiliary_heating_systems: null,
  pv_field: null,
  inverter: null,
  system_performance_aging: null,
  energy_prices: null,
  economic_parameters: null,
  subsidy_and_tax_benefits: null,
  system_costing: null,
  uncertainty: null,
  PPA_financing_models: null,
  results: emptyResults,
  report_information_en: null,
  report_information_du: null,
  last_updated_report_information_en: null,
  last_updated_report_information_du: null,
  created_at: '',
  updated_at: '',
}

const emptySimulation = {
  id: localStorage.getItem('simulation_id'),
  current_step: '',
  last_step: '',
  status: '',
  client: {
    id: '',
    name: '',
    country: {
      id: '',
      code: '',
      currencies: [],
      is_active: '',
      name: '',
    },
    email: '',
    address: '',
    phone: '',
  },
  steps: {
    general_information: {
      name: '',
      client: null,
      currency_selected: null,
      currency_name: '',
    },
    location_details: {
      country_region: '',
      state: '',
      city: '',
      address: '',
      postal_code: '',
      weather_file: '',
      weather_file_name: '',
    },
    hot_water_demand: {
      sector: '',
      daily_profile: null,
      maximum_hot_water_demand: '',
      desired_hot_water_temperature: '',
      is_recirculation: false,
      water_temperature: '',
      f_mon: '',
      f_tue: '',
      f_wed: '',
      f_thu: '',
      f_fri: '',
      f_sat: '',
      f_sun: '',
      f_jan: '',
      f_feb: '',
      f_mar: '',
      f_apr: '',
      f_may: '',
      f_jun: '',
      f_jul: '',
      f_aug: '',
      f_sep: '',
      f_oct: '',
      f_nov: '',
      f_dec: '',
      will_complete: false,
    },
    electricity_demand: {
      id: '',
      supply_choices: 'annual',
      annual_demand: null,
      fel_jan: null,
      fel_feb: null,
      fel_mar: null,
      fel_apr: null,
      fel_may: null,
      fel_jun: null,
      fel_jul: null,
      fel_aug: null,
      fel_sep: null,
      fel_oct: null,
      fel_nov: null,
      fel_dec: null,
      will_complete: false,
    },
    existing_hot_water_system: {
      id: '',
      system: '',
      other_system_name: '',
      auxiliary_hot_water_system_capacity: '',
      age_of_the_existing_system: '',
      new_auxiliary_heating_system_required: false,
    },
    roof_and_plant: {
      id: '',
      available_roof_space_PVT: '',
      available_roof_space_PV: '',
      max_roof_load: '',
      space_for_additional_storage_in_plant_room: false,
    },
    environmental_indicators: {
      id: '',
      CO2_Fuel_txt: '',
      kWH_CF_Fuel_txt: '',
      n_ex_syst: '',
      electricity: '',
      CO2_saved_per_year_by_tree: 24,
    },
    solar_thermal_field: {
      id: '',
      antifreeze_composition: '',
      include_thermal_system_loss: false,
      panel_one: '',
      panel_two: '',
      will_complete: false,
      another_panel: false,
    },
    solar_storage: {
      total_solar_storage_volume: '',
      total_number_buffers: '',
      immersed_electric_heater_in_the_buffer: false,
      name_of_the_electrical_heater: '',
      capacity_of_the_electrical_heater: '',
    },
    auxiliary_heating_system: {
      system_one: {
        id: '',
        system: '',
        name: '',
        capacity: '',
        size_of_the_buffer_connected_to_auxiliary_heater: '',
      },
      system_two: {
        id: '',
        system: '',
        name: '',
        capacity: '',
        size_of_the_buffer_connected_to_auxiliary_heater: '',
      },
      will_complete: false,
      another_system: false,
    },
    pv_field: {
      first_panel: {
        id: '',
        name: '',
        model: '',
        number_of_panels: '',
        type_of_roof: '',
        panel_orientation: '',
        panel_tilt: '',
        number_of_panels_per_row: '',
        distance_between_rows: '',
      },
      second_panel: {
        id: '',
        name: '',
        model: '',
        number_of_panels: '',
        type_of_roof: '',
        panel_orientation: '',
        panel_tilt: '',
        number_of_panels_per_row: '',
        distance_between_rows: '',
      },
      will_complete: false,
      another_field: false,
    },
    inverter: {
      id: '',
      include_pv_system_loss: false,
      DC_AC_ratio_PV: 1.2,
      DC_AC_ratio_PVT: 1.2,
    },
    system_performance_aging: {
      id: '',
      PV_electrical_out_degradation: '',
      auxiliary_heating_degradation: '',
      solar_thermal_out_degradation: '',
    },
    energy_price: {
      id: '',
      average_heat_price: '',
      average_electricity_price: '',
      heat_escalation_price: '',
      electricity_price_escalation_rate: '',
      electricity_price_rise_scenario: '',
    },
    economic_parameters: {
      id: '',
      inflation_rate: '',
      discount_rate: '',
      interest_rate: '',
      O_and_M_escalation_rates: '',
      project_economic_lifetime: '',
    },
    subsidy_and_tax_benefits: {
      id: '',
      is_tax_subsidy_applicable: false,
      is_operational_subsidy_applicable: false,
      tax_subsidy: '',
      operational_subsidy_for_heat: '',
      operational_subsidy_for_electricity: '',
      num_of_years_for_operational_subsidy: '',
      year_1: '',
      year_2: '',
      year_3: '',
      year_4: '',
      year_5: '',
      year_6: '',
      year_7: '',
      year_8: '',
      year_9: '',
      year_10: '',
      year_11: '',
      year_12: '',
      year_13: '',
      year_14: '',
      year_15: '',
      year_16: '',
      year_17: '',
      year_18: '',
      year_19: '',
      year_20: '',
      year_21: '',
      year_22: '',
      year_23: '',
      year_24: '',
      year_25: '',
      is_funding_or_grant_applicable: false,
      description_of_funding_or_grant: '',
      funding_or_grant_amount: '',
      co2_tax: '',
      co2_tax_escalation_rate: '',
    },
    system_costing: {
      id: '',
      currency_conversion_factor: '',
      total_PVT_system_cost: '',
      total_thermal_system_cost: '',
      total_PV_system_cost: '',
      total_auxiliary_system_cost: '',
      other_costs: '',
      contingency_project: '',
      contingency_civil: '',
      margin_for_third_party: '',
      discount: '',
      O_and_M_PVT_cost: '',
      O_and_M_thermal_cost: '',
      O_and_M_PV_cost: '',
      O_and_M_auxiliary_cost: '',
      system_insurance: '',
      monitoring_and_metering_charges: '',
      year_of_solar_equipment_replacement: '',
      replacement_PVT_system_cost: '',
      replacement_thermal_system_cost: '',
      replacement_PV_system_cost: '',
      year_of_auxilairy_system_replacement: '',
      replacement_auxiliary_heaying_system_cost: '',
    },
    uncertainty: {
      id: '',
      cost_uncertainty: '',
      heat_production_uncertainty: '',
      electricity_production_uncertainty: '',
      cash_flow_year_1: 0,
      cash_flow_year_2: 0,
      cash_flow_year_3: 0,
      cash_flow_year_4: 0,
      cash_flow_year_5: 0,
      cash_flow_year_6: 0,
      cash_flow_year_7: 0,
      cash_flow_year_8: 0,
      cash_flow_year_9: 0,
      cash_flow_year_10: 0,
      cash_flow_year_11: 0,
      cash_flow_year_12: 0,
      cash_flow_year_13: 0,
      cash_flow_year_14: 0,
      cash_flow_year_15: 0,
      cash_flow_year_16: 0,
      cash_flow_year_17: 0,
      cash_flow_year_18: 0,
      cash_flow_year_19: 0,
      cash_flow_year_20: 0,
      cash_flow_year_21: 0,
      cash_flow_year_22: 0,
      cash_flow_year_23: 0,
      cash_flow_year_24: 0,
      cash_flow_year_25: 0,
    },
    ppa_financial_model: {
      id: '',
      will_complete: false,
      period_1: {
        id: '',
        number_of_years: '',
        electricity_pric_per_kwh: '',
        electricity_escalation_rate: '',
        heat_price_per_kwh: '',
        heat_escalation_rate: '',
      },
      period_2: {
        id: '',
        number_of_years: '',
        electricity_pric_per_kwh: '',
        electricity_escalation_rate: '',
        heat_price_per_kwh: '',
        heat_escalation_rate: '',
      },
      period_3: {
        id: '',
        number_of_years: '',
        electricity_pric_per_kwh: '',
        electricity_escalation_rate: '',
        heat_price_per_kwh: '',
        heat_escalation_rate: '',
      },
    },
  },
}

const emptyFlowDecisions = {
  PPA_option: false,
  economic_lifetime: null,
  electricity_demand: false,
  hot_water_demand: false,
  include_a_pv_field: false,
  include_a_t_or_pvt: false,
  include_another_auxiliary_system: false,
  include_another_pv_field: false,
  include_another_t_or_pvt: false,
  include_auxiliary_system: false,
}

const steps = [
  {
    title: 'General information',
    path: 'general-information',
    backendStep: 'general_information',
    value: 0,
  },
  {
    title: 'Location details',
    path: 'location-details',
    backendStep: 'location',
    value: 1,
  },
  {
    title: 'Hot water demand',
    path: 'hot-water-demand',
    backendStep: 'hot_water_demand_details',
    value: 2,
  },
  {
    title: 'Electricity demand',
    path: 'electricity-demand',
    backendStep: 'electricity_demand_detail',
    value: 3,
  },
  {
    title: 'Hot water system',
    path: 'hot-water-system',
    backendStep: 'existing_hot_water_system_details',
    value: 4,
  },
  {
    title: 'Roof and plant room',
    path: 'hot-water-system',
    backendStep: 'roof_and_plant_room_details',
    value: 4,
  },
  {
    title: 'Environmental indicators',
    path: 'environmental-indicators',
    backendStep: 'environmental_indicators_and_conversion_factors',
    value: 5,
  },
  {
    title: 'Solar thermal field',
    path: 'solar-thermal-field',
    backendStep: 'solar_thermal_field',
    value: 6,
  },
  {
    title: 'Solar storage',
    path: 'solar-storage',
    backendStep: 'solar_storage',
    value: 7,
  },
  {
    title: 'Auxiliary heating system',
    path: 'auxiliary-heating-system',
    backendStep: 'auxiliary_heating_system',
    value: 8,
  },
  {
    title: 'PV field',
    path: 'pv-field',
    backendStep: 'pv_field',
    value: 9,
  },
  {
    title: 'Inverter',
    path: 'inverter',
    backendStep: 'inverter',
    value: 10,
  },
  {
    title: 'System performance aging',
    path: 'system-performance-aging',
    backendStep: 'system_performance_aging',
    value: 11,
  },
  {
    title: 'Energy price',
    path: 'energy-price',
    backendStep: 'energy_price',
    value: 12,
  },
  {
    title: 'Economic parameters',
    path: 'economic-parameters',
    backendStep: 'economic_parameters',
    value: 13,
  },
  {
    title: 'Subsidy & tax benefits',
    path: 'subsidy-&-tax-benefits',
    backendStep: 'subsidy_and_tax_benefits',
    value: 14,
  },
  {
    title: 'System costing',
    path: 'system-costing',
    backendStep: 'system_costing',
    value: 15,
  },
  {
    title: 'Uncertainty',
    path: 'uncertainty',
    backendStep: 'uncertainty',
    value: 16,
  },
  {
    title: 'PPA financial model',
    path: 'ppa-financial-model',
    backendStep: 'ppa_financing_model',
    value: 17,
  },
  {
    title: 'Finished',
    path: 'finished',
    backendStep: 'finished',
    value: 18,
  },
]

const minProfileValue = 0.995
const maxProfileValue = 1.005

const emptyProfile = [
  {
    label: '0 - 1',
    value: 0,
  },
  {
    label: '1 - 2',
    value: 0,
  },
  {
    label: '2 - 3',
    value: 0,
  },
  {
    label: '3 - 4',
    value: 0,
  },
  {
    label: '4 - 5',
    value: 0,
  },
  {
    label: '5 - 6',
    value: 0,
  },
  {
    label: '6 - 7',
    value: 0,
  },
  {
    label: '7 - 8',
    value: 0,
  },
  {
    label: '8 - 9',
    value: 0,
  },
  {
    label: '9 - 10',
    value: 0,
  },
  {
    label: '10 - 11',
    value: 0,
  },
  {
    label: '11 - 12',
    value: 0,
  },
  {
    label: '12 - 13',
    value: 0,
  },
  {
    label: '13 - 14',
    value: 0,
  },
  {
    label: '14 - 15',
    value: 0,
  },
  {
    label: '15 - 16',
    value: 0,
  },
  {
    label: '16 - 17',
    value: 0,
  },
  {
    label: '17 - 18',
    value: 0,
  },
  {
    label: '18 - 19',
    value: 0,
  },
  {
    label: '19 - 20',
    value: 0,
  },
  {
    label: '20 - 21',
    value: 0,
  },
  {
    label: '21 - 22',
    value: 0,
  },
  {
    label: '22 - 23',
    value: 0,
  },
  {
    label: '23 - 24',
    value: 0,
  },
]

const emptyClients = {
  data: [],
  dolar: {
    id: 0,
    code: 'USD',
    name: 'United States Dolar',
  },
  euro: {
    id: 0,
    code: 'EUR',
    name: 'Euro',
  },
  page: 1,
  total_items: 0,
  total_pages: 1,
}

const checkboxOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
]

const twoDecimalRegex = /^\d+\.\d{3,}$/
const threeDecimalRegex = /^\d+\.\d{4,}$/

const normalizePercentToSend = (value) =>
  Math.round((Number(value) / 100) * 100000) / 100000

const normalizePercentToShow = (value) => {
  const newValue = Math.round(Number(value) * 100 * 1000) / 1000
  if (typeof value == 'number') return newValue
}

const normalizeNumberWithCommas = (value) =>
  Number(value?.toString()?.replace(/,/g, ''))

const normalizeNumber = (number: number, maximumFractionDigits = 0) =>
  number?.toLocaleString('en-US', { maximumFractionDigits })

export const normalizeNumberWithType = (
  number: number,
  maximumFractionDigits = 0,
  type: 'dot' | 'comma'
) =>
  number.toLocaleString(type === 'comma' ? 'en-US' : 'de-DE', {
    maximumFractionDigits,
  })

export const swicthSeparators = (string: string) => { 
  const parts = string.split(' ')

  const regex = new RegExp(/^[0-9.,]+$/g)

  const change = (value: string) => 
    value.split('.').map(item => item.replace(/[,]/g, '.')).join(',')
  
  if (regex.test(parts[0])) {
    parts[0] = change(parts[0])
  } else if (regex.test(parts[1])) {
    parts[1] = change(parts[1])
  }

  return parts.join(' ')
} 

const handlestring = (
  string: string,
  maximumFractionDigits = 0,
  type: 'dot' | 'comma'
) => {
  const separators = string.replace(/[0-9]/g, '').split('')

  if (separators.length >= 2) {
    if (separators.at(-1) !== separators.at(-2)) {
      const allNumber = string.split(separators.at(-1))
      const decimal = allNumber.slice(-1)
      const integer = allNumber.slice(0, -1).join('').replace(/[.,]/g, '')
      string = normalizeNumberWithType(
        Number([integer, decimal].join('.')),
        maximumFractionDigits,
        type
      )
    } else {
      string = normalizeNumberWithType(
        Number(string.replace(/[.,]/g, '')),
        maximumFractionDigits,
        type
      )
    }
  } else {
    if (separators.at(0) === (type === 'dot' ? '.' : ',')) {
      string = string.replace(/[.,]/g, '')
    } else {
      string = string.replace(/[.,]/g, '.')
    }
    string = normalizeNumberWithType(
      Number(string),
      maximumFractionDigits,
      type
    )
  }
  return string
}

export const normalizeNumberinString = (
  string: string,
  maximumFractionDigits = 0,
  type: 'dot' | 'comma'
) => {
  const parts = string.split(' ')

  const regex = new RegExp(/^[0-9.,]+$/g)

  if (regex.test(parts[0])) {
    parts[0] = handlestring(parts[0], maximumFractionDigits, type)
  } else if (regex.test(parts[1])) {
    parts[1] = handlestring(parts[1], maximumFractionDigits, type)
  }

  return parts.join(' ')
}

export {
  // URL,
  API_URL,
  defaultToken,
  simulationStatus,
  userTypes,
  emptySimulation,
  emptyClients,
  emptyFlowDecisions,
  steps,
  minProfileValue,
  maxProfileValue,
  emptyProfile,
  checkboxOptions,
  twoDecimalRegex,
  threeDecimalRegex,
  normalizePercentToSend,
  normalizePercentToShow,
  normalizeNumberWithCommas,
  normalizeNumber,
}
