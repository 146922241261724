import { useState, useEffect } from 'react'

const useMatchMedia = (media = '(max-width: 767px)') => {
  const [isMedia, setIsMedia] = useState(false)

  useEffect(() => {
    const checkIsMedia = () =>
      setIsMedia(window.matchMedia(media).matches)

    checkIsMedia()

    window.addEventListener('resize', checkIsMedia)

    return () => window.removeEventListener('resize', checkIsMedia)
  }, [media])

  return isMedia
}

export default useMatchMedia