import { getQuery, patchQuery, postQuery } from '../../apiFunctions'

export const getSubsidyAndTaxBenefits = async (token, simulation_id) => {
  return getQuery<any>({
    path: `/simulations/subsidy-and-tax-benefits/${simulation_id}/`,
    token,
    callback: (data) => data.data
  })
}

export const addSubsidyAndTaxBenefits = async (token, simulation_id, body) => {
  return postQuery<any>({
    path: `/simulations/subsidy-and-tax-benefits/${simulation_id}/`,
    token,
    body
  })
}

export const updateSubsidyAndTaxBenefits = async (token, simulation_id, body) => {
  return patchQuery<any>({
    path: `/simulations/subsidy-and-tax-benefits/${simulation_id}/`,
    token,
    body
  })
}