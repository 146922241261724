import { SVGProps } from 'react'
const SvgIEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.365 2.13a6.969 6.969 0 0 1 7.594 11.365L31.973 27.482a7.61 7.61 0 0 1-3.89 2.081l-6.467 1.295a2.442 2.442 0 0 1-2.874-2.873l1.293-6.47a7.615 7.615 0 0 1 2.084-3.888L36.105 3.641a6.968 6.968 0 0 1 2.26-1.51Zm2.667 4.354a2.084 2.084 0 0 0-1.474.61l-2.213 2.214 2.948 2.946 2.212-2.212a2.083 2.083 0 0 0-1.473-3.558Zm-4.193 9.224-2.948-2.946-8.319 8.319c-.381.38-.642.867-.748 1.395l-.575 2.873 2.876-.575a2.725 2.725 0 0 0 1.394-.746l8.32-8.32Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.926 11.368a2.442 2.442 0 0 0-2.442 2.442v26.864a2.442 2.442 0 0 0 2.442 2.442H35.79a2.442 2.442 0 0 0 2.442-2.442v-7.327a2.442 2.442 0 1 1 4.884 0v7.327A7.326 7.326 0 0 1 35.79 48H8.926A7.326 7.326 0 0 1 1.6 40.674V13.81a7.326 7.326 0 0 1 7.326-7.326h7.327a2.442 2.442 0 0 1 0 4.884H8.926Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgIEdit
