import { IMoveSub } from '../iconComponents'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Switch from './Switch'
import { PageSingle } from '../types/generateReport'
import classNames from 'classnames'
import useReportContext from '../hooks/useReportContext'

type Props = {
  subPage: PageSingle;
  parentId: string;
};

export default function PagesSortISubtem({
  subPage,
  parentId,
}: Props) {
  const { idSelected, updateIdSelected, handleTogglePage } = useReportContext()
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({ id: subPage.id })

  const style: React.CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  }

  return (
    <div
      className={classNames('PagesSort__Item_Header', {Active: subPage.id === idSelected})}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <div className='PagesSort__Item_Header_Icon' {...listeners}>
        <IMoveSub
          width={24}
          height={24}
        />
      </div>
      <p
        className="text-6 PagesSort__Item_Header_text"
        onClick={() => updateIdSelected(subPage.id)}
      >
        {subPage.label}
      </p>
      <div className='PagesSort__Item_Header_Switch' onClick={() => handleTogglePage(parentId, subPage.id)} >
        <Switch
          active={subPage.active}
          size="Small"
          color="Green"
        />
      </div>
    </div>
  )
}
