import { getQuery, patchQuery, postQuery } from '../../apiFunctions'

export const getEnvironmentalIndicators = async (token, simulation_id) => {
  return getQuery<any>({
    path: `/simulations/environmental-indicators/${simulation_id}/`,
    token,
    callback: data => data.data
  })
}

export const addEnvironmentalIndicators = async (token, simulation_id, body) => {
  return postQuery<any>({
    path: `/simulations/environmental-indicators/${simulation_id}/`,
    token,
    body
  })
}

export const updateEnvironmentalIndicators = async (token, simulation_id, body) => {
  return patchQuery<any>({
    path: `/simulations/environmental-indicators/${simulation_id}/`,
    token,
    body
  })
}