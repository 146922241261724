import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Error: 404</title>
      </Helmet>

      <main className="d-flex flex-column justify-content-center align-items-center gap-4">
        <h1>404</h1>
        <Link to="/" className="">
          Go home
        </Link>
      </main>
    </>
  )
}

export default NotFound