import { SVGProps } from 'react'

export default function ICircleCheck(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M48.4375 27.8125C46.5625 37.1875 39.4937 46.015 29.575 47.9875C24.7375 48.9509 19.7193 48.3635 15.235 46.309C10.7507 44.2544 7.02894 40.8375 4.59959 36.5447C2.17025 32.2519 1.15718 27.3021 1.70465 22.4C2.25212 17.498 4.33221 12.8936 7.64874 9.24249C14.4512 1.74999 25.9375 -0.312511 35.3125 3.43749"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5625 24.0625L25.9375 33.4375L48.4375 9.0625"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
