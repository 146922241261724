import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { addEnergyPrice, updateEnergyPrice } from '../../api/simulations/financial-parameters/energy-price'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import CreateSimulationContainer from '../../containers/CreateSimulationContainer'
import SimulationFormikHandler from '../../containers/SimulationFormikHandler'
import CreateSimulationTitle from '../../components/CreateSimulationTitle'
import InputSmall from '../../components/InputSmall'
import { submitStep } from '../../utils/submitStep'
import MoneyIcon from '../../assets/icons/money.svg'
import { normalizeNumberWithCommas, normalizePercentToSend, normalizePercentToShow, threeDecimalRegex } from '../../constants'

const EnergyPrice = () => {
  const { token } = useAppContext()
  const { newSimulation, updateNewSimulation, setIsStepLoading } = useNewSimulationContext()

  const yupNumberValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('min', 'Min: 0', value => value >= 0)
    .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))

  const yupPercentageValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('min', 'Min: 0%', value => value >= 0)
    // .test('max', 'Max: 100%', value => value <= 100)
    .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))

  const formik = useFormik({
    initialValues: {
      average_heat_price: '',
      average_electricity_price: '',
      heat_escalation_price: null,
      electricity_price_escalation_rate: null,
      electricity_price_rise_scenario: 0,
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      average_heat_price: yupNumberValidation,
      average_electricity_price: yupNumberValidation,
      heat_escalation_price: yupPercentageValidation,
      electricity_price_escalation_rate: yupPercentageValidation,
      electricity_price_rise_scenario: yupPercentageValidation,
    }),
    onSubmit: async (values) => {
      const newValues = {
        average_heat_price: normalizeNumberWithCommas(values.average_heat_price),
        average_electricity_price: normalizeNumberWithCommas(values.average_electricity_price),
        heat_escalation_price: normalizePercentToSend(normalizeNumberWithCommas(values.heat_escalation_price)),
        electricity_price_escalation_rate: normalizePercentToSend(normalizeNumberWithCommas(values.electricity_price_escalation_rate)),
        electricity_price_rise_scenario: normalizePercentToSend(normalizeNumberWithCommas(values.electricity_price_rise_scenario)),
      }

      await submitStep({
        pathname: location.pathname,
        lastStep: newSimulation.last_step,
        addFunction: () => addEnergyPrice(token, newSimulation.id, newValues),
        updateFunction: () => updateEnergyPrice(token, newSimulation.id, newValues),
        updateNewSimulation,
        setIsStepLoading
      })
    }
  })

  // Show saved simulation step
  useEffect(() => {
    formik.setValues({
      average_heat_price: newSimulation.steps.energy_price.average_heat_price,
      average_electricity_price: newSimulation.steps.energy_price.average_electricity_price,
      heat_escalation_price: normalizePercentToShow(newSimulation.steps.energy_price.heat_escalation_price),
      electricity_price_escalation_rate: normalizePercentToShow(newSimulation.steps.energy_price.electricity_price_escalation_rate),
      electricity_price_rise_scenario: normalizePercentToShow(newSimulation.steps.energy_price.electricity_price_rise_scenario),
    })
  }, [newSimulation])

  return (
    <SimulationFormikHandler formik={formik}>
      <Helmet>
        <title>Create Simulation: Energy Price</title>
      </Helmet>

      <CreateSimulationContainer>
        <header className="d-flex align-items-center flex-wrap gap-2">
          <img
            src={MoneyIcon}
            className="CreateSimulation__Title-Icon"
            alt='Energy price'
            title='Energy price'
          />

          <CreateSimulationTitle>Energy price</CreateSimulationTitle>
        </header>

        <InputSmall
          topLabel='Average heat price'
          rightLabel={`${newSimulation.steps.general_information.currency_symbol} per kWh`}
          name='average_heat_price'
          placeholder='0'
          value={formik.values.average_heat_price}
          onChange={formik.handleChange}
          error={formik.errors.average_heat_price}
        />

        <InputSmall
          topLabel='Average electricity price'
          rightLabel={`${newSimulation.steps.general_information.currency_symbol} per kWh`}
          name='average_electricity_price'
          placeholder='0'
          value={formik.values.average_electricity_price}
          onChange={formik.handleChange}
          error={formik.errors.average_electricity_price}
        />

        <InputSmall
          topLabel='Heat escalation price'
          rightLabel='% per year'
          name='heat_escalation_price'
          placeholder='0'
          value={formik.values.heat_escalation_price}
          onChange={formik.handleChange}
          error={formik.errors.heat_escalation_price}
        />

        <InputSmall
          topLabel='Electricity price escalation rate'
          rightLabel='% per year'
          name='electricity_price_escalation_rate'
          placeholder='0'
          value={formik.values.electricity_price_escalation_rate}
          onChange={formik.handleChange}
          error={formik.errors.electricity_price_escalation_rate}
        />

        <InputSmall
          topLabel='Energy price rise scenario'
          rightLabel='% per year'
          name='electricity_price_rise_scenario'
          placeholder='0'
          value={formik.values.electricity_price_rise_scenario}
          onChange={formik.handleChange}
          error={formik.errors.electricity_price_rise_scenario}
        />
      </CreateSimulationContainer>
    </SimulationFormikHandler>
  )
}

export default EnergyPrice