import { getQuery, patchQuery, postQuery } from '../../apiFunctions'

export const getElectricityDemand = async (token, simulation_id) => {
  return getQuery<any>({
    path: `/simulations/electricity-demand/${simulation_id}/`,
    token,
    callback: data => data.data
  })
}

export const addElectricityDemand = async (token, simulation_id, body) => {
  return postQuery<any>({
    path: `/simulations/electricity-demand/${simulation_id}/`,
    token,
    body
  })
}

export const updateElectricityDemand = async (token, simulation_id, body) => {
  return patchQuery<any>({
    path: `/simulations/electricity-demand/${simulation_id}/`,
    token,
    body
  })
}