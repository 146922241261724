import Loader from '../Loader'

const SimulationLoadingModal = () => {
  return (
    <div
      className="SimulationLoading"
    >
      <Loader/>
    </div>
  )
}

export default SimulationLoadingModal