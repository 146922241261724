import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { addLocationDetails, getWeatherFiles, updateLocationDetails } from '../../api/simulations/general-simulation'
import { getCountries, getStates } from '../../api/organizations'
import { getCountriesByRegion } from '../../api/organizations'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import SimulationFormikHandler from '../../containers/SimulationFormikHandler'
import CreateSimulationTitle from '../../components/CreateSimulationTitle'
import Input from '../../components/Input'
import SearchInput from '../../components/SearchInput'
import { submitStep } from '../../utils/submitStep'
import CountryInput from '../../components/CountryInput'

const LocationDetails = () => {
  const { token } = useAppContext()
  const { newSimulation, updateNewSimulation, setIsStepLoading } = useNewSimulationContext()
  const [countries, setCountries] = useState([])
  const [countriesLists, setCountriesLists] = useState({
    regionCountries: [],
    allCountries: []
  })
  const [states, setStates] = useState([])
  const [weatherFiles, setWeatherFiles] = useState([])
  const location = useLocation()

  const region = useMemo(() => newSimulation.steps.general_information.region?.id, [newSimulation.steps.general_information.region])

  const formik = useFormik({
    initialValues: {
      country: null,
      state: null,
      city: '',
      address: '',
      postal_code: '',
      weather_file: null,
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      country: Yup.number().nullable().required('Required'),
      state: Yup.number().required('Required'),
      city: Yup.string().required('Required'),
      address: Yup.string().required('Required'),
      postal_code: Yup.string().required('Required'),
      weather_file: Yup.number().required('Required'),
    }),
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        country: countries.find(country => Number(country.id) == Number(values.country))?.id,
      }

      await submitStep({
        pathname: location.pathname,
        lastStep: newSimulation.last_step,
        addFunction: () => addLocationDetails(token, newSimulation.id, newValues),
        updateFunction: () => updateLocationDetails(token, newSimulation.id, newValues),
        updateNewSimulation,
        setIsStepLoading
      })
    }
  })

  //Show saved simulation step
  useEffect(() => {
    formik.setValues({
      country: newSimulation.steps.location_details.country,
      state: newSimulation.steps.location_details.state,
      city: newSimulation.steps.location_details.city,
      address: newSimulation.steps.location_details.address,
      postal_code: newSimulation.steps.location_details.postal_code,
      weather_file: newSimulation.steps.location_details.weather_file,
    })
  }, [newSimulation])

  // Set all countries
  useEffect(() => {
    const getData = async () => {
      const response = await getCountries(token)

      setCountries(response.all_countries)
    }

    if(token) getData()
  }, [token])

  // Set countries lists
  useEffect(() => {
    const getData = async () => {
      const regionData = await getCountriesByRegion(token, region)

      const regionCountries = regionData
        .map(country => ({
          value: country.country,
          label: country.country_name
        }))
        .sort((a, b) => {
          if (a.label > b.label) return 1
          if (a.label < b.label) return -1
          return 0
        })
      const allCountries = countries
        .map(country => ({
          value: country.id,
          label: country.name
        }))
        .sort((a, b) => {
          if (a.label > b.label) return 1
          if (a.label < b.label) return -1
          return 0
        })

      setCountriesLists({
        regionCountries,
        allCountries
      })
    }

    if(token && region && countries.length) getData()
  }, [token, region, countries])

  // Set states
  useEffect(() => {
    const setCurrentStates = async () => {
      const currentStates = await getStates(token, formik.values.country) || []

      if(currentStates.data.length) {
        const normalizeStates = currentStates.data.map(state => ({
          value: state.id,
          label: state.name
        }))

        setStates(normalizeStates)
      }
    }

    if(token && formik.values.country && countries.length) {
      setCurrentStates()
    }
  }, [token, formik.values.country, countries])

  // Set whater files
  useEffect(() => {
    const getData = async () => {
      const countrySelected = countries.find(country => Number(country.id) == Number(formik.values.country))
      const params = {
        country_code: ''
      }

      if(countrySelected) {
        params.country_code = countrySelected.code2
      }
      const response = await getWeatherFiles(token, params)

      const normalizeWeatherFiles = response.map(file => ({
        value: file.id,
        label: file.file_name,
      }))

      setWeatherFiles(normalizeWeatherFiles)
    }

    if(token && formik.values.country && countries.length && region) getData()
  }, [token, formik.values.country, countries, region])

  // Save country on reset
  useEffect(() => {
    formik.setFieldValue('country', newSimulation.last_step == 'location-details' ? formik.values.country : newSimulation.steps.location_details.country)
  }, [formik.values.country])

  return (
    <SimulationFormikHandler formik={formik}>
      <Helmet>
        <title>Create Simulation: Location Details</title>
      </Helmet>

      <div className="CreateSimulation__Container">
        <h1 className='text-4 fw-medium text-center'>Location Details</h1>

        <CountryInput
          label="Country"
          name="country"
          firstList={countriesLists.regionCountries}
          secondList={countriesLists.allCountries}
          value={formik.values.country}
          placeholder="Select a country"
          onChange={(id) => {
            if(!id) {
              formik.setStatus('reset')
              formik.setFieldValue('country', id)
            }
            else {
              formik.setStatus(null)
              setTimeout(() => formik.setFieldValue('country', id), 100)
            }
          }}
          error={formik.errors.country}
          reset={newSimulation.last_step == 'location-details' ? formik.status : ''}
          warning={newSimulation.last_step == 'location-details' ? 'This field will not be editable' : null}
          disabled={countries?.length == 0 || newSimulation.last_step != 'location-details'}
          disabledPlaceholder={formik.values.country
            ? countries?.find(country => Number(country.value) == Number(formik.values.country))?.label
            : 'No countries available'}
        />

        <SearchInput
          label="State"
          name="state"
          search={states}
          value={formik.values.state}
          placeholder="Select a state"
          onChange={(id) => formik.setFieldValue('state', id)}
          error={formik.errors.state}
          reset={formik.status}
          disabled={!formik.values.country || states?.length == 0}
          disabledPlaceholder={!formik.values.country ? 'Select a country first' : 'No states available'}
        />

        <Input
          label="City"
          name="city"
          placeholder="Location city"
          value={formik.values.city}
          onChange={formik.handleChange}
          error={formik.errors.city}
        />

        <Input
          label="Postal code"
          name="postal_code"
          placeholder="Location postal code"
          value={formik.values.postal_code}
          onChange={formik.handleChange}
          error={formik.errors.postal_code}
        />

        
        <Input
          label="Address"
          name="address"
          placeholder="Street, n 2746"
          value={formik.values.address}
          onChange={formik.handleChange}
          error={formik.errors.address}
        />

        {/* <Input
              label="Coordinates"
              name="coordinates"
              placeholder="-120230, -135485"
            /> */}

        <SearchInput
          label="Choose weather file"
          name="weather_file"
          search={weatherFiles}
          value={formik.values.weather_file}
          placeholder="Select a weather file"
          onChange={(id) => formik.setFieldValue('weather_file', id)}
          error={formik.errors.weather_file}
          reset={formik.status}
          disabled={!formik.values.country || weatherFiles?.length == 0}
          disabledPlaceholder={!formik.values.country ? 'Select a country first' : 'No weather files available'}
        />
      </div>
    </SimulationFormikHandler>
  )
}

export default LocationDetails
