import { faEye } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UserComplete } from '../../api/apiTypes'
import TableItemMenu from './TableItemMenu'
import { MouseEvent } from 'react'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { userTypeObject } from '../../utils/userTypes'
import TableItem from './TableItem'
import useAppContext from '../../hooks/useAppContext'
import { IList, IOrganization, IUser } from '../../iconComponents'

dayjs.extend(customParseFormat)

interface props {
  data: UserComplete;
  edit?: (data: UserComplete) => void;
  details: (data: UserComplete) => void;
  oneSelection?: boolean;
  unselectable?: boolean;
  selected?: boolean;
  handleSelectItem?: (data: UserComplete) => void;
  isMobile: boolean;
}

export const TableUsers = ({
  data,
  isMobile,
  edit,
  details,
  oneSelection = false,
  unselectable = false,
  selected = false,
  handleSelectItem,
}: props) => {
  const { isCentralAdmin, isOrganizationAdmin, isRegionAdmin } =
    useAppContext()

  const preventClick = (
    event: MouseEvent<HTMLDivElement>,
    callback: () => void
  ) => {
    event.stopPropagation()
    callback()
  }

  const actions = [
    {
      id: 1,
      label: 'view details',
      callback: () => details(data),
    },
  ]
  if (edit && data.user_profile?.user_type !== 'central_admin')
    actions.splice(0, 0, {
      id: 0,
      label: 'edit',
      callback: () => edit(data),
    })

  if (isMobile)
    return (
      <TableItem
        data={data}
        unselectable={unselectable}
        selected={selected}
        handleSelectItem={handleSelectItem}
      >
        <div className="TableItem__Cell">
          <p>
            {data.first_name
              ? `${data.first_name} ${data.last_name}`
              : 'Invitation sent'}
          </p>
        </div>

        <div className="TableItem__Cell of-visible justify-content-end">
          {isCentralAdmin || isOrganizationAdmin || isRegionAdmin ? (
            <p className="TableItem__Pill  text-capitalize text-6">
              {data.permissions.slice(0, 4) || ''}
            </p>
          ) : (
            <></>
          )}
          <TableItemMenu actions={actions} />
        </div>
        {isCentralAdmin ? (
          <div className="TableItem__Cell TableItem__Cell--Mb">
            <IOrganization className="TableItem__Cell_Icon text-secondary" />
            <p>{data.user_profile?.organization?.name || '-'}</p>
          </div>
        ) : isOrganizationAdmin ? (
          <div className="TableItem__Cell TableItem__Cell--Mb">
            <IOrganization className="TableItem__Cell_Icon" />
            <p>{data.user_profile?.region?.name || '-'}</p>
          </div>
        ) : (
          <></>
        )}
        <div className="TableItem__Cell TableItem__Cell--Mb">
          <IUser className="TableItem__Cell_Icon text-secondary" />
          <p>
            {data.user_profile?.user_type
              ? userTypeObject[data.user_profile?.user_type]
              : '-'}
          </p>
        </div>
        {!isCentralAdmin && !isOrganizationAdmin ? (
          <div className="TableItem__Cell TableItem__Cell--Mb">
            <IList className="TableItem__Cell_Icon text-secondary" />
            <p className="text-5 text-secondary">{data.simulations ?? '-'}</p>
          </div>
        ) : (
          <></>
        )}
      </TableItem>
    )

  return (
    <TableItem
      data={data}
      unselectable={unselectable}
      selected={selected}
      handleSelectItem={handleSelectItem}
    >
      <div className="TableItem__Cell--center">
        {oneSelection ? (
          <div className="Radio" />
        ) : (
          <div className="Checkbox" />
        )}
      </div>
      <div
        className="TableItem__Cell"
        onClick={(e) => (edit ? preventClick(e, () => edit(data)) : null)}
        title={
          data.first_name
            ? `${data.first_name} ${data.last_name}`
            : 'Invitation sent'
        }
      >
        <p>
          {data.first_name
            ? `${data.first_name} ${data.last_name}`
            : 'Invitation sent'}
        </p>
      </div>
      <div
        className="TableItem__Cell"
        onClick={(e) => (edit ? preventClick(e, () => edit(data)) : null)}
        title={data.email}
      >
        <p>{data.email || '-'}</p>
      </div>
      {isCentralAdmin ? (
        <div
          className="TableItem__Cell"
          onClick={(e) => (edit ? preventClick(e, () => edit(data)) : null)}
          title={data.user_profile?.organization?.name}
        >
          <p>{data.user_profile?.organization?.name || '-'}</p>
        </div>
      ) : isOrganizationAdmin ? (
        <div
          className="TableItem__Cell"
          onClick={(e) => (edit ? preventClick(e, () => edit(data)) : null)}
          title={data.user_profile?.region?.name}
        >
          <p>{data.user_profile?.region?.name || '-'}</p>
        </div>
      ) : (
        <></>
      )}
      <div
        className="TableItem__Cell"
        onClick={(e) => (edit ? preventClick(e, () => edit(data)) : null)}
      >
        <p>
          {data.user_profile?.user_type
            ? userTypeObject[data.user_profile?.user_type]
            : '-'}
        </p>
      </div>
      {isCentralAdmin || isOrganizationAdmin || isRegionAdmin ? (
        <>
          <div
            className="TableItem__Cell"
            onClick={(e) => (edit ? preventClick(e, () => edit(data)) : null)}
          >
            <p className="TableItem__Pill text-capitalize text-6">
              {data.permissions.slice(0, 4) || ''}
            </p>
          </div>
          <div
            className="TableItem__Cell"
            onClick={(e) => (edit ? preventClick(e, () => edit(data)) : null)}
            title={
              data.created_by?.first_name
                ? `${data.created_by.first_name} ${data.created_by.last_name}`
                : null
            }
          >
            <p>
              {data.created_by?.first_name
                ? `${data.created_by.first_name} ${data.created_by.last_name}`
                : '-'}
            </p>
          </div>
        </>
      ) : (
        <></>
      )}
      <div
        className="TableItem__Cell"
        onClick={(e) => (edit ? preventClick(e, () => edit(data)) : null)}
      >
        <p>{data.simulations ?? '-'}</p>
      </div>
      {isCentralAdmin || isOrganizationAdmin || isRegionAdmin ? (
        <>
          <div
            className="TableItem__Cell"
            onClick={(e) => (edit ? preventClick(e, () => edit(data)) : null)}
          >
            <p>
              {data.last_active
                ? dayjs(data.last_active, 'YYYY-MM-DD').format('DD/MM/YY')
                : '-'}
            </p>
          </div>
          {edit && data.user_profile?.user_type !== 'central_admin' ? (
            <div
              className="TableItem__Cell"
              onClick={(e) => preventClick(e, () => edit(data))}
            >
              <FontAwesomeIcon icon={faEye} />
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </TableItem>
  )
}
