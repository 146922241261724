import classNames from 'classnames'

interface prop {
  totalItems?: number;
  itemsSelected?: number;
  mobile?: boolean
}

const TableTitle = ({ totalItems = 0, itemsSelected, mobile = false }: prop) => {
  return (
    <>
      <div className="TableTitle d-none d-md-flex align-items-center gap-2">
        <p className="text-4 fw-semibold">{totalItems}</p>
        <p className="text-5">Results</p>
      </div>
      {itemsSelected !== undefined ? (
        <div className={classNames(
          'TableTitle',
          {'d-none d-md-flex': !mobile },
          {'d-flex d-md-none': mobile },
          'align-items-center gap-2',
        )}>
          <p className="text-4 fw-semibold">{itemsSelected}</p>
          <p className="text-5">Selected</p>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default TableTitle
