import { Helmet } from 'react-helmet'
import classNames from 'classnames'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import NavigateSteps from '../../../components/NavigateSteps'
import Path from '../../../components/Path'
import useAppContext from '../../../hooks/useAppContext'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import SelectSimulations from './SelectSimulations'
import SelectUser from './SelectUser'
import Info from './Info'
import ConfirmData from './ConfirmData'
import EndStep from './EndStep'
import { deleteUser } from '../../../api/users'
import Modal from '../../../components/modals/Modal'
import { IAlert } from '../../../iconComponents'
import LoadingDots from '../../../components/LoadingDots'
import useMatchMedia from '../../../hooks/useMatchMedia'

const DeleteUser = () => {
  const {
    token,
    user,
    simulationActionSelected,
    setSimulationActionSelected,
    userActionSelected,
    setUserActionSelected,
    userToDelete,
    setUserToDelete,
    simulationsToTransfer,
    setsimulationsToTransfer,
    userToTransfer,
    setUserToTransfer,
  } = useAppContext()
  const [step, setStep] = useState(1)
  const totalSteps = 4

  const [showDeleteSimModal, setShowDeleteSimModal] = useState(false)
  const [buttonIsLoading, setButtonIsLoading] = useState(false)
  const [buttonModalIsLoading, setButtonModalIsLoading] = useState(false)

  const isMobile = useMatchMedia()
  const navigate = useNavigate()

  useEffect(() => {
    if (simulationActionSelected.length === 0)
      setSimulationActionSelected('choose')
    else if (
      (simulationActionSelected === 'all' ||
        (simulationActionSelected === 'choose' &&
          simulationsToTransfer.length !== 0)) &&
      userActionSelected.length === 0
    ) {
      setStep(2)
      setUserActionSelected('to other')
    } else if (
      (simulationActionSelected === 'all' ||
        (simulationActionSelected === 'choose' &&
          simulationsToTransfer.length !== 0)) &&
      (userActionSelected === 'to me' ||
        (userActionSelected === 'to other' && userToTransfer.length !== 0))
    ) {
      setStep(3)
    }
  }, [])

  const handleBackToUsers = () => {
    navigate('/dashboard/users')
    setUserToDelete([])
    setsimulationsToTransfer([])
    setUserToTransfer([])
    setSimulationActionSelected('')
    setUserActionSelected('')
  }

  const handleBack = () => {
    if (step === 1 || step === totalSteps) return

    if (step === 2) {
      setUserToTransfer([])
      setUserActionSelected('')
    }

    setStep((prev) => prev - 1)
  }

  const handleNext = async () => {
    if (step === totalSteps) return handleBackToUsers()

    if (
      step === 1 &&
      simulationActionSelected === 'choose' &&
      simulationsToTransfer.length === 0
    )
      return
    else if (step === 1 && simulationActionSelected === 'delete')
      return openShowDeleteSimModal()
    else if (step === 1) setUserActionSelected('to other')

    if (
      step === 2 &&
      userActionSelected === 'to other' &&
      userToTransfer.length === 0
    )
      return

    if (step === 3 && buttonIsLoading) return
    else if (step === 3) {
      setButtonIsLoading(true)

      const body: {
        transfer: string;
        transfer_to?: number;
        simulation_ids?: string;
      } = {
        transfer: `${simulationActionSelected !== 'delete'}`,
      }
      if (simulationActionSelected !== 'delete') {
        body.transfer_to =
          userActionSelected === 'to other' ? userToTransfer[0].id : user.id
        body.simulation_ids =
          simulationActionSelected === 'all'
            ? 'all'
            : simulationsToTransfer
              .reduce((acc, cur) => acc + `${cur.id},`, '')
              .slice(0, -1)
      }

      const response = await deleteUser(token, userToDelete[0].id, body)

      if (response.error) console.error(response.error)
      else if (response) setStep((prev) => prev + 1)
      return setButtonIsLoading(false)
    }

    setStep((prev) => prev + 1)
  }

  const handleDeleteSimulations = async () => {
    if (buttonModalIsLoading) return
    setButtonModalIsLoading(true)

    const response = await deleteUser(token, userToDelete[0].id, {
      transfer: 'false',
      simulation_ids: 'all',
    })

    if (response.error) console.error(response.error)
    else if (response) {
      setStep(4)
      setShowDeleteSimModal(false)
    }

    setButtonModalIsLoading(false)
  }

  const openShowDeleteSimModal = () => setShowDeleteSimModal(true)
  const closeShowDeleteSimModal = () => setShowDeleteSimModal(false)

  const simulationsActions = [
    {
      id: 0,
      label: 'Choose which simulation to transfer',
      value: 'choose',
    },
    {
      id: 1,
      label: 'Transfer all simulations',
      value: 'all',
    },
    {
      id: 2,
      label: 'Delete all user’s simulations',
      value: 'delete',
    },
  ]

  const usersActions = [
    {
      id: 0,
      label: 'Transfer simulations to other account',
      value: 'to other',
    },
    {
      id: 1,
      label: 'Transfer simulations to my account',
      value: 'to me',
    },
  ]

  return (
    <>
      <Helmet>
        <title>Solarus: Delete user</title>
      </Helmet>

      <section
        className={classNames('Dashboard__Table_path_info Info_2Row', {
          No_Info: (step >= 3 && !isMobile) || (step === 4 && isMobile),
        })}
      >
        <Path
          handleBack={handleBackToUsers}
          parentPath="Users"
          path="Delete user"
          currentStepName={classNames(
            { 'Choose simulation’s options': step === 1 },
            { 'Choose where to transfer': step === 2 },
            { 'Confirm data': step === 3 }
          )}
          disable={step === 4}
        />
        <div className="Dashboard__Table_path_info_Info">
          <Info />
        </div>
        <SwitchTransition>
          <CSSTransition key={step} timeout={200}>
            <div className="Dashboard__Table_path_info_Content Animation_Opacity">
              {step === 1 ? (
                <>
                  <ActionSelector
                    actions={simulationsActions}
                    actionSelected={simulationActionSelected}
                    setActionSelected={setSimulationActionSelected}
                  />
                  <SelectSimulations isMobile={isMobile} />
                </>
              ) : step === 2 ? (
                <>
                  <ActionSelector
                    actions={usersActions}
                    actionSelected={userActionSelected}
                    setActionSelected={setUserActionSelected}
                  />
                  <SelectUser isMobile={isMobile} />
                </>
              ) : step === 3 ? (
                <ConfirmData />
              ) : (
                <EndStep
                  title="¡User deleted!"
                  info={
                    simulationActionSelected === 'delete'
                      ? 'All user’s simulations were deleted'
                      : simulationActionSelected === 'all'
                        ? 'All user’s simulations were transfered'
                        : `${
                          userToDelete[0].simulations -
                          simulationsToTransfer.length
                        } simulations were deleted and ${
                          simulationsToTransfer.length
                        } simulations were transfered`
                  }
                />
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
        <NavigateSteps
          step={step}
          totalSteps={totalSteps}
          handleBack={handleBack}
          handleNext={handleNext}
          nextDisabled={
            (step === 1 &&
              simulationActionSelected === 'choose' &&
              simulationsToTransfer.length === 0) ||
            (step === 2 &&
              userActionSelected === 'to other' &&
              userToTransfer.length === 0)
          }
          nextIsLoading={buttonIsLoading}
        />
      </section>

      {showDeleteSimModal ? (
        <Modal title="Delete simulations" closeModal={closeShowDeleteSimModal}>
          <IAlert
            width={60}
            height={60}
            className="text-yellow d-block mt-4 mx-auto mb-3"
          />
          <p className="text-center fw-medium my-4">
            Are you sure you want to delete ALL the user’s simulations?
          </p>
          <p className="text-center my-4">
            If you delete all, simulation’s data will be lost
          </p>
          <div className="d-flex align-items-center gap-2 mb-3">
            <button
              className="button-blue button_defult w-100 text-4 gap-0"
              onClick={handleDeleteSimulations}
            >
              {buttonModalIsLoading ? (
                <>
                  <span>deleting</span> <LoadingDots className="enter-done" />
                </>
              ) : (
                <span>Yes, delete</span>
              )}
            </button>
            <button
              className="button_transparent_blue w-100 text-4"
              onClick={closeShowDeleteSimModal}
            >
              Do not delete
            </button>
          </div>
        </Modal>
      ) : (
        <></>
      )}
    </>
  )
}

export default DeleteUser

interface ActionSelectorProps {
  actions: Array<{ id: number; label: string; value: string }>;
  actionSelected: string;
  setActionSelected: React.Dispatch<React.SetStateAction<string>>;
}

export const ActionSelector = ({
  actions,
  actionSelected,
  setActionSelected,
}: ActionSelectorProps) => {
  const [isEditing, setIsEditing] = useState(false)

  const handleSelectAction = (value: string) => {
    if (isEditing) {
      setActionSelected(value)
      setIsEditing(false)
    } else setIsEditing(true)
  }

  return (
    <div>
      {actions.map(({ id, label, value }) => (
        <CSSTransition
          key={id}
          in={isEditing || value === actionSelected}
          timeout={400}
          unmountOnExit
        >
          <div
            className={classNames(
              'Dashboard__Table_path_info_Content_Action cursor-pointer',
              {
                Selected: value === actionSelected,
              }
            )}
            onClick={() => handleSelectAction(value)}
          >
            <div
              className={classNames(
                'Radio',
                {
                  Selected: value === actionSelected,
                },
                'blue'
              )}
            />
            <p className="text-5 fw-normal me-auto me-md-0">{label}</p>
            {!isEditing && value === actionSelected ? (
              <p className="text-5 fw-normal text-blue_primary cursor-pointer">
                Edit
              </p>
            ) : (
              <></>
            )}
          </div>
        </CSSTransition>
      ))}
    </div>
  )
}
