import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { SectionListInfo } from '../../types/generateReport'
import dayjs from 'dayjs'
import { DateFormats } from '../../types/index'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  title: {
    flexBasis: '60%',
    padding: '4pt 16pt',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: '#F8F8F8',
    fontSize: 12,
    color: '#5A5A5A',
  },
  info: {
    flexBasis: '40%',
    padding: '4pt 16pt',
    borderTop: '1pt solid #F8F8F8',
    borderLeft: '1pt solid #F8F8F8',
    borderBottom: '1pt solid #F8F8F8',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    fontSize: 12,
    color: '#252525',
  },
})

type Props = {
  section: SectionListInfo;
  dateFormat: DateFormats
};

export default function ListInfoRender({ section, dateFormat }: Props) {
  return (
    <View style={styles.container}>
      {Object.entries(section.value).map(([key, values], index) => {
        if (values.type !== 'file' && values.show) {
          return (
            <View
              key={key + index}
              style={styles.item}
              wrap={false}
            >
              <Text style={styles.title}>{values.label}</Text>
              <Text style={styles.info}>
                {values.type === 'date' && values.value
                  ? dayjs(values.value, 'YYYY-MM-DD').format(dateFormat) 
                  : values.value}
              </Text>
            </View>
          )
        }
      })}
    </View>
  )
}
