interface SubmitStep {
  pathname: string;
  lastStep: string;
  addFunction: any;
  updateFunction: any;
  updateNewSimulation?: any;
  setIsStepLoading: any;
}

export const submitStep = async ({ pathname, lastStep, addFunction, updateFunction, updateNewSimulation, setIsStepLoading }: SubmitStep) => {
  setIsStepLoading(true)

  const currentStep = pathname.split('/').pop()
  let response:any = 'No changes'

  // Add step
  if(lastStep === currentStep || !lastStep) {
    response = await addFunction()
    if(!lastStep) {
      localStorage.setItem('simulation_id', response.data.id)
    }
  }
  // Update step
  else {
    response = await updateFunction()
  }

  if(response?.error) throw Error(JSON.stringify(response.error))

  if((response?.data || response?.message) && updateNewSimulation) {
    // await updateNewSimulation(currentStep, response)
    await updateNewSimulation()
  }

  if(Array.isArray(response)) {
    // await updateNewSimulation(currentStep, response)
    await updateNewSimulation()
  }

  setTimeout(() => {
    setIsStepLoading(false)
  }, 1000)

  return response
}