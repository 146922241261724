import { StyleSheet, Link } from '@react-pdf/renderer'
import { SectionText } from '../../types/generateReport'

const styles = StyleSheet.create({
  link: {
    fontFamily: 'Roboto',
    fontSize: 12,
    color: '#0070CC'
  },
})

type Props = {
  section: SectionText;
};

export default function LinkRender({ section }: Props) {
  // const videoIdRegex = /(?:\?v=|\/embed\/|\/watch\?v=|\/v\/|youtu\.be\/)([a-zA-Z0-9_-]{11})/
  // const match = section.value.match(videoIdRegex)

  // if (match && match[1]) {
  //   return (
  //     <>
  //       <Image src={`http://img.youtube.com/vi/${match[1]}/maxresdefault.jpg`} style={styles.image}/>
  //       <Link src={`https://www.youtube.com/watch?v=${match[1]}`} />
  //     </>
  //   )
  // } else {
  //   return (
  //     <>
  //       <View style={styles.emptyImage}>
  //         <ImageSvg width={32} height={32} />
  //       </View>
  //       <Link src={section.value} />
  //     </>
  //   )
  // }
  return <Link src={section.value} style={styles.link}>{section.value}</Link>
}
