import { Helmet } from 'react-helmet'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { getOrganizationDetail } from '../../api/organizations'
import OrganizationInfo from '../../components/OrganizationInfo'
import Path from '../../components/Path'
import ShowRegions from '../../components/ShowRegions'
import useAppContext from '../../hooks/useAppContext'
import ShowOffices from '../../components/ShowOffices'
import EditOrganizationModal from '../../components/modals/EditOrganizationModal'

const OrganizationDetail = () => {
  const { token } = useAppContext()
  const { id } = useParams()
  const navigate = useNavigate()

  const { data: organizationDetail, refetch } = useQuery(
    ['getOrganizationDetail', id],
    () => getOrganizationDetail(token, Number(id)),
    {
      initialData: {
        id: 0,
        name: '',
        country_residence: {id: 0, country_name: ''},
        phone: '',
        admin_email: '',
        email: '',
        address: '',
        operating_countries: 0,
        admins: [],
        regions: [],
        users: 0,
      },
      enabled: token !== '' && typeof Number(id) === 'number',
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  )
  const [showEditOrganizationModal, setShowEditOrganizationModal] =
    useState(false)

  useEffect(() => {
    if (typeof Number(id) === 'number') return
    handleBackToOrganizations()
  }, [])

  const handleBackToOrganizations = () => {
    navigate('/dashboard/organizations')
  }

  const openShowEditOrganizationModal = () => 
    setShowEditOrganizationModal(true)
  

  const closeShowEditOrganizationModal = () =>
    setShowEditOrganizationModal(false)

  return (
    <>
      <Helmet>
        <title>Solarus: Organization’s details</title>
      </Helmet>

      <section className="Dashboard__Table_path_info">
        <Path
          handleBack={handleBackToOrganizations}
          parentPath="Clients & organizations"
          path="Organization’s details"
          currentStepName="Regions"
        />
        <div className="Dashboard__Table_path_info_Info">
          <OrganizationInfo organization={organizationDetail} openShowEditOrganizationModal={openShowEditOrganizationModal} />
        </div>
        <div className="Dashboard__Table_path_info_Content Special gap-4 Scroll overflow-auto">
          <ShowOffices
            organizationId={Number(id)}
            organization={organizationDetail}
            className="Dashboard flex-shrink-0 h-Max p-0"
          />
          <ShowRegions 
            organizationId={Number(id)} 
            className="Dashboard flex-shrink-0 h-Max p-0"
          />
        </div>
      </section>

      {showEditOrganizationModal ? (
        <EditOrganizationModal
          organization={organizationDetail}
          refetch={refetch}
          closeModal={closeShowEditOrganizationModal}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default OrganizationDetail
