import { useEffect, useState } from 'react'
import {
  addReportImageToGallery,
  getReportImagesGallery,
  uploadImages3,
} from '../../api/newReport'
import useAppContext from '../../hooks/useAppContext'
import SearchInput from '../SearchInput'
import ImagesSelector from './ImagesSelector'
import { IDelete } from '../../iconComponents'
import InputFile from '../InputFile'
import { handleInputFile } from '../../utils/handleInputFile'
import Input from '../InputCleared'
import { URL as URL_BACK } from '../../constants'
import Button from '../buttons/Button'

type Props = {
  organizations: Array<{
    value: number;
    label: string;
  }>;

  id: string;
  value: string | null;
  placeholder?: string;
  gallery_type?: string;

  library: Awaited<ReturnType<typeof getReportImagesGallery>>;
  isFetching: boolean;
  refetch: () => void;
  onSelect: (value: string) => void;
  onDelete: () => void;

  rounded?: boolean;
};

export default function ImageLibrarySelector({
  organizations,

  id,
  value,
  placeholder,
  gallery_type,

  library,
  isFetching,
  refetch,

  onSelect,
  onDelete,

  rounded,
}: Props) {
  const { token, isCentralAdmin, user } = useAppContext()

  const [organization_id, setOrganization_id] = useState<number | null>(null)
  const [organizationError, setOrganizationError] = useState('')

  const [file, setFile] = useState<File | null>(null)
  const [fileName, setFileName] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [error, seterror] = useState('')

  useEffect(() => {
    if (!error) return
    const clearError = setTimeout(() => seterror(''), 10000)

    return () => clearTimeout(clearError)
  }, [error])

  const clearInput = () => {
    onDelete()
    setFile(null)
    setFileName('')
  }

  const UploadImage = async () => {
    if (!file) return
    if (!fileName) return 
    if (isLoading) return 
    setIsLoading(true)

    const formData = new FormData()
    formData.append('image', file)
    formData.append(
      'organization',
      isCentralAdmin
        ? organization_id.toString()
        : user.organization.id.toString()
    )
    formData.append('name', fileName)
    formData.append('gallery_type', gallery_type ?? 'general')

    const { ok, data } = await addReportImageToGallery(token, formData)

    if (!ok) {
      console.error(data)
    } else {
      onSelect(URL_BACK + data.image)
      refetch()
    }
    setFile(null)
    setFileName('')
    setIsLoading(false)
    refetch()
  }

  return (
    <>
      {isCentralAdmin && value === null && (
        <SearchInput
          label="Chose organization"
          name="organization_id"
          placeholder="Choose organization"
          search={organizations}
          value={organization_id}
          onChange={(id) => setOrganization_id(id as unknown as number)}
          error={organizationError}
        />
      )}

      {value === null ? (
        <InputFile
          name={'image_' + id}
          accept={['image/jpg', 'image/jpeg', 'image/png']}
          onChange={(event) =>
            handleInputFile({
              event,
              fileHandler: async (file: File) => {
                const formData = new FormData()
                formData.append('image', file)
                const { ok, data } = await uploadImages3(token, formData)
                
                if (!ok) {
                  console.error(data)
                } else {
                  onSelect(data.url)
                }
                setFile(file)
              },
              fileTypes: ['image/jpg', 'image/jpeg', 'image/png'],
              fileMaxSize: 5,
              errorHandler: (error) => seterror(error),
            })
          }
          onClick={(e) => {
            if (!isCentralAdmin) return null
            if (!organization_id) {
              e.preventDefault()
              return setOrganizationError('Organization is required')
            }
            if (isLoading) return e.preventDefault()
            setOrganizationError('')
          }}
          error={error}
        >
          <span className="InputFile_Text">
            <span className="text-5 text-primary">
              {placeholder ?? 'Upload image'}
            </span>
            <span className="text-5 text-secondary">Max 5mb.</span>
          </span>
          <span className="Button xs blue">
            Add image
          </span>
        </InputFile>
      ) : (
        !file ? (
          <div className="InputFile">
            <img
              src={value}
              className={!rounded ? 'InputFile_Image' : 'InputFile_Image_Circle'}
            />
            <div className="InputFile_Text">
              <p className="text-5 text-primary">
                {placeholder ?? 'Upload image'}
              </p>
              <span className="text-5 text-secondary">Max 5mb.</span>
            </div>
            <IDelete
              width={24}
              height={24}
              className="text-primary me-2"
              onClick={onDelete}
            />
          </div>): (
          <div className="InputFile_Library">
            <div className="InputFile_Library_Header">
              <img src={value} className="InputFile_Image" />
              <span className="InputFile_Text">
                <span className="text-5 text-primary">
                  {(file.size / 1024 ** 2).toFixed(2)}MB
                </span>
              </span>
              <IDelete
                width={24}
                height={24}
                className="text-primary me-2"
                onClick={clearInput}
              />
            </div>
            <Input
              name="image_name"
              label="Save image to library?"
              placeholder="Name"
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
              innerRight={
                <Button
                  size='xs'
                  onClick={UploadImage}
                  disabled={!fileName || isLoading}
                >
                Save
                </Button>
              }
            />
          </div>
        )
      )}

      <ImagesSelector
        library={library}
        isFetching={isFetching}
        refetch={refetch}
        onSelect={(image: string) => onSelect(image)}
      />
    </>
  )
}
