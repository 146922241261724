import { IAngleUp } from '../iconComponents'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import classNames from 'classnames'

interface Props {
  handleBack: () => void;
  parentPath: string;
  path: string;
  currentStepName?: string;
  disable?: boolean;
}

const Path = ({
  handleBack,
  parentPath,
  path,
  currentStepName = '',
  disable = false,
}: Props) => {
  return (
    <div className="Dashboard__Table_path_info_Path">
      <button
        className="Dashboard__Table_path_info_Path_Show arrow_left"
        onClick={handleBack}
        disabled={disable}
      >
        <IAngleUp width={32} height={32} className="-rotate-90" />
      </button>
      <p className="text-5 fw-light text-secondary d-none d-md-block">
        {parentPath}
      </p>
      <IAngleUp width={16} height={16} className="rotate-90 text-secondary d-none d-md-block" />
      <p className="text-2 fw-medium text-primary">{path}</p>
      <div
        className={classNames(
          'Dashboard__Table_path_info_Path_Show',
          { Disabled: currentStepName.length === 0 },
          'text-2 text-secondarys d-none d-md-block'
        )}
      >
        |
      </div>
      <SwitchTransition>
        <CSSTransition key={currentStepName} timeout={200}>
          <p className="Dashboard__Table_path_info_Path_Current_Step_Name Animation_Opacity text-4 fw-medium text-primary d-none d-md-block">
            {currentStepName}
          </p>
        </CSSTransition>
      </SwitchTransition>
    </div>
  )
}

export default Path
