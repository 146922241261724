import { isValidPhoneNumber } from 'libphonenumber-js'
import { Office } from '../../api/apiTypes'
import { IEdit, IHousing, ILocation, IWorld } from '../../iconComponents'
import { preventDivClick } from '../../utils/preventClick'
import TableItem from './TableItem'
import TableItemMenu from './TableItemMenu'
import { formatPhoneNumberIntl } from 'react-phone-number-input'

interface Props {
  data: Office;
  action: (data: Office) => void;
  unselectable?: boolean;
  selected?: boolean;
  handleSelectItem?: (data: Office) => void;
  isMobile?: boolean;
}

const TableOffices = ({
  data,
  action,
  unselectable = false,
  selected = false,
  handleSelectItem,
  isMobile,
}: Props) => {
  const actions = [
    {
      id: 1,
      label: 'edit',
      callback: () => action(data),
    },
  ]

  if (isMobile)
    return (
      <TableItem
        data={data}
        unselectable={unselectable}
        selected={selected}
        handleSelectItem={handleSelectItem}
      >
        <div className="TableItem__Cell">
          {data.HQ ? (
            <div className="button_transparent_yellow gap-1 px-2 py-0 me-2">
              <IHousing /> HQ
            </div>
          ) : (
            <></>
          )}
          <p className="text-4 fw-medium">{data.name || '-'}</p>
        </div>

        <div className="TableItem__Cell of-visible justify-content-end">
          <TableItemMenu actions={actions} />
        </div>
        <div className="TableItem__Cell TableItem__Cell--Mb">
          <ILocation className="TableItem__Cell_Icon text-secondary" />
          <p>
            {data.country?.name}, {data.city}
          </p>
        </div>

        <div className="TableItem__Cell TableItem__Cell--Mb">
          <IWorld className="TableItem__Cell_Icon text-secondary" />
          <p className="text-5 text-secondary">{data.email}</p>
        </div>
      </TableItem>
    )

  return (
    <TableItem
      data={data}
      unselectable={unselectable}
      selected={selected}
      handleSelectItem={handleSelectItem}
      className="h-auto"
    >
      <div className="TableItem__Cell--center">
        <div className="Checkbox" />
      </div>
      <div
        className="TableItem__Cell cursor-pointer"
        onClick={(e) => preventDivClick(e, () => action(data))}
        title={data.name}
      >
        {data.HQ ? (
          <div className="button_transparent_yellow gap-1 px-2 py-0 me-2">
            <IHousing /> HQ
          </div>
        ) : (
          <></>
        )}
        <p>{data.name || '-'}</p>
      </div>
      <div
        className="TableItem__Cell cursor-pointer"
        onClick={(e) => preventDivClick(e, () => action(data))}
        title={`${data.country?.name} ${data.city}`}
      >
        <p>
          {data.country?.name}, {data.city}
        </p>
      </div>
      <div
        className="TableItem__Cell cursor-pointer"
        onClick={(e) => preventDivClick(e, () => action(data))}
        title={`${data.zip_code}, ${data.address_line_1}${
          data.address_line_2 ? `, ${data.address_line_2}` : ''
        }`}
      >
        <p>
          {`${data.zip_code}, ${data.address_line_1}${
            data.address_line_2 ? `, ${data.address_line_2}` : ''
          }`}
        </p>
      </div>
      <div
        className="TableItem__Cell cursor-pointer"
        onClick={(e) => preventDivClick(e, () => action(data))}
        title={data.email}
      >
        <p>{data.email}</p>
      </div>
      <div
        className="TableItem__Cell cursor-pointer"
        onClick={(e) => preventDivClick(e, () => action(data))}
      >
        <p>{isValidPhoneNumber(data.phone) ? formatPhoneNumberIntl(data.phone) : data.phone || '-'}</p>
      </div>
      <div
        className="TableItem__Cell gap-2 cursor-pointer"
        onClick={(e) => preventDivClick(e, () => action(data))}
      >
        <IEdit className="text-blue_primary" />
      </div>
    </TableItem>
  )
}

export default TableOffices
