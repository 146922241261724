import { Dispatch, SetStateAction } from 'react'
import {
  Page,
  Section,
  SectionGoal,
  SectionImage,
  InputFile,
  InputGeneric,
} from '../types/generateReport'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import { DateFormats } from '../types/index'

type Props = {
  setPages: Dispatch<SetStateAction<Array<Page>>>;
  pageSelectedIndexs: {
    pageIndex: number;
    subPageIndex: number;
  };
  reportLanguage: 'en' | 'du';
  dateFormat: DateFormats;
};

const sectionsUpdater = ({
  pages,
  pageSelectedIndexs,
  sectionsHandler,
}: {
  pages: Array<Page>;
  pageSelectedIndexs: {
    pageIndex: number;
    subPageIndex: number;
  };
  sectionsHandler: (sections: Array<Section>) => Array<Section>;
}): Array<Page> => {
  const { pageIndex, subPageIndex } = pageSelectedIndexs

  const page = pages[pageIndex]

  if (!page) return pages

  if (page.type === 'single') {
    const newPageSingle = {
      ...page,
      sections: sectionsHandler(page.sections),
    }

    return [
      ...pages.slice(0, pageIndex),
      newPageSingle,
      ...pages.slice(pageIndex + 1),
    ]
  }

  const subPage = page.subPages[subPageIndex]

  if (!subPage) return pages

  const newSubPages = [
    ...page.subPages.slice(0, subPageIndex),
    {
      ...subPage,
      sections: sectionsHandler(subPage.sections),
    },
    ...page.subPages.slice(subPageIndex + 1),
  ]

  return [
    ...pages.slice(0, pageIndex),
    {
      ...page,
      subPages: newSubPages,
    },
    ...pages.slice(pageIndex + 1),
  ]
}

const sectionUpdater = ({
  pages,
  pageSelectedIndexs,
  sectionId,
  sectionHandler,
}: {
  pages: Array<Page>;
  pageSelectedIndexs: {
    pageIndex: number;
    subPageIndex: number;
  };
  sectionId: string;
  sectionHandler: (section: Section) => Section;
}): Array<Page> => {
  return sectionsUpdater({
    pages,
    pageSelectedIndexs,
    sectionsHandler: (sections: Array<Section>) => {
      const sectionIndex = sections.findIndex(
        (section) => section.id === sectionId
      )

      const section = sections[sectionIndex]

      if (!section) return sections

      return [
        ...sections.slice(0, sectionIndex),
        sectionHandler(section),
        ...sections.slice(sectionIndex + 1),
      ]
    },
  })
}

export default function useSectionHandlers({
  setPages,
  pageSelectedIndexs,
  reportLanguage,
  dateFormat,
}: Props) {
  const handleToggleSection = (sectionId: string) => {
    setPages((pages) => {
      return sectionUpdater({
        pages,
        pageSelectedIndexs,
        sectionId,
        sectionHandler: (section: Section) => {
          return {
            ...section,
            active: !section.active,
          }
        },
      })
    })
  }

  const handleUpdateText = (sectionId: string, value: string) => {
    setPages((pages) => {
      return sectionUpdater({
        pages,
        pageSelectedIndexs,
        sectionId,
        sectionHandler: (section: Section) => {
          if (
            section.type !== 'text' &&
            section.type !== 'text_title' &&
            section.type !== 'text_conf' &&
            section.type !== 'text_special' &&
            section.type !== 'text_link'
          )
            return section

          return {
            ...section,
            value,
          }
        },
      })
    })
  }

  const handleUpdateImage = (
    sectionId: string,
    value: SectionImage['value']
  ) => {
    setPages((pages) => {
      return sectionUpdater({
        pages,
        pageSelectedIndexs,
        sectionId,
        sectionHandler: (section: Section) => {
          if (
            section.type !== 'image' &&
            section.type !== 'image_select' &&
            section.type !== 'image_logo'
          )
            return section

          return {
            ...section,
            value,
          }
        },
      })
    })
  }

  const handleUpdateImageText = (
    sectionId: string,
    type: 'text' | 'subtitle' | 'description',
    value: string
  ) => {
    setPages((pages) => {
      return sectionUpdater({
        pages,
        pageSelectedIndexs,
        sectionId,
        sectionHandler: (section: Section) => {
          if (section.type !== 'image_select') return section

          return {
            ...section,
            [type]: value,
          }
        },
      })
    })
  }

  const handleUpdateListInfoText = (
    sectionId: string,
    name: string,
    value: string | number | readonly string[]
  ) => {
    setPages((pages) => {
      return sectionUpdater({
        pages,
        pageSelectedIndexs,
        sectionId,
        sectionHandler: (section: Section) => {
          if (section.type !== 'list_info' && section.type !== 'contact')
            return section

          const inputInfo = {
            ...section.value[name],
            value: value,
          } as InputGeneric

          return {
            ...section,
            value: {
              ...section.value,
              [name]: inputInfo,
            },
          }
        },
      })
    })
  }

  const handleUpdateListInfoFile = (
    sectionId: string,
    name: string,
    value: InputFile['value']
  ) => {
    setPages((pages) => {
      return sectionUpdater({
        pages,
        pageSelectedIndexs,
        sectionId,
        sectionHandler: (section: Section) => {
          if (section.type !== 'list_info' && section.type !== 'contact')
            return section

          const inputInfo = {
            ...section.value[name],
            value,
          } as InputFile

          return {
            ...section,
            value: {
              ...section.value,
              [name]: inputInfo,
            },
          }
        },
      })
    })
  }

  const handleToggleListInfoShow = (sectionId: string, name: string) => {
    setPages((pages) => {
      return sectionUpdater({
        pages,
        pageSelectedIndexs,
        sectionId,
        sectionHandler: (section: Section) => {
          if (section.type !== 'list_info' && section.type !== 'contact')
            return section

          const inputInfo = {
            ...section.value[name],
            show: !section.value[name].show,
          }

          return {
            ...section,
            value: {
              ...section.value,
              [name]: inputInfo,
            },
          }
        },
      })
    })
  }

  const handleUpdateGoalLabel = (sectionId: string, value: string) => {
    setPages((pages) => {
      return sectionUpdater({
        pages,
        pageSelectedIndexs,
        sectionId,
        sectionHandler: (section: Section) => {
          if (section.type !== 'goal') return section

          return {
            ...section,
            label: {
              ...section.label,
              value,
            },
          }
        },
      })
    })
  }

  const handleUpdateGoalItemText = (
    sectionId: string,
    goalId: string,
    name: string,
    value: string
  ) => {
    setPages((pages) => {
      return sectionUpdater({
        pages,
        pageSelectedIndexs,
        sectionId,
        sectionHandler: (section: Section) => {
          if (section.type !== 'goal') return section

          const goalIndex = section.value.findIndex(
            (goal) => goal.id === goalId
          )

          if (goalIndex < 0) return section

          return {
            ...section,
            value: [
              ...section.value.slice(0, goalIndex),
              {
                ...section.value[goalIndex],
                [name]: {
                  ...section.value[goalIndex][name],
                  value,
                },
              },
              ...section.value.slice(goalIndex + 1),
            ],
          }
        },
      })
    })
  }

  const handleUpdateGoalItemImage = (
    sectionId: string,
    goalId: string,
    name: string,
    value: SectionGoal['value'][number]['image']
  ) => {
    setPages((pages) => {
      return sectionUpdater({
        pages,
        pageSelectedIndexs,
        sectionId,
        sectionHandler: (section: Section) => {
          if (section.type !== 'goal') return section

          const goalIndex = section.value.findIndex(
            (goal) => goal.id === goalId
          )

          return {
            ...section,
            value: [
              ...section.value.slice(0, goalIndex),
              {
                ...section.value[goalIndex],
                [name]: value,
              },
              ...section.value.slice(goalIndex + 1),
            ],
          }
        },
      })
    })
  }

  const handleAddGoalItem = (sectionId: string, withImage: boolean) => {
    setPages((pages) => {
      return sectionUpdater({
        pages,
        pageSelectedIndexs,
        sectionId,
        sectionHandler: (section: Section) => {
          if (section.type !== 'goal') return section

          return {
            ...section,
            value: [
              ...section.value,
              {
                id: uuidv4(),
                subtitle: {
                  label: 'Subtitle',
                  placeholder: 'Type a subtitle',
                  value: '',
                },
                description: {
                  label: 'Description',
                  placeholder: 'Type a description',
                  value: '',
                },
                image: withImage
                  ? {
                    placeholder: 'Upload image',
                    value: null,
                  }
                  : undefined,
              },
            ],
          }
        },
      })
    })
  }

  const handleRemoveGoalItem = (sectionId: string, goalId: string) => {
    setPages((pages) => {
      return sectionUpdater({
        pages,
        pageSelectedIndexs,
        sectionId,
        sectionHandler: (section: Section) => {
          if (section.type !== 'goal') return section

          const goalIndex = section.value.findIndex(
            (goal) => goal.id === goalId
          )

          if (goalIndex < 0) return section

          return {
            ...section,
            value: [
              ...section.value.slice(0, goalIndex),
              ...section.value.slice(goalIndex + 1),
            ],
          }
        },
      })
    })
  }

  const handleToggleGridBoolean = (
    sectionId: string,
    itemId: string,
    name: string
  ) => {
    setPages((pages) => {
      return sectionUpdater({
        pages,
        pageSelectedIndexs,
        sectionId,
        sectionHandler: (section: Section) => {
          if (section.type !== 'grid') return section

          const itemIndex = section.value.findIndex(
            (item) => item.id === itemId
          )

          if (itemIndex < 0) return section

          return {
            ...section,
            value: [
              ...section.value.slice(0, itemIndex),
              {
                ...section.value[itemIndex],
                [name]: !section.value[itemIndex][name],
              },
              ...section.value.slice(itemIndex + 1),
            ],
          }
        },
      })
    })
  }

  const handleUpdateGridText = (
    sectionId: string,
    itemId: string,
    name: string,
    value: string
  ) => {
    setPages((pages) => {
      return sectionUpdater({
        pages,
        pageSelectedIndexs,
        sectionId,
        sectionHandler: (section: Section) => {
          if (section.type !== 'grid') return section

          const itemIndex = section.value.findIndex(
            (item) => item.id === itemId
          )

          if (itemIndex < 0) return section

          return {
            ...section,
            value: [
              ...section.value.slice(0, itemIndex),
              {
                ...section.value[itemIndex],
                [name]: value,
              },
              ...section.value.slice(itemIndex + 1),
            ],
          }
        },
      })
    })
  }

  const handleToggleTableColumn = (sectionId: string, columnId: string) => {
    setPages((pages) => {
      return sectionUpdater({
        pages,
        pageSelectedIndexs,
        sectionId,
        sectionHandler: (section: Section) => {
          if (section.type !== 'table') return section

          const columnIndex = section.value.findIndex(
            (column) => column.id === columnId
          )

          if (columnIndex < 0) return section

          return {
            ...section,
            value: [
              ...section.value.slice(0, columnIndex),
              {
                ...section.value[columnIndex],
                show: !section.value[columnIndex].show,
              },
              ...section.value.slice(columnIndex + 1),
            ],
          }
        },
      })
    })
  }

  const handleAddImageGalery = (sectionId: string, value: string) => {
    setPages((pages) => {
      return sectionUpdater({
        pages,
        pageSelectedIndexs,
        sectionId,
        sectionHandler: (section: Section) => {
          if (section.type !== 'image_galery') return section

          return {
            ...section,
            value: [
              ...section.value,
              {
                id: uuidv4(),
                value,
              },
            ],
          }
        },
      })
    })
  }

  const handleUpdateImageGalery = (
    sectionId: string,
    imageId: string,
    value: string
  ) => {
    setPages((pages) => {
      return sectionUpdater({
        pages,
        pageSelectedIndexs,
        sectionId,
        sectionHandler: (section: Section) => {
          if (section.type !== 'image_galery') return section

          const imageIndex = section.value.findIndex(
            (image) => image.id === imageId
          )

          if (imageIndex < 0) return section

          return {
            ...section,
            value: [
              ...section.value.slice(0, imageIndex),
              {
                id: uuidv4(),
                value,
              },
              ...section.value.slice(imageIndex + 1),
            ],
          }
        },
      })
    })
  }

  const hadleDeleteImageGalery = (sectionId: string, imageId: string) => {
    setPages((pages) => {
      return sectionUpdater({
        pages,
        pageSelectedIndexs,
        sectionId,
        sectionHandler: (section: Section) => {
          if (section.type !== 'image_galery') return section

          const imageIndex = section.value.findIndex(
            (image) => image.id === imageId
          )

          if (imageIndex < 0) return section

          return {
            ...section,
            value: [
              ...section.value.slice(0, imageIndex),
              ...section.value.slice(imageIndex + 1),
            ],
          }
        },
      })
    })
  }

  const handleUpdateExpirationDate = (sectionId: string, value: string) => {
    setPages((pages) => {
      return sectionUpdater({
        pages,
        pageSelectedIndexs,
        sectionId,
        sectionHandler: (section: Section) => {
          if (section.type !== 'expiration_date') return section

          return {
            ...section,
            value,
          }
        },
      })
    })
    setTimeout(() => {
      setPages((pages) => {
        if (!value) return pages

        const pageIndex = pages.findIndex(
          (page) => page.id === 'd19181eb-8453-4362-96c5-a70774cbfcc3'
        )
        const page = pages[pageIndex]

        if (!page) return pages
        if (page.type === 'container') return pages

        const sectionIndex = page.sections.findIndex(
          (section) => section.id === '74caaf6e-6ba9-4796-ab68-cc2c479bc360'
        )
        const section = page.sections[sectionIndex]

        if (!section) return pages
        if (section.type !== 'text_conf') return pages

        const newValue =
          reportLanguage === 'en'
            ? `<p><span style="color: rgb(0, 0, 0);">· The installation will be completed within 3 to 6 months after approval, provided the materials are in stock and there are favorable weather conditions. Exceptions are nationally recognized holidays and/or demonstrable force majeure as a result of government restrictive measures.</span></p><p><br></p><p><span style="color: rgb(0, 0, 0);">· The client ensures a properly functioning internet connection for monitoring the solar system. When commissioning the solar system, Solarus guarantees good PLC communication.</span></p><p><br></p><p><span style="color: rgb(0, 0, 0);">· Due to the unpredictable market for solar panels, no guarantee is given on the availability of the selected panels. If not in stock, Solarus will offer an alternative panel of equal quality, power and price.</span></p><p><br></p><p><span style="color: rgb(0, 0, 0);">· Payment conditions:</span></p><p><br></p><ul><li><span style="color: rgb(0, 0, 0);">40% when agreed in writing.</span></li></ul><p><br></p><p><br></p><ul><li><span style="color: rgb(0, 0, 0);">55% at the start of the work.</span></li></ul><p><br></p><p><br></p><ul><li><span style="color: rgb(0, 0, 0);"> 5% upon delivery.</span></li></ul><p><br></p><p><span style="color: rgb(0, 0, 0);">· The price in this quote is valid until ${dayjs(
              value,
              'YYYY-MM-DD'
            ).format(
              dateFormat
            )}.</span></p><p><br></p><p><span style="color: rgb(0, 0, 0);">· Offerte excludes:</span></p><p><br></p><ul><li><span style="color: rgb(0, 0, 0);">Delivery of new roofing and other roofing work.</span></li></ul><p><br></p><p><br></p><ul><li><span style="color: rgb(0, 0, 0);">Earthworks.</span></li></ul><p><br></p><p><br></p><ul><li><span style="color: rgb(0, 0, 0);">Work on materials that (possibly) contain asbestos.</span></li></ul><p><br></p><p><br></p><ul><li><span style="color: rgb(0, 0, 0);">Applications/construction costs for strengthening utilities (if applicable).</span></li></ul>`
            : `<p><span style="color: rgb(0, 0, 0);">· De installatie wordt gerealiseerd binnen 3 tot 6 maanden na akkoord, mits de materialen op voorraad zijn en er tevens gunstige weersomstandigheden zijn. Uitzonderingen zijn nationaal erkende feestdagen en/of aantoonbare overmacht als gevolg van overheids beperkende maatregelen.</span></p><p><br></p><p><span style="color: rgb(0, 0, 0);">·  De opdrachtgever zorgt voor een goed functionerende internetverbinding voor het bewaken van het zonnesysteem. Bij ingebruikname van het zonnesysteem garandeert Solarus een goede PLC-communicatie.</span></p><p><br></p><p><span style="color: rgb(0, 0, 0);">· Vanwege de onvoorspelbare markt voor zonnepanelen wordt er geen garantie gegeven op de beschikbaarheid van de gekozen panelen. Indien niet op voorraad, zal Solarus een alternatief paneel van gelijke kwaliteit, vermogen en prijs aanbieden.</span></p><p><br></p><p><span style="color: rgb(0, 0, 0);">· Betalingsverloop:</span></p><p><br></p><ul><li><span style="color: rgb(0, 0, 0);">40% bij akkoord.</span></li></ul><p><br></p><p><br></p><ul><li><span style="color: rgb(0, 0, 0);">55% bij aanvang van de werkzaamheden.</span></li></ul><p><br></p><p><br></p><ul><li><span style="color: rgb(0, 0, 0);"> 5% bij oplevering.</span></li></ul><p><br></p><p><span style="color: rgb(0, 0, 0);">· De prijs in deze offerte is geldig tot ${dayjs(
              value,
              'YYYY-MM-DD'
            ).format(
              dateFormat
            )}.</span></p><p><br></p><p><span style="color: rgb(0, 0, 0);">· Offerte is exclusief:</span></p><p><br></p><ul><li><span style="color: rgb(0, 0, 0);">Levering van nieuw dakwerk/ en overige dakwerkzaamheden.</span></li></ul><p><br></p><p><br></p><ul><li><span style="color: rgb(0, 0, 0);">Grondwerkzaamheden.</span></li></ul><p><br></p><p><br></p><ul><li><span style="color: rgb(0, 0, 0);">Werkzaamheden aan materialen die (mogelijk) asbest bevatten.</span></li></ul><p><br></p><p><br></p><ul><li><span style="color: rgb(0, 0, 0);">Aanvragen/ kosten aanleg voor versterking van nutsvoorzieningen (indien van toepassing).</span></li></ul>`

        return [
          ...pages.slice(0, pageIndex),
          {
            ...page,
            sections: [
              ...page.sections.slice(0, sectionIndex),
              {
                ...section,
                value: newValue,
              },
              ...page.sections.slice(sectionIndex + 1),
            ],
          },
          ...pages.slice(pageIndex + 1),
        ]
      })
    }, 100)
  }

  return {
    handleToggleSection,
    handleUpdateText,
    handleUpdateImage,
    handleUpdateImageText,
    handleUpdateListInfoText,
    handleUpdateListInfoFile,
    handleToggleListInfoShow,
    handleUpdateGoalLabel,
    handleUpdateGoalItemText,
    handleUpdateGoalItemImage,
    handleAddGoalItem,
    handleRemoveGoalItem,
    handleToggleGridBoolean,
    handleUpdateGridText,
    handleToggleTableColumn,
    handleAddImageGalery,
    handleUpdateImageGalery,
    hadleDeleteImageGalery,
    handleUpdateExpirationDate,
  }
}
