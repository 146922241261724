import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import ImageSvg from './ImageSvg'
import { SectionListInfo } from '../../types/generateReport'

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    right: 32,
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    // gap: 2,
  },
  name: {
    fontSize: 10,
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: '#252525',
  },
  designation: {
    fontSize: 10,
    fontFamily: 'Roboto',
    fontWeight: 400,
    color: '#5A5A5A',
    paddingTop: 2
  },
  phone: {
    fontSize: 10,
    fontFamily: 'Roboto',
    fontWeight: 400,
    color: '#252525',
    paddingTop: 4
  },
  email: {
    fontSize: 10,
    fontFamily: 'Roboto',
    fontWeight: 400,
    color: '#252525',
    paddingTop: 4
  },
  image: {
    width: 40,
    height: 40,
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    borderBottomRightRadius: 40,
    borderBottomLeftRadius: 40,
    objectFit: 'cover',
  },
  emptyImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    borderBottomRightRadius: 40,
    borderBottomLeftRadius: 40,
    backgroundColor: '#D9D9D9',
  },
})

type Props = {
  section: SectionListInfo;
};

export default function ContactRender({ section }: Props) {
  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        {section.value.name && section.value.name.type !== 'file' && (
          <Text style={styles.name}>{section.value.name.value}</Text>
        )}
        {section.value.designation && section.value.designation.type !== 'file' && (
          <Text style={styles.designation}>{section.value.designation.value}</Text>
        )}
        {section.value.phone && section.value.phone.type !== 'file' && (
          <Text style={styles.phone}>{section.value.phone.value}</Text>
        )}
        {section.value.email && section.value.email.type !== 'file' && (
          <Text style={styles.email}>{section.value.email.value}</Text>
        )}
      </View>
      {section.value.image &&
      section.value.image.type === 'file' &&
      section.value.image.value ? (
          <Image src={section.value.image.value} style={styles.image} />
        ) : (
          <View style={styles.emptyImage}>
            <ImageSvg width={24} height={24} />
          </View>
        )}
    </View>
  )
}
