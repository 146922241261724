import { Dispatch, SetStateAction } from 'react'
import { FiltersGroup, FiltersSelected, Option } from '../types'

import DropdownSearch from './DropdownSearch'

interface Props {
  filtersSelected: FiltersSelected[];
  setFiltersSelected: Dispatch<SetStateAction<FiltersSelected[]>>;
  filtersGroup: FiltersGroup[];
}

const TableFilters = ({
  filtersSelected,
  setFiltersSelected,
  filtersGroup,
}: Props) => {
  const handleOptionSelected = (filterIndex: number, option: Option) => {
    setFiltersSelected((prev) => {
      if (
        JSON.stringify(filtersSelected[filterIndex].option.value) ===
        JSON.stringify(option.value)
      )
        return [
          ...prev.slice(0, filterIndex),
          {
            ...prev[filterIndex],
            option: filtersSelected[filterIndex].defaultOption,
          },
          ...prev.slice(filterIndex + 1),
        ]
      else
        return [
          ...prev.slice(0, filterIndex),
          {
            ...prev[filterIndex],
            option: option,
          },
          ...prev.slice(filterIndex + 1),
        ]
    })
  }

  const handleSearch = (filterIndex: number, value: string) => {
    setFiltersSelected((prev) => [
      ...prev.slice(0, filterIndex),
      {
        ...prev[filterIndex],
        search: value,
      },
      ...prev.slice(filterIndex + 1),
    ])
  }

  return (
    <>
      {filtersGroup.map((filter, index) => (
        <DropdownSearch
          key={index}
          name={filter.label}
          filterIndex={index}
          search={filtersSelected[index].search}
          optionSelected={filtersSelected[index].option}
          options={filter.options}
          onSelectOption={handleOptionSelected}
          onSearch={handleSearch}
        />
      ))}
    </>
  )
}

export default TableFilters
