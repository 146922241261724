import Modal from './Modal'
import {
  IAdmin,
  ICalendar,
  ILocation,
  IOrganization,
  IUser,
} from '../../iconComponents'
import { FakeLog } from '../../pages/admin/Logs'

interface Props {
  closeModal: () => void;
  info: FakeLog;
}

const LogInfoModal = ({ closeModal, info }: Props) => {
  const data = [
    {
      icon: <IUser />,
      title: 'Created by',
      value: `${info?.user?.first_name} ${info?.user?.last_name}`,
    },
    {
      icon: <ILocation />,
      title: 'Country',
      value: '???',
    },
    {
      icon: <IAdmin />,
      title: 'Owned by',
      value: '-',
    },
    {
      icon: <IOrganization />,
      title: 'Organization',
      value: '-',
    },
    {
      icon: <ICalendar />,
      title: 'Date',
      value: '-',
    },
  ]

  return (
    <Modal closeModal={closeModal} title="Simulation information">
      <p className=" p-1 text-center text-4 fw-medium">Simulation’s name</p>
      <div className="Modal__Divider" />

      <div className="DeleteSimulationModal__Grid my-4">
        {data.map((item, index) => (
          <div className="DeleteSimulationModal__Grid_Item" key={index}>
            <div className="DeleteSimulationModal__Grid_Item_Title">
              {item.icon}
              <p className="text-5 text-secondary">{item.title}</p>
            </div>
            <div className="DeleteSimulationModal__Grid_Item_Info text-5">
              {item.value}
            </div>
          </div>
        ))}
      </div>
    </Modal>
  )
}

export default LogInfoModal
