import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  addExistingHotWaterSystem,
  getHotWaterSystemDetails,
  updateExistingHotWaterSystem,
} from '../../api/simulations/input-parameters/existing-hot-water-system-details'
import {
  addRoofAndPlant,
  updateRoofAndPlant,
} from '../../api/simulations/input-parameters/roof-and-plant'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import useModalsContext from '../../hooks/useModalsContext'
import CreateSimulationContainer from '../../containers/CreateSimulationContainer'
import SimulationFormikHandler from '../../containers/SimulationFormikHandler'
import Checkboxes from '../../components/Checkboxes'
import CreateSimulationTitle from '../../components/CreateSimulationTitle'
import Input from '../../components/Input'
import InputSmall from '../../components/InputSmall'
import SearchInput from '../../components/SearchInput'
import {
  checkboxOptions,
  normalizeNumberWithCommas,
  normalizePercentToShow,
  threeDecimalRegex,
} from '../../constants'
import { submitStep } from '../../utils/submitStep'
import FireIcon from '../../assets/icons/fire.svg'
import RoofIcon from '../../assets/icons/roof.svg'
import { ListIcon } from '../../assets/icons'
import SimulationSwitch from '../../components/SimulationSwitch'

const HotWaterSystem = () => {
  const { token } = useAppContext()
  const { newSimulation, updateNewSimulation, waterSystems, setIsStepLoading } =
    useNewSimulationContext()
  const { toggleShowManageSystemModal } = useModalsContext()
  const [systems, setSystems] = useState([])
  const [systemSelected, setSystemSelected] = useState({
    system_name: '',
    energy_source: '',
    CO2_Fuel_txt: '',
    CO2_Fuel: '',
    kWH_CF_Fuel_txt: '',
    kWh_CF_Fuel: '',
    n_ex_syst: null,
  })

  const yupNumberValidation = Yup.mixed()
    .required('Required')
    .transform((value) => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', (value) => !isNaN(value))
    .test('min', 'Min: 0', (value) => value >= 0)
    .test(
      'decimals',
      'Max: 3 decimals',
      (value) => !threeDecimalRegex.test(value)
    )

  const yupIntegerValidation = Yup.mixed()
    .required('Required')
    .transform((value) => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', (value) => !isNaN(value))
    .test('min', 'Min: 0', (value) => value >= 0)
    .test('integer', 'Only integers', (value) =>
      Number.isInteger(Number(value))
    )

  const formik = useFormik({
    initialValues: {
      // Hot Water System
      system: null,
      other_system_name: '',
      auxiliary_hot_water_system_capacity: '',
      age_of_the_existing_system: '',
      new_auxiliary_heating_system_required: false,
      // Roof & Plant
      available_roof_space_PVT: '',
      available_roof_space_PV: '',
      max_roof_load: '',
      space_for_additional_storage_in_plant_room: false,
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      system: Yup.number().required('Required'),
      other_system_name: Yup.string().required('Required'),
      auxiliary_hot_water_system_capacity: yupNumberValidation,
      age_of_the_existing_system: yupIntegerValidation,
      new_auxiliary_heating_system_required: Yup.bool(),
      available_roof_space_PVT: yupNumberValidation,
      available_roof_space_PV: yupNumberValidation,
      max_roof_load: yupNumberValidation,
      space_for_additional_storage_in_plant_room: Yup.bool(),
    }),
    onSubmit: async (values) => {
      const waterSystemValues = {
        system: values.system,
        other_system_name: values.other_system_name,
        auxiliary_hot_water_system_capacity: normalizeNumberWithCommas(
          values.auxiliary_hot_water_system_capacity
        ),
        age_of_the_existing_system: normalizeNumberWithCommas(
          values.age_of_the_existing_system
        ),
        new_auxiliary_heating_system_required: String(
          values.new_auxiliary_heating_system_required
        ),
      }

      const roofAndPlantValues = {
        available_roof_space_PVT: normalizeNumberWithCommas(
          values.available_roof_space_PVT
        ),
        available_roof_space_PV: normalizeNumberWithCommas(
          values.available_roof_space_PV
        ),
        max_roof_load: normalizeNumberWithCommas(values.max_roof_load),
        space_for_additional_storage_in_plant_room: String(
          values.space_for_additional_storage_in_plant_room
        ),
      }

      await submitStep({
        pathname: location.pathname,
        lastStep: newSimulation.last_step,
        addFunction: async () => {
          const response = await Promise.all([
            await addExistingHotWaterSystem(
              token,
              newSimulation.id,
              waterSystemValues
            ),
            await addRoofAndPlant(token, newSimulation.id, roofAndPlantValues),
          ])
          return response
        },
        updateFunction: async () => {
          const response = await Promise.all([
            await updateExistingHotWaterSystem(
              token,
              newSimulation.id,
              waterSystemValues
            ),
            await updateRoofAndPlant(
              token,
              newSimulation.id,
              roofAndPlantValues
            ),
          ])
          return response
        },
        updateNewSimulation,
        setIsStepLoading,
      })
    },
  })

  // Show saved simulation step
  useEffect(() => {
    formik.setValues({
      system: newSimulation.steps.existing_hot_water_system.system,
      other_system_name:
        newSimulation.steps.existing_hot_water_system.other_system_name,
      auxiliary_hot_water_system_capacity:
        newSimulation.steps.existing_hot_water_system
          .auxiliary_hot_water_system_capacity,
      age_of_the_existing_system:
        newSimulation.steps.existing_hot_water_system
          .age_of_the_existing_system,
      new_auxiliary_heating_system_required:
        newSimulation.steps.existing_hot_water_system
          .new_auxiliary_heating_system_required,
      available_roof_space_PVT:
        newSimulation.steps.roof_and_plant.available_roof_space_PVT,
      available_roof_space_PV:
        newSimulation.steps.roof_and_plant.available_roof_space_PV,
      max_roof_load: newSimulation.steps.roof_and_plant.max_roof_load,
      space_for_additional_storage_in_plant_room:
        newSimulation.steps.roof_and_plant
          .space_for_additional_storage_in_plant_room,
    })
  }, [newSimulation])

  // Normalize water systems
  useEffect(() => {
    const getCurrentSystems = async () => {
      const normalizeSystems = waterSystems.map((water_system) => ({
        value: water_system.id,
        label: water_system.system_name,
      }))

      // Reset if profile was changed
      if (formik.values.system) {
        const existProfileModel = normalizeSystems.find(
          (model) => model.value == formik.values.system
        )
        if (
          !existProfileModel ||
          existProfileModel.label !== systemSelected.system_name
        ) {
          formik.setFieldValue('system', '')
          formik.setStatus('reset')
          setTimeout(() => formik.setStatus(''), 150)
        }
      }

      setSystems(normalizeSystems)
    }

    if (token && waterSystems?.length > 0) {
      getCurrentSystems()
    }
  }, [token, waterSystems])

  // Get water system details
  useEffect(() => {
    const getCurrentSystemDetail = async () => {
      const response = await getHotWaterSystemDetails(
        token,
        formik.values.system
      )
      const {
        system_name,
        energy_source,
        CO2_Fuel_txt,
        CO2_Fuel,
        kWH_CF_Fuel_txt,
        kWh_CF_Fuel,
        n_ex_syst,
      } = response

      if (response.error) {
        setSystemSelected({
          system_name: '',
          energy_source: '',
          CO2_Fuel_txt: '',
          CO2_Fuel: '',
          kWH_CF_Fuel_txt: '',
          kWh_CF_Fuel: '',
          n_ex_syst: '',
        })
        formik.setFieldValue('system', '')
        formik.setStatus('reset')
        setTimeout(() => formik.setStatus(''), 150)
        return
      } else {
        setSystemSelected({
          system_name,
          energy_source,
          CO2_Fuel_txt,
          CO2_Fuel,
          kWH_CF_Fuel_txt,
          kWh_CF_Fuel,
          n_ex_syst: normalizePercentToShow(n_ex_syst),
        })
      }
    }

    if (token && formik.values.system && waterSystems.length > 0) {
      getCurrentSystemDetail()
    } else {
      setSystemSelected({
        system_name: '',
        energy_source: '',
        CO2_Fuel_txt: '',
        CO2_Fuel: '',
        kWH_CF_Fuel_txt: '',
        kWh_CF_Fuel: '',
        n_ex_syst: '',
      })
    }
  }, [token, formik.values.system, waterSystems])

  return (
    <SimulationFormikHandler formik={formik}>
      <Helmet>
        <title>Create Simulation: Hot Water System / Roof & Plant</title>
      </Helmet>

      {/* First Container */}
      <CreateSimulationContainer>
        <header className="d-flex align-items-center flex-wrap gap-2">
          <img
            src={FireIcon}
            className="CreateSimulation__Title-Icon"
            alt="Existing hot water system details"
            title="Existing hot water system details"
          />

          <CreateSimulationTitle>
            Existing hot water system details
          </CreateSimulationTitle>
        </header>

        <SearchInput
          label="Existing hot water system details"
          name="system"
          search={systems}
          value={formik.values.system}
          placeholder="Select an existing hot water system"
          onChange={(id) => formik.setFieldValue('system', id)}
          error={formik.errors.system}
          reset={formik.status}
          disabled={systems?.length == 0}
          disabledPlaceholder={
            systems?.length == 0 ? 'No system available' : ''
          }
          buttonLabel="Manage"
          buttonIcon={<ListIcon />}
          buttonOnClick={toggleShowManageSystemModal}
        />

        <Input
          label="Energy source"
          name="energy_source"
          value={systemSelected.energy_source}
          placeholder="Energy source"
          disabled
        />

        <SimulationSwitch label="Show energy source information">
          <Input
            label="CO2 factor text"
            name="CO2_Fuel_txt"
            value={systemSelected.CO2_Fuel_txt}
            placeholder="CO2 factor text"
            disabled
          />

          <Input
            label="CO2 factor"
            name="CO2_Fuel"
            value={systemSelected.CO2_Fuel}
            placeholder="0"
            disabled
          />

          <Input
            label="Energy factor text"
            name="kWH_CF_Fuel_txt"
            value={systemSelected.kWH_CF_Fuel_txt}
            placeholder="Energy factor text"
            disabled
          />

          <Input
            label="Energy factor"
            name="kWH_CF_Fuel"
            value={systemSelected.kWh_CF_Fuel}
            placeholder="0"
            disabled
          />

          <Input
            label="Thermal efficiency / COP"
            name="n_ex_syst"
            value={systemSelected.n_ex_syst && `${systemSelected.n_ex_syst}%`}
            placeholder="0%"
            disabled
          />
        </SimulationSwitch>

        <Input
          label="System name"
          name="other_system_name"
          placeholder="Enter a system name"
          value={formik.values.other_system_name}
          onChange={formik.handleChange}
          error={formik.errors.other_system_name}
        />

        <InputSmall
          topLabel="Existing hot water system capacity"
          rightLabel="kW"
          name="auxiliary_hot_water_system_capacity"
          placeholder="0"
          value={formik.values.auxiliary_hot_water_system_capacity}
          onChange={formik.handleChange}
          error={formik.errors.auxiliary_hot_water_system_capacity}
        />

        <InputSmall
          topLabel="Age of the existing system"
          rightLabel="Years"
          name="age_of_the_existing_system"
          placeholder="0"
          value={formik.values.age_of_the_existing_system}
          onChange={formik.handleChange}
          error={formik.errors.age_of_the_existing_system}
        />

        <Checkboxes
          label="New auxiliary heating system required?"
          options={checkboxOptions}
          optionSelected={formik.values.new_auxiliary_heating_system_required}
          handleSelect={(value) =>
            formik.setFieldValue('new_auxiliary_heating_system_required', value)
          }
          small
        />
      </CreateSimulationContainer>

      {/* Second Container */}
      <CreateSimulationContainer>
        <header className="d-flex align-items-center flex-wrap gap-2">
          <img
            src={RoofIcon}
            className="CreateSimulation__Title-Icon"
            alt="Roof & plant room details"
            title="Roof & plant room details"
          />

          <CreateSimulationTitle>
            Roof & plant room details
          </CreateSimulationTitle>
        </header>

        <InputSmall
          topLabel="Available roof space for PVT thermal panel installation"
          rightLabel="m2"
          name="available_roof_space_PVT"
          placeholder="0"
          value={formik.values.available_roof_space_PVT}
          onChange={formik.handleChange}
          error={formik.errors.available_roof_space_PVT}
        />

        <InputSmall
          topLabel="Available roof space for PV installation"
          rightLabel="m2"
          name="available_roof_space_PV"
          placeholder="0"
          value={formik.values.available_roof_space_PV}
          onChange={formik.handleChange}
          error={formik.errors.available_roof_space_PV}
        />

        <InputSmall
          topLabel="Max roof load"
          rightLabel="kg/m2"
          name="max_roof_load"
          placeholder="0"
          value={formik.values.max_roof_load}
          onChange={formik.handleChange}
          error={formik.errors.max_roof_load}
        />

        <Checkboxes
          label="Space for additional storage in plant room?"
          options={checkboxOptions}
          optionSelected={
            formik.values.space_for_additional_storage_in_plant_room
          }
          small
          handleSelect={(e) =>
            formik.setFieldValue(
              'space_for_additional_storage_in_plant_room',
              e
            )
          }
        />
      </CreateSimulationContainer>
    </SimulationFormikHandler>
  )
}

export default HotWaterSystem
