import {
  createContext,
  useMemo,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
} from 'react'
import {
  Page,
  SectionGoal,
  SectionImage,
  InputFile,
  PageSingle,
} from '../types/generateReport'
import useSectionHandlers from '../hooks/useSectionHandlers'
import { useLocalStorage } from 'usehooks-ts'
import useMatchMedia from '../hooks/useMatchMedia'
import useFooterHandlers from '../hooks/useFooterHandlers'
import usePageHandlers from '../hooks/usePageHandlers'
import { DateFormats } from '../types/index'

export interface ReportContextProps {
  pages: Array<Page>;
  setPages: Dispatch<SetStateAction<Array<Page>>>;
  idSelected: string;
  numbersType: 'dot' | 'comma';
  setNumbersType: (value: 'dot' | 'comma') => void;
  reportLanguage: 'en' | 'du';
  setReportLanguage: (value: 'en' | 'du') => void;
  dateFormat: DateFormats;
  setDateFormat: (value: DateFormats) => void;
  showReport: boolean;
  showPages: boolean;
  updateIdSelected: (id: string) => void;
  pageSelected: PageSingle | null;
  pageSelectedIndexs: {
    pageIndex: number;
    subPageIndex: number;
  };
  handleToggleShowReport: () => void;
  handleToggleShowPages: () => void;
  handleAddPage: () => void;
  handleDeletePage: (pageId: string) => void;
  handleTogglePage: (id: string, subId?: string) => void;
  updatePageTitle: (value: string) => void;
  updatePageImage: (value: string | null) => void;
  updatePageSubPagesCount: (value: number) => void;
  handleToggleSection: (sectionId: string) => void;
  handleUpdateText: (sectionId: string, value: string) => void;
  handleUpdateImage: (sectionId: string, value: SectionImage['value']) => void;
  handleUpdateImageText: (sectionId: string, type: 'text' | 'subtitle' | 'description', value: string) => void
  handleUpdateListInfoText: (
    sectionId: string,
    name: string,
    value: string | number | readonly string[]
  ) => void;
  handleUpdateListInfoFile: (
    sectionId: string,
    name: string,
    value: InputFile['value']
  ) => void;
  handleToggleListInfoShow: (sectionId: string, name: string) => void;
  handleUpdateGoalLabel: (sectionId: string, value: string) => void;
  handleUpdateGoalItemText: (
    sectionId: string,
    goalId: string,
    name: string,
    value: string
  ) => void;
  handleUpdateGoalItemImage: (
    sectionId: string,
    goalId: string,
    name: string,
    value: SectionGoal['value'][number]['image']
  ) => void;
  handleAddGoalItem: (sectionId: string, withImage: boolean) => void;
  handleRemoveGoalItem: (sectionId: string, goalId: string) => void;
  handleToggleGridBoolean: (
    sectionId: string,
    itemId: string,
    name: string
  ) => void;
  handleUpdateGridText: (
    sectionId: string,
    itemId: string,
    name: string,
    value: string
  ) => void;
  handleToggleTableColumn: (sectionId: string, columnId: string) => void;
  handleAddImageGalery: (sectionId: string, value: string) => void;
  handleUpdateImageGalery: (sectionId: string, imageId: string, value: string) => void;
  hadleDeleteImageGalery: (sectionId: string, imageId: string) => void;
  handleUpdateExpirationDate: (sectionId: string, value: string) => void
  handleUpdateFooterImage: (footerId: string, value: string) => void;
}

export const ReportContext = createContext<ReportContextProps | null>(null)

interface Props {
  children: React.ReactNode;
}

export default function ReportContextProvider({ children }: Props) {
  const [pages, setPages] = useLocalStorage<Array<Page>>('pages', [])
  const [idSelected, setIdSelected] = useLocalStorage('idSelected', '')
  const [numbersType, setNumbersType] = useLocalStorage<'dot' | 'comma'>(
    'numbersType',
    'dot'
  )
  const [reportLanguage, setReportLanguage] = useLocalStorage<'en' | 'du'>(
    'reportLanguage',
    'en'
  )
  const [dateFormat, setDateFormat] = useLocalStorage<'YYYY/MM/DD' | 'DD/MM/YYYY' | 'MM/DD/YYYY'>(
    'dateFormat',
    'DD/MM/YYYY'
  )

  const [showReport, setShowReport] = useState(false)
  const [showPages, setShowPages] = useState(false)
  const isMobile = useMatchMedia()


  useEffect(() => {
    if (!isMobile) {
      setShowReport(false)
      setShowPages(false)
    }
  }, [isMobile])


  const pageSelected: PageSingle | null = useMemo(() => {
    if (!idSelected) return null
    let newPage: PageSingle | null = null

    for (const page of pages) {
      if (page.type === 'single' && page.id === idSelected) {
        newPage = page
        break
      } else if (page.type === 'container') {
        for (const subPage of page.subPages) {
          if (subPage.id === idSelected) {
            newPage = subPage
            break
          }
        }
      }
    }

    return newPage
  }, [pages, idSelected])
  // console.log(pages)
  // console.log(pageSelected)

  const pageSelectedIndexs = useMemo(() => {
    let pageIndex = -1
    let subPageIndex = -1
    if (!idSelected) return { pageIndex, subPageIndex }

    for (const [iPage, page] of pages.entries()) {
      if (page.type === 'single' && page.id === pageSelected.id) {
        pageIndex = iPage
        break
      } else if (page.type === 'container') {
        for (const [iSubPage, subPage] of page.subPages.entries()) {
          if (subPage.id === pageSelected.id) {
            pageIndex = iPage
            subPageIndex = iSubPage
            break
          }
        }
      }
    }

    return { pageIndex, subPageIndex }
  }, [pages, idSelected])


  const updateIdSelected = (id: string) => {
    setIdSelected(id)
    setShowPages(false)
  }

  const handleToggleShowReport = () => setShowReport((p) => !p)
  const handleToggleShowPages = () => setShowPages((p) => !p)


  const {
    handleAddPage,
    handleDeletePage,
    handleTogglePage,
    updatePageTitle,
    updatePageImage,
    updatePageSubPagesCount,
  } = usePageHandlers({
    setPages,
    pageSelectedIndexs,
    setIdSelected,
  })

  const {
    handleToggleSection,
    handleUpdateText,
    handleUpdateImage,
    handleUpdateImageText,
    handleUpdateListInfoText,
    handleUpdateListInfoFile,
    handleToggleListInfoShow,
    handleUpdateGoalLabel,
    handleUpdateGoalItemText,
    handleUpdateGoalItemImage,
    handleAddGoalItem,
    handleRemoveGoalItem,
    handleToggleGridBoolean,
    handleUpdateGridText,
    handleToggleTableColumn,
    handleAddImageGalery,
    handleUpdateImageGalery,
    hadleDeleteImageGalery,
    handleUpdateExpirationDate,
  } = useSectionHandlers({ setPages, pageSelectedIndexs, reportLanguage, dateFormat})

  const { handleUpdateFooterImage } = useFooterHandlers({
    setPages,
    pageSelectedIndexs,
  })

  return (
    <ReportContext.Provider
      value={{
        pages,
        setPages,
        idSelected,
        numbersType,
        setNumbersType,
        reportLanguage,
        setReportLanguage,
        dateFormat,
        setDateFormat,
        showReport,
        showPages,
        pageSelected,
        pageSelectedIndexs,
        updateIdSelected,
        handleToggleShowReport,
        handleToggleShowPages,
        handleAddPage,
        handleDeletePage,
        handleTogglePage,
        updatePageTitle,
        updatePageImage,
        handleUpdateImageText,
        updatePageSubPagesCount,
        handleToggleSection,
        handleUpdateText,
        handleUpdateImage,
        handleUpdateListInfoText,
        handleUpdateListInfoFile,
        handleToggleListInfoShow,
        handleUpdateGoalLabel,
        handleUpdateGoalItemText,
        handleUpdateGoalItemImage,
        handleAddGoalItem,
        handleRemoveGoalItem,
        handleToggleGridBoolean,
        handleUpdateGridText,
        handleToggleTableColumn,
        handleAddImageGalery,
        handleUpdateImageGalery,
        hadleDeleteImageGalery,
        handleUpdateFooterImage,
        handleUpdateExpirationDate,
      }}
    >
      {children}
    </ReportContext.Provider>
  )
}
