import { Client } from '../../api/apiTypes'
import { IAdmin, IList, IOrganization, IWork } from '../../iconComponents'
import { FakeLog } from '../../pages/admin/Logs'
import TableItem from './TableItem'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

interface Props {
  data: FakeLog;
  unselectable?: boolean;
  selected?: boolean;
  handleSelectItem?: (data: Client) => void;
  isMobile: boolean;
}

export const TableLogs = ({
  data,
  unselectable,
  selected = false,
  handleSelectItem,
  isMobile,
}: Props) => {
  if (isMobile)
    return (
      <TableItem
        data={data}
        unselectable={unselectable}
        selected={selected}
        handleSelectItem={handleSelectItem}
      >
        <div className="TableItem__Cell">
          <p>
            {data.date
              ? dayjs(data.date, 'YYYY-MM-DDTHH:mm:ss').format(
                'DD/MM/YY | HH:mm'
              )
              : '-'}
          </p>
        </div>
        <div className="TableItem__Cell justify-content-end">
          <p className="TableItem__Pill  text-capitalize text-6">
            {data.type || '-'}
          </p>
        </div>
        <div className="TableItem__Cell big">
          <p className="text-4 fw-medium">{data.action || '-'}</p>
        </div>
        <div className="TableItem__Cell TableItem__Cell">
          <IAdmin className="TableItem__Cell_Icon text-secondary" />
          <p className="text-5 text-secondary">
            {data.user.first_name
              ? `${data.user.first_name} ${data.user.last_name}`
              : '-'}
          </p>
        </div>
        <div className="TableItem__Cell">
          <IOrganization className="TableItem__Cell_Icon text-secondary" />
          <p className="text-5 text-secondary">
            {data.organization.name || '-'}
          </p>
        </div>
        <div className="TableItem__Cell TableItem__Cell--Mb">
          <IList className="TableItem__Cell_Icon text-secondary" />
          <p>{data.simulation_id || '-'}</p>
        </div>
        <div className="TableItem__Cell TableItem__Cell--Mb">
          <IWork className="TableItem__Cell_Icon text-secondary" />
          <p>{data.client.name || '-'}</p>
        </div>
      </TableItem>
    )

  return (
    <TableItem
      data={data}
      unselectable={unselectable}
      selected={selected}
      handleSelectItem={handleSelectItem}
    >
      <div className="TableItem__Cell" />
      <div className="TableItem__Cell">
        <p>
          {data.date
            ? dayjs(data.date, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YY | HH:mm')
            : '-'}
        </p>
      </div>
      <div className="TableItem__Cell">
        <p>{data.action || '-'}</p>
      </div>
      <div
        className="TableItem__Cell"
        title={
          data.user.first_name
            ? `${data.user.first_name} ${data.user.last_name}`
            : null
        }
      >
        <p>
          {data.user.first_name
            ? `${data.user.first_name} ${data.user.last_name}`
            : '-'}
        </p>
      </div>
      <div className="TableItem__Cell">
        <p>{data.simulation_id || '-'}</p>
      </div>
      <div className="TableItem__Cell" title={data.client?.name}>
        <p>{data.client.name || '-'}</p>
      </div>
      <div className="TableItem__Cell" title={data.organization?.name}>
        <p className="text-nowrap">{data.organization.name || '-'}</p>
      </div>
      <div
        className="TableItem__Cell"
        title={
          data.target_user.first_name
            ? `${data.target_user.first_name} ${data.target_user.last_name}`
            : null
        }
      >
        <p className="text-nowrap">
          {data.target_user.first_name
            ? `${data.target_user.first_name} ${data.target_user.last_name}`
            : '-'}
        </p>
      </div>
    </TableItem>
  )
}
