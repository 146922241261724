import classNames from 'classnames'
import { IAngleUp, ITrash } from '../../iconComponents'
import { FocusEvent, KeyboardEvent, useRef, useState } from 'react'
import { deleteReportImageFromGallery, getReportImagesGallery } from '../../api/newReport'
import useAppContext from '../../hooks/useAppContext'
import { URL as URL_BACK } from '../../constants'

type Props = {
  library: Awaited<ReturnType<typeof getReportImagesGallery>>
  isFetching: boolean;
  refetch: () => void;
  onSelect: (value: string) => void
}

export default function ImagesSelector({
  library,
  isFetching,
  refetch,
  onSelect,
}: Props) {
  const { token } = useAppContext()
  const [showList, setShowList] = useState(false)
  const listRef = useRef<HTMLUListElement>(null)

  const handleOpenList = () => {
    if (showList) return setShowList(false)
    setTimeout(() => {
      setShowList(true)
      listRef.current?.focus()
    })
  }

  const handleOpenListOnKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
    if (!(e.key === 'Enter' || e.key === ' ')) return
    handleOpenList()
  }

  const handleBlur = (e: FocusEvent<HTMLUListElement>) => {
    const isChildren = listRef.current?.contains(e.relatedTarget)
    if (!isChildren && !isChildren) setShowList(false)
  }

  const handleSelect = (value: string) => {
    onSelect(value)
    setShowList(false)
  }

  return (
    <div
      className={classNames('ImageLibraryCard_Dropdown_Containder', {
        Active: showList,
      })}
    >
      <button
        className="ImageLibraryCard_Dropdown_Header"
        onMouseDown={handleOpenList}
        onKeyDown={handleOpenListOnKeyDown}
      >
        <p className="ImageLibraryCard_Dropdown_Header_Text">
            + Choose from library
        </p>
        <IAngleUp
          width={16}
          height={16}
          className="ImageLibraryCard_Dropdown_Header_Arrow"
        />
      </button>

      <div className="ImageLibraryCard_Dropdown_List_Container">
        <ul
          className={classNames('ImageLibraryCard_Dropdown_List_Content')}
          role="listbox"
          tabIndex={0}
          onBlur={handleBlur}
          ref={listRef}
        >
          {isFetching
            ? 'Loading...'
            : !library.ok
              ? 'It was not possible to access the images'
              : library.data.data.length === 0
                ? 'No images to show'
                : library.data.data.map((image) => (
                  <li
                    key={image.id}
                    className="ImageLibraryCard_Dropdown_List_Item"
                    onClick={() => handleSelect(URL_BACK + image.image)}
                  >
                    <img
                      className="ImageLibraryCard_Dropdown_List_Item_Image"
                      src={URL_BACK + image.image}
                    />
                    <p className='text-5 text-start flex-grow-1'>
                      {image.name}
                    </p>
                    <ITrash
                      width={32}
                      height={32}
                      className="ImageLibraryCard_Dropdown_List_Item_Icon text-red"
                      onClick={async (e) => {
                        e.stopPropagation()
                        await deleteReportImageFromGallery(token, {
                          image_id: image.id,
                        })
                        await refetch()
                      }}
                    />
                  </li>
                ))}
        </ul>
      </div>
    </div>
  )
}
