const Loader = () => {
  return (
    <div className="d-flex justify-content-center align-items-center py-5">
      <div className="spinner">
        {/* <div className="Loader__Center" /> */}
        <div className="spinnerContainer active common">
          <div className="spinner-layer layer-1 common">
            <div className="circle-clipper left common">
              <div
                className="circle common"
                style={{ borderWidth: '10px' }}
              />
            </div>
            <div className="gap-patch common">
              <div
                className="circle common"
                style={{ borderWidth: '10px' }}
              />
            </div>
            <div className="circle-clipper right common">
              <div
                className="circle common"
                style={{ borderWidth: '10px' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loader
