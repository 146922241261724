import { useEffect } from 'react'
import { useLocation, Outlet, useNavigate } from 'react-router'
import useAppContext from '../hooks/useAppContext'
import SolarusBanner from '../components/SolarusBanner'

const AuthLayout = () => {
  const { token, firstCharge, user } = useAppContext()
  const location = useLocation()
  const navigate = useNavigate()

  // If user is not logged in, redirect to login page
  useEffect(() => {
    if(!firstCharge && !token) {
      navigate('/login')
    }
  }, [token, firstCharge, user])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <main className="Auth">
      <section className="Auth__Logo">
        <SolarusBanner/>
      </section>
      <Outlet/>
    </main>
  )
}

export default AuthLayout