import { ChangeEventHandler, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { getSimulations } from '../../../api/simulations/general-simulation'
import Input from '../../../components/Input'
import TableFilters from '../../../components/TableFilters'
import TableFiltersMobile from '../../../components/TableFiltersMobile'
import Table from '../../../components/tables/Table'
import { TableSimulations } from '../../../components/tables/TableSimulations'
import TableTitle from '../../../components/TableTitle'
import useAppContext from '../../../hooks/useAppContext'
import useTable from '../../../hooks/useTable'
import { FiltersGroup, FiltersSelected, TableHeaders } from '../../../types'
import useOrderby from '../../../hooks/useOrderby'
import { simulationStatus } from '../../../constants'
import { ISearch } from '../../../iconComponents'

interface Props {
  isMobile: boolean;
}

const SelectSimulations = ({ isMobile }: Props) => {
  const {
    token,
    userToDelete,
    simulationsToTransfer,
    setsimulationsToTransfer,
  } = useAppContext()

  const page = 1
  const [search, setSearch] = useState('')
  const { orderBy, sort, handleSort } = useOrderby('created_at', false)
  const [filtersSelected, setFiltersSelected] = useState<FiltersSelected[]>([
    // {
    //   label: 'City',
    //   search: '',
    //   defaultOption: {
    //     label: 'All',
    //     value: '',
    //   },
    //   option: {
    //     label: 'All',
    //     value: '',
    //   },
    // },
    {
      label: 'Status',
      defaultOption: {
        label: 'All',
        value: '',
      },
      option: {
        label: 'All',
        value: '',
      },
    },
  ])
  const { data: simulations, isFetching } = useQuery(
    [
      'getSimulations - user',
      search,
      filtersSelected,
      orderBy,
      userToDelete[0].id,
    ],
    () =>
      getSimulations(token, {
        page,
        per_page: 0,
        search,
        order_by: orderBy,
        status:
          typeof filtersSelected[0].option.value === 'string'
            ? filtersSelected[0].option.value
            : '',
        user_id: userToDelete[0].id,
      }),
    {
      initialData: {
        page: 1,
        total_items: 0,
        total_pages: 1,
        data: [],
      },
      enabled: token !== '',
    }
  )
  const { selectedItems, handleSelectItem, handleSelectAll, change } = useTable({
    tableData: simulations.data,
    initialValue: simulationsToTransfer.map((sim) => sim.id),
  })

  useEffect(() => {
    if (change < 2) return 
    setsimulationsToTransfer(selectedItems)
  }, [selectedItems])

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearch(event.target.value)
  }

  const filtersGroup: FiltersGroup[] = [
    {
      label: 'Status',
      options: Object.keys(simulationStatus).map((status) => ({
        label: simulationStatus[status],
        value: status,
      })),
    },
  ]

  const tableHeaders: TableHeaders[] = [
    {
      id: 0,
      label: 'Date',
      value: 'created_at',
    },
    {
      id: 1,
      label: 'Last update',
      value: 'updated_at',
    },
    {
      id: 2,
      label: 'Location',
      value: '',
    },
    {
      id: 3,
      label: 'Client',
      value: 'client',
    },
    {
      id: 4,
      label: 'Project name',
      value: 'name',
    },
    {
      id: 5,
      label: 'Owner',
      value: '',
    },
    {
      id: 6,
      label: 'Creator',
      value: 'creator',
    },
    {
      id: 7,
      label: 'Status',
      value: 'status',
    },
    {
      id: 8,
      label: 'Id',
      value: 'id_number',
    },
  ]

  return (
    <>
      <p className="text-5 fw-medium text-secondary_black d-none d-md-block">
        Search simulations from this user
      </p>
      <div className="TransferSimulation__Content_Filters_Container">
        <div className="flex-grow-1">
          <Input
            name="Search"
            placeholder="Search"
            value={search}
            onChange={handleSearch}
            icon={<ISearch className="Input__Icon" />}
          />
        </div>

        <div className="Dashboard__Filters_Container_Mobile d-flex justify-between gap-3 d-md-none pr-1">
          <TableFiltersMobile
            filtersSelected={filtersSelected}
            setFiltersSelected={setFiltersSelected}
            filtersGroup={filtersGroup}
            headers={tableHeaders}
            sort={sort}
            handleSort={handleSort}
          />

          <TableTitle
            totalItems={simulations.data.length}
            itemsSelected={selectedItems.length}
            mobile
          />
        </div>

        <TableFilters
          filtersSelected={filtersSelected}
          setFiltersSelected={setFiltersSelected}
          filtersGroup={filtersGroup}
        />
        <TableTitle
          totalItems={simulations.data.length}
          itemsSelected={selectedItems.length}
        />
      </div>

      <Table
        type={'Simulations_Extended'}
        handleSelectAll={handleSelectAll}
        selectedItemsLength={selectedItems.length}
        headers={tableHeaders}
        dataLength={simulations.data.length}
        loading={isFetching || token === ''}
        sort={sort}
        handleSort={handleSort}
      >
        {simulations.data.map((item) => (
          <TableSimulations
            key={item.id}
            data={item}
            selected={selectedItems.some(
              (itemSelect) => itemSelect?.id === item.id
            )}
            handleSelectItem={handleSelectItem}
            isMobile={isMobile}
          />
        ))}
      </Table>
    </>
  )
}

export default SelectSimulations
