import { useState } from 'react'
import Modal from './Modal'
import { IAlert } from '../../iconComponents'
import useAppContext from '../../hooks/useAppContext'
import LoadingDots from '../LoadingDots'
import CheckModal from './CheckModal'
import { Office } from '../../api/apiTypes'
import { deleteOffice } from '../../api/organizations'

interface Props {
  closeModal: () => void;
  officeInfo: Office;
  refetch: () => void;
}

const DeleteOfficeModal = ({ closeModal, officeInfo, refetch }: Props) => {
  const { token } = useAppContext()
  const [step, setStep] = useState(1)
  const [buttonModalIsLoading, setButtonModalIsLoading] = useState(false)
  const [serverError, setServerError] = useState(false)

  const handleDeleteOffice = async () => {
    if (buttonModalIsLoading) return
    setButtonModalIsLoading(true)

    const response = await deleteOffice(token, officeInfo.id)

    if (response.error) {
      setServerError(response.error)
    }
    else {
      setServerError(false)
      setStep((prev) => prev + 1)
      refetch()
    }

    setButtonModalIsLoading(false)
  }

  if (step === 1)
    return (
      <Modal title="Delete Office" closeModal={closeModal}>
        <IAlert
          width={60}
          height={60}
          className="text-yellow d-block mt-4 mx-auto mb-3"
        />

        <p className="text-center fw-medium my-4">
          Are you sure you want to delete {officeInfo.name}?
        </p>

        {serverError && <p className="text-red text-center pb-3">{ serverError }</p>}

        <div className="d-flex align-items-center gap-2 mb-3">
          <button
            className="button_transparent_red button_defult w-100 text-4 gap-0"
            onClick={handleDeleteOffice}
            disabled={buttonModalIsLoading}
          >
            {buttonModalIsLoading ? (
              <>
                <span>Deleting</span>
                <LoadingDots className="enter-done" />
              </>
            ) : (
              <span className='text-red'>Yes, delete</span>
            )}
          </button>
          <button
            className="button_transparent_blue w-100 text-4"
            onClick={closeModal}
          >
            Do not delete
          </button>
        </div>
      </Modal>
    )

  return (
    <CheckModal
      closeModal={closeModal}
      title="Delete Office"
      message="¡Office deleted successfully!"
    />
  )
}

export default DeleteOfficeModal
