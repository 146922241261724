import { ReactNode } from 'react'
import CreateSimulationContainer from '../containers/CreateSimulationContainer'
import WarningIcon from '../assets/icons/warning-black.svg'
import CreateSimulationTitle from './CreateSimulationTitle'

interface Props {
  title: string;
  message: ReactNode;
}

const CreateSimulationDisabled = ({ title, message }: Props) => {
  return (
    <CreateSimulationContainer>
      <header className="d-flex align-items-center flex-wrap gap-2">
        <img
          src={WarningIcon}
          alt={ title }
          title={ title }
        />

        <CreateSimulationTitle>{ title }</CreateSimulationTitle>
      </header>

      <h2 className='text-4 fw-normal'>{ message }</h2>
    </CreateSimulationContainer>
  )
}

export default CreateSimulationDisabled