import { Dispatch, SetStateAction } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
} from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { TableHeaders } from '../../types'
import LoadingDots from '../LoadingDots'

interface Props {
  type: string;
  firstHeaderEmpty?: boolean;
  handleSelectAll?: () => void;
  selectedItemsLength?: number;
  headers: TableHeaders[];
  dataLength: number;
  children: JSX.Element[];
  loading: boolean;
  isEmptyMenssage?: string;
  sort: {
    value: string;
    bool: boolean;
  };
  handleSort: (value: string) => void;
  page?: number;
  setPage?: Dispatch<SetStateAction<number>>;
  perPage?: number;
  totalPages?: number;
  totalItems?: number;
}

const Table = ({
  type,
  firstHeaderEmpty = false,
  handleSelectAll,
  selectedItemsLength,
  headers,
  dataLength = 0,
  children,
  loading,
  isEmptyMenssage = 'There is no information to display yet.',
  sort,
  handleSort,
  page = 1,
  setPage,
  perPage = 12,
  totalPages = 1,
  totalItems = 0,
}: Props) => {
  return (
    <>
      <section className={classNames('Table', type)}>
        <TableHeader
          firstHeaderEmpty={firstHeaderEmpty}
          handleSelectAll={handleSelectAll}
          selectedItemsLength={selectedItemsLength}
          dataLength={dataLength}
          headers={headers}
          sort={sort}
          handleSort={handleSort}
        />

        {dataLength !== 0 ? (
          <div className="Table__Content">{children}</div>
        ) : loading ? (
          <div className="Table__Content_Empty text-5">
            Loading <LoadingDots className="enter-done" />
          </div>
        ) : (
          <div className="Table__Content_Empty text-5">{isEmptyMenssage}</div>
        )}
      </section>
      <TablePages
        dataLength={dataLength}
        page={page}
        setPage={setPage}
        perPage={perPage}
        totalPages={totalPages}
        totalItems={totalItems}
      />
    </>
  )
}

export default Table

interface TableHeaderProps {
  firstHeaderEmpty: boolean;
  handleSelectAll: () => void;
  selectedItemsLength: number;
  dataLength: number;
  headers: TableHeaders[];
  sort: {
    value: string;
    bool: boolean;
  };
  handleSort: (value: string) => void;
}

const TableHeader = ({
  firstHeaderEmpty,
  handleSelectAll,
  selectedItemsLength,
  dataLength,
  headers,
  sort,
  handleSort,
}: TableHeaderProps) => {
  return (
    <div className={'Table__Header d-none d-md-grid'}>
      {firstHeaderEmpty || handleSelectAll ? (
        <></>
      ) : (
        <div className="Table__Header_Item text-4" />
      )}
      {handleSelectAll ? (
        <div className="Table__Header_Item Center text-4">
          <div
            className={classNames('Checkbox', {
              Selected: dataLength !== 0 && selectedItemsLength === dataLength,
            })}
            onClick={handleSelectAll}
          />
        </div>
      ) : (
        <></>
      )}
      {headers.map((header) => {
        return (
          <button
            key={header.id}
            className="Table__Header_Item"
            onClick={() => handleSort(header.value)}
            disabled={header.value === ''}
          >
            {header.icon ?? <></>}
            <p className="Table__Header_Title text-4 fw-medium">
              {header.label}
            </p>
            {header.value !== '' ? (
              sort.value === header.value &&
              (sort.bool ? (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className="Table__Header_Icon text-4"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faAngleUp}
                  className="Table__Header_Icon text-4"
                />
              ))
            ) : (
              <></>
            )}
          </button>
        )
      })}
    </div>
  )
}

interface TablePagesProps {
  dataLength: number;
  page?: number;
  setPage?: Dispatch<SetStateAction<number>>;
  perPage?: number;
  totalPages?: number;
  totalItems?: number;
}

function TablePages({
  dataLength,
  page,
  setPage,
  perPage,
  totalPages,
  totalItems,
}: TablePagesProps) {
  const handlePrevPage = () => {
    if (page < 2) return
    setPage((prev) => prev - 1)
  }

  const handleNextPage = () => {
    if (page >= totalPages) return
    setPage((prev) => prev + 1)
  }

  if (totalItems !== 0)
    return (
      <div className="Table__Pages">
        <button
          className="Table__Pages_Arrow"
          onClick={handlePrevPage}
          disabled={page < 2}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <p className="Table__Pages_Text">
          <span>
            {dataLength !== 0
              ? `${(page - 1) * perPage + 1}-${
                (page - 1) * perPage + dataLength
              }`
              : '0'}
          </span>
          <span>{' of '}</span>
          <span>{totalItems}</span>
        </p>
        <button
          className="Table__Pages_Arrow"
          onClick={handleNextPage}
          disabled={page >= totalPages}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    )
  else return <></>
}
