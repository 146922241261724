import { SVGProps } from 'react'
const SvgIMove = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.85 8H20.15C20.3754 8 20.5916 7.89464 20.751 7.70711C20.9104 7.51957 21 7.26522 21 7C21 6.73478 20.9104 6.48043 20.751 6.29289C20.5916 6.10536 20.3754 6 20.15 6H4.85C4.62457 6 4.40837 6.10536 4.24896 6.29289C4.08955 6.48043 4 6.73478 4 7C4 7.26522 4.08955 7.51957 4.24896 7.70711C4.40837 7.89464 4.62457 8 4.85 8ZM20.15 16H4.85C4.62457 16 4.40837 16.1054 4.24896 16.2929C4.08955 16.4804 4 16.7348 4 17C4 17.2652 4.08955 17.5196 4.24896 17.7071C4.40837 17.8946 4.62457 18 4.85 18H20.15C20.3754 18 20.5916 17.8946 20.751 17.7071C20.9104 17.5196 21 17.2652 21 17C21 16.7348 20.9104 16.4804 20.751 16.2929C20.5916 16.1054 20.3754 16 20.15 16ZM20.15 11H4.85C4.62457 11 4.40837 11.1054 4.24896 11.2929C4.08955 11.4804 4 11.7348 4 12C4 12.2652 4.08955 12.5196 4.24896 12.7071C4.40837 12.8946 4.62457 13 4.85 13H20.15C20.3754 13 20.5916 12.8946 20.751 12.7071C20.9104 12.5196 21 12.2652 21 12C21 11.7348 20.9104 11.4804 20.751 11.2929C20.5916 11.1054 20.3754 11 20.15 11Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgIMove
