import { Page } from '../../types/generateReport'
import { deleteQuery, getQuery, patchQuery, postQuery } from '../apiFunctions'
import { FlowDecisions, Simulation } from '../apiTypes'

interface GetSimulations {
  page: number;
  total_items: number;
  total_pages: number;
  data?: Simulation[];
  error?: string;
}

export const getSimulations = async (
  token: string,
  params?: {
    page?: number;
    per_page?: number;
    status?: string;
    search?: string;
    order_by?: string;
    only_mine?: boolean;
    user_id?: number;
  }
) => {
  if (params)
    params = Object.entries(params).reduce(
      (a, [k, v]) => ((v !== '' && v !== undefined && v !== null) ? ((a[k] = v), a) : a),
      {}
    )

  return await getQuery<GetSimulations>({
    path: '/simulations/',
    token,
    params,
    callback: (data: GetSimulations) =>
      data.data instanceof Array
        ? data
        : { ...data, page: 1, total_items: 0, total_pages: 1, data: [] },
  })
}

interface SimulationDetails {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
  status: string;
  step_status: string;
  last_step_done: string;
  id_number: string;
  convert_ratio: string | null;
  currency_selected: {
    id: number;
    name: string;
    code: string;
  };
  organization: number;
  region: {
    id: number;
    oraganization: number;
  };
  office: {
    id: number;
  };
  creator: {
    id: number;
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    date_joined: Date;
    is_register_completed: boolean;
  };
  owner: {
    id: number;
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    date_joined: Date;
    is_register_completed: boolean;
  };
  client: {
    id: number;
    creator: string;
    last_updater: string;
    country: {
      id: number;
      currencies: {
        id: number;
        name: string;
        code: string;
      }[];
      name: string;
      name_ascii: string;
      slug: string;
      geoname_id: number;
      alternate_names: string;
      code2: string;
      code3: string;
      continent: string;
      tld: string;
      phone: string;
      name_en: string;
      is_active: boolean;
    };
    simulation_quantity: number;
    name: string;
    address: string;
    email: string;
    phone: string;
    created_at: Date;
    updated_at: Date;
    is_active: boolean;
  };
  location: {
    id: number;
    city: string;
    address: string;
    postal_code: string;
    weather_file: {
      id: number;
      file_name: string;
      file_path: string;
    };
    simulation: number;
    country: {
      id: number;
      currencies: {
        id: number;
        name: string;
      }[];
      name: string;
      name_ascii: string;
      slug: string;
      geoname_id: number;
      alternate_names: string;
      code2: string;
      code3: string;
      continent: string;
      tld: string;
      phone: string;
      name_en: string;
      is_active: boolean;
    };
    state: number;
  };
  demand_detail: {
    id: number;
    daily_profile: {
      id: number;
      name: string;
      is_admin_creation;
      boolean;
      f_h1: number;
      f_h2: number;
      f_h3: number;
      f_h4: number;
      f_h5: number;
      f_h6: number;
      f_h7: number;
      f_h8: number;
      f_h9: number;
      f_h10: number;
      f_h11: number;
      f_h12: number;
      f_h13: number;
      f_h14: number;
      f_h15: number;
      f_h16: number;
      f_h17: number;
      f_h18: number;
      f_h19: number;
      f_h20: number;
      f_h21: number;
      f_h22: number;
      f_h23: number;
      f_h24: number;
      is_showed: boolean;
      deleted: boolean;
      owner: number;
    };
    weekly_profile: {
      id: number;
      f_mon: number;
      f_tue: number;
      f_wed: number;
      f_thu: number;
      f_fri: number;
      f_sat: number;
      f_sun: number;
    };
    monthly_profile: { [key: string]: number };
    sector: string;
    maximum_hot_water_demand: number;
    desired_hot_water_temperature: number;
    is_recirculation: boolean;
    water_temperature: number;
    simulation: number;
  };
  electricity_demand: {
    id: number;
    supply_choices: string;
    annual_demand: number;
    fel_jan: number;
    fel_feb: number;
    fel_mar: number;
    fel_apr: number;
    fel_may: number;
    fel_jun: number;
    fel_jul: number;
    fel_aug: number;
    fel_sep: number;
    fel_oct: number;
    fel_nov: number;
    fel_dec: number;
    simulation: number;
  };
  hot_water_system: {
    id: number;
    system: {
      id: number;
      system_name: string;
      energy_source: string;
      is_admin_creation: boolean;
      CO2_Fuel_txt: string;
      CO2_Fuel: number;
      kWH_CF_Fuel_txt: string;
      kWh_CF_Fuel: number;
      n_ex_syst: number;
      deleted: boolean;
      is_showed: boolean;
      owner: number;
    };
    other_system_name: string;
    auxiliary_hot_water_system_capacity: number;
    age_of_the_existing_system: number;
    new_auxiliary_heating_system_required: boolean;
    simulation: number;
  };
  roof_and_plant_detail: {
    id: number;
    available_roof_space_PVT: number;
    available_roof_space_PV: number;
    max_roof_load: number;
    space_for_additional_storage_in_plant_room: boolean;
    simulation: number;
  };
  environmental_indicators: {
    id: number;
    CO2_Fuel_txt: string;
    kWH_CF_Fuel_txt: string;
    n_ex_syst: number;
    electricity: number;
    CO2_saved_per_year_by_tree: number;
    simulation: number;
  };
  solar_thermal_field: {
    id: number;
    simulation: number;
    antifreeze_composition: number;
    include_thermal_system_loss: boolean;
    panels_parameters: {
      id: number;
      name: string;
      model_panel_parameters: {
        id: number;
        is_admin_creation: boolean;
        model_name: string;
        panel_type: string;
        power_thermal_capacity: number;
        gross_area: number;
        aperture_area: number;
        collector_height: number;
        collector_width: number;
        optical_efficiency: number;
        kd: number;
        c1: number;
        c2: number;
        c3: number;
        c4: number;
        c5: number;
        c6: number;
        peak_eletricity_capacity: number;
        panel_efficiency_capacity: number;
        maximum_power_point_voltage: number;
        maximum_power_point_current: number;
        open_circuit_voltage: number;
        short_circuit_current: number;
        temperature_coefficient_max_power_point: number;
        temperature_coefficient_open_circuit_voltage_yVoc: number;
        temperature_coefficient_open_circuit_voltage_ylsc: number;
        nominal_operating_cell_temperature: number;
        number_of_cells: number;
        deleted: boolean;
        is_showed: boolean;
        owner: number;
      };
      general_panel_parameters: {
        id: number;
        number_of_panels: number;
        type_of_roof: string;
        panel_orientation: number;
        panel_tilt: number;
        number_of_panels_per_row: number;
        distance_between_rows: number;
        lenght_of_piping_between_solar_field_and_pump: number;
      };
      positional_fields: { [key: string]: number };
    }[];
  };
  solar_storage: {
    id: number;
    total_solar_storage_volume: number;
    total_number_buffers: number;
    immersed_electric_heater_in_the_buffer: boolean;
    name_of_the_electrical_heater: string | null;
    capacity_of_the_electrical_heater: number | null;
    simulation: number;
  };
  new_auxiliary_heating_systems: {
    id: number;
    name_1: string;
    name_2: string;
    capacity_1: number;
    capacity_2: number;
    size_of_the_buffer_connected_to_auxiliary_heater: number;
    simulation: number;
    heating_system: number;
    solar_buffer_system: number;
  }[];
  pv_field: {
    id: number;
    model: {
      id: number;
      name: string;
      model: string;
      panel_type: string;
      is_admin_creation: boolean;
      deleted: boolean;
      ag: number;
      h_col: number;
      w_col: number;
      ppvt: number;
      ref_eff: number;
      vmpp: number;
      impp: number;
      voc: number;
      isc: number;
      gamma_Pmpp: number;
      gamma_Voc: number;
      gamma_Isc: number;
      t_NOCT: number;
      n_cell_series: number;
      is_showed: boolean;
      owner: number;
    };
    name: string;
    number_of_panels: number;
    type_of_roof: string;
    panel_orientation: number;
    panel_tilt: number;
    number_of_panels_per_row: number;
    distance_between_rows: number;
    simulation: number;
  }[];
  inverter: {
    id: number;
    include_pv_system_loss: boolean;
    DC_AC_ratio_PV: number;
    DC_AC_ratio_PVT: number;
    simulation: number;
  };
  system_performance_aging: {
    id: number;
    PV_electrical_out_degradation: number;
    solar_thermal_out_degradation: number;
    auxiliary_heating_degradation: number;
    simulation: number;
  };
  energy_prices: {
    id: number;
    average_heat_price: number;
    average_electricity_price: number;
    heat_escalation_price: number;
    electricity_price_escalation_rate: number;
    electricity_price_rise_scenario: number;
    simulation: number;
  };
  economic_parameters: {
    id: number;
    inflation_rate: number;
    discount_rate: number;
    interest_rate: number;
    O_and_M_escalation_rates: number;
    project_economic_lifetime: number;
    simulation: number;
  };
  subsidy_and_tax_benefits: {
    id: number;
    is_operational_subsidy_applicable: boolean;
    num_of_years_for_operational_subsidy: number;
    operational_subsidy_for_heat: number;
    operational_subsidy_for_electricity: number;
    is_tax_subsidy_applicable: boolean;
    tax_subsidy: number;
    number_of_years: number;
    year_1: number;
    year_2: number;
    year_3: number;
    year_4: number;
    year_5: number;
    year_6: number;
    year_7: number;
    year_8: number;
    year_9: number;
    year_10: number;
    year_11: number;
    year_12: number;
    year_13: number;
    year_14: number;
    year_15: number;
    year_16: number;
    year_17: number;
    year_18: number;
    year_19: number;
    year_20: number;
    year_21: number;
    year_22: number;
    year_23: number;
    year_24: number;
    year_25: number;
    is_funding_or_grant_applicable: boolean;
    description_of_funding_or_grant: string;
    funding_or_grant_amount: number;
    co2_tax: number;
    co2_tax_escalation_rate: number;
    simulation: number;
  };
  system_costing: { [key: string]: number };
  uncertainty: { [key: string]: number };
  PPA_financing_models: {
    id: number;
    simulation: number;
    period_1: {
      id: number;
      number_of_years: number;
      electricity_price_per_kwh: number;
      heat_price_per_kwh: number;
      heat_escalation_rate: number;
      electricity_escalation_rate: number;
    };
    period_2: {
      id: number;
      number_of_years: number;
      electricity_price_per_kwh: number;
      heat_price_per_kwh: number;
      heat_escalation_rate: number;
      electricity_escalation_rate: number;
    };
    period_3: {
      id: number;
      number_of_years: number;
      electricity_price_per_kwh: number;
      heat_price_per_kwh: number;
      heat_escalation_rate: number;
      electricity_escalation_rate: number;
    };
  };
  results: {
    error_message: string;
    trnsys_error: string;
  },
  report_information: Array<Page> | null
}

export const getSimulationDetails = async (
  token: string,
  simulation_id: number,
  params = {}
) => {
  return getQuery<SimulationDetails>({
    path: `/simulations/${simulation_id}/`,
    token,
    params,
    callback: (data) => data.data,
  })
}

export const getCSV = async (token: string, simulation_id: number) => {
  return getQuery<any>({
    path: `/simulations/string-csv/${simulation_id}/`,
    token,
    callback: (data) => data.data,
  })
}

export const createSimulation = async (
  token: string,
  body: {
    name: string;
    organization?: string;
    region?: string;
    office: string;
    client: string;
    currency_selected: string;
  }
) => {
  return postQuery<any>({
    path: '/simulations/',
    token,
    body,
  })
}

export const updateSimulation = async (token: string, body: any) => {
  return patchQuery<any>({
    path: '/simulations/',
    token,
    body,
  })
}

interface TransferSimulation {
  message?: string;
  error?: string;
  e?: string;
}

export const transferSimulation = async (
  token: string,
  body: { simulation_ids: number[]; new_owner_id: number }
) => {
  return await postQuery<TransferSimulation>({
    path: '/simulations/transfer/',
    token,
    body,
  })
}

interface DuplicateSimulation {
  error?: string;
  e?: string;
}

export const duplicateSimulation = async (
  token: string,
  simulation_id: number
) => {
  return await postQuery<DuplicateSimulation>({
    path: `/simulations/clone/${simulation_id}/`,
    token,
  })
}

interface DeleteSimulation {
  message?: string;
  error?: string;
  e?: string;
}

export const deleteSimulation = async (
  token: string,
  body: { simulation_id: number }
) => {
  return await deleteQuery<DeleteSimulation>({
    path: '/simulations/',
    token,
    body,
  })
}

// Location Details
export const getLocationDetails = async (
  token: string,
  simulation_id: number
) => {
  return getQuery<any>({
    path: `/simulations/location/${simulation_id}/`,
    token,
    callback: (data) => data.data,
  })
}

export const addLocationDetails = async (
  token: string,
  simulation_id: number,
  body: any
) => {
  return postQuery<any>({
    path: `/simulations/location/${simulation_id}/`,
    token,
    body,
  })
}

export const updateLocationDetails = async (
  token: string,
  simulation_id: number,
  body: any
) => {
  return patchQuery<any>({
    path: `/simulations/location/${simulation_id}/`,
    token,
    body,
  })
}

// Flow Decisions
export const getFlowDecisions = async (
  token: string,
  simulation_id: number,
) => {
  return getQuery<FlowDecisions>({
    path: `/simulations/flow-decisions/${simulation_id}/`,
    token,
    callback: (data) => data.data,
  })
}

// Simulation Summary
interface SimulationSummary {
  error?: string;
  PVT_1_electrical_capacity: number;
  PVT_1_name: string;
  PVT_1_quantity: number;
  PVT_1_surface_area: number;
  PVT_1_thermal_capacity: number;
  PVT_2_name: string;
  PVT_2_quantity: number;
  PVT_2_surface_area: number;
  PVT_2_thermal_capacity: number;
  PV_field_1_electrical_capacity: number;
  PV_field_1_name: string;
  PV_field_1_quantity: number;
  PV_field_1_surface_area: number;
  PV_field_2_electrical_capacity: number;
  PV_field_2_name: string;
  PV_field_2_quantity: number;
  PV_field_2_surface_area: number;
  aux_buffer_capacity: number;
  aux_heater_1_capacity: number;
  aux_heater_1_name: string;
  aux_heater_2_capacity: number;
  aux_heater_2_name: string;
  elec_heater_capacity: number;
  elec_heater_capacity_name: string;
  net_system_cost: number;
  number_of_buffers: number;
  subsidy: number;
  system_cost: number;
  total_solar_buffer_capacity: number;
}
export const getSimulationSummary = async (
  token: string,
  simulation_id: number,
) => {
  return getQuery<SimulationSummary>({
    path: `/simulations/simulation-summary/${simulation_id}/`,
    token,
    callback: (data) => data.data,
  })
}

// Run simulation
export const runSimulation = async (token: string, simulation_id: number) => {
  return postQuery<any>({
    path: `/simulations/run-simulation/${simulation_id}/`,
    token,
  })
}

interface ReportData {
  error?: string;
  solution_details: {
    PVT_1_name:                     string;
    PVT_1_quantity:                 number;
    PVT_1_surface_area:             number;
    PVT_1_thermal_capacity:         number;
    PVT_1_electrical_capacity:      number;
    PVT_2_name:                     string;
    PVT_2_quantity:                 number;
    PVT_2_surface_area:             number;
    PVT_2_thermal_capacity:         number;
    PVT_2_electrical_capacity:      number;
    PV_field_1_name:                string;
    PV_field_1_quantity:            number;
    PV_field_1_surface_area:        number;
    PV_field_1_electrical_capacity: number;
    PV_field_2_name:                string;
    PV_field_2_quantity:            number;
    PV_field_2_surface_area:        number;
    PV_field_2_electrical_capacity: number;
    total_solar_buffer_capacity:    number;
    number_of_buffers:              number;
    elec_heater_capacity_name:      string;
    elec_heater_capacity:           number;
    aux_heater_1_name:              string;
    aux_heater_1_capacity:          number;
    aux_heater_2_name:              string;
    aux_heater_2_capacity:          number;
    aux_buffer_capacity:            number;
    system_cost:                    number;
    subsidy:                        number;
    net_system_cost:                number;
  };
  energy_performance: {
    id:                                        number;
    total_heat_demand:                         number;
    total_heat_supplied:                       number;
    heat_produced_first_PVT:                   null;
    thermal_efficiency_first_PVT:              null;
    heat_produced_second_PVT:                  null;
    thermal_efficiency_second_PVT:             null;
    heat_supplied_by_existing_hot_water:       null;
    existing_hot_water_efficiency:             null;
    heat_supplied_by_first_new_hot_water:      null;
    first_new_hot_water_cop:                   null;
    heat_supplied_by_second_new_hot_water:     null;
    second_new_hot_water_cop:                  null;
    solar_fraction:                            number;
    renewable_fraction:                        number;
    total_ac_electricity:                      null;
    total_renewable_electricity:               null;
    dc_electricity_first_PVT:                  null;
    first_PVT_electrical_efficiency:           null;
    dc_electricity_second_PVT:                 null;
    second_PVT_electrical_efficiency:          null;
    dc_electricity_first_PV:                   null;
    first_PV_electrical_efficiency:            null;
    dc_electricity_second_PV:                  null;
    second_PV_electrical_efficiency:           null;
    electricity_consumed_solar_pumps:          number;
    electricity_consumed_existing_hot_water:   null;
    electricity_consumed_first_new_hot_water:  null;
    electricity_consumed_second_new_hot_water: null;
    result:                                    number;
  };
  financial_indicators: {
    id:                      number;
    energy_savings:          number;
    payback_period:          number;
    internal_rate_of_return: number;
    roi:                     number;
    total_savings_life_span: number;
    tco:                     number;
    system_cost:             number;
    investment_subsidy:      number;
    net_system_cost:         number;
    net_present_value:       number;
    result:                  number;
    subsidy:                 number;
  };
  financial_ppa: {
    id:                              number;
    total_energy_bill:               number;
    current_heat_price:              number;
    current_electricity_price:       number;
    current_energy_price_escalation: number;
    ppa_heat_price:                  number;
    ppa_electricity_price:           number;
    ppa_energy_price_escalation:     number;
    net_cost_savings:                number;
    total_net_cost_savings:          null;
    irr_for_spv:                     number;
    average_yearly_payments:         number;
    result:                          number;
  }[];
}

export const getReportData = async (token: string, simulation_id: number) => {
  return getQuery<ReportData>({
    path: `/simulations/generate-report/${simulation_id}/`,
    token,
    callback: (data) => data.data,
  })
}

export const generateReport = async (token: string, simulation_id: number, body: any) => {
  return postQuery<any>({
    path: `/simulations/generate-report/${simulation_id}/`,
    token,
    body,
  })
}

export const updateSimulationReport = async (token: string, simulation_id: number, body: any) => {
  return patchQuery<any>({
    path: `/simulations/simulation-results/${simulation_id}/`,
    token,
    body,
  })
}

interface WeatherFiles {
  id: number;
  file_name: string;
  file_path: string;
}

// Get weather files
export const getWeatherFiles = async (token: string, params) => {
  return getQuery<WeatherFiles[]>({
    path: '/simulations/weather-files/',
    token,
    params,
    callback: (data) => data.data,
  })
}
