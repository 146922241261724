import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import { SectionGoal } from '../../types/generateReport'
import ImageSvg from './ImageSvg'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    width: '100%',
    padding: '8pt 16pt',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: '#F8F8F8',
  },
  itemimage: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    width: '100%',
    padding: '8pt 16pt',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: '#F8F8F8',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  title: {
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: '#252525',
  },
  description: {
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeight: 400,
    color: '#252525',
  },
  image: {
    width: 64,
    height: 64,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    objectFit: 'cover',
  },
  emptyImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 64,
    height: 64,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: '#D9D9D9',
  },
})

type Props = {
  section: SectionGoal;
};

export default function GoalRender({ section }: Props) {
  return (
    <View style={styles.container}>
      <Text>{section.label.value}</Text>
      {section.value.map((item) => {
        if (item.image === undefined) {
          return (
            <View key={item.id} style={styles.item} wrap={false}>
              <Text style={styles.title}>{item.subtitle.value}</Text>
              <Text style={styles.description}>{item.description.value}</Text>
            </View>
          )
        } else {
          return (
            <View key={item.id} style={styles.itemimage} wrap={false}>
              {item.image.value ? (
                <Image src={item.image.value} style={styles.image} />
              ) : (
                <View style={styles.emptyImage}>
                  <ImageSvg width={24} height={24} />
                </View>
              )}
              <View style={styles.textContainer}>
                <Text style={{ ...styles.title, width: 531.28 - 100 }}>
                  {item.subtitle.value}
                </Text>
                <Text style={{ ...styles.description, width: 531.28 - 100 }}>
                  {item.description.value}
                </Text>
              </View>
            </View>
          )
        }
      })}
    </View>
  )
}
