import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMemo, useState, useEffect } from 'react'
import { UserComplete } from '../../api/apiTypes'
import useAppContext from '../../hooks/useAppContext'
import Modal from './Modal'
import SearchInput from '../SearchInput'
import Input from '../Input'
import { updateUserProfile } from '../../api/users'
import CheckModal from './CheckModal'
import { useQuery } from 'react-query'
import {
  getOrganizationRegions,
  getOrganizations,
} from '../../api/organizations'
import { userTypes } from '../../constants'
import LoadingDots from '../LoadingDots'

interface Props {
  closeModal: () => void;
  userInfo: UserComplete;
  refetch: () => void;
}

const EditUserModal = ({ closeModal, userInfo, refetch }: Props) => {
  const { token, isCentralAdmin, isOrganizationAdmin } = useAppContext()
  const [step, setStep] = useState(1)
  const [firstChange, setFirstChange] = useState(false)
  const [resetInput, setResetInput] = useState(false)
  const initialData = { page: 1, total_items: 0, total_pages: 1, data: [] }
  const { data: organizatoins } = useQuery(
    ['getOrganizations'],
    () => getOrganizations(token, initialData),
    {
      initialData,
      enabled: token !== '' && isCentralAdmin,
    }
  )
  const organizatoinsForInput = useMemo(
    () =>
      organizatoins.data?.map((organizatoin) => ({
        value: organizatoin.id,
        label: organizatoin.name,
      })),
    [organizatoins]
  )
  const [serverError, setServerError] = useState(false)

  const permissionsList = [
    { value: 'full', label: 'Full' },
    { value: 'partial', label: 'Partial' },
  ]

  const formik = useFormik({
    initialValues: {
      user_type: userInfo?.user_profile.user_type ?? '',
      organization_id: userInfo?.user_profile.organization?.id
        ? `${userInfo?.user_profile.organization?.id}`
        : '',
      region_id: userInfo?.user_profile.region?.id
        ? `${userInfo?.user_profile.region?.id}`
        : '',
      permissions: 'partial',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      user_type: Yup.string().required('Required'),
      organization_id: Yup.string().required('Organization is required'),
      region_id: Yup.number().test(
        'check if is required',
        'Region is required',
        (value) =>
          (formik.values.user_type !== 'organization_admin' &&
            typeof value === 'number') ||
          formik.values.user_type === 'organization_admin'
      ),
      permissions: Yup.string().required('Required'),
    }),
    onSubmit: async ({
      user_type,
      organization_id,
      region_id,
      permissions,
    }) => {
      const body: {
        permissions: string;
        user_type: string;
        organization_id: number;
        region_id?: number;
      } = {
        permissions,
        user_type,
        organization_id: Number(organization_id),
      }
      if (user_type !== 'organization_admin')
        body.region_id = Number(region_id)

      const response = await updateUserProfile(token, userInfo.id, body)

      if (response.error) {
        setServerError(true)
      } else if (response) {
        refetch()
        setStep((p) => p + 1)
      }
    },
  })

  const { data: regions, isFetching: isRegionsFetching } = useQuery(
    ['getOrganizationRegions'],
    () => getOrganizationRegions(token, formik.values.organization_id),
    {
      initialData: { data: [] },
      enabled:
        token !== '' &&
        (isCentralAdmin || isOrganizationAdmin) &&
        formik.values.user_type !== 'organization_admin' &&
        Boolean(formik.values.organization_id),
    }
  )
  const regionsForInput = useMemo(
    () =>
      regions.data.map((region) => ({
        value: region.id,
        label: region.name,
      })),
    [regions]
  )

  useEffect(() => {
    if (!firstChange) return setFirstChange(true)

    formik.setFieldValue('region_id', '')
    setResetInput(true)
    setTimeout(() => {
      setResetInput(false)
    })
  }, [formik.values.organization_id])

  const usertypesObject = Object.keys(userTypes)
    .slice(isCentralAdmin ? 1 : isOrganizationAdmin ? 2 : 3)
    .map((value) => ({ value, label: userTypes[value] }))

  if (step === 1)
    return (
      <Modal title="User information" closeModal={closeModal}>
        <div className="UserInfoModal__Data">
          <h4 className="text-4 fw-medium">
            {userInfo?.first_name ? `${userInfo?.first_name} ${userInfo?.last_name}` : 'Invitation sent'}
          </h4>
        </div>
        <div className="d-flex flex-column gap-3 py-3">
          {isCentralAdmin || isOrganizationAdmin ? (
            <Input
              label="User type"
              name="user_type"
              placeholder="User type"
              list={usertypesObject}
              value={usertypesObject.find(
                (option) => option.value === formik.values.user_type
              )}
              onChange={(name, value) =>
                formik.setFieldValue(name, value.value)
              }
              error={formik.errors.user_type}
            />
          ) : (
            <Input
              label="User type"
              name="user_type"
              value={formik.values.user_type}
              disabled
            />
          )}

          {isCentralAdmin ? (
            <SearchInput
              label="Organization"
              name="organization_id"
              placeholder="Choose organization"
              search={organizatoinsForInput}
              value={Number(formik.values.organization_id)}
              onChange={(id) => formik.setFieldValue('organization_id', id)}
              error={formik.errors.organization_id}
            />
          ) : (
            <Input
              label="Organization"
              name="organization_id"
              value={userInfo?.user_profile.organization?.name}
              disabled
            />
          )}

          {isCentralAdmin || isOrganizationAdmin ? (
            formik.values.user_type === 'region_admin' ||
              formik.values.user_type === 'operator' ? (
                <SearchInput
                  label="Region"
                  name="region_id"
                  placeholder="Choose region"
                  search={regionsForInput}
                  value={Number(formik.values.region_id)}
                  onChange={(id) => formik.setFieldValue('region_id', id)}
                  reset={resetInput}
                  error={
                    formik.errors.region_id ||
                    (formik.values.organization_id &&
                      !isRegionsFetching &&
                      regionsForInput.length === 0)
                      ? 'no regions'
                      : ''
                  }
                  disabled={
                    formik.values.organization_id
                      ? regionsForInput.length === 0
                      : true
                  }
                />
              ) : (
                <></>
              )
          ) : (
            <Input
              label="Region"
              name="region_id"
              value={userInfo?.user_profile.region?.name}
              disabled
            />
          )}

          <Input
            label="Permissions"
            name="permissions"
            placeholder="Permissions"
            list={permissionsList}
            value={permissionsList.find(
              (option) => option.value === formik.values.permissions
            )}
            onChange={(name, value) =>
              formik.setFieldValue(name, value.value)
            }
            error={formik.errors.permissions}
          />
        </div>

        {serverError ? <p className='text-red'>Something went wrong</p> : <></>}

        <div className="d-flex flex-column align-items-center gap-2">
          <button
            className="button-blue gap-0 w-100 text-4"
            type="submit"
            onClick={() => formik.handleSubmit()}
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? (
              <>
                <span>Saving</span>
                <LoadingDots className='enter-done' />
              </>
            ) : (
              <span>Save changes</span>
            )}
          </button>
        </div>
      </Modal>
    )
  
  return (
    <CheckModal
      closeModal={closeModal}
      title="User information"
      message="¡Changes saved!"
    />
  )
}

export default EditUserModal
