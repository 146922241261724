import { deleteQuery, getQuery, patchQuery, postQuery } from '../../apiFunctions'

export const getSolarThermalField = async (token, simulation_id) => {
  return getQuery<any>({
    path: `/simulations/solar-thermal-field/${simulation_id}/`,
    token
  })
}

export const addSolarThermalField = async (token, simulation_id, body) => {
  return postQuery<any>({
    path: `/simulations/solar-thermal-field/${simulation_id}/`,
    token,
    body
  })
}

export const updateSolarThermalField = async (token, simulation_id, body) => {
  return patchQuery<any>({
    path: `/simulations/solar-thermal-field/${simulation_id}/`,
    token,
    body
  })
}

// Panel models
export const getPanelModels = async (token) => {
  return getQuery<any>({
    path: '/simulations/model-panel-parameters/',
    token,
    callback: (data) => data.data
  })
}

export const createPanelModel = async (token, body) => {
  return postQuery<any>({
    path: '/simulations/model-panel-parameters/',
    token,
    body
  })
}

export const updatePanelModel = async (token, panel_id, body) => {
  return patchQuery<any>({
    path: `/simulations/model-panel-parameters/${panel_id}/`,
    token,
    body
  })
}

export const getPanelModelDetails = async (token, panel_id) => {
  return getQuery<any>({
    path: `/simulations/model-panel-parameters/${panel_id}/`,
    token,
    callback: (data) => data.data
  })
}

export const deletePanelModel = async (token, panel_id) => {
  return deleteQuery<any>({
    path: `/simulations/model-panel-parameters/${panel_id}/`,
    token
  })
}

// interface CreatePanelModels {
//   data?: {
//     id: number;
//     is_admin_creation: boolean;
//     model_name: string;
//     panel_type: string;
//     power_thermal_capacity: number;
//     gross_area: number;
//     aperture_area: number;
//     collector_height: number;
//     collector_width: number;
//     optical_efficiency: number;
//     kd: number;
//     c1: number;
//     c2: number;
//     c3: number;
//     c4: number;
//     c5: number;
//     c6: number;
//     peak_eletricity_capacity: number;
//     panel_efficiency_capacity: number;
//     maximum_power_point_voltage: number;
//     maximum_power_point_current: number;
//     open_circuit_voltage: number;
//     short_circuit_current: number;
//     temperature_coefficient_max_power_point: number;
//     temperature_coefficient_open_circuit_voltage_yVoc: number;
//     temperature_coefficient_open_circuit_voltage_ylsc: number;
//     nominal_operating_cell_temperature: number;
//     number_of_cells: number;
//     deleted: boolean;
//     owner: number;
//   };
//   error?: string;
// }

// export const createPanelModels = async (
//   token: string,
//   body: {
//     model_name: string;
//     panel_type: string;
//     power_thermal_capacity: number;
//     aperture_area: number;
//     collector_height: number;
//     collector_width: number;
//     optical_efficiency: number;
//     kd: number;
//     c1: number;
//     c2: number;
//     c3: number;
//     c4: number;
//     c5: number;
//     c6: number;
//   },
//   optionalBody?: {
//     peak_eletricity_capacity: number;
//     panel_efficiency_capacity: number;
//     maximum_power_point_voltage: number;
//     maximum_power_point_current: number;
//     open_circuit_voltage: number;
//     short_circuit_current: number;
//     temperature_coefficient_max_power_point: number;
//     temperature_coefficient_open_circuit_voltage_yVoc: number;
//     temperature_coefficient_open_circuit_voltage_ylsc: number;
//     nominal_operating_cell_temperature: number;
//     number_of_cells: number;
//   }
// ) => {
//   return await postQuery<CreatePanelModels>({
//     path: '/simulations/model-panel-parameters/',
//     token,
//     body: { ...body, ...optionalBody },
//   })
// }