import Modal from './Modal'
import {
  IEditUser,
  IList,
  IMail,
  IOrganization,
  IUser,
} from '../../iconComponents'
import { UserComplete } from '../../api/apiTypes'
import useAppContext from '../../hooks/useAppContext'
import { userTypeObject } from '../../utils/userTypes'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

interface Props {
  closeModal: () => void;
  data: UserComplete;
}

const UserInfoModal = ({ closeModal, data }: Props) => {
  const { isCentralAdmin, isOrganizationAdmin, isRegionAdmin } = useAppContext()

  const list = [
    {
      icon: <IMail />,
      title: 'Email',
      value: data.email || '-',
    },
    {
      icon: <IUser />,
      title: 'User type',
      value: data.user_profile?.user_type
        ? userTypeObject[data.user_profile.user_type]
        : '-',
    },
    {
      icon: <IList />,
      title: 'Simulations',
      value: data.simulations ?? '-',
    },
  ]

  if (isCentralAdmin)
    list.splice(1, 0, {
      icon: <IOrganization />,
      title: 'Organization',
      value: data.user_profile?.organization.name || '-',
    })
  else if (isOrganizationAdmin)
    list.splice(1, 0, {
      icon: <IOrganization />,
      title: 'Region',
      value: data.user_profile?.region.name || '-',
    })

  if (isCentralAdmin || isOrganizationAdmin || isRegionAdmin) {
    list.splice(
      isCentralAdmin || isOrganizationAdmin ? 4 : 3,
      0,
      {
        icon: <IEditUser />,
        title: 'Permissions',
        value: data.permissions.slice(0, 4) || '',
      },
      {
        icon: <IEditUser />,
        title: 'Created by',
        value: data.created_by?.first_name
          ? `${data.created_by.first_name} ${data.created_by.last_name}`
          : '-',
      },
    )
    list.push({
      icon: <IEditUser />,
      title: 'Permissions',
      value: data.last_active
        ? dayjs(data.last_active, 'YYYY-MM-DD').format('DD/MM/YY')
        : '-',
    })
  }

  return (
    <Modal closeModal={closeModal} title="Simulation information">
      <p className=" p-1 text-center text-4 fw-medium">
        {data.first_name
          ? `${data.first_name} ${data.last_name}`
          : 'Invitation sent'}
      </p>
      <div className="Modal__Divider" />

      <div className="DeleteSimulationModal__Grid my-4">
        {list.map((item, index) => (
          <div className="DeleteSimulationModal__Grid_Item" key={index}>
            <div className="DeleteSimulationModal__Grid_Item_Title">
              {item.icon}
              <p className="text-5 text-secondary">{item.title}</p>
            </div>
            <div className="DeleteSimulationModal__Grid_Item_Info text-5">
              {item.value}
            </div>
          </div>
        ))}
      </div>
    </Modal>
  )
}

export default UserInfoModal
