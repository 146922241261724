import { deleteQuery, getQuery, patchQuery, postQuery } from './apiFunctions'
import { UserComplete } from './apiTypes'

interface ChangeUserType {
  data?: string;
  error?: string;
}

export const changeUserType = (
  token: string,
  user_id: number,
  body: { user_type: string }
) => {
  return patchQuery<ChangeUserType>({
    path: `/users/change-type/${user_id}/`,
    token,
    body,
  })
}

interface UsersList {
  page: number;
  total_items: number;
  total_pages: number;
  data?: UserComplete[];
  error?: string;
}

export const usersList = async (
  token: string,
  params?: {
    page?: number;
    per_page?: number;
    search?: string;
    order_by?: string;
    sim_lower?: number;
    sim_upper?: number;
  }
) => {
  if (params)
    params = Object.entries(params).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )

  return await getQuery<UsersList>({
    path: '/users/list/',
    token,
    params,
    callback: (data: UsersList) =>
      data.data instanceof Array
        ? data
        : { ...data, page: 1, total_items: 0, total_pages: 1, data: [] },
  })
}

interface TransferUserRegion {
  data?: string;
  error?: string;
}

export const transferUserRegion = (
  token: string,
  body: { user_id: number; region_id: number }
) => {
  return postQuery<TransferUserRegion>({
    path: '/users/transfer/',
    token,
    body,
  })
}

interface ChangeUserPermissions {
  data?: string;
  error?: string;
}

export const updateUserProfile = (
  token: string,
  user_id: number,
  body: {
    permissions: string;
    user_type: string;
    organization_id?: number;
    region_id?: number;
  }
) => {
  return patchQuery<ChangeUserPermissions>({
    path: `/users/update-data/${user_id}/`,
    token,
    body,
  })
}

export const deleteUser = (
  token: string,
  user_id: number,
  body: {
    transfer: string;
    transfer_to?: number;
    simulation_ids?: string;
  }
) => {
  return deleteQuery<ChangeUserPermissions>({
    path: `/users/delete/${user_id}/`,
    token,
    body,
  })
}
