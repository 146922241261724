import { useState, useEffect, useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { steps } from '../constants'
import useNewSimulationContext from '../hooks/useNewSimulationContext'

const SimulationSteps = () => {
  const { newSimulation, asideSteps } = useNewSimulationContext()
  const [currentStep, setCurrentStep] = useState(1)
  const [currentGroup, setCurrentGroup] = useState(1)
  const { pathname } = useLocation()

  // Set current step
  useEffect(() => {
    const stepPath = pathname.split('/').pop()

    asideSteps.find((item) => {
      if (item.path === stepPath) {
        setCurrentStep(item.value)
        setCurrentGroup(item.group)
      }
    })
  }, [pathname])

  const lastStep = useMemo(
    () => newSimulation.last_step,
    [newSimulation.last_step]
  )
  const isDev = process.env.NODE_ENV === 'development'

  return (
    <aside className="SimulationSteps">
      {asideSteps.map((item, index) => {
        const lastStepValue =
          steps.find((step) => step.path === lastStep)?.value + 1

        const { main } = item

        // Link disabled
        if (
          (!lastStep && item.value != 1) ||
          (item.value > lastStepValue && !isDev)
        )
          return (
            <div
              key={`Simulation-Step-${index}`}
              className={classNames(
                'SimulationSteps__Link',
                { main },
                'disabled text-6 fw-normal'
              )}
            >
              {item.group == currentGroup && (
                <div className="SimulationSteps__Detail" />
              )}
              <div className="SimulationSteps__Circle" />
              {item.title}
            </div>
          )
        // Link in edited status
        else if (
          item.value <= lastStepValue &&
          item.value > currentStep &&
          !isDev
        )
          return (
            <div
              key={`Simulation-Step-${index}`}
              className={classNames(
                'SimulationSteps__Link',
                {
                  main,
                  disabled: !lastStep && !(item.value <= currentStep),
                  completed: item.value <= currentStep,
                  edited:
                    item.value <= lastStepValue - 1 && item.value > currentStep,
                  edited_active:
                    item.value <= lastStepValue && item.value > currentStep,
                },
                'text-6 fw-normal'
              )}
            >
              {item.group == currentGroup && (
                <div className="SimulationSteps__Detail" />
              )}
              <div className="SimulationSteps__Circle" />
              {item.title}
            </div>
          )
        // Link in current step or completed status
        else
          return (
            <NavLink
              key={`Simulation-Step-${index}`}
              to={`/dashboard/create-simulation/step/${item.path}`}
              className={({ isActive }) =>
                classNames(
                  'SimulationSteps__Link',
                  {
                    main,
                    disabled: item.value > lastStepValue,
                    active: (main && item.value <= currentStep && item.group == currentGroup) || isActive,
                    completed: item.value <= currentStep,
                    edited:
                      item.value <= lastStepValue - 1 &&
                      item.value > currentStep,
                    edited_active:
                      item.value <= lastStepValue && item.value > currentStep,
                  },
                  'text-6 fw-normal'
                )
              }
            >
              {item.group == currentGroup && (
                <div className="SimulationSteps__Detail" />
              )}
              <div className="SimulationSteps__Circle" />
              {item.title}
            </NavLink>
          )
      })}
    </aside>
  )
}

export default SimulationSteps
