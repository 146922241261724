import { deleteQuery, getQuery, patchQuery, postQuery } from './apiFunctions'
import { Client } from './apiTypes'

export interface GetClients {
  page: number;
  total_items: number;
  total_pages: number;
  data?: Client[];
  error?: string;
  dolar: {
    id: number,
    code: string,
    name: string,
  },
  euro: {
    id: number,
    code: string,
    name: string,
  },
}

export const getClients = async (
  token: string,
  params?: {
    page?: number;
    per_page?: number;
    organization?: number;
    country?: string;
    search?: string;
    sim_lower?: number;
    sim_upper?: number;
  }
) => {
  if (params)
    params = Object.entries(params).reduce(
      (a, [k, v]) =>
        (typeof v === 'string' && v !== '') || typeof v === 'number'
          ? ((a[k] = v), a)
          : a,
      {}
    )
  return await getQuery<GetClients>({
    path: '/simulations/clients/',
    token,
    params,
    callback: (data: GetClients) =>
      data.data instanceof Array
        ? data
        : { ...data, page: 1, total_items: 0, total_pages: 1, data: [] },
  })
}

export const createNewClient = async (
  token: string,
  body: {
    name: string;
    organization: number;
    country: number | string;
    address: string;
    email: string;
    phone: string;
  },
  optionalBody?: {
    company?: string;
    role?: string;
  },
) => {
  if (optionalBody)
    optionalBody = Object.entries(optionalBody).reduce(
      (a, [k, v]) =>
        (typeof v === 'string' && v !== '') || typeof v === 'number' || v
          ? ((a[k] = v), a)
          : a,
      {}
    )
    
  return await postQuery<any>({
    path: '/simulations/clients/',
    token,
    body: { ...body, ...optionalBody},
  })
}

export const updateClient = async (
  token: string,
  body: {
    client_id: number;
  },
  optionalBody?: {
    name?: string;
    country?: number | string;
    address?: string;
    email?: string;
    phone?: string;
    organization?: number;
    company?: string;
    role?: string;
  },
) => {
  if (optionalBody)
    optionalBody = Object.entries(optionalBody).reduce(
      (a, [k, v]) =>
        (typeof v === 'string' && v !== '') || typeof v === 'number' || v
          ? ((a[k] = v), a)
          : a,
      {}
    )

  return await patchQuery<any>({
    path: '/simulations/clients/',
    token,
    body: { ...body, ...optionalBody},
  })
}

export const deleteClient = async (token, body: {client_id: number}) => { 
  return await deleteQuery<any>({
    path: '/simulations/clients/',
    token,
    body
  })
}
