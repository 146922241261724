import { FormEvent } from 'react'

export const handleInputFile = ({
  event,
  fileHandler,
  fileListMaxLength,
  fileTypes,
  fileMaxSize, //MB
  errorHandler,
}: {
  event: FormEvent<HTMLLabelElement>;
  fileHandler: (data: File) => void;
  fileListMaxLength?: number;
  fileTypes?: Array<string>;
  fileMaxSize?: number;
  errorHandler?: (error: string) => void;
}) => {
  const input = event.target as HTMLInputElement
  if (!input.files) return

  const fileList = Array.from(input.files)

  fileList.slice(0, fileListMaxLength).forEach((file) => {
    if (fileTypes && !fileTypes.includes(file['type'])) {
      return errorHandler
        ? errorHandler('the file type is not accepted')
        : console.error('the file type is not accepted')
    }

    if (fileMaxSize && file.size > fileMaxSize * 1024 ** 2) {
      const error = `The file is very large (${Math.floor(
        file.size / 1024 ** 2
      )}MB), max size is ${fileMaxSize}MB`
      return errorHandler ? errorHandler(error) : console.error(error)
    }

    fileHandler(file)
  })

  input.files = new DataTransfer().files
}

export const getImageString = (
  data: File,
  callback: (data: File, image: string) => void
) => {
  const imageReader = new FileReader()

  imageReader.readAsDataURL(data)

  imageReader.onload = () => {
    if (imageReader.readyState == 2) {
      const imageToShow = imageReader.result?.toString()
      if (imageToShow) {
        callback(data, imageToShow)
      }
    }
  }
}

export const getVideoString = (
  data: File,
  callback: (data: File, image: string) => void
) => {
  const fileVideoReader = URL.createObjectURL(data)

  callback(data, fileVideoReader)
}

export const convertFileToBase64 = (file: File) => {
  const fileReader = new FileReader()
  fileReader.readAsDataURL(file)

  return new Promise<string | undefined>((resolve) => {
    fileReader.onload = () => {
      resolve(fileReader.result?.toString())
    }
  })
}
