import { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { addElectricityDemand, updateElectricityDemand } from '../../api/simulations/input-parameters/electricity-demand'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import CreateSimulationContainer from '../../containers/CreateSimulationContainer'
import SimulationFormikHandler from '../../containers/SimulationFormikHandler'
import Checkboxes from '../../components/Checkboxes'
import CreateSimulationSelector from '../../components/CreateSimulationSelector'
import ElectricityIcon from '../../assets/icons/electricity.svg'
import ErrorIcon from '../../assets/icons/warning-red.svg'
import { checkboxOptions, normalizeNumberWithCommas, normalizePercentToShow, threeDecimalRegex } from '../../constants'
import { submitStep } from '../../utils/submitStep'
import InputSmall from '../../components/InputSmall'

const ElectricityDemand = () => {
  const { token } = useAppContext()
  const { newSimulation, updateNewSimulation, setIsStepLoading } = useNewSimulationContext()

  // const yupNumberValidation = Yup
  //   .mixed()
  //   .required('Required')
  //   .transform(value => value?.toString()?.replace(/,/g, ''))
  //   .test('type', 'Only numbers', value => !isNaN(value))
  //   .test('min', 'Min: 0', value => value >= 0)
  //   .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))

  const formik = useFormik({
    initialValues: {
      supply_choices: 'annual',
      annual_demand: null,
      fel_jan: null,
      fel_feb: null,
      fel_mar: null,
      fel_apr: null,
      fel_may: null,
      fel_jun: null,
      fel_jul: null,
      fel_aug: null,
      fel_sep: null,
      fel_oct: null,
      fel_nov: null,
      fel_dec: null,
      will_complete: false,
      general_error: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      supply_choices: Yup.string().required('Required'),
      annual_demand: Yup.mixed().nullable(),
      fel_jan: Yup.mixed().nullable(),
      fel_feb: Yup.mixed().nullable(),
      fel_mar: Yup.mixed().nullable(),
      fel_apr: Yup.mixed().nullable(),
      fel_may: Yup.mixed().nullable(),
      fel_jun: Yup.mixed().nullable(),
      fel_jul: Yup.mixed().nullable(),
      fel_aug: Yup.mixed().nullable(),
      fel_sep: Yup.mixed().nullable(),
      fel_oct: Yup.mixed().nullable(),
      fel_nov: Yup.mixed().nullable(),
      fel_dec: Yup.mixed().nullable(),
    }),
    validate: (values) => {
      const errors = {
        general_error: '',
      }
      let numberValues

      if(values.supply_choices == 'annual') {
        numberValues = {
          annual_demand: values.annual_demand && normalizeNumberWithCommas(values.annual_demand)
        }
      } else {
        numberValues = {
          fel_jan: values.fel_jan && normalizeNumberWithCommas(values.fel_jan),
          fel_feb: values.fel_feb && normalizeNumberWithCommas(values.fel_feb),
          fel_mar: values.fel_mar && normalizeNumberWithCommas(values.fel_mar),
          fel_apr: values.fel_apr && normalizeNumberWithCommas(values.fel_apr),
          fel_may: values.fel_may && normalizeNumberWithCommas(values.fel_may),
          fel_jun: values.fel_jun && normalizeNumberWithCommas(values.fel_jun),
          fel_jul: values.fel_jul && normalizeNumberWithCommas(values.fel_jul),
          fel_aug: values.fel_aug && normalizeNumberWithCommas(values.fel_aug),
          fel_sep: values.fel_sep && normalizeNumberWithCommas(values.fel_sep),
          fel_oct: values.fel_oct && normalizeNumberWithCommas(values.fel_oct),
          fel_nov: values.fel_nov && normalizeNumberWithCommas(values.fel_nov),
          fel_dec: values.fel_dec && normalizeNumberWithCommas(values.fel_dec),
        }
      }

      const numberValuesArray = Object.values(numberValues)
      const hasInvalidValues = numberValuesArray.some(value => typeof value !== 'number' || isNaN(value))
      const hasEmptyValues = numberValuesArray.some(value => value === '')
      const hasMinValues = numberValuesArray.some(value => value < 0)
      const hasMaxDecimals = numberValuesArray.some(value => threeDecimalRegex.test(String(value)))

      if(hasEmptyValues) {
        if(values.supply_choices == 'annual') {
          errors.general_error = 'Complete annual field'
        } else {
          errors.general_error = 'Complete all fields'
        }
      } else if(hasInvalidValues){
        errors.general_error = 'Invalid values: only numbers'
      } else if(hasMinValues) {
        errors.general_error = 'Min value: 0'
      } else if(hasMaxDecimals) {
        errors.general_error = 'Max decimals: 3'
      }

      if(errors.general_error && values.will_complete) return errors
      else return
    },
    onSubmit: async (values) => {
      const newValues = {
        supply_choices: values.supply_choices,
        annual_demand: values.annual_demand && normalizeNumberWithCommas(values.annual_demand),
        fel_jan: values.fel_jan && normalizeNumberWithCommas(values.fel_jan),
        fel_feb: values.fel_feb && normalizeNumberWithCommas(values.fel_feb),
        fel_mar: values.fel_mar && normalizeNumberWithCommas(values.fel_mar),
        fel_apr: values.fel_apr && normalizeNumberWithCommas(values.fel_apr),
        fel_may: values.fel_may && normalizeNumberWithCommas(values.fel_may),
        fel_jun: values.fel_jun && normalizeNumberWithCommas(values.fel_jun),
        fel_jul: values.fel_jul && normalizeNumberWithCommas(values.fel_jul),
        fel_aug: values.fel_aug && normalizeNumberWithCommas(values.fel_aug),
        fel_sep: values.fel_sep && normalizeNumberWithCommas(values.fel_sep),
        fel_oct: values.fel_oct && normalizeNumberWithCommas(values.fel_oct),
        fel_nov: values.fel_nov && normalizeNumberWithCommas(values.fel_nov),
        fel_dec: values.fel_dec && normalizeNumberWithCommas(values.fel_dec),
        will_complete: String(values.will_complete),
      }

      await submitStep({
        pathname: location.pathname,
        lastStep: newSimulation.last_step,
        addFunction: () => addElectricityDemand(token, newSimulation.id, newValues),
        updateFunction: () => updateElectricityDemand(token, newSimulation.id, newValues),
        updateNewSimulation,
        setIsStepLoading
      })
    }
  })

  const electricityDemandOptions = [
    {
      label: 'Annual',
      value: 'annual'
    }, {
      label: 'Monthly',
      value: 'monthly'
    }
  ]
  const demandPerMonth = useMemo(() => [
    {
      label: 'Jan',
      name: 'fel_jan',
      value: formik.values.fel_jan
    }, {
      label: 'Feb',
      name: 'fel_feb',
      value: formik.values.fel_feb
    }, {
      label: 'Mar',
      name: 'fel_mar',
      value: formik.values.fel_mar
    }, {
      label: 'Apr',
      name: 'fel_apr',
      value: formik.values.fel_apr
    }, {
      label: 'May',
      name: 'fel_may',
      value: formik.values.fel_may
    }, {
      label: 'Jun',
      name: 'fel_jun',
      value: formik.values.fel_jun
    }, {
      label: 'Jul',
      name: 'fel_jul',
      value: formik.values.fel_jul
    }, {
      label: 'Aug',
      name: 'fel_aug',
      value: formik.values.fel_aug
    }, {
      label: 'Sep',
      name: 'fel_sep',
      value: formik.values.fel_sep
    }, {
      label: 'Oct',
      name: 'fel_oct',
      value: formik.values.fel_oct
    }, {
      label: 'Nov',
      name: 'fel_nov',
      value: formik.values.fel_nov
    }, {
      label: 'Dec',
      name: 'fel_dec',
      value: formik.values.fel_dec
    }, {
      label: 'Annual',
      name: 'annual_demand',
      value: formik.values.annual_demand
    }
  ], [formik.values])

  // Show saved simulation step
  useEffect(() => {
    formik.setValues({
      supply_choices: newSimulation.steps.electricity_demand.supply_choices,
      annual_demand: newSimulation.steps.electricity_demand.annual_demand,
      fel_jan: newSimulation.steps.electricity_demand.fel_jan,
      fel_feb: newSimulation.steps.electricity_demand.fel_feb,
      fel_mar: newSimulation.steps.electricity_demand.fel_mar,
      fel_apr: newSimulation.steps.electricity_demand.fel_apr,
      fel_may: newSimulation.steps.electricity_demand.fel_may,
      fel_jun: newSimulation.steps.electricity_demand.fel_jun,
      fel_jul: newSimulation.steps.electricity_demand.fel_jul,
      fel_aug: newSimulation.steps.electricity_demand.fel_aug,
      fel_sep: newSimulation.steps.electricity_demand.fel_sep,
      fel_oct: newSimulation.steps.electricity_demand.fel_oct,
      fel_nov: newSimulation.steps.electricity_demand.fel_nov,
      fel_dec: newSimulation.steps.electricity_demand.fel_dec,
      will_complete: newSimulation.steps.electricity_demand.will_complete,
      general_error: '',
    })
  }, [newSimulation.steps.electricity_demand])


  // Set month values if annual value is changed
  useEffect(() => {
    const normalizedAnnualValue = formik.values.annual_demand && normalizeNumberWithCommas(formik.values.annual_demand)

    const annualValue = {
      annual_demand: normalizedAnnualValue
    }

    const annualValueArray = Object.values(annualValue)

    const hasInputErrors = checkInputErrors(annualValueArray)
    if(hasInputErrors) {
      return
    } else if(formik.values.supply_choices == 'annual' && formik.values.annual_demand && !(formik.status == 'reset')) {

      const monthlyValue = Number(normalizePercentToShow(Number(normalizedAnnualValue / 12) / 100))

      formik.setValues({
        ...formik.values,
        fel_jan: monthlyValue,
        fel_feb: monthlyValue,
        fel_mar: monthlyValue,
        fel_apr: monthlyValue,
        fel_may: monthlyValue,
        fel_jun: monthlyValue,
        fel_jul: monthlyValue,
        fel_aug: monthlyValue,
        fel_sep: monthlyValue,
        fel_oct: monthlyValue,
        fel_nov: monthlyValue,
        fel_dec: monthlyValue,
      })
    }
  }, [formik.values.supply_choices, formik.values.annual_demand])

  // Set annual value if month values are changed
  useEffect(() => {
    const monthValues = {
      fel_jan: formik.values.fel_jan && normalizeNumberWithCommas(formik.values.fel_jan),
      fel_feb: formik.values.fel_feb && normalizeNumberWithCommas(formik.values.fel_feb),
      fel_mar: formik.values.fel_mar && normalizeNumberWithCommas(formik.values.fel_mar),
      fel_apr: formik.values.fel_apr && normalizeNumberWithCommas(formik.values.fel_apr),
      fel_may: formik.values.fel_may && normalizeNumberWithCommas(formik.values.fel_may),
      fel_jun: formik.values.fel_jun && normalizeNumberWithCommas(formik.values.fel_jun),
      fel_jul: formik.values.fel_jul && normalizeNumberWithCommas(formik.values.fel_jul),
      fel_aug: formik.values.fel_aug && normalizeNumberWithCommas(formik.values.fel_aug),
      fel_sep: formik.values.fel_sep && normalizeNumberWithCommas(formik.values.fel_sep),
      fel_oct: formik.values.fel_oct && normalizeNumberWithCommas(formik.values.fel_oct),
      fel_nov: formik.values.fel_nov && normalizeNumberWithCommas(formik.values.fel_nov),
      fel_dec: formik.values.fel_dec && normalizeNumberWithCommas(formik.values.fel_dec),
    }

    const numberValuesArray = Object.values(monthValues)

    const hasInputErrors = checkInputErrors(numberValuesArray)
    if(hasInputErrors) {
      return
    } else {
      const monthlyValues = demandPerMonth.filter(month => month.name != 'annual_demand')
      const annualValue = normalizePercentToShow(monthlyValues.reduce((acc, month) => acc + normalizeNumberWithCommas(month.value), 0) / 100)

      if(formik.values.supply_choices == 'monthly' && annualValue && !(formik.status == 'reset')) {
        formik.setValues({
          ...formik.values,
          annual_demand: annualValue
        })
      }
    }

  }, [formik.values.supply_choices, formik.values.fel_jan, formik.values.fel_feb, formik.values.fel_mar, formik.values.fel_apr, formik.values.fel_may, formik.values.fel_jun, formik.values.fel_jul, formik.values.fel_aug, formik.values.fel_sep, formik.values.fel_oct, formik.values.fel_nov, formik.values.fel_dec])

  // Reset values
  useEffect(() => {
    if(formik.status == 'reset') {
      formik.resetForm({
        values: formik.initialValues
      })
    }
  }, [formik.status])

  const checkInputErrors = (values) => {
    const hasInvalidValues = values.some(value => typeof value !== 'number' || isNaN(value))
    const hasEmptyValues = values.some(value => value === '')
    const hasMinValues = values.some(value => value < 0)

    if(hasEmptyValues) return true
    else if(hasInvalidValues) return true
    else if(hasMinValues) return true
    else return false
  }

  return (
    <SimulationFormikHandler formik={formik}>
      <Helmet>
        <title>Create Simulation: Electricity Demand</title>
      </Helmet>


      {/* First Container */}
      <CreateSimulationSelector
        title='Electricity demand'
        alert='Important decision: The “yes” answer enables below content'
        subtitle='Is there an electrical demand?'
        options={checkboxOptions}
        optionSelected={formik.values.will_complete}
        handleSelect={(value) => formik.setFieldValue('will_complete', value)}
      />

      {/* Second Container */}
      <CreateSimulationContainer available={formik.values.will_complete}>
        <header className='d-flex align-items-center gap-2'>
          <img
            src={ElectricityIcon}
            className="CreateSimulation__Title-Icon"
            alt='Electricity demand'
            title='Electricity demand'
          />

          <h2 className='text-4 fw-semibold'>Electricity demand</h2>
        </header>

        <Checkboxes
          label='Input electricity demand'
          options={electricityDemandOptions}
          optionSelected={formik.values.supply_choices}
          small
          handleSelect={(value: string) => formik.setFieldValue('supply_choices', value)}
          disabled={!formik.values.will_complete}
        />


        <div className="d-flex flex-column gap-4">
          <div className="w-100 d-flex align-items-center gap-2">
            <h3 className='d-flex align-items-center gap-1 text-4 fw-medium'>
              {formik.errors.general_error && (
                <div className='Input__Error-Icon-Container'>
                  <img className='Input__Error-Icon' src={ErrorIcon} />
                </div>
              )}
                  Demand
            </h3>
            {formik.errors.general_error && (
              <span className='Input__Error text-6'>{ formik.errors.general_error }</span>
            )}
          </div>

          {/* <div style={{ maxWidth: '260px' }}> */}
          <InputSmall
            topLabel={demandPerMonth[demandPerMonth.length - 1].label}
            rightLabel='kWh'
            name={demandPerMonth[demandPerMonth.length - 1].name}
            value={demandPerMonth[demandPerMonth.length - 1].value}
            placeholder='0'
            // type='number'
            onChange={formik.handleChange}
            disabled={!formik.values.will_complete || formik.values.supply_choices == 'monthly'}
          />
          {/* </div> */}

          <div className="CreateSimulation__Divisor-Horizontal"></div>

          <div className="d-flex flex-column gap-2">
            <h3 className='text-5 fw-medium'>Monthly</h3>
            <div className="CreateSimulation__Grid Thirteen">
              {demandPerMonth.length > 0 && demandPerMonth.map((item, index) => {
                if(index == demandPerMonth.length - 1) return
                else return (
                  <InputSmall
                    key={`Input-Percentage-Per-Day-${index}`}
                    topLabel={item.label}
                    name={item.name}
                    value={item.value}
                    placeholder='0'
                    onChange={formik.handleChange}
                    disabled={!formik.values.will_complete || formik.values.supply_choices == 'annual'}
                  />
                )}
              )}

              <div className="CreateSimulation__Grid Thirteen_Extra">
                <span className="text-6 text-secondary pb-2">kWh</span>
                <span className="text-6 text-secondary pb-2">kWh</span>
                <span className="text-6 text-secondary pb-2 measure">kWh</span>
              </div>
            </div>
          </div>
        </div>
      </CreateSimulationContainer>
    </SimulationFormikHandler>
  )
}

export default ElectricityDemand