import { Image, StyleSheet, View } from '@react-pdf/renderer'
import { SectionImageGalery } from '../../types/generateReport'

type Props = {
  section: SectionImageGalery;
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 16,
  },
  image: {
    width: 112,
    height: 112,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    objectFit: 'contain',
  },
})

export default function ImageGaleryRender({ section }: Props) {
  return (
    <View style={styles.container}>
      {section.value.map((image) => (
        <Image key={image.id} src={image.value} style={styles.image} />
      ))}
    </View>
  )
}
