import { SVGProps } from 'react'

export default function AngleLeftIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 30 30"
      fill="currentColor"
      {...props}
    >
      <path
        d="M20.0138 5.6287L10.8788 14.9999L20.0138 24.3712C20.1773 24.5386 20.2689 24.7634 20.2689 24.9974C20.2689 25.2315 20.1773 25.4563 20.0138 25.6237C19.9343 25.7048 19.8395 25.7692 19.7349 25.8131C19.6303 25.8571 19.5179 25.8798 19.4044 25.8798C19.2909 25.8798 19.1785 25.8571 19.0739 25.8131C18.9693 25.7692 18.8745 25.7048 18.795 25.6237L9.07503 15.6543C8.90438 15.4792 8.80887 15.2444 8.80887 14.9999C8.80887 14.7555 8.90438 14.5206 9.07503 14.3456L18.7932 4.3762C18.8726 4.29455 18.9677 4.22966 19.0727 4.18535C19.1777 4.14104 19.2905 4.11822 19.4044 4.11822C19.5184 4.11822 19.6312 4.14104 19.7361 4.18535C19.8411 4.22966 19.9362 4.29455 20.0157 4.3762C20.1792 4.54363 20.2708 4.7684 20.2708 5.00245C20.2708 5.2365 20.1792 5.46126 20.0157 5.6287H20.0138Z"
        fill="currentColor"
      />
    </svg>
  )
}
