import { useEffect, useMemo, useState } from 'react'
import { TableTypes } from '../api/apiTypes'

interface props<T> {
  tableData: Array<T>;
  oneSelection?: boolean;
  initialValue?: Array<string | number>;
}

const useTable = <T extends TableTypes>({
  tableData,
  oneSelection = false,
  initialValue = [],
}: props<T>) => {
  const [change, setChange] = useState(0)
  const [selectedItemsId, setSelectedItemsId] = useState<
    Array<string | number>
  >([])
  const selectedItems = useMemo(
    () => selectedItemsId.map((id) => tableData.find((item) => item.id === id)),
    [selectedItemsId, tableData]
  )

  useEffect(() => {
    if (change >= 2) return 
    if(initialValue.length !== 0 && tableData.length !== 0)
      setSelectedItemsId(initialValue)

    setChange(p => p + 1)
  }, [initialValue, tableData])

  useEffect(() => {
    setSelectedItemsId((prev) =>
      prev.filter((id) => tableData.some((item) => item.id === id))
    )
  }, [tableData])

  const handleSelectItem = (item: T) => {
    if (selectedItems.some((itemSelect) => itemSelect.id === item.id)) {
      setSelectedItemsId((prev) => prev.filter((id) => id !== item.id))
    } else {
      if (oneSelection) setSelectedItemsId([item.id])
      else setSelectedItemsId((prev) => [...prev, item.id])
    }
  }

  const handleSelectAll = () => {
    if (tableData.length === selectedItemsId.length) setSelectedItemsId([])
    else setSelectedItemsId(tableData.map((item) => item.id))
  }

  return {
    selectedItems,
    handleSelectItem,
    handleSelectAll,
    selectedItemsId,
    setSelectedItemsId,
    change,
  }
}

export default useTable
