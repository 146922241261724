import { useMemo, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'
import {
  createDailyProfile,
  getDailyProfileDetail,
  updateDailyProfile,
} from '../../api/simulations/input-parameters/hot-water-demand'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import useModalsContext from '../../hooks/useModalsContext'
import Input from '../Input'
import Modal from './Modal'
import CheckModal from './CheckModal'
import { threeDecimalRegex, maxProfileValue, minProfileValue, normalizeNumberWithCommas } from '../../constants'
import roundToDecimals from '../../utils/roundToDecimals'
import ErrorIcon from '../../assets/icons/warning-red.svg'
import LoadingDots from '../LoadingDots'
import Button from '../buttons/Button'
import Switch from '../Switch'

const NewProfileModal = () => {
  const { token } = useAppContext()
  const { setWaterProfiles } = useNewSimulationContext()
  const { modalId, closeShowNewProfileModal, toggleShowManageProfilesModal } = useModalsContext()
  const [step, setStep] = useState(1)
  const [serverError, setServerError] = useState(false)

  const formik = useFormik({
    initialValues: {
      name: '',
      f_h1: null,
      f_h2: null,
      f_h3: null,
      f_h4: null,
      f_h5: null,
      f_h6: null,
      f_h7: null,
      f_h8: null,
      f_h9: null,
      f_h10: null,
      f_h11: null,
      f_h12: null,
      f_h13: null,
      f_h14: null,
      f_h15: null,
      f_h16: null,
      f_h17: null,
      f_h18: null,
      f_h19: null,
      f_h20: null,
      f_h21: null,
      f_h22: null,
      f_h23: null,
      f_h24: null,
      is_showed: false,
      general_error: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Required')
        .min(3, 'Must be at least 3 characters')
        .max(60, 'Must be 60 characters or less'),
    }),
    validate: (values) => {
      const errors = {
        general_error: '',
      }

      const percentagesValues = {
        f_h1: values.f_h1 === null ? null : normalizeNumberWithCommas(values.f_h1) / 100,
        f_h2: values.f_h2 === null ? null : normalizeNumberWithCommas(values.f_h2) / 100,
        f_h3: values.f_h3 === null ? null : normalizeNumberWithCommas(values.f_h3) / 100,
        f_h4: values.f_h4 === null ? null : normalizeNumberWithCommas(values.f_h4) / 100,
        f_h5: values.f_h5 === null ? null : normalizeNumberWithCommas(values.f_h5) / 100,
        f_h6: values.f_h6 === null ? null : normalizeNumberWithCommas(values.f_h6) / 100,
        f_h7: values.f_h7 === null ? null : normalizeNumberWithCommas(values.f_h7) / 100,
        f_h8: values.f_h8 === null ? null : normalizeNumberWithCommas(values.f_h8) / 100,
        f_h9: values.f_h9 === null ? null : normalizeNumberWithCommas(values.f_h9) / 100,
        f_h10: values.f_h10 === null ? null : normalizeNumberWithCommas(values.f_h10) / 100,
        f_h11: values.f_h11 === null ? null : normalizeNumberWithCommas(values.f_h11) / 100,
        f_h12: values.f_h12 === null ? null : normalizeNumberWithCommas(values.f_h12) / 100,
        f_h13: values.f_h13 === null ? null : normalizeNumberWithCommas(values.f_h13) / 100,
        f_h14: values.f_h14 === null ? null : normalizeNumberWithCommas(values.f_h14) / 100,
        f_h15: values.f_h15 === null ? null : normalizeNumberWithCommas(values.f_h15) / 100,
        f_h16: values.f_h16 === null ? null : normalizeNumberWithCommas(values.f_h16) / 100,
        f_h17: values.f_h17 === null ? null : normalizeNumberWithCommas(values.f_h17) / 100,
        f_h18: values.f_h18 === null ? null : normalizeNumberWithCommas(values.f_h18) / 100,
        f_h19: values.f_h19 === null ? null : normalizeNumberWithCommas(values.f_h19) / 100,
        f_h20: values.f_h20 === null ? null : normalizeNumberWithCommas(values.f_h20) / 100,
        f_h21: values.f_h21 === null ? null : normalizeNumberWithCommas(values.f_h21) / 100,
        f_h22: values.f_h22 === null ? null : normalizeNumberWithCommas(values.f_h22) / 100,
        f_h23: values.f_h23 === null ? null : normalizeNumberWithCommas(values.f_h23) / 100,
        f_h24: values.f_h24 === null ? null : normalizeNumberWithCommas(values.f_h24) / 100,
      }

      const percentagesValuesArray = Object.values(percentagesValues)

      const hasEmptyValues = percentagesValuesArray.some((value) => value === null)
      const hasInvalidValues = percentagesValuesArray.some((value) => typeof value !== 'number' || isNaN(value))
      const hasInvalidMinMaxValues = percentagesValuesArray.some((value) => value < 0 || value > 100)
      const hasMaxDecimals = percentagesValuesArray.some((value) => threeDecimalRegex.test(String(value * 100)))
      const totalValues = roundToDecimals(percentagesValuesArray, 4)

      if (hasEmptyValues) {
        errors.general_error = 'Complete all fields'
      } else if (hasInvalidValues) {
        errors.general_error =
          'There are invalid values, only numbers are allowed'
      } else if (hasInvalidMinMaxValues) {
        errors.general_error =
          'Invalid values: all values must be between 0 and 100'
      } else if (hasMaxDecimals) {
        errors.general_error = 'Invalid values: all values must have a maximum of 3 decimals'
      } else if (
        totalValues < minProfileValue ||
        totalValues > maxProfileValue
      ) {
        errors.general_error = `The total sum is: ${(totalValues * 100).toFixed(
          2
        )}, but it must be between 99.5 and 100.5`
      }

      if (errors.general_error) return errors
      else return
    },
    onSubmit: async (values) => {
      const percentagesValues = {
        f_h1: Number(values.f_h1) / 100,
        f_h2: Number(values.f_h2) / 100,
        f_h3: Number(values.f_h3) / 100,
        f_h4: Number(values.f_h4) / 100,
        f_h5: Number(values.f_h5) / 100,
        f_h6: Number(values.f_h6) / 100,
        f_h7: Number(values.f_h7) / 100,
        f_h8: Number(values.f_h8) / 100,
        f_h9: Number(values.f_h9) / 100,
        f_h10: Number(values.f_h10) / 100,
        f_h11: Number(values.f_h11) / 100,
        f_h12: Number(values.f_h12) / 100,
        f_h13: Number(values.f_h13) / 100,
        f_h14: Number(values.f_h14) / 100,
        f_h15: Number(values.f_h15) / 100,
        f_h16: Number(values.f_h16) / 100,
        f_h17: Number(values.f_h17) / 100,
        f_h18: Number(values.f_h18) / 100,
        f_h19: Number(values.f_h19) / 100,
        f_h20: Number(values.f_h20) / 100,
        f_h21: Number(values.f_h21) / 100,
        f_h22: Number(values.f_h22) / 100,
        f_h23: Number(values.f_h23) / 100,
        f_h24: Number(values.f_h24) / 100,
      }

      let response = null

      if (!modalId) response = await createDailyProfile(token, {
        name: values.name,
        is_showed: String(values.is_showed),
        ...percentagesValues,
      })
      else response = await updateDailyProfile(token, modalId, {
        name: values.name,
        is_showed: String(values.is_showed),
        ...percentagesValues,
      })

      const nonFieldErrors = response?.error?.non_field_errors

      if ((nonFieldErrors?.length && nonFieldErrors[0] == 'A daily profile with the same name already exists') || response?.error == 'Daily profile with this name already exists') {
        formik.setFieldError('name', 'This name is already in use')
      }
      if (response.data) {
        setStep(2)
        setWaterProfiles((prev) => {
          if (modalId) {
            const index = prev.findIndex((item) => item.id === modalId)
            const newWaterProfiles = [...prev]
            newWaterProfiles[index] = response.data
            return newWaterProfiles
          } else {
            return [...prev, response.data]
          }
        })
      }
    },
  })

  useEffect(() => {
    if (!modalId) return
    const getInfo = async () => {
      const response = await getDailyProfileDetail(token, modalId)
      if (response.error) setServerError(true)
      else if (response) {
        formik.setValues({
          name: response.name,
          f_h1: response.f_h1 * 100,
          f_h2: response.f_h2 * 100,
          f_h3: response.f_h3 * 100,
          f_h4: response.f_h4 * 100,
          f_h5: response.f_h5 * 100,
          f_h6: response.f_h6 * 100,
          f_h7: response.f_h7 * 100,
          f_h8: response.f_h8 * 100,
          f_h9: response.f_h9 * 100,
          f_h10: response.f_h10 * 100,
          f_h11: response.f_h11 * 100,
          f_h12: response.f_h12 * 100,
          f_h13: response.f_h13 * 100,
          f_h14: response.f_h14 * 100,
          f_h15: response.f_h15 * 100,
          f_h16: response.f_h16 * 100,
          f_h17: response.f_h17 * 100,
          f_h18: response.f_h18 * 100,
          f_h19: response.f_h19 * 100,
          f_h20: response.f_h20 * 100,
          f_h21: response.f_h21 * 100,
          f_h22: response.f_h22 * 100,
          f_h23: response.f_h23 * 100,
          f_h24: response.f_h24 * 100,
          is_showed: response.is_showed,
          general_error: '',
        })
      }
    }
    getInfo()
  }, [modalId])

  const percentagesPerHour = useMemo(() => {
    const data = []

    for (let i = 0; i < 24; i++) {
      data.push({
        label: `${i} - ${i + 1}`,
        name: `f_h${i + 1}`,
        value: formik.values[`f_h${i + 1}`],
        error: formik.errors[`f_h${i + 1}`],
      })
    }

    return data
  }, [formik.values, formik.errors])

  if (step === 1)
    return (
      <Modal
        title={!modalId ? '+ Add new profile' : 'Edit profile'}
        closeModal={closeShowNewProfileModal}
        prevStep={() => {toggleShowManageProfilesModal(); closeShowNewProfileModal()}}
        notCloseOnBlur
      >
        <div
          className="flex flex-col justify-center items-center gap-4 h-100 py-3 mx-auto"
          style={{ maxHeight: 'calc(90dvh - 160px)' }}
        >
          <Input
            label="Daily profile"
            name="name"
            value={formik.values.name}
            placeholder="Profile name"
            onChange={formik.handleChange}
            error={formik.errors.name}
          />

          <div className="w-100 d-flex align-items-center gap-2">
            <h3 className="d-flex align-items-center gap-1 text-4 fw-medium">
              {formik.errors.general_error && (
                <div className="Input__Error-Icon-Container">
                  <img className="Input__Error-Icon" src={ErrorIcon} />
                </div>
              )}
              % per hour
            </h3>
            {formik.errors.general_error && (
              <span className="Input__Error text-6">
                {formik.errors.general_error}
              </span>
            )}
          </div>

          <div className="HotWaterDemand_Grid overflow-auto">
            <div className="HotWaterDemand_Grid_Percentages">
              <span className="text-6 text-secondary pb-2 pb-md-1">%</span>
              <span className="text-6 text-secondary pb-2 pb-md-1">%</span>
              <span className="text-6 text-secondary pb-2 pb-md-1">%</span>
              <span className="text-6 text-secondary pb-2 pb-md-1">%</span>
              <span className="d-block d-sm-none text-6 text-secondary pb-2 pb-md-1">
                %
              </span>
              <span className="d-block d-sm-none text-6 text-secondary pb-2 pb-md-1">
                %
              </span>
            </div>
            {percentagesPerHour.map((item, index) => (
              <Input
                key={`Input-Percentage-Per-Hour-${index}`}
                label={item.label}
                name={item.name}
                value={item.value}
                placeholder="0"
                type='number'
                onChange={formik.handleChange}
                error={item.error}
                // warning={item.warning}
                small
              />
            ))}
          </div>

          {!modalId && (
            <div className="d-flex justify-content-between space-be gap-2 w-100">
              <p className="text-5 fw-medium">
                  Save profile for future simulations?
              </p>
              <div className='d-flex gap-2'>
                <p className='text-5'>{formik.values.is_showed ? 'Yes' : 'No'}</p>
                <Switch active={formik.values.is_showed} onClick={() =>formik.setFieldValue('is_showed', !formik.values.is_showed)} />
              </div>
            </div>
          )}
        </div>

        {serverError ? <p className='text-red'>Something went wrong</p> : <></>}

        <Button
          className={classNames('w-100', { 'mt-2': modalId })}
          type="submit"
          onClick={() => formik.handleSubmit()}
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? (
            <>
              <span>{!modalId ? 'Creating' : 'Saving'}</span>
              <LoadingDots className='enter-done' />
            </>
          ) : (
            <span>{!modalId ? 'Create' : 'Save'}</span>
          )}
        </Button>
      </Modal>
    )

  if (step === 2)
    return (
      <CheckModal
        closeModal={closeShowNewProfileModal}
        title={!modalId ? '+ Add new profile' : 'Edit profile'}
        message={
          !modalId
            ? 'The new profile has been added successfully, now you can select it in the simulation'
            : 'Profile has been edited successfully'
        }
      />
    )
}

export default NewProfileModal
