import { useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { getPanelModelDetails } from '../api/simulations/system-parameters/solar-thermal-field'
import useAppContext from '../hooks/useAppContext'
import useNewSimulationContext from '../hooks/useNewSimulationContext'
import useModalsContext from '../hooks/useModalsContext'
import CreateSimulationContainer from '../containers/CreateSimulationContainer'
import Checkboxes from './Checkboxes'
import EditButton from './EditButton'
import Input from './Input'
import InputSmall from './InputSmall'
import SearchInput from './SearchInput'
import { checkboxOptions, normalizePercentToShow, threeDecimalRegex } from '../constants'
import PanelIcon from '../assets/icons/panel.svg'
import SimulationSwitch from './SimulationSwitch'

interface Props {
  available: boolean;
  isSecondForm: boolean;
  checkboxSelected?: boolean;
  handleSelect?: (value: boolean) => void;
  panelName: string;
  panels: any;
  setPanels: (value: any) => void;
}

const SolarThermalFieldForm = ({
  available,
  isSecondForm,
  checkboxSelected,
  handleSelect,
  panelName,
  panels,
  setPanels,
}: Props) => {
  const { token } = useAppContext()
  const { panelModels } = useNewSimulationContext()
  const { panelModelTypeSelected, setPanelModelTypeSelected } = useModalsContext()
  const [ edit, setEdit ] = useState(false)
  const [normalizedModels, setNormalizedModels] = useState([])
  const [modelSelected, setModelSelected] = useState({
    aperture_area: '',
    c1: '',
    c2: '',
    c3: '',
    c4: '',
    c5: '',
    c6: '',
    collector_height: '',
    collector_width: '',
    deleted: '',
    gross_area: '',
    id: '',
    is_admin_creation: '',
    kd: '',
    maximum_power_point_current: '',
    maximum_power_point_voltage: '',
    model_name: '',
    nominal_operating_cell_temperature: '',
    number_of_cells: '',
    open_circuit_voltage: '',
    optical_efficiency: '',
    owner: '',
    panel_efficiency_capacity: '',
    panel_type: '',
    peak_eletricity_capacity: '',
    power_thermal_capacity: '',
    short_circuit_current: '',
    temperature_coefficient_max_power_point: '',
    temperature_coefficient_open_circuit_voltage_yVoc: '',
    temperature_coefficient_open_circuit_voltage_ylsc: '',
    transversal_10: '',
    transversal_20: '',
    transversal_30: '',
    transversal_40: '',
    transversal_50: '',
    transversal_60: '',
    transversal_70: '',
    transversal_80: '',
    transversal_90: '',
    longitudinal_10: '',
    longitudinal_20: '',
    longitudinal_30: '',
    longitudinal_40: '',
    longitudinal_50: '',
    longitudinal_60: '',
    longitudinal_70: '',
    longitudinal_80: '',
    longitudinal_90: '',
  })

  const yupNumberValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))

  const yupIntegerValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('integer', 'Only integers', value => Number.isInteger(Number(value)))

  const yupDegreeValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))
    .test('min', 'Min: -90°', value => value >= -90)
    .test('max', 'Max: 90°', value => value <= 90)


  const formik = useFormik({
    initialValues: {
      name: '',
      general_panel_parameters: '',
      panel_parameters: '',
      panel_type: '',
      panel_model: null,
      number_of_panels: '',
      type_of_roof: '',
      panel_orientation: '',
      panel_tilt: '',
      number_of_panels_per_row: '',
      distance_between_rows: 4.5,
      lenght_of_piping_between_solar_field_and_pump: 30,
      positional_parameters: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      panel_type: Yup.string().required('Required'),
      panel_model: Yup.number().nullable().required('Required'),
      number_of_panels: yupIntegerValidation.test('min', 'Min: 5', value => value >= 5).test('max', 'Max: 5000', value => value <= 5000),
      type_of_roof: Yup.string().required('Required'),
      panel_orientation: yupDegreeValidation,
      panel_tilt: yupDegreeValidation,
      number_of_panels_per_row: yupIntegerValidation.test('min', 'Min: 1', value => value >= 1).test('max', 'Max: 20', value => value <= 20),
      distance_between_rows: yupNumberValidation.test('min', 'Min: 0.1', value => value >= 0.1).test('max', 'Max: 10', value => value <= 10),
      lenght_of_piping_between_solar_field_and_pump: yupNumberValidation.test('min', 'Min: 5', value => value >= 5).test('max', 'Max: 100', value => value <= 100)
    }),
    onSubmit: () => {
      return
    },
  })

  // Set formik panel
  useEffect(() => {
    const newPanels = panels

    newPanels[panelName] = formik

    setPanels(newPanels)
  }, [formik.isValidating])

  // Set panel type selected in context
  useEffect(() => {
    if (formik.values.panel_type) {
      if (!isSecondForm) {
        setPanelModelTypeSelected({
          ...panelModelTypeSelected,
          firstPanel: formik.values.panel_type
        })
      } else if(panelModelTypeSelected.firstPanel) {
        setPanelModelTypeSelected({
          ...panelModelTypeSelected,
          secondPanel: formik.values.panel_type
        })
      }
    }
  }, [formik.values.panel_type])

  // Normalize panel models
  useEffect(() => {
    const getCurrentPanelModels = async () => {
      const newNormalizedModels = panelModels
        .map((model) => ({
          id: model.id,
          value: model.model_name,
          type: model.panel_type,
        }))

      // Reset if panel was changed
      if(formik.values.panel_model) {
        const existPanelModel = newNormalizedModels.find((model) => model.id == formik.values.panel_model)
        if(!existPanelModel || existPanelModel.value !== modelSelected.model_name) {
          formik.setFieldValue('panel_model', '')
          formik.setStatus('reset')
          setTimeout(() => formik.setStatus(''), 150)
        }
      }

      setNormalizedModels(newNormalizedModels)
    }

    if (token && panelModels.length > 0) {
      getCurrentPanelModels()
    }
  }, [token, panelModels])

  // Set model selected
  useEffect(() => {
    const getCurrentModelDetails = async () => {
      const response = await getPanelModelDetails(token, formik.values.panel_model)

      if(response.error) {
        formik.setFieldValue('panel_model', '')
        formik.setStatus('reset')
        setTimeout(() => formik.setStatus(''), 150)
      }

      if (response?.id) setModelSelected({
        ...response,
        optical_efficiency: normalizePercentToShow(response.optical_efficiency),
        panel_efficiency_capacity: normalizePercentToShow(response.panel_efficiency_capacity),
        temperature_coefficient_max_power_point: normalizePercentToShow(response.temperature_coefficient_max_power_point),
        temperature_coefficient_open_circuit_voltage_yVoc: normalizePercentToShow(response.temperature_coefficient_open_circuit_voltage_yVoc),
        temperature_coefficient_open_circuit_voltage_ylsc: normalizePercentToShow(response.temperature_coefficient_open_circuit_voltage_ylsc),
        transversal_10: response.positional_fields.transversal_10,
        transversal_20: response.positional_fields.transversal_20,
        transversal_30: response.positional_fields.transversal_30,
        transversal_40: response.positional_fields.transversal_40,
        transversal_50: response.positional_fields.transversal_50,
        transversal_60: response.positional_fields.transversal_60,
        transversal_70: response.positional_fields.transversal_70,
        transversal_80: response.positional_fields.transversal_80,
        transversal_90: response.positional_fields.transversal_90,
        longitudinal_10: response.positional_fields.longitudinal_10,
        longitudinal_20: response.positional_fields.longitudinal_20,
        longitudinal_30: response.positional_fields.longitudinal_30,
        longitudinal_40: response.positional_fields.longitudinal_40,
        longitudinal_50: response.positional_fields.longitudinal_50,
        longitudinal_60: response.positional_fields.longitudinal_60,
        longitudinal_70: response.positional_fields.longitudinal_70,
        longitudinal_80: response.positional_fields.longitudinal_80,
        longitudinal_90: response.positional_fields.longitudinal_90,
      })
    }

    if (token && formik.values.panel_model) {
      getCurrentModelDetails()
    } else {
      setModelSelected({
        aperture_area: '',
        c1: '',
        c2: '',
        c3: '',
        c4: '',
        c5: '',
        c6: '',
        collector_height: '',
        collector_width: '',
        deleted: '',
        gross_area: '',
        id: '',
        is_admin_creation: '',
        kd: '',
        maximum_power_point_current: '',
        maximum_power_point_voltage: '',
        model_name: '',
        nominal_operating_cell_temperature: '',
        number_of_cells: '',
        open_circuit_voltage: '',
        optical_efficiency: '',
        owner: '',
        panel_efficiency_capacity: '',
        panel_type: '',
        peak_eletricity_capacity: '',
        power_thermal_capacity: '',
        short_circuit_current: '',
        temperature_coefficient_max_power_point: '',
        temperature_coefficient_open_circuit_voltage_yVoc: '',
        temperature_coefficient_open_circuit_voltage_ylsc: '',
        transversal_10: '',
        transversal_20: '',
        transversal_30: '',
        transversal_40: '',
        transversal_50: '',
        transversal_60: '',
        transversal_70: '',
        transversal_80: '',
        transversal_90: '',
        longitudinal_10: '',
        longitudinal_20: '',
        longitudinal_30: '',
        longitudinal_40: '',
        longitudinal_50: '',
        longitudinal_60: '',
        longitudinal_70: '',
        longitudinal_80: '',
        longitudinal_90: '',
      })
    }
  }, [token, formik.values.panel_model, panelModels])

  // Listen to edit state
  useEffect(() => {
    if(!edit && (!formik.values.distance_between_rows || !formik.values.lenght_of_piping_between_solar_field_and_pump)) {
      formik.setValues({
        ...formik.values,
        distance_between_rows: 4.5,
        lenght_of_piping_between_solar_field_and_pump: 30,
      })
    }
  }, [edit])

  // const handleOpenManageModelsModal = () => {
  //   if (!isSecondForm) {
  //     setPanelModelTypeSelected({
  //       panelSelected: 'firstPanel',
  //       firstPanel: formik.values.panel_type,
  //       secondPanel: panelModelTypeSelected.secondPanel,
  //     })
  //   } else {
  //     setPanelModelTypeSelected({
  //       panelSelected: 'secondPanel',
  //       firstPanel: panelModelTypeSelected.firstPanel,
  //       secondPanel: formik.values.panel_type,
  //     })
  //   }

  //   toggleShowManageModelsModal()
  // }

  const filterPanelModels = useMemo(() => {
    const filteredModels = normalizedModels
      .filter(model => model.type === formik.values.panel_type)
      .map(model => ({
        value: model.id,
        label: model.value,
      })) || []

    return filteredModels
  }, [normalizedModels, formik.values.panel_type])

  const panelOptions = [
    {
      label: 'T',
      value: 'T',
    },
    {
      label: 'PVT',
      value: 'PVT',
    },
  ]

  const roofOptions = [
    {
      label: 'Flat',
      value: 'Flat',
    },
    {
      label: 'Sloped',
      value: 'Sloped',
    },
  ]

  const angleLabels = ['10°', '20°', '30°', '40°', '50°', '60°', '70°', '80°', '90°']

  const tansversalInputs = [
    {
      name: 'transversal_10',
      value: modelSelected.transversal_10,
    }, {
      name: 'transversal_20',
      value: modelSelected.transversal_20,
    }, {
      name: 'transversal_30',
      value: modelSelected.transversal_30,
    }, {
      name: 'transversal_40',
      value: modelSelected.transversal_40,
    }, {
      name: 'transversal_50',
      value: modelSelected.transversal_50,
    }, {
      name: 'transversal_60',
      value: modelSelected.transversal_60,
    }, {
      name: 'transversal_70',
      value: modelSelected.transversal_70,
    }, {
      name: 'transversal_80',
      value: modelSelected.transversal_80,
    }, {
      name: 'transversal_90',
      value: modelSelected.transversal_90,
    }
  ]

  const longitudinalInputs = [
    {
      name: 'longitudinal_10',
      value: modelSelected.longitudinal_10,
    }, {
      name: 'longitudinal_20',
      value: modelSelected.longitudinal_20,
    }, {
      name: 'longitudinal_30',
      value: modelSelected.longitudinal_30,
    }, {
      name: 'longitudinal_40',
      value: modelSelected.longitudinal_40,
    }, {
      name: 'longitudinal_50',
      value: modelSelected.longitudinal_50,
    }, {
      name: 'longitudinal_60',
      value: modelSelected.longitudinal_60,
    }, {
      name: 'longitudinal_70',
      value: modelSelected.longitudinal_70,
    }, {
      name: 'longitudinal_80',
      value: modelSelected.longitudinal_80,
    }, {
      name: 'longitudinal_90',
      value: modelSelected.longitudinal_90,
    }
  ]

  return (
    <>
      <CreateSimulationContainer available={available} hide={isSecondForm && !available}>
        <header className="d-flex align-items-center flex-wrap gap-2">
          <img
            src={PanelIcon}
            className="CreateSimulation__Title-Icon"
            alt="Solar thermal field"
            title="Solar thermal field"
          />

          <h2 className="text-4 fw-semibold">Solar thermal field</h2>

          <h3 className="text-5 fw-normal">
            Important decision: The “PVT or T” type panel enables below content
          </h3>
        </header>

        <Input
          label="Name of PVT of T field"
          name="name"
          value={formik.values.name}
          placeholder="Enter field name"
          onChange={formik.handleChange}
          error={formik.errors.name}
          disabled={!available}
        />

        <Input
          label="Type of panel"
          name="panel_type"
          list={panelOptions}
          value={
            formik.values.panel_type
              ? panelOptions.find(
                (panel) => panel.value === formik.values.panel_type
              )
              : {
                label: '',
                value: '',
              }
          }
          placeholder="Select an option"
          onChange={(name, item) => {
            formik.setFieldValue(name, item.value)
            formik.setStatus('reset')
            setTimeout(() => formik.setStatus(''), 150)
          }}
          error={formik.errors.panel_type}
          disabled={!available}
        />

        <SearchInput
          label="Model"
          name="panel_model"
          search={filterPanelModels}
          value={formik.values.panel_model}
          placeholder="Select an option"
          onChange={(id) => formik.setFieldValue('panel_model', id)}
          error={formik.errors.panel_model}
          reset={formik.status}
          disabled={!available || !formik.values.panel_type || !filterPanelModels.length}
          disabledPlaceholder={!formik.values.panel_type ? 'Select a panel type' : !filterPanelModels.length ? 'No models available for this panel type' : undefined}
          // buttonLabel={formik.values.panel_type ? 'Manage' : ''}
          // buttonIcon={ListIcon}
          // buttonOnClick={handleOpenManageModelsModal}
        />

        <SimulationSwitch label='Show additional information of the panel'>
          <InputSmall
            topLabel="Power thermal capacity Wpth"
            rightLabel="W"
            name="power_thermal_capacity"
            value={modelSelected.power_thermal_capacity}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="Gross area"
            rightLabel="m2"
            name="gross_area"
            value={modelSelected.gross_area}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="Aperture area"
            rightLabel="m2"
            name="aperture_area"
            value={modelSelected.aperture_area}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="Collector height"
            rightLabel="m"
            name="collector_height"
            value={modelSelected.collector_height}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="Collector width"
            rightLabel="m"
            name="collector_width"
            value={modelSelected.collector_width}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="Optical efficiency eta0b"
            rightLabel="%"
            name="optical_efficiency"
            value={modelSelected.optical_efficiency}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="Kd"
            name="kd"
            value={modelSelected.kd}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="c1"
            rightLabel="W/m2K"
            name="c1"
            value={modelSelected.c1}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="c2"
            rightLabel="W/m2K"
            name="c2"
            value={modelSelected.c2}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="c3"
            rightLabel="J/m3K"
            name="c3"
            value={modelSelected.c3}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="c4"
            name="c4"
            value={modelSelected.c4}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="c5"
            rightLabel="J/m2K"
            name="c5"
            value={modelSelected.c5}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="c6"
            rightLabel="s/m"
            name="c6"
            value={modelSelected.c6}
            placeholder="0"
            disabled
          />
        </SimulationSwitch>
        
        <SimulationSwitch label='Show angle & KθT,coll information'>
          <div className="d-flex flex-column gap-2">
            <h3 className="text-5 fw-medium">Incidence angle modifier</h3>

            <div className="CreateSimulation__Grid Ten">
              {angleLabels.map((item, index) => (
                <Input
                  key={`Input-Angle-${index}`}
                  name={`name-${item}`}
                  value={item}
                  placeholder="0"
                  disabled={true}
                  small
                />
              ))}
              <div className="text-6 fw-medium text-secondary text-center" style={{ minWidth: '36px' }}>Angle</div>
            </div>
          </div>

          <div className="d-flex flex-column gap-2">
            <h3 className="text-5 fw-medium">Transversal</h3>

            <div className="CreateSimulation__Grid Ten">
              {tansversalInputs.map((item, index) => (
                <Input
                  key={`Input-Transversal-${index}`}
                  name={item.name}
                  value={item.value}
                  placeholder="0"
                  onChange={formik.handleChange}
                  small
                  disabled
                />
              ))}
              <div className="text-6 fw-medium text-secondary text-center" style={{ minWidth: '36px' }}>KθT,coll</div>
            </div>
          </div>

          <div className="d-flex flex-column gap-2">
            <h3 className="text-5 fw-medium">
                  Longitudinal
            </h3>

            <div className="CreateSimulation__Grid Ten">
              {longitudinalInputs.map((item, index) => (
                <Input
                  key={`Input-Angle-${index}`}
                  name={item.name}
                  value={item.value}
                  placeholder="0"
                  onChange={formik.handleChange}
                  small
                  disabled
                />
              ))}
              <div className="text-6 fw-medium text-secondary text-center" style={{ minWidth: '36px' }}>KθL,coll</div>
            </div>
          </div>
        </SimulationSwitch>
        
        <SimulationSwitch label='Show PV data'>
          <InputSmall
            topLabel="Peak electrical capacity Wpel"
            rightLabel="W"
            name="peak_eletricity_capacity"
            value={modelSelected.peak_eletricity_capacity}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="Panel efficiency at STC"
            rightLabel="%"
            name="panel_efficiency_capacity"
            value={modelSelected.panel_efficiency_capacity}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="Maximum power point voltage Vmpp"
            rightLabel="V"
            name="maximum_power_point_voltage"
            value={modelSelected.maximum_power_point_voltage}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="Maximum power point current Impp"
            rightLabel="A"
            name="maximum_power_point_current"
            value={modelSelected.maximum_power_point_current}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="Open circuit voltage Voc"
            rightLabel="V"
            name="open_circuit_voltage"
            value={modelSelected.open_circuit_voltage}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="Short circuit current Isc"
            rightLabel="A"
            name="short_circuit_current"
            value={modelSelected.short_circuit_current}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="Temperature coefficient for maximum power point ympp"
            rightLabel="%/°C"
            name="temperature_coefficient_max_power_point"
            value={modelSelected.temperature_coefficient_max_power_point}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="Temperature coefficient for open circuit voltage yVoc"
            rightLabel="%/°C"
            name="temperature_coefficient_open_circuit_voltage_yVoc"
            value={
              modelSelected.temperature_coefficient_open_circuit_voltage_yVoc
            }
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="Temperature coefficient for open circuit voltage ylsc"
            rightLabel="%/°C"
            name="temperature_coefficient_open_circuit_voltage_ylsc"
            value={
              modelSelected.temperature_coefficient_open_circuit_voltage_ylsc
            }
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="Nominal operating cell temperature TNOCT"
            rightLabel="°C"
            name="nominal_operating_cell_temperature"
            value={modelSelected.nominal_operating_cell_temperature}
            placeholder="0"
            disabled
          />

          <InputSmall
            topLabel="Number of cells in series"
            rightLabel='n°'
            name="number_of_cells"
            value={modelSelected.number_of_cells}
            placeholder="0"
            disabled
          />
        </SimulationSwitch>
      </CreateSimulationContainer>

      {/* <CreateSimulationContainer available={available} hide={isSecondForm && !available}>
        <header className="d-flex align-items-center flex-wrap gap-2">
          <img src={PanelIcon} alt="PV data" title="PV data" />

          <h2 className="text-4 fw-semibold">PV data</h2>
        </header>
      </CreateSimulationContainer> */}

      <CreateSimulationContainer available={available} hide={isSecondForm && !available}>
        <EditButton setEdit={setEdit} className="ms-auto ms-md-0" available={available} />

        
        <Input
          label="Number of panels"
          name="number_of_panels"
          type="number"
          value={formik.values.number_of_panels}
          placeholder="Enter number of panels"
          onChange={formik.handleChange}
          error={formik.errors.number_of_panels}
          disabled={!available}
        />

        <Input
          label="Type of roof"
          name="type_of_roof"
          list={roofOptions}
          value={
            formik.values.type_of_roof
              ? roofOptions.find(
                (roof) => roof.value === formik.values.type_of_roof
              )
              : {
                label: '',
                value: '',
              }
          }
          placeholder="Select an option"
          onChange={(name, item) => formik.setFieldValue(name, item.value)}
          error={formik.errors.type_of_roof}
          disabled={!available}
        />

        <InputSmall
          topLabel="Panel orientation"
          rightLabel="deg°"
          name="panel_orientation"
          value={formik.values.panel_orientation}
          placeholder="0"
          onChange={formik.handleChange}
          error={formik.errors.panel_orientation}
          disabled={!available}
          tip="Panel orientation is defined positive when facing the equator: 0 = facing the equator, 90 facing the west, -90 facing the east"
        />

        <InputSmall
          topLabel="Panel tilt"
          rightLabel="deg°"
          name="panel_tilt"
          value={formik.values.panel_tilt}
          placeholder="0"
          onChange={formik.handleChange}
          error={formik.errors.panel_tilt}
          disabled={!available}
          tip="Panel tilt is defined positive when tilted towards the equator: 0 = horizontal, 90 = vertical and facing the azimuth, -90 = vertical and facing away from the axis"
        />

        <InputSmall
          topLabel="Number of panels in a row"
          rightLabel="n°"
          name="number_of_panels_per_row"
          value={formik.values.number_of_panels_per_row}
          placeholder="0"
          onChange={formik.handleChange}
          error={formik.errors.number_of_panels_per_row}
          disabled={!available}
        />

        <InputSmall
          topLabel="Distance between two rows"
          rightLabel="m"
          name="distance_between_rows"
          value={formik.values.distance_between_rows}
          placeholder="4.5"
          onChange={formik.handleChange}
          error={formik.errors.distance_between_rows}
          disabled={!available || !edit}
          edit
        />

        <InputSmall
          topLabel="Length of piping between solar field & the pump station"
          rightLabel="m"
          name="lenght_of_piping_between_solar_field_and_pump"
          value={formik.values.lenght_of_piping_between_solar_field_and_pump}
          placeholder="30"
          onChange={formik.handleChange}
          error={formik.errors.lenght_of_piping_between_solar_field_and_pump}
          disabled={!available || !edit}
          edit
        />

        {panelName == 'panel_one' && (
          <Checkboxes
            label='Include another PVT or a T field?'
            options={checkboxOptions}
            optionSelected={checkboxSelected}
            small
            handleSelect={handleSelect}
            disabled={!available}
          />
        )}
      </CreateSimulationContainer>
    </>
  )
}

export default SolarThermalFieldForm
