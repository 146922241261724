import Modal from './Modal'
import {
  IList,
  ILocation,
  IMail,
  IOrganization,
  IPhone,
} from '../../iconComponents'
import { Client } from '../../api/apiTypes'
import useAppContext from '../../hooks/useAppContext'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { formatPhoneNumberIntl } from 'react-phone-number-input'

interface Props {
  closeModal: () => void;
  info: Client;
}

const ClientInfoModal = ({ closeModal, info }: Props) => {
  const { isCentralAdmin } = useAppContext()
  const data = [
    {
      icon:  <ILocation />,
      title: 'Location',
      value: `${info.country_name || '-'}, ${info.address || '-'}`,
    },
    {
      icon:  <IList />,
      title: 'Simulations',
      value: info.simulation_count ?? '-',
    },
    {
      icon:  <IMail />,
      title: 'Email',
      value: info.email || '-',
    },
    {
      icon:  <IPhone />,
      title: 'Phone number',
      value: isValidPhoneNumber(info.phone) ? formatPhoneNumberIntl(info.phone) : info.phone || '-',
    },
  ]
  if (isCentralAdmin)
    data.splice(0, 0, {
      icon:  <IOrganization />,
      title: 'Organization',
      value: info.organization_name,
    })

  return (
    <Modal closeModal={closeModal} title="Simulation information">
      <p className=" p-1 text-center text-4 fw-medium">{info.name || '-'}</p>
      <div className="Modal__Divider" />

      <div className="DeleteSimulationModal__Grid my-4">
        {data.map((item, index) => (
          <div className="DeleteSimulationModal__Grid_Item" key={index}>
            <div className="DeleteSimulationModal__Grid_Item_Title">
              {item.icon}
              <p className="text-5 text-secondary">{item.title}</p>
            </div>
            <div className="DeleteSimulationModal__Grid_Item_Info text-5">
              {item.value}
            </div>
          </div>
        ))}
      </div>
    </Modal>
  )
}

export default ClientInfoModal