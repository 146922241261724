import { Helmet } from 'react-helmet'
import { ChangeEventHandler, useState } from 'react'
import { useQuery } from 'react-query'
import Input from '../../components/Input'
import TableFilters from '../../components/TableFilters'
import TableFiltersMobile from '../../components/TableFiltersMobile'
import Table from '../../components/tables/Table'
import TableTitle from '../../components/TableTitle'
import useAppContext from '../../hooks/useAppContext'
import { FiltersGroup, FiltersSelected, TableHeaders } from '../../types'
import { getCountries, getOrganizations } from '../../api/organizations'
import TableOrganizations from '../../components/tables/TableOrganizations'
import {
  IAdmin,
  ILocation,
  IMail,
  IOrganization,
  IPhone,
  ISearch,
  IUser,
  IWorld,
} from '../../iconComponents'
import { useNavigate } from 'react-router-dom'
import NewOrganizationModal from '../../components/modals/NewOrganizationModal'
import useOrderby from '../../hooks/useOrderby'
import EditOrganizationModal from '../../components/modals/EditOrganizationModal'
import { Organization } from '../../api/apiTypes'
import useMatchMedia from '../../hooks/useMatchMedia'
import DeleteOrganizationModal from '../../components/modals/DeleteOrganizationModal'

const Organizations = () => {
  const { token } = useAppContext()
  const [page, setPage] = useState(1)
  const perPage = 20
  const [search, setSearch] = useState('')
  const { orderBy, sort, handleSort } = useOrderby('name')
  const [filtersSelected, setFiltersSelected] = useState<FiltersSelected[]>([
    {
      label: 'Country',
      search: '',
      defaultOption: {
        label: 'All',
        value: '',
      },
      option: {
        label: 'All',
        value: '',
      },
    },
  ])
  const params = {
    page,
    per_page: perPage,
    search,
    order_by: orderBy,
  }
  const initialData = { page: 1, total_items: 0, total_pages: 1, data: [] }
  const {
    data: organizations,
    isFetching,
    refetch,
  } = useQuery(
    ['getOrganizations', page, search, orderBy],
    () => getOrganizations(token, initialData, params),
    {
      initialData,
      enabled: token !== '',
    }
  )
  const { data: countries } = useQuery(
    ['getCountries'],
    () => getCountries(token),
    {
      initialData: {
        user_countries: [],
        all_countries: [],
      }, // TODO
      enabled: token !== '',
      refetchOnWindowFocus: false,
    }
  )
  const [showNewOrganizationModal, setShowNewOrganizationModal] =
    useState(false)
  const [showEditOrganizationModal, setShowEditOrganizationModal] =
    useState(false)
  const [showDeleteOrganizationModal, setShowDeleteOrganizationModal] =
    useState(false)
  const [organizationSelected, setOrganizationSelected] =
    useState<Organization>(null)

  const isMobile = useMatchMedia()
  const navigate = useNavigate()

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    setPage(1)
    setSearch(event.target.value)
  }

  const filtersGroup: FiltersGroup[] = [
    {
      label: 'Country',
      options: countries.all_countries
        ? countries.all_countries.map((country) => ({
          label: country.name,
          value: country.name,
        }))
        : [],
    },
  ]

  const tableHeaders: TableHeaders[] = [
    {
      id: 0,
      label: 'Organization’s Name',
      value: 'name',
      icon: <IOrganization width={18} height={18} />,
    },
    {
      id: 1,
      label: 'Country',
      value: 'address',
      icon: <ILocation width={18} height={18} />,
    },
    {
      id: 2,
      label: 'Operating countries',
      value: 'operating_countries',
      icon: <IWorld width={18} height={18} />,
    },
    {
      id: 3,
      label: 'Users',
      value: 'users',
      icon: <IUser width={18} height={18} />,
    },
    {
      id: 4,
      label: 'Admins',
      value: '',
      icon: <IAdmin width={18} height={18} />,
    },
    {
      id: 5,
      label: 'Phone',
      value: 'phone',
      icon: <IPhone width={18} height={18} />,
    },
    {
      id: 6,
      label: 'Email',
      value: 'email',
      icon: <IMail width={18} height={18} />,
    },
    {
      id: 7,
      label: 'Actions',
      value: '',
    },
  ]

  const openShowNewOrganizationModal = () => setShowNewOrganizationModal(true)

  const closeShowNewOrganizationModal = () =>
    setShowNewOrganizationModal(false)

  const openShowEditOrganizationModal = (organization: Organization) => {
    setOrganizationSelected(organization)
    setShowEditOrganizationModal(true)
  }

  const closeShowEditOrganizationModal = () =>
    setShowEditOrganizationModal(false)

  const openShowDeleteOrganizationModal = (organization: Organization) => {
    setOrganizationSelected(organization)
    setShowDeleteOrganizationModal(true)
  }

  const closeShowDeleteOrganizationModal = () =>
    setShowDeleteOrganizationModal(false)

  return (
    <>
      <Helmet>
        <title>Solarus: Organizations</title>
      </Helmet>
      <section className="Dashboard">
        <div>
          <Input
            name="Search"
            placeholder="Search"
            value={search}
            onChange={handleSearch}
            icon={<ISearch className="Input__Icon" />}
          />
        </div>

        <div className="Dashboard__Filters_Container">
          <div className="Dashboard__Filters_Container_Mobile d-flex justify-between d-md-none pr-1 w-100">
            <TableFiltersMobile
              filtersSelected={filtersSelected}
              setFiltersSelected={setFiltersSelected}
              filtersGroup={filtersGroup}
              headers={tableHeaders}
              sort={sort}
              handleSort={handleSort}
            />
            <button
              className="button-yellow text-5"
              onClick={openShowNewOrganizationModal}
            >
              New organization
            </button>
          </div>

          <TableFilters
            filtersSelected={filtersSelected}
            setFiltersSelected={setFiltersSelected}
            filtersGroup={filtersGroup}
          />
          <TableTitle totalItems={organizations.data?.length} />

          <button
            className="button-yellow ms-auto d-none d-md-flex"
            onClick={openShowNewOrganizationModal}
          >
            New organization
          </button>
        </div>

        <Table
          type={'Organizations Special'}
          firstHeaderEmpty
          headers={tableHeaders}
          dataLength={organizations.data?.length}
          loading={isFetching || token === ''}
          sort={sort}
          handleSort={handleSort}
          page={page}
          setPage={setPage}
          perPage={perPage}
          totalPages={organizations.total_pages}
          totalItems={organizations.total_items}
        >
          {organizations.data.map((item) => {
            return (
              <TableOrganizations
                key={item.id}
                data={item}
                handleSelectItem={(item) =>
                  navigate(`organization-detail/${item.id}`)
                }
                openEdit={openShowEditOrganizationModal}
                openDelete={openShowDeleteOrganizationModal}
                isMobile={isMobile}
              />
            )
          })}
        </Table>
      </section>

      {showNewOrganizationModal ? (
        <NewOrganizationModal
          refetch={refetch}
          closeModal={closeShowNewOrganizationModal}
        />
      ) : (
        <></>
      )}

      {showEditOrganizationModal ? (
        <EditOrganizationModal
          organization={organizationSelected}
          refetch={refetch}
          closeModal={closeShowEditOrganizationModal}
        />
      ) : (
        <></>
      )}

      {showDeleteOrganizationModal ? (
        <DeleteOrganizationModal
          organizationInfo={organizationSelected}
          refetch={refetch}
          closeModal={closeShowDeleteOrganizationModal}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default Organizations
