import { ITick } from '../../../iconComponents'

interface Props {
  title: string;
  info?: string;
}

const EndStep = ({ title, info }: Props) => {
  return (
    <div className="CheckModal h-100">
      <ITick width={64} height={64} className="text-completed" />
      <h4 className="text-3 fw-medium">{title}</h4>
      {info ? <p className="text-4 fw-normal">{info}</p> : <></>}
    </div>
  )
}

export default EndStep
