import { isValidPhoneNumber } from 'libphonenumber-js'
import { OrganizationDetail } from '../api/apiTypes'
import { IAdmin, IEdit, ILocation, IMail, IPhone, IUser, IWorld } from '../iconComponents'
import TableOrganizations from './tables/TableOrganizations'
import { formatPhoneNumberIntl } from 'react-phone-number-input'

interface Props {
  organization: OrganizationDetail;
  openShowEditOrganizationModal: () => void;
}

const OrganizationInfo = ({ organization, openShowEditOrganizationModal }: Props) => {
  return (
    <>
      <div className="Dashboard__Table_path_info_Info_content d-none d-md-flex">
        <div>
          <div className='d-flex justify-content-between align-items-center'>
            <p className="text-5 fw-medium text-secondary">Organization info</p>
            <IEdit className='text-5 text-secondary cursor-pointer' onClick={() => openShowEditOrganizationModal()} />
          </div>
          <p className="text-4 fw-medium text-primary mt-2">{organization.name}</p>
        </div>

        <div className="Modal__Divider" />

        <div>
          <div className="flex align-items-center gap-1">
            <ILocation width={16} height={16} className="text-secondary" />
            <p className="text-5 fw-medium text-secondary">Country</p>
          </div>
          <p className='mt-1 ms-3 ps-1 text-5 text-primary'>
            {organization.country_residence.country_name}
          </p>
        </div>
        <div>
          <div className="flex align-items-center gap-1">
            <ILocation width={16} height={16} className="text-secondary" />
            <p className="text-5 fw-medium text-secondary">Address</p>
          </div>
          <p className='mt-1 ms-3 ps-1 text-5 text-primary'>
            {organization.address}
          </p>
        </div>
        <div className='pt-3'>
          <div className="flex align-items-center gap-1">
            <IWorld width={16} height={16} className="text-secondary" />
            <p className="text-5 fw-medium text-secondary">Countries</p>
          </div>
          <p className='mt-1 ms-3 ps-1 text-5 text-primary'>
            {organization?.operating_countries ?? '-'}
          </p>
        </div>
        <div>
          <div className="flex align-items-center gap-1">
            <ILocation width={16} height={16} className="text-secondary" />
            <p className="text-5 fw-medium text-secondary">Regions</p>
          </div>
          <p className='mt-1 ms-3 ps-1 text-5 text-primary'>
            {organization.regions.length}
          </p>
        </div>
        <div>
          <div className="flex align-items-center gap-1">
            <IUser width={16} height={16} className="text-secondary" />
            <p className="text-5 fw-medium text-secondary">Users</p>
          </div>
          <p className='mt-1 ms-3 ps-1 text-5 text-primary'>
            {organization.users}
          </p>
        </div>
        <div>
          <div className="flex align-items-center gap-1">
            <IAdmin width={16} height={16} className="text-secondary" />
            <p className="text-5 fw-medium text-secondary">Admin</p>
          </div>
          <p className='mt-1 ms-3 ps-1 text-5 text-primary'>
            {organization.admins.length !== 0
              ? organization.admins
                .reduce((acc, cur) => `${acc} ${cur.first_name ? `${cur.first_name} ${cur.last_name}`: 'Invitation sent' } , `, '')
                .slice(0, -2)
              : '-'}
          </p>
        </div>
        <div>
          <div className="flex align-items-center gap-1">
            <IPhone width={16} height={16} className="text-secondary" />
            <p className="text-5 fw-medium text-secondary">Phone</p>
          </div>
          <p className='mt-1 ms-3 ps-1 text-5 text-primary'>
            {isValidPhoneNumber(organization.phone) ? formatPhoneNumberIntl(organization.phone) : organization.phone || '-'}
          </p>
        </div>
        <div>
          <div className="flex align-items-center gap-1">
            <IMail width={16} height={16} className="text-secondary" />
            <p className="text-5 fw-medium text-secondary">Email</p>
          </div>
          <p className='mt-1 ms-3 ps-1 text-5 text-primary'>
            {organization.email || '-'}
          </p>
        </div>
      </div>
      <div className="TransferSimulation__Simulation_Info_content d-flex d-md-none">
        <p className="text-5 fw-light text-primary">Organization information</p>
        <div className='Dashboard__Table_path_info_Info_mobile'>
          <TableOrganizations data={organization} openEdit={openShowEditOrganizationModal} isMobile unselectable />
        </div>
      </div>
    </>
  )
}

export default OrganizationInfo