import { SVGProps } from 'react'

export default function EditIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 17 16"
      fill="currentColor"
      {...props}
    >
      <path
        d="M12.4188 6.38356L10.0933 4.08219L10.8594 3.31507C11.0691 3.10502 11.3268 3 11.6325 3C11.9382 3 12.1957 3.10502 12.4051 3.31507L13.1711 4.08219C13.3809 4.29224 13.4903 4.54575 13.4994 4.84274C13.5085 5.13973 13.4082 5.39306 13.1985 5.60274L12.4188 6.38356ZM11.6254 7.19178L5.82545 13H3.5V10.6712L9.29994 4.86301L11.6254 7.19178Z"
        fill="currentColor"
      />
    </svg>
  )
}
