import classNames from 'classnames'

interface Props extends React.DetailedHTMLProps<
React.ButtonHTMLAttributes<HTMLButtonElement>,
HTMLButtonElement
> {
  active: boolean
  size?: 'Small'
  color?: 'Green'
}

export default function Switch({
  className,
  active,
  size,
  color,
  ...props
}: Props) {
  return <button className={classNames('Switch',{ active }, size, color, className)} {...props} />
}
