import classNames from 'classnames'
import { IAngleUp } from '../iconComponents'
import { useState } from 'react'
import useAppContext from '../hooks/useAppContext'
import { CSSTransition } from 'react-transition-group'
import AccountInfoModal from './modals/AccountInfoModal'
import LogoutModal from './modals/LogoutModal'

export default function AccountActions() {
  const {
    user
  } = useAppContext()
  const [showUserMenu, setShowUserMenu] = useState(false)
  const [showAccountInfoModal, setShowAccountInfoModal] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false)

  const toggleUserMenu = () => setShowUserMenu((p) => !p)

  return (
    <>
      <button
        className={classNames('DashboardHeader__Account_Container text-5', {
          Active: showUserMenu,
        })}
        onBlur={() => setShowUserMenu(false)}
      >
        <div className="DashboardHeader__Account" onClick={toggleUserMenu}>
          <p className="text-blue fw-medium">{user?.first_name}</p>{' '}
          <IAngleUp className="flex-shrink-0 mt-1 rotate_180" />
        </div>

        <CSSTransition in={showUserMenu} timeout={300} unmountOnExit>
          <div className="DashboardHeader__Account_Show_Menu Animation_Opacity">
            <div
              className="DashboardHeader__Account"
              onClick={toggleUserMenu}
            >
              <p className="text-blue fw-medium">{user?.first_name}</p>{' '}
              <IAngleUp className="flex-shrink-0 mt-1" />
            </div>

            <div className="DashboardHeader__Account_Show_Menu_Items_Container">
              <div
                className="DashboardHeader__Account_Show_Menu_Items text-6 text-blue"
                onClick={() => setShowAccountInfoModal(p => !p)}
              >
                    Account
              </div>
              <div
                className="DashboardHeader__Account_Show_Menu_Items text-6 text-blue"
                onClick={() => setShowLogoutModal(p => !p)}
              >
                    Log out
              </div>
            </div>
          </div>
        </CSSTransition>
      </button>

      {showAccountInfoModal && (
        <AccountInfoModal closeModal={() => setShowAccountInfoModal(false)} />
      )}

      {showLogoutModal && <LogoutModal closeModal={() => setShowLogoutModal(false)} />}
    </>
  )
}
