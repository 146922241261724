import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { getHotWaterSystemDetails } from '../../api/simulations/input-parameters/existing-hot-water-system-details'
import { addEnvironmentalIndicators, updateEnvironmentalIndicators } from '../../api/simulations/input-parameters/enviromental-indicators'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import CreateSimulationContainer from '../../containers/CreateSimulationContainer'
import SimulationFormikHandler from '../../containers/SimulationFormikHandler'
import CreateSimulationTitle from '../../components/CreateSimulationTitle'
import EditButton from '../../components/EditButton'
import InputSmall from '../../components/InputSmall'
import Input from '../../components/Input'
import { submitStep } from '../../utils/submitStep'
import PlantIcon from '../../assets/icons/plant.svg'
import { normalizeNumberWithCommas, threeDecimalRegex } from '../../constants'

const EnvironmentalIndicators = () => {
  const { token } = useAppContext()
  const { newSimulation, updateNewSimulation, setIsStepLoading } = useNewSimulationContext()
  const [ edit, setEdit ] = useState(false)
  const [ systemSelected, setSystemSelected] = useState({
    CO2_Fuel: '',
    CO2_Fuel_txt: '',
    deleted: false,
    energy_source: '',
    id: '',
    is_admin_creation: false,
    kWH_CF_Fuel_txt: '',
    kWh_CF_Fuel: '',
    n_ex_syst: '',
    owner: '',
    system_name: '',
  })

  const yupNumberValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('min', 'Min: 0', value => value >= 0)
    .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))

  const formik = useFormik({
    initialValues: {
      electricity: '',
      CO2_saved_per_year_by_tree: 24
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      electricity: yupNumberValidation,
      CO2_saved_per_year_by_tree: yupNumberValidation
    }),
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        electricity: normalizeNumberWithCommas(values.electricity),
        CO2_saved_per_year_by_tree: normalizeNumberWithCommas(values.CO2_saved_per_year_by_tree)
      }

      await submitStep({
        pathname: location.pathname,
        lastStep: newSimulation.last_step,
        addFunction: () => addEnvironmentalIndicators(token, newSimulation.id, newValues),
        updateFunction: () => updateEnvironmentalIndicators(token, newSimulation.id, newValues),
        updateNewSimulation,
        setIsStepLoading
      })
    }
  })

  // Show saved simulation step
  useEffect(() => {
    formik.setValues({
      electricity: newSimulation.steps.environmental_indicators.electricity,
      CO2_saved_per_year_by_tree: newSimulation.steps.environmental_indicators.CO2_saved_per_year_by_tree
    })
  }, [newSimulation])

  // Normalize water systems
  useEffect(() => {
    const getCurrentWaterSystem = async () => {
      const waterSystemDetails = await getHotWaterSystemDetails(token, newSimulation.steps.existing_hot_water_system.system)

      setSystemSelected(waterSystemDetails)
    }

    if(newSimulation.steps.existing_hot_water_system.system) {
      getCurrentWaterSystem()
    }
  }, [newSimulation.steps.existing_hot_water_system.system])

  // Listen to edit state
  useEffect(() => {
    if(!edit && !formik.values.CO2_saved_per_year_by_tree) {
      formik.setFieldValue('CO2_saved_per_year_by_tree', 24)
    }
  }, [edit])

  return (
    <SimulationFormikHandler formik={formik}>
      <Helmet>
        <title>Create Simulation: Environmental Indicators</title>
      </Helmet>

      {/* First Container */}
      <CreateSimulationContainer>
        <EditButton setEdit={setEdit} available={newSimulation.steps.hot_water_demand.will_complete} className="ms-auto d-md-none" />
        <header className="d-flex align-items-center flex-wrap gap-2">
          <img
            src={PlantIcon}
            className="CreateSimulation__Title-Icon"
            alt='Environmental indicators'
            title='Environmental indicators'
          />

          <CreateSimulationTitle>Environmental indicators</CreateSimulationTitle>

          <EditButton setEdit={setEdit} available={newSimulation.steps.hot_water_demand.will_complete} className="d-none d-md-flex" />
        </header>

        <Input
          label='Hot water system'
          name='Hot water system'
          value={systemSelected?.system_name}
          placeholder='Hot water system name'
          disabled
        />

        <InputSmall
          topLabel='Electricity'
          rightLabel="kg CO2/kWh"
          name='electricity'
          value={formik.values.electricity}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.electricity}
        />

        <InputSmall
          topLabel='Kg CO2 saved per year by a single tree'
          rightLabel="kg CO2"
          name='CO2_saved_per_year_by_tree'
          value={formik.values.CO2_saved_per_year_by_tree}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.CO2_saved_per_year_by_tree}
          disabled={!edit}
          edit
          // edit='There must be a demand for hot water to edit this field'
        />

        <InputSmall
          topLabel='Energy factor'
          rightLabel="kWh to m3 gas"
          name='energy-factor'
          value={systemSelected?.kWh_CF_Fuel}
          placeholder='0'
          disabled
        />

        <InputSmall
          topLabel='Efficiency of hot water system'
          rightLabel="kWh to m3 gas"
          name='energy-factor'
          value={systemSelected?.n_ex_syst}
          placeholder='0'
          disabled
        />
      </CreateSimulationContainer>
    </SimulationFormikHandler>
  )
}

export default EnvironmentalIndicators