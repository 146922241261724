import { useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { updateSimulationReport } from '../../api/simulations/general-simulation'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import CheckIcon from '../../assets/icons/check-black.svg'
import SolarusLogo from '../../assets/images/solarus-logo.png'

const UploadReport = () => {
  const { token } = useAppContext()
  const { newSimulation } = useNewSimulationContext()
  const [file, setFile] = useState(null)
  const [error, setError] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const inputRef = useRef(null)
  const navigate = useNavigate()

  const handleData = (file) => {
    const fileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
    if (fileTypes.includes(file?.type)) {
      setFile(file)
      setError('')
    } else {
      setFile('')
      setError('Only DOC or PDF files are allowed')
    }
  }

  const handleClick = () => {
    inputRef.current.click()
  }

  const handleChange = (e) => {
    e.preventDefault()
    const files = e.target.files
    const file = files[0]

    handleData(file)
  }

  const handleDrop = (e) => {
    e.preventDefault()

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...e.dataTransfer.items].forEach((item) => {
        // If dropped items aren't files, reject them
        if (item.kind === 'file') {
          const file = item.getAsFile()

          handleData(file)
        }
      })
    } else {
      // Use DataTransfer interface to access the file(s)
      [...e.dataTransfer.files].forEach((file) => {
        handleData(file)
      })
    }
  }

  const handleDropOver = (e) => {
    e.preventDefault()
  }

  const updateReport = async () => {
    setDisabled(true)

    const response = await updateSimulationReport(
      token,
      newSimulation.id,
      file
    )

    if (response.data) {
      navigate('/dashboard/create-simulation/running-complete')
    }

    setDisabled(false)
  }

  return (
    <>
      <Helmet>
        <title>Upload Report</title>
      </Helmet>

      <div className="UploadReport">
        <img
          className="UploadReport__Logo d-none d-md-block"
          src={SolarusLogo}
          alt='Solarus - Smart Energy Solutions'
          title='Solarus - Smart Energy Solutions'
        />

        <div className='w-100 text-center d-flex flex-column align-items-center gap-3'>
          <h1 className='text-2'>Upload report</h1>

          <button
            className={classNames(
              'UploadReport__File-Input',
              { 'UploadReport__File-Input--Active': file }
            )}
            onClick={handleClick}
            onDrop={handleDrop}
            onDragOver={handleDropOver}
            title='Search on your device'
          >
            {file
              ? <>
                <span className='UploadReport__File-Name'>{file.name}</span>
                <img src={CheckIcon} />
              </>
              : error
                ? <>
                  <FontAwesomeIcon icon={faTimes} color={'#FF0000CC'} size='lg' className='me-2' />
                  {error}
                </>
                : 'Drag file or search on your device'
            }
            <input
              ref={inputRef}
              type="file"
              className='d-none'
              onChange={handleChange}
              accept='.pdf, .docx, .doc'
            />
          </button>

          <button
            className={classNames(
              'button-blue',
              { 'button-disabled': disabled || !file }
            )}
            onClick={updateReport}
            disabled={disabled || !file}
          >
            Upload report
          </button>
        </div>
      </div>
    </>
  )
}

export default UploadReport