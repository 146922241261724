import { MouseEvent } from 'react'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Simulation } from '../../api/apiTypes'
import TableItemMenu from './TableItemMenu'
import { simulationStatus } from '../../constants'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import TableItem from './TableItem'
import useAppContext from '../../hooks/useAppContext'
import { IAdmin, ICalendar, ILocation, IUser, IWork } from '../../iconComponents'
import classNames from 'classnames'

dayjs.extend(customParseFormat)

interface Props {
  data: Simulation;
  unselectable?: boolean;
  selected?: boolean;
  handleSelectItem?: (data: Simulation) => void;
  isMobile: boolean;
  action?: (simulation: Simulation) => void;
  handleCopy?: (value: string) => void;
}

export const TableSimulations = ({
  data,
  unselectable = false,
  selected = false,
  handleSelectItem,
  isMobile,
  action,
  handleCopy,
}: Props) => {
  const { isCentralAdmin, isOrganizationAdmin, isRegionAdmin } =
    useAppContext()

  const preventClick = (
    event: MouseEvent<HTMLDivElement>,
    callback: () => void
  ) => {
    event.stopPropagation()
    callback()
  }

  const actions = [
    {
      id: 0,
      label: 'Edit',
      callback: () => action(data),
    },
  ]

  const copyToClipboard = (value: string) => {
    navigator.clipboard
      .writeText(value)
      .then(() =>
        handleCopy ? handleCopy(`${value} copied to clipboard`) : null
      )
  }

  if (isMobile)
    return (
      <TableItem
        data={data}
        unselectable={unselectable}
        selected={selected}
        handleSelectItem={handleSelectItem}
      >
        <div
          className={classNames('TableItem__Cell', {
            big:
              !action || data?.status === 'waiting' || data?.status === 'running',
          })}
        >
          <p className="text-4 fw-medium">{data?.project_name || '-'}</p>
        </div>
        {action && data?.status !== 'waiting' && data?.status !== 'running' ? (
          <div className="TableItem__Cell of-visible justify-content-end">
            <TableItemMenu actions={actions} />
          </div>
        ) : (
          <></>
        )}
        <div className="TableItem__Cell">
          <ICalendar className='TableItem__Cell_Icon' />
          <p className="text-5 text-secondary">
            {data?.created_at
              ? dayjs(data.created_at, 'YYYY-MM-DD').format('DD/MM/YY')
              : '-'}
          </p>
        </div>
        <div className="TableItem__Cell">
          <ICalendar className="TableItem__Cell_Icon" />
          <p className="text-5 text-secondary">
            {data?.updated_at
              ? dayjs(data.updated_at, 'YYYY-MM-DD').format('DD/MM/YY')
              : '-'}
          </p>
        </div>
        <div className="TableItem__Cell">
          <div className={`TableItem__Circle--${data?.status}`} />
          <p className="text-5 text-secondary text-capitalize">
            {simulationStatus[data?.status] || '-'}
          </p>
        </div>
        {isCentralAdmin || isOrganizationAdmin || isRegionAdmin ? (
          <div className="TableItem__Cell">
            <IUser className="TableItem__Cell_Icon text-secondary" />
            <p className="text-5 text-secondary">{data?.owner_first_name ? `${data?.owner_first_name} ${data?.owner_last_name}` :  '-'}</p>
          </div>
        ) : (
          <></>
        )}
        <div className="TableItem__Cell">
          <IAdmin className='TableItem__Cell_Icon' />
          <p className="text-5 text-secondary">{data?.client_name || '-'}</p>
        </div>

        <div className="TableItem__Cell">
          <IWork className='TableItem__Cell_Icon' />
          <p className="text-5 text-secondary">{data?.creator_name || '-'}</p>
        </div>
        <div className="TableItem__Cell">
          <ILocation className="TableItem__Cell_Icon" />
          <p className="text-5 text-secondary">
            {data?.location_country_name || '-'}
          </p>
        </div>
        {/* <div className="TableItem__Cell">
            <img
              src={locationDotIcon}
              alt="user star icon"
              className="TableItem__Cell_Icon"
            />
            <p className="text-5 text-secondary">{'-'}</p>
          </div> */}
        <div
          className="TableItem__Cell--center TableItem__Cell--Doble_columns"
          title="Copy id to clipboard"
          onClick={(e) =>
            data?.id_number
              ? preventClick(e, () => copyToClipboard(data.id_number))
              : null
          }
        >
          {data?.id_number ? (
            <p>
              <u>{data.id_number}</u>
            </p>
          ) : (
            <p>-</p>
          )}
        </div>
      </TableItem>
    )

  return (
    <TableItem
      data={data}
      unselectable={unselectable}
      selected={selected}
      handleSelectItem={handleSelectItem}
    >
      <div className="TableItem__Cell--center">
        <div className="Checkbox" />
      </div>
      <div
        className="TableItem__Cell"
        onClick={(e) => (action ? preventClick(e, () => action(data)) : null)}
      >
        <p>
          {data?.created_at
            ? dayjs(data.created_at, 'YYYY-MM-DD').format('DD/MM/YY')
            : '-'}
        </p>
      </div>
      <div
        className="TableItem__Cell"
        onClick={(e) => (action ? preventClick(e, () => action(data)) : null)}
      >
        <p>
          {data?.updated_at
            ? dayjs(data.updated_at, 'YYYY-MM-DD').format('DD/MM/YY')
            : '-'}
        </p>
      </div>
      <div
        className="TableItem__Cell"
        onClick={(e) => (action ? preventClick(e, () => action(data)) : null)}
        title={
          data?.location_country_name
            ? `${data?.location_country_name}, ${data?.location_address}`
            : ''
        }
      >
        <p>
          {data?.location_country_name
            ? `${data?.location_country_name}, ${data?.location_address}`
            : '-'}
        </p>
      </div>
      <div
        className="TableItem__Cell"
        onClick={(e) => (action ? preventClick(e, () => action(data)) : null)}
        title={data?.client_name}
      >
        <p>{data?.client_name || '-'}</p>
      </div>
      <div
        className="TableItem__Cell"
        onClick={(e) => (action ? preventClick(e, () => action(data)) : null)}
        title={data?.project_name}
      >
        <p>{data?.project_name || '-'}</p>
      </div>
      {isCentralAdmin || isOrganizationAdmin || isRegionAdmin ? (
        <div
          className="TableItem__Cell"
          onClick={(e) => (action ? preventClick(e, () => action(data)) : null)}
          title={data?.owner_first_name ? `${data.owner_first_name} ${data?.owner_last_name}` : ''}
        >
          <p>{data?.owner_first_name ? `${data.owner_first_name} ${data?.owner_last_name}` :  '-'}</p>
        </div>
      ) : (
        <></>
      )}
      <div
        className="TableItem__Cell"
        onClick={(e) => (action ? preventClick(e, () => action(data)) : null)}
        title={data?.creator_name}
      >
        <p>{data?.creator_name || '-'}</p>
      </div>
      <div
        className="TableItem__Cell"
        onClick={(e) => (action ? preventClick(e, () => action(data)) : null)}
      >
        <div className="d-flex align-items-center gap-2">
          <div className={`TableItem__Circle--${data?.status}`} />
          <p className="text-capitalize">
            {simulationStatus[data?.status] || '-'}
          </p>
        </div>
      </div>
      <div
        className="TableItem__Cell"
        title="Copy id to clipboard"
        onClick={(e) =>
          data?.id_number
            ? preventClick(e, () => copyToClipboard(data.id_number))
            : null
        }
      >
        {data?.id_number ? (
          <p>
            <u>{data.id_number}</u>
          </p>
        ) : (
          <p>-</p>
        )}
      </div>
      {action && data?.status !== 'waiting' && data?.status !== 'running' ? (
        <div
          className="TableItem__Cell"
          onClick={(e) => preventClick(e, () => action(data))}
        >
          <FontAwesomeIcon icon={faPenToSquare} />
        </div>
      ) : (
        <></>
      )}
    </TableItem>
  )
}
