import { ChangeEventHandler, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import { UserComplete } from '../../../api/apiTypes'
import { usersList } from '../../../api/users'
import Input from '../../../components/Input'
import UserInfoModal from '../../../components/modals/UserInfoModal'
import TableFilters from '../../../components/TableFilters'
import TableFiltersMobile from '../../../components/TableFiltersMobile'
import Table from '../../../components/tables/Table'
import { TableUsers } from '../../../components/tables/TableUsers'
import TableTitle from '../../../components/TableTitle'
import useAppContext from '../../../hooks/useAppContext'
import useTable from '../../../hooks/useTable'
import { FiltersGroup, FiltersSelected, TableHeaders } from '../../../types'
import useOrderby from '../../../hooks/useOrderby'
import { ISearch } from '../../../iconComponents'

interface Props {
  isMobile: boolean;
}

const SelectUser = ({ isMobile }: Props) => {
  const {
    token,
    user,
    isCentralAdmin,
    isOrganizationAdmin,
    isRegionAdmin,
    userToDelete,
    userToTransfer,
    setUserToTransfer,
  } = useAppContext()
  const page = 1
  const [perPage, setPerPage] = useState(20)
  const [search, setSearch] = useState('')
  const { orderBy, sort, handleSort } = useOrderby('first_name')
  const [filtersSelected, setFiltersSelected] = useState<FiltersSelected[]>([
    {
      label: 'N° of Simulations',
      defaultOption: {
        label: 'All',
        value: { sim_lower: null, sim_upper: null },
      },
      option: {
        label: 'All',
        value: { sim_lower: null, sim_upper: null },
      },
    },
    // {
    //   label: 'Usertype',
    //   defaultOption: {
    //     label: 'All',
    //     value: '',
    //   },
    //   option: {
    //     label: 'All',
    //     value: '',
    //   },
    // },
  ])
  const { data: users, isFetching } = useQuery(
    ['usersList', perPage, search, filtersSelected, orderBy],
    () =>
      usersList(token, {
        page,
        per_page: perPage,
        search,
        order_by: orderBy,
        sim_lower:
          typeof filtersSelected[0].option.value === 'object'
            ? filtersSelected[0].option.value.sim_lower
            : null,
        sim_upper:
          typeof filtersSelected[0].option.value === 'object'
            ? filtersSelected[0].option.value.sim_upper
            : null,
      }),
    {
      initialData: {
        page: 1,
        total_items: 0,
        total_pages: 1,
        data: [],
      },
      enabled: token !== '',
    }
  )
  const usersToTransfer = useMemo(
    () =>
      userToDelete.length === 0
        ? users.data.filter((item) => item.id !== user.id)
        : users.data.filter(
          (item) => item.id !== user.id && item.id !== userToDelete[0].id
        ),
    [users.data, user]
  )
  const { selectedItems, handleSelectItem, change } = useTable({
    tableData: usersToTransfer,
    oneSelection: true,
    initialValue: userToTransfer.map(user => user.id)
  })
  const [userInfo, setUserInfo] = useState<UserComplete>(null)
  const [showUserInfoModal, setShowUserInfoModal] = useState(false)

  useEffect(() => {
    if (users.total_items) setPerPage(users.total_items)
  }, [users])

  useEffect(() => {
    if (change < 2) return
    setUserToTransfer(selectedItems)
  }, [selectedItems])

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearch(event.target.value)
  }

  const openShowUserInfoModal = (info: UserComplete) => {
    setUserInfo(info)
    setShowUserInfoModal(true)
  }

  const closeShowUserInfoModal = () => {
    setShowUserInfoModal(false)
  }

  const filtersGroup: FiltersGroup[] = [
    {
      label: 'N° of Simulations',
      options: [
        {
          label: '0 - 10',
          value: { sim_lower: 0, sim_upper: 10 },
        },
        {
          label: '10 - 30',
          value: { sim_lower: 10, sim_upper: 30 },
        },
        {
          label: '30 - 50',
          value: { sim_lower: 30, sim_upper: 50 },
        },
        {
          label: '50 +',
          value: { sim_lower: 50, sim_upper: 999999999 },
        },
      ],
    },
    // {
    //   label: 'Usertype',
    //   options: [
    //     {
    //       label: 'Region admin',
    //       value: 'region_admin',
    //     },
    //     {
    //       label: 'Operator',
    //       value: 'operator',
    //     },
    //   ],
    // },
  ]

  const tableHeaders: TableHeaders[] = [
    {
      id: 0,
      label: 'Name',
      value: 'first_name',
    },
    {
      id: 1,
      label: 'Email',
      value: 'email',
    },
    {
      id: 3,
      label: 'User type',
      value: 'user_profile__user_type',
    },
    {
      id: 6,
      label: 'Simulations',
      value: 'simulations',
    },
  ]

  if (isCentralAdmin) {
    tableHeaders.splice(2, 0, {
      id: 2,
      label: 'Organization',
      value: 'user_profile__organization__name',
    })
  }

  if (isOrganizationAdmin) {
    tableHeaders.splice(2, 0, {
      id: 2,
      label: 'Region',
      value: '', // TODO
    })
  }

  if (isCentralAdmin || isOrganizationAdmin || isRegionAdmin) {
    tableHeaders.splice(
      isCentralAdmin || isOrganizationAdmin ? 4 : 3,
      0,
      {
        id: 4,
        label: 'Permissions',
        value: 'permissions',
      },
      {
        id: 5,
        label: 'Created by',
        value: 'created_by__first_name',
      }
    )
    tableHeaders.push({
      id: 7,
      label: 'Last active',
      value: 'last_active',
    })
  }

  return (
    <>
      <p className="text-5 fw-medium text-secondary_black d-none d-md-block">
        Select destination user
      </p>
      <div className="TransferSimulation__Content_Filters_Container">
        <div className="flex-grow-1">
          <Input
            name="Search"
            placeholder="Search"
            value={search}
            onChange={handleSearch}
            icon={<ISearch className="Input__Icon" />}
          />
        </div>

        <div className="Dashboard__Filters_Container_Mobile d-flex justify-between gap-3 d-md-none pr-1">
          <TableFiltersMobile
            filtersSelected={filtersSelected}
            setFiltersSelected={setFiltersSelected}
            filtersGroup={filtersGroup}
            headers={tableHeaders}
            sort={sort}
            handleSort={handleSort}
          />

          <TableTitle
            totalItems={usersToTransfer.length}
            itemsSelected={selectedItems.length}
            mobile
          />
        </div>

        <TableFilters
          filtersSelected={filtersSelected}
          setFiltersSelected={setFiltersSelected}
          filtersGroup={filtersGroup}
        />
        <TableTitle
          totalItems={usersToTransfer.length}
          itemsSelected={selectedItems.length}
        />
      </div>
      <Table
        type={
          isCentralAdmin || isOrganizationAdmin
            ? 'Users_Extended_Plus'
            : 'Users_Extended'
        }
        headers={tableHeaders}
        dataLength={usersToTransfer.length}
        loading={isFetching || token === ''}
        sort={sort}
        handleSort={handleSort}
      >
        {usersToTransfer.map((item) => (
          <TableUsers
            key={item.id}
            data={item}
            selected={selectedItems.some(
              (itemSelect) => itemSelect?.id === item.id
            )}
            handleSelectItem={handleSelectItem}
            details={openShowUserInfoModal}
            isMobile={isMobile}
            oneSelection
          />
        ))}
      </Table>
      {showUserInfoModal ? (
        <UserInfoModal data={userInfo} closeModal={closeShowUserInfoModal} />
      ) : (
        <></>
      )}
    </>
  )
}

export default SelectUser
