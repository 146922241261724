import { useState } from 'react'
import { deleteHotWaterSystem } from '../../api/simulations/input-parameters/existing-hot-water-system-details'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import useModalsContext from '../../hooks/useModalsContext'
import TableManage from '../tables/TableManage'
import Modal from './Modal'
import ErrorIcon from '../../assets/icons/warning-red.svg'

const ManageSystemModal = () => {
  const { token } = useAppContext()
  const { newSimulation, waterSystems, getExtraData } = useNewSimulationContext()
  const { setModalId, openShowNewSystemModal, toggleShowManageSystemModal } = useModalsContext()
  const [showDeleteSystem, setShowDeleteSystem] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [systemToDelete, setSystemToDelete] = useState(null)

  const handleEdit = (id: number) => {
    toggleShowManageSystemModal()
    setModalId(id)
    openShowNewSystemModal()
  }

  const openshowDeleteModel = (model) => {
    setSystemToDelete(model)
    setShowDeleteSystem(true)
  }

  const handleDelete = async () => {
    const hotWaterSystem = newSimulation.steps.existing_hot_water_system?.system
    const heatingSystemOne = newSimulation.steps.auxiliary_heating_system?.system_one?.system
    const heatingSystemTwo = newSimulation.steps.auxiliary_heating_system?.system_two?.system

    const deleteSystem = async () => {
      const response = await deleteHotWaterSystem(token, systemToDelete.id)

      if (response.error) console.error(response.error)
      else if (response) getExtraData()

      setShowDeleteSystem(false)
    }

    if(hotWaterSystem || heatingSystemOne || heatingSystemTwo) {
      if(hotWaterSystem === systemToDelete.id || heatingSystemOne === systemToDelete.id || heatingSystemTwo === systemToDelete.id) {
        setShowErrorModal(true)
      } else {
        deleteSystem()
      }
    } else {
      deleteSystem()
    }
  }

  return (
    <Modal title="Manage systems" closeModal={toggleShowManageSystemModal}>
      <div
        className="Modal__Scroll-Content d-flex flex-column align-items-center"
        style={{ maxHeight: 'calc(90dvh - 80px)' }}
      >
        <button
          className="p-2 text-6 fw-normal"
          onClick={() => {
            openShowNewSystemModal()
            toggleShowManageSystemModal()
          }}
        >
          + Add new system
        </button>
        <div className="Modal__Divider mb-2" />

        <TableManage
          name="systems"
          data={waterSystems}
          handleEdit={handleEdit}
          openDeleteModal={openshowDeleteModel}
        />
      </div>

      {showDeleteSystem && (
        <Modal
          title="Delete system"
          closeModal={() => setShowDeleteSystem(false)}
        >
          <div className="d-flex flex-column justify-content-center align-items-center py-5">
            <p className="text-center">Are you sure you want to delete the <span className="fw-medium">{systemToDelete.system_name}</span> system?</p>
          </div>
          <button className="button-blue mx-auto" onClick={handleDelete}>
            Delete
          </button>
        </Modal>
      )}

      {showErrorModal && (
        <Modal
          title="Can't delete this system"
          closeModal={() => {
            setShowDeleteSystem(false)
            setShowErrorModal(false)
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center py-5 gap-3">
            <img className='Modal__Error' src={ErrorIcon} alt="Can't delete this system"  title="Can't delete this system" />
            <p className="text-center">You cannot delete a system that is being used in the simulation</p>
          </div>
          <button
            className="button-blue mx-auto w-100"
            onClick={() => {
              setShowDeleteSystem(false)
              setShowErrorModal(false)
            }}
          >
            Delete
          </button>
        </Modal>
      )}
    </Modal>
  )
}

export default ManageSystemModal
