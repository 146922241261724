import useReportContext from '../../hooks/useReportContext'
import { ITrash } from '../../iconComponents'
import { getReportImagesGallery } from '../../api/newReport'
import useAppContext from '../../hooks/useAppContext'
import InputDebounce from '../InputDebounce'
import { useState, useEffect, useRef, KeyboardEvent, FocusEvent, useMemo } from 'react'
import { useQuery } from 'react-query'
import { URL as URL_BACK } from '../../constants'
import ImageLibrarySelector from './ImageLibrarySelector'
import { getOrganizations } from '../../api/organizations'
import Button from '../buttons/Button'

export default function PageInfoHeader() {
  const { token, isCentralAdmin } = useAppContext()
  const { pageSelected, updatePageTitle, updatePageImage, handleDeletePage, updatePageSubPagesCount } =
    useReportContext()
  const [showList, setShowList] = useState(false)
  const listRef = useRef<HTMLUListElement>(null)

  const {
    data: library,
    isFetching,
    refetch,
  } = useQuery(
    ['getReportImagesGallery'],
    () => getReportImagesGallery(token, { gallery_type: 'general' }),
    {
      initialData: {
        response: new Response(),
        data: {
          data: [],
        },
        ok: true,
      },
      enabled: token !== '',
      refetchOnWindowFocus: false,
    }
  )
  const initialData = { page: 1, total_items: 0, total_pages: 1, data: [] }
  const { data: organizatoins } = useQuery(
    ['getOrganizations'],
    () => getOrganizations(token, initialData),
    {
      initialData,
      enabled: token !== '' && isCentralAdmin,
      refetchOnWindowFocus: false,
    }
  )
  const organizatoinsForInput = useMemo(
    () =>
      organizatoins.data?.map((organizatoin) => ({
        value: organizatoin.id,
        label: organizatoin.name,
      })),
    [organizatoins]
  )

  if (!pageSelected) return null

  const [error, seterror] = useState('')

  useEffect(() => {
    if (!error) return 
    const clearError = setTimeout(() => seterror(''), 10000)
  
    return () => clearTimeout(clearError)
  }, [error])

  const handleOpenList = () => {
    if (showList) return setShowList(false)
    setTimeout(() => {
      setShowList(true)
      listRef.current?.focus()
    })
  }

  const handleOpenListOnKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
    if (!(e.key === 'Enter' || e.key === ' ')) return
    handleOpenList()
  }

  const handleBlur = (e: FocusEvent<HTMLUListElement>) => {
    const isChildren = listRef.current?.contains(e.relatedTarget)
    if (!isChildren && !isChildren) setShowList(false)
  }

  const handleSelect = (value: string) => {
    updatePageImage(URL_BACK + value)
    setShowList(false)
  }

  return (
    <>
      <div className="PageInfo__Card">
        <div className="PageInfo__Card_Header">
          <p className="PageInfo__Card_Header_Text">Page name</p>
          
          {pageSelected.removable && (
            <Button
              variant="transparent_red"
              style={{ transform: 'translate(12px)' }}
              onClick={() => handleDeletePage(pageSelected.id)}
            >
              <ITrash className="PageInfo__Card_Header_Icon" /> Delete page
            </Button>
          )}
        </div>

        <InputDebounce
          name="Page"
          placeholder={pageSelected.label}
          // innerRight={
          //   <label htmlFor="cover_name" className="text-5 text-secondary">
          //     0/25
          //   </label>
          // }
          value={pageSelected.title}
          onDebounce={(value) => updatePageTitle(value)}
        />

        {pageSelected.image !== undefined && (
          <ImageLibrarySelector 
            organizations={organizatoinsForInput}

            id={pageSelected.id}
            value={pageSelected.image}
              
            library={library}
            isFetching={isFetching}
            refetch={refetch as any}

            onSelect={(value: string) => updatePageImage(value)}
            onDelete={() => updatePageImage(null)}
          />
        )}
      </div>
      {/* <div className="PageInfo__Card">
        <p className='text-4 fw-medium'>¿How many pages does this section have?</p>
        <p className='text-5'>Please indicate the number of pages in this section to accurately calculate the table of contents.</p>
        <div className='PageInfo__Card_Subpages text-4'>
          <p>Subpages:</p>
          {[1, 2, 3].map(num => (
            <div className='PageInfo__Card_Subpages_options' key={num}>
              <p>{num}</p>
              <div
                className={classNames('Checkbox Small Color-secondary', {
                  Selected: pageSelected.subPagesCount === num,
                })}
                onClick={() => updatePageSubPagesCount(num)}
              />
            </div>
          ))}
        </div>
      </div> */}
    </>
  )
}
