import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { SectionGrid } from '../../types/generateReport'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 16,
  },
  item: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    padding: 16,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    color: '#5A5A5A',
    textAlign: 'center',
  },
  value: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: '#252525',
    textAlign: 'center',
  },
})

type Props = {
  section: SectionGrid;
};

export default function GridRender({ section }: Props) {
  return (
    <View style={styles.container}>
      {section.value.map((item) => {
        if (!item.show) return null
        return (
          <View
            key={item.id}
            style={{
              ...styles.item,
              flexBasis: !item.highlight ? (531.28 - (section.columns - 1) * 16) / section.columns : 531.28,
              backgroundColor: !item.highlight ? '#F8F8F8' : '#D5E9D1',
              padding: section.size === 'small' ? 8 : 16,
            }}
            wrap={false}
          >
            <Text
              style={{
                ...styles.title,
                fontSize: section.size === 'small' ? 10 : 14,
                color: !item.highlight ? '#5A5A5A' : '#252525',
              }}
            >
              {item.title}
            </Text>
            <Text
              style={{
                ...styles.value,
                fontSize: section.size === 'small' ? 12 : 16,
                color: !item.highlight ? '#252525' : '#2B8F1A',
              }}
            >
              {item.value || item.placeholder}
            </Text>
          </View>
        )
      })}
    </View>
  )
}
