import { SVGProps } from 'react'
const SvgIOrganization = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M14.5 5C12.9883 5 11.5385 5.60054 10.4695 6.6695C9.40054 7.73846 8.80001 9.18828 8.80001 10.7V39.2001H6.9C5.85066 39.2001 5 40.0507 5 41.1001C5 42.1494 5.85066 43.0001 6.9 43.0001H41.1001C42.1494 43.0001 43.0001 42.1494 43.0001 41.1001C43.0001 40.0507 42.1494 39.2001 41.1001 39.2001H39.2001V10.7C39.2001 9.18827 38.5995 7.73845 37.5306 6.6695C36.4616 5.60054 35.0118 5 33.5001 5H14.5ZM13.1565 9.35651C13.5128 9.00019 13.9961 8.80001 14.5 8.80001H33.5001C34.004 8.80001 34.4873 9.00019 34.8436 9.35651C35.1999 9.71283 35.4001 10.1961 35.4001 10.7V39.2001H29.7001V31.6001C29.7001 30.5922 29.2997 29.6257 28.5871 28.9131C27.8744 28.2004 26.9079 27.8001 25.9001 27.8001H22.1C21.0922 27.8001 20.1257 28.2004 19.413 28.9131C18.7004 29.6257 18.3 30.5922 18.3 31.6001V39.2001H12.6V10.7C12.6 10.1961 12.8002 9.71283 13.1565 9.35651ZM18.3 12.6C17.2507 12.6 16.4 13.4507 16.4 14.5C16.4 15.5494 17.2507 16.4 18.3 16.4H20.2C21.2494 16.4 22.1 15.5494 22.1 14.5C22.1 13.4507 21.2494 12.6 20.2 12.6H18.3ZM27.8001 12.6C26.7507 12.6 25.9001 13.4507 25.9001 14.5C25.9001 15.5494 26.7507 16.4 27.8001 16.4H29.7001C30.7494 16.4 31.6001 15.5494 31.6001 14.5C31.6001 13.4507 30.7494 12.6 29.7001 12.6H27.8001ZM18.3 20.2C17.2507 20.2 16.4 21.0507 16.4 22.1C16.4 23.1494 17.2507 24 18.3 24H20.2C21.2494 24 22.1 23.1494 22.1 22.1C22.1 21.0507 21.2494 20.2 20.2 20.2H18.3ZM27.8001 20.2C26.7507 20.2 25.9001 21.0507 25.9001 22.1C25.9001 23.1494 26.7507 24 27.8001 24H29.7001C30.7494 24 31.6001 23.1494 31.6001 22.1C31.6001 21.0507 30.7494 20.2 29.7001 20.2H27.8001ZM22.1 31.6001H25.9001V39.2001H22.1V31.6001Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgIOrganization
