import { useState, MouseEvent } from 'react'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CSSTransition } from 'react-transition-group'

interface Props {
  actions: {
    id: number;
    label: string;
    callback: () => void;
  }[];
}

const TableItemMenu = ({ actions }: Props) => {
  const [showMenu, setShowMenu] = useState(false)

  const preventClick = (event: MouseEvent<HTMLDivElement>, callback: () => void) => {
    event.stopPropagation()
    callback()
  }

  const closeShowMenu = () => {
    setShowMenu(false)
  }

  return (
    <button 
      className="TableItem__Menu" 
      onBlur={closeShowMenu}
    >
      <div
        className="TableItem__Menu_Icon"
        onClick={(event) => preventClick(event, () => setShowMenu(true))}
      >
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </div>
      <CSSTransition in={showMenu} timeout={300} unmountOnExit>
        <div className="TableItem__Menu_Content">
          {actions.map((action) => (
            <div
              key={action.id}
              className="TableItem__Menu_Content_Item"
              onClick={(event) =>
                preventClick(event, () => {
                  action.callback()
                  closeShowMenu()
                })
              }
            >
              {action.label}
            </div>
          ))}
        </div>
      </CSSTransition>
    </button>
  )
}

export default TableItemMenu