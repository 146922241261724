import { Helmet } from 'react-helmet'
import useAppContext from '../../hooks/useAppContext'
import ShowRegions from '../../components/ShowRegions'
import OrganizationInfo from '../../components/OrganizationInfo'
import { useQuery } from 'react-query'
import { getOrganizationDetail } from '../../api/organizations'
import ShowOffices from '../../components/ShowOffices'
import { useState } from 'react'
import EditOrganizationModal from '../../components/modals/EditOrganizationModal'

const Regions = () => {
  const { token, user } = useAppContext()
  const { data: organizationDetail, refetch } = useQuery(
    ['getOrganizationDetail'],
    () => getOrganizationDetail(token, user.organization.id),
    {
      initialData: {
        id: 0,
        name: '',
        country_residence: {id: 0, country_name: ''},
        phone: '',
        admin_email: '',
        email: '',
        address: '',
        operating_countries: 0,
        admins: [],
        regions: [],
        users: 0,
      },
      enabled: token !== '' && typeof user?.organization.id === 'number',
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  )
  const [showEditOrganizationModal, setShowEditOrganizationModal] =
    useState(false)

  const openShowEditOrganizationModal = () => 
    setShowEditOrganizationModal(true)
  

  const closeShowEditOrganizationModal = () =>
    setShowEditOrganizationModal(false)

  return (
    <>
      <Helmet>
        <title>Solarus: Regions</title>
      </Helmet>

      <div className="Dashboard__Container_Special">
        <div className="Dashboard__Table_path_info_Info">
          <OrganizationInfo organization={organizationDetail} openShowEditOrganizationModal={openShowEditOrganizationModal} />
        </div>
        <div className="Dashboard__Table_path_info_Content Region gap-4 Scroll overflow-auto">
          <ShowOffices
            organizationId={user.organization.id}
            organization={organizationDetail}
            className="Dashboard flex-shrink-0 h-Max p-0"
          />
          <ShowRegions 
            organizationId={user.organization.id} 
            className="Dashboard flex-shrink-0 h-Max p-0"
          />
        </div>
      </div>
      {showEditOrganizationModal ? (
        <EditOrganizationModal
          organization={organizationDetail}
          refetch={refetch}
          closeModal={closeShowEditOrganizationModal}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default Regions
