import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import {
  getCSV,
  getSimulationDetails,
  runSimulation,
} from '../../api/simulations/general-simulation'
import Loader from '../../components/Loader'
import SolarusLogo from '../../assets/images/solarus-logo.png'
import ErrorIcon from '../../assets/icons/warning-red.svg'
import Button from '../../components/buttons/Button'

const RunningSimulation = () => {
  const { token } = useAppContext()
  const { newSimulation, setNewSimulation, refetchSimulationDetail } = useNewSimulationContext()
  const [ error, setError ] = useState({
    matlab: '',
    trnsys: '',
    csv: {
      financial_parameters: {},
      input_parameters: {},
      PV_parameters: {},
      PVT_parameters: {},
      system_parameters: {}
    }
  })
  const navigate = useNavigate()

  // Run and check simulation status
  useEffect(() => {
    const handleRunSimulation = async () => {
      if (newSimulation.status === 'ready_to_run') {
        await runSimulation(token, newSimulation.id)

        const interval = setInterval(async () => {
          const response = await getSimulationDetails(token, newSimulation.id)

          if (response.status === 'finished') {
            refetchSimulationDetail()
            clearInterval(interval)
            setNewSimulation({
              ...newSimulation,
              status: 'finished'
            })
            navigate('/dashboard/create-simulation/running-complete')
          } else if (response.status === 'error_running') {
            const csv = await getCSV(token, newSimulation.id)

            clearInterval(interval)
            setError({
              matlab: response.results?.error_message,
              trnsys: response.results?.trnsys_error,
              csv: csv
            })
          }
        }, 15000)
      } else if (newSimulation.status === 'error_running') {
        const response = await getSimulationDetails(token, newSimulation.id)
        const csv = await getCSV(token, newSimulation.id)

        setError({
          matlab: response.results?.error_message,
          trnsys: response.results?.trnsys_error,
          csv: csv
        })
      }
    }

    if (token && newSimulation.id) {
      handleRunSimulation()
    }
  }, [token, newSimulation.id])

  const goBackToSimualtion = () => {
    setNewSimulation({
      ...newSimulation,
      status: 'creating'
    })
    navigate('/dashboard/create-simulation/step/general-information', {
      replace: true
    })
  }

  const normalizeCSVData = (data: any) => {
    const joinedData = data['parameters,value'].join('\n')
    const normalizedData = joinedData.replace(/,/g, ' = ')
    return normalizedData
  }

  return (
    <>
      <Helmet>
        <title>Running Simulation</title>
      </Helmet>

      <div className="RunningSimulation">
        <img
          className="RunningSimulation__Logo d-none d-md-block"
          src={SolarusLogo}
          alt="Solarus - Smart Energy Solutions"
          title="Solarus - Smart Energy Solutions"
        />

        {!error.matlab && !error.trnsys
          ? (
            <div className='w-100 text-center d-flex flex-column align-items-center gap-3'>
              <h1 className='text-2'>Running simulation</h1>

              <Loader />

              <span>This may take a few minutes, please wait.</span>

              {/* <Link to='/dashboard/create-simulation/running-complete' className="button-blue">Go to next step</Link> */}
            </div>
          ) : (
            <div className="w-100 text-center d-flex flex-column align-items-center gap-5 pt-5 pb-4">
              <h1 className="text-2">Error running the simulation</h1>

              <img
                className="RunningSimulation__Error"
                src={ErrorIcon}
                alt="Error running the simulation"
                title="Error running the simulation"
              />

              <span>
              Please write down the name of this simulation{' '}
                <span className="fw-medium">
                &quot;{newSimulation.steps.general_information.name}&quot;
                </span>{' '}
              and contact the support team.
              </span>

              {error.matlab && (
                <div className="w-100 d-flex flex-column gap-2 mx-auto text-start" style={{ maxWidth: '850px' }}>
                  <span className='text-3 fw-semibold'>Matlab message:</span>
                  <textarea className='RunningSimulation__Error-Container' value={error.matlab} readOnly></textarea>
                </div>
              )}

              {error.trnsys && (
                <div className="w-100 d-flex flex-column gap-2 mx-auto text-start" style={{ maxWidth: '850px' }}>
                  <span className='text-3 fw-semibold'>TRNSYS message:</span>
                  <textarea className='RunningSimulation__Error-Container' value={error.trnsys} readOnly></textarea>
                  {/* <a className='button-blue text-3 fw-medium mx-auto' href={`https://solarus-api.linkchar.com${error.trnsys}`} target="_blank" rel="noopener noreferrer">Download TRNSYS Message</a> */}
                </div>
              )}

              {error.csv && (
                <div className='w-100 d-flex flex-column gap-3'>
                  <h4 className='text-2 fw-semibold'>CSV</h4>

                  <div className="w-100 d-flex flex-column gap-2 mx-auto text-start" style={{ maxWidth: '850px' }}>
                    <span className='text-3 fw-semibold'>Financial Parameters:</span>
                    <textarea className='RunningSimulation__Error-Container' value={normalizeCSVData(error.csv?.financial_parameters)} readOnly></textarea>
                  </div>

                  <div className="w-100 d-flex flex-column gap-2 mx-auto text-start" style={{ maxWidth: '850px' }}>
                    <span className='text-3 fw-semibold'>Inputs Parameters:</span>
                    <textarea className='RunningSimulation__Error-Container' value={normalizeCSVData(error.csv?.input_parameters)} readOnly></textarea>
                  </div>

                  <div className="w-100 d-flex flex-column gap-2 mx-auto text-start" style={{ maxWidth: '850px' }}>
                    <span className='text-3 fw-semibold'>PV Parameters:</span>
                    <textarea className='RunningSimulation__Error-Container' value={normalizeCSVData(error.csv?.PV_parameters)} readOnly></textarea>
                  </div>

                  <div className="w-100 d-flex flex-column gap-2 mx-auto text-start" style={{ maxWidth: '850px' }}>
                    <span className='text-3 fw-semibold'>PVT Parameters:</span>
                    <textarea className='RunningSimulation__Error-Container' value={normalizeCSVData(error.csv?.PVT_parameters)} readOnly></textarea>
                  </div>

                  <div className="w-100 d-flex flex-column gap-2 mx-auto text-start" style={{ maxWidth: '850px' }}>
                    <span className='text-3 fw-semibold'>System Parameters:</span>
                    <textarea className='RunningSimulation__Error-Container' value={normalizeCSVData(error.csv?.system_parameters)} readOnly></textarea>
                  </div>
                </div>
              )}

              <Button onClick={goBackToSimualtion}>
                Go back to simulation
              </Button>
              {/* <Link to='/dashboard/create-simulation/running-complete' className="button-blue">Go to next step</Link> */}
            </div>
          )}
      </div>
    </>
  )
}

export default RunningSimulation
