import { useNavigate } from 'react-router-dom'
import useAppContext from '../../hooks/useAppContext'
import Modal from './Modal'
import { queryClient } from '../..'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'

interface Props {
  closeModal: () => void;
}

const LogoutModal = ({ closeModal }: Props) => {
  const { setToken } = useAppContext()
  const { resetNewSimulation } = useNewSimulationContext()
  const navigate = useNavigate()

  const logout = () => {
    setToken('')
    closeModal()
    navigate('/login')
    resetNewSimulation()
    queryClient.clear()
  }

  return (
    <Modal
      title="Logout"
      closeModal={closeModal}
    >
      <div className="py-5 text-center">
        <p className="text-3 fw-medium">Are you sure you want to logout?</p>
      </div>

      <div className="d-flex align-items-center gap-2">
        <button className="button-blue w-100" onClick={logout}>Yes</button>
        <button className="button-gray w-100" onClick={closeModal}>No</button>
      </div>
    </Modal>
  )
}

export default LogoutModal