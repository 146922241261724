import {
  getReportImagesGallery,
} from '../../api/newReport'
import useAppContext from '../../hooks/useAppContext'
import useReportContext from '../../hooks/useReportContext'
import { SectionListInfo } from '../../types/generateReport'
import { handleShowPicker } from '../../utils/handleShowPicker'
import InputDebounce from '../InputDebounce'
import { useMemo, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { normalizeNumberinString } from '../../constants'
import { getOrganizations } from '../../api/organizations'
import classNames from 'classnames'
import ImageLibrarySelector from './ImageLibrarySelector'

type Props = {
  section: SectionListInfo;
};

export default function ListInfoCard({ section }: Props) {
  const { token, isCentralAdmin } = useAppContext()
  const {
    handleUpdateListInfoText,
    handleUpdateListInfoFile,
    handleToggleListInfoShow,
    numbersType,
  } = useReportContext()

  const {
    data: library,
    isFetching,
    refetch,
  } = useQuery(
    ['getReportImagesGallery'],
    () => getReportImagesGallery(token, { gallery_type: 'general' }),
    {
      initialData: {
        response: new Response(),
        data: {
          data: [],
        },
        ok: true,
      },
      enabled: token !== '',
      refetchOnWindowFocus: false,
    }
  )
  const initialData = { page: 1, total_items: 0, total_pages: 1, data: [] }
  const { data: organizatoins } = useQuery(
    ['getOrganizations'],
    () => getOrganizations(token, initialData),
    {
      initialData,
      enabled: token !== '' && isCentralAdmin,
      refetchOnWindowFocus: false,
    }
  )
  const organizatoinsForInput = useMemo(
    () =>
      organizatoins.data?.map((organizatoin) => ({
        value: organizatoin.id,
        label: organizatoin.name,
      })),
    [organizatoins]
  )

  const [error, seterror] = useState('')

  useEffect(() => {
    if (!error) return
    const clearError = setTimeout(() => seterror(''), 10000)

    return () => clearTimeout(clearError)
  }, [error])

  return (
    <>
      {Object.entries(section.value).map(([key, value], index) => {
        if (value.type !== 'file') {
          return (
            <InputDebounce
              key={key + index}
              label={value.label}
              labelActions={
                <>
                  <div
                    className={classNames('Checkbox Small Color-secondary', {
                      Selected: value.show,
                    })}
                    onClick={() => handleToggleListInfoShow(section.id, key)}
                  />
                  <p
                    className="text-6 text-secondary cursor-pointer"
                    onClick={() => handleToggleListInfoShow(section.id, key)}
                  >
                    Show
                  </p>
                </>
              }
              name={key}
              placeholder={value.placeholder}
              type={value.type}
              value={value.value}
              onClick={(e) =>
                value.type === 'date' ? handleShowPicker(e) : null
              }
              onDebounce={(text) =>
                handleUpdateListInfoText(
                  section.id,
                  key,
                  typeof text === 'string' &&
                    'isFormatted' in value &&
                    value.isFormatted
                    ? normalizeNumberinString(text, 3, numbersType)
                    : text
                )
              }
            />
          )
        } else {
          return (
            <ImageLibrarySelector 
              key={key + index}
              organizations={organizatoinsForInput}

              id={section.id}
              value={value.value}
              placeholder={value.placeholder}
              
              library={library}
              isFetching={isFetching}
              refetch={refetch as any}

              onSelect={(value: string) => handleUpdateListInfoFile(section.id, key, value)}
              onDelete={() => handleUpdateListInfoFile(section.id, key, null)}

              rounded
            />
          )
        }
      })}
    </>
  )
}
