import { SVGProps } from 'react'
const SvgIPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.253 8.06a.589.589 0 0 0-.418-.173.586.586 0 0 0-.428.179l-5.377 5.377a.59.59 0 0 0-.174.417c0 .165.059.31.179.429l5.495 5.495-.916 2.018a21.659 21.659 0 0 1-4.423 6.381 21.73 21.73 0 0 1-6.378 4.438l-2.02.922-5.504-5.504a.59.59 0 0 0-.418-.173.586.586 0 0 0-.429.178l-5.38 5.371a.592.592 0 0 0-.174.42v.004c0 .165.058.308.178.428l4.987 4.987c.539.541 1.3.858 2.078.858.164 0 .308-.013.446-.037l.014-.002.014-.002c5.582-.92 11.259-3.922 15.902-8.561 4.635-4.63 7.635-10.302 8.569-15.907a2.97 2.97 0 0 0-.834-2.557l-4.99-4.985Zm8.98 8.065c-1.055 6.337-4.411 12.6-9.464 17.648-5.058 5.054-11.325 8.414-17.644 9.455a5.758 5.758 0 0 1-.994.084 6.161 6.161 0 0 1-4.345-1.8L5.803 36.53a3.783 3.783 0 0 1-1.115-2.69 3.791 3.791 0 0 1 1.115-2.692l5.377-5.367a3.784 3.784 0 0 1 2.69-1.115 3.789 3.789 0 0 1 2.686 1.115l3.928 3.928a18.533 18.533 0 0 0 5.443-3.787A18.462 18.462 0 0 0 29.7 20.48l-3.928-3.928a3.784 3.784 0 0 1-1.116-2.691 3.786 3.786 0 0 1 1.116-2.686l5.372-5.372a3.783 3.783 0 0 1 2.69-1.115 3.79 3.79 0 0 1 2.686 1.115l4.992 4.988a6.169 6.169 0 0 1 1.72 5.334Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgIPhone
