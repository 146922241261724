import classNames from 'classnames'
import useReportContext from '../../hooks/useReportContext'
import { IAngleUp } from '../../iconComponents'

export default function PageHeader() {
  const { pageSelected, showPages, handleToggleShowPages } = useReportContext()

  return (
    <div className="GenerateReport_Header" onClick={handleToggleShowPages}>
      <p className="text-4 text-white fw-medium">{pageSelected.label}</p>
      <IAngleUp
        className={classNames('GenerateReport_Header_Angle text-white', {
          Active: showPages,
        })}
      />
    </div>
  )
}
