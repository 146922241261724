import { useState } from 'react'
import Modal from './Modal'
import { IAlert } from '../../iconComponents'
import { deleteUser } from '../../api/users'
import useAppContext from '../../hooks/useAppContext'
import LoadingDots from '../LoadingDots'
import CheckModal from './CheckModal'

interface Props {
  closeModal: () => void;
  refetch: () => void;
}

const DeleteUserModal = ({ closeModal, refetch }: Props) => {
  const { token, userToDelete, setUserToDelete } = useAppContext()
  const [step, setStep] = useState(1)
  const [buttonModalIsLoading, setButtonModalIsLoading] = useState(false)
  const [serverError, setServerError] = useState(false)

  const handleDeleteUser = async () => {
    if (buttonModalIsLoading) return
    setButtonModalIsLoading(true)

    const response = await deleteUser(token, userToDelete[0].id, {
      transfer: 'false',
      simulation_ids: 'all',
    })

    if (response.error) setServerError(true)
    else if (response) {
      setStep(4)
      setUserToDelete([])
      refetch()
    }

    setButtonModalIsLoading(false)
  }
  
  if (step === 1)
    return (
      <Modal title="Delete User" closeModal={closeModal}>
        <IAlert
          width={60}
          height={60}
          className="text-yellow d-block mt-4 mx-auto mb-3"
        />
        <p className="text-center fw-medium my-4">
          Are you sure you want to delete {userToDelete[0].first_name ? `${userToDelete[0].first_name} ${userToDelete[0].last_name}` : 'this user'}?
        </p>
        {serverError ? <p className="text-red">Something went wrong</p> : <></>}
        <div className="d-flex align-items-center gap-2 mb-3">
          <button
            className="button_transparent_red button_defult w-100 text-4 gap-0"
            onClick={handleDeleteUser}
            disabled={buttonModalIsLoading}
          >
            {buttonModalIsLoading ? (
              <>
                <span>Deleting</span>
                <LoadingDots className="enter-done" />
              </>
            ) : (
              <span className='text-red'>Yes, delete user</span>
            )}
          </button>
          <button
            className="button_transparent_blue w-100 text-4"
            onClick={closeModal}
          >
            No, do not delete
          </button>
        </div>
      </Modal>
    )
    
  return (
    <CheckModal
      closeModal={closeModal}
      title="Delete User"
      message="¡User deleted successfully!"
    />
  )
}

export default DeleteUserModal
