import { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { addSubsidyAndTaxBenefits, updateSubsidyAndTaxBenefits } from '../../api/simulations/financial-parameters/subsidy-y-tax-benefits'
import useAppContext from '../../hooks/useAppContext'
import useNewSimulationContext from '../../hooks/useNewSimulationContext'
import CreateSimulationContainer from '../../containers/CreateSimulationContainer'
import SimulationFormikHandler from '../../containers/SimulationFormikHandler'
import Checkboxes from '../../components/Checkboxes'
import CreateSimulationTitle from '../../components/CreateSimulationTitle'
import Input from '../../components/Input'
import InputSmall from '../../components/InputSmall'
import { checkboxOptions, normalizeNumberWithCommas, normalizePercentToSend, normalizePercentToShow, threeDecimalRegex } from '../../constants'
import { submitStep } from '../../utils/submitStep'
import PaperIcon from '../../assets/icons/paper.svg'
import ErrorIcon from '../../assets/icons/warning-red.svg'

const SubsidyTaxBenefits = () => {
  const { token } = useAppContext()
  const { newSimulation, updateNewSimulation, setIsStepLoading } = useNewSimulationContext()

  const yupNumberValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('min', 'Min: 0', value => value >= 0)
    .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))

  const yupPercentageValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('min', 'Min: 0%', value => value >= 0)
    // .test('max', 'Max: 100%', value => value <= 100)
    .test('decimals', 'Max: 3 decimals', value => !threeDecimalRegex.test(value))

  // const yupYearsOfOperationalSubsidyValidation = Yup
  //   .mixed()
  //   .required('Required')
  //   .transform(value => value?.toString()?.replace(/,/g, ''))
  //   .test('type', 'Only numbers', value => !isNaN(value))
  //   .test('min', 'Min: 5 years', value => value >= 5)
  //   .test('max', 'Max: 25 years', value => value <= 25)
  //   .test('integer', 'Only integers', value => !Number.isInteger(value))

  const yupNumberOfYearsValidation = Yup
    .mixed()
    .required('Required')
    .transform(value => value?.toString()?.replace(/,/g, ''))
    .test('type', 'Only numbers', value => !isNaN(value))
    .test('min', 'Min: 1 year', value => value >= 1)
    .test('max', 'Max: 25 years', value => value <= 25)
    .test('integer', 'Only integers', value => !Number.isInteger(value))

  const formik = useFormik({
    initialValues: {
      is_operational_subsidy_applicable: false,
      num_of_years_for_operational_subsidy: '',
      operational_subsidy_for_heat: '',
      operational_subsidy_for_electricity: '',
      is_tax_subsidy_applicable: false,
      tax_subsidy: null,
      number_of_years: '',
      year_1: 0,
      year_2: 0,
      year_3: 0,
      year_4: 0,
      year_5: 0,
      year_6: 0,
      year_7: 0,
      year_8: 0,
      year_9: 0,
      year_10: 0,
      year_11: 0,
      year_12: 0,
      year_13: 0,
      year_14: 0,
      year_15: 0,
      year_16: 0,
      year_17: 0,
      year_18: 0,
      year_19: 0,
      year_20: 0,
      year_21: 0,
      year_22: 0,
      year_23: 0,
      year_24: 0,
      year_25: 0,
      is_funding_or_grant_applicable: false,
      description_of_funding_or_grant: '',
      funding_or_grant_amount: '',
      co2_tax: '',
      co2_tax_escalation_rate: null,
      years_error: ''
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      is_operational_subsidy_applicable: Yup.boolean().required('Required'),
      num_of_years_for_operational_subsidy: Yup.mixed().when('is_operational_subsidy_applicable', {
        is: true,
        then: yupNumberOfYearsValidation,
      }),
      operational_subsidy_for_heat: Yup.mixed().when('is_operational_subsidy_applicable', {
        is: true,
        then: yupNumberValidation,
      }),
      operational_subsidy_for_electricity: Yup.mixed().when('is_operational_subsidy_applicable', {
        is: true,
        then: yupNumberValidation,
      }),
      is_tax_subsidy_applicable: Yup.boolean().required('Required'),
      tax_subsidy: Yup.mixed().when('is_tax_subsidy_applicable', {
        is: true,
        then: yupPercentageValidation.test('max', 'Max: 100%', value => value <= 100),
      }),
      number_of_years: Yup.mixed().when('is_tax_subsidy_applicable', {
        is: true,
        then: yupNumberOfYearsValidation,
      }),
      is_funding_or_grant_applicable: Yup.boolean().required('Required'),
      description_of_funding_or_grant: Yup.string().when('is_funding_or_grant_applicable', {
        is: true,
        then: Yup.string().required('Required'),
      }),
      funding_or_grant_amount: Yup.mixed().when('is_funding_or_grant_applicable', {
        is: true,
        then: yupNumberValidation,
      }),
      co2_tax: yupNumberValidation,
      co2_tax_escalation_rate: yupPercentageValidation,
    }),
    validate: values => {
      const errors: any = {}
      const projectEconomicLifetime = Number(newSimulation.steps.economic_parameters.project_economic_lifetime)

      if(values.is_tax_subsidy_applicable && projectEconomicLifetime > 0) {
        let sum = 0
        for (let i = 0; i < projectEconomicLifetime; i++) {
          sum += normalizeNumberWithCommas(values[`year_${i + 1}`])
        }
        if(sum < 99.5 || sum > 100.5) {
          errors.years_error = 'Sum of percentages must be 100, currently is ' + sum
        }
      }

      if(errors.years_error) return errors
    },
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        is_operational_subsidy_applicable: String(values.is_operational_subsidy_applicable),
        num_of_years_for_operational_subsidy: normalizeNumberWithCommas(values.number_of_years),
        operational_subsidy_for_heat: normalizeNumberWithCommas(values.operational_subsidy_for_heat),
        operational_subsidy_for_electricity: normalizeNumberWithCommas(values.operational_subsidy_for_electricity),
        is_tax_subsidy_applicable: String(values.is_tax_subsidy_applicable),
        tax_subsidy: normalizePercentToSend(normalizeNumberWithCommas(values.tax_subsidy)),
        number_of_years: normalizeNumberWithCommas(values.number_of_years),
        co2_tax_escalation_rate: normalizePercentToSend(normalizeNumberWithCommas(values.co2_tax_escalation_rate)),
        year_1: normalizePercentToSend(normalizeNumberWithCommas(values.year_1)),
        year_2: normalizePercentToSend(normalizeNumberWithCommas(values.year_2)),
        year_3: normalizePercentToSend(normalizeNumberWithCommas(values.year_3)),
        year_4: normalizePercentToSend(normalizeNumberWithCommas(values.year_4)),
        year_5: normalizePercentToSend(normalizeNumberWithCommas(values.year_5)),
        year_6: normalizePercentToSend(normalizeNumberWithCommas(values.year_6)),
        year_7: normalizePercentToSend(normalizeNumberWithCommas(values.year_7)),
        year_8: normalizePercentToSend(normalizeNumberWithCommas(values.year_8)),
        year_9: normalizePercentToSend(normalizeNumberWithCommas(values.year_9)),
        year_10: normalizePercentToSend(normalizeNumberWithCommas(values.year_10)),
        year_11: normalizePercentToSend(normalizeNumberWithCommas(values.year_11)),
        year_12: normalizePercentToSend(normalizeNumberWithCommas(values.year_12)),
        year_13: normalizePercentToSend(normalizeNumberWithCommas(values.year_13)),
        year_14: normalizePercentToSend(normalizeNumberWithCommas(values.year_14)),
        year_15: normalizePercentToSend(normalizeNumberWithCommas(values.year_15)),
        year_16: normalizePercentToSend(normalizeNumberWithCommas(values.year_16)),
        year_17: normalizePercentToSend(normalizeNumberWithCommas(values.year_17)),
        year_18: normalizePercentToSend(normalizeNumberWithCommas(values.year_18)),
        year_19: normalizePercentToSend(normalizeNumberWithCommas(values.year_19)),
        year_20: normalizePercentToSend(normalizeNumberWithCommas(values.year_20)),
        year_21: normalizePercentToSend(normalizeNumberWithCommas(values.year_21)),
        year_22: normalizePercentToSend(normalizeNumberWithCommas(values.year_22)),
        year_23: normalizePercentToSend(normalizeNumberWithCommas(values.year_23)),
        year_24: normalizePercentToSend(normalizeNumberWithCommas(values.year_24)),
        year_25: normalizePercentToSend(normalizeNumberWithCommas(values.year_25)),
        is_funding_or_grant_applicable: String(values.is_funding_or_grant_applicable),
        funding_or_grant_amount: normalizeNumberWithCommas(values.funding_or_grant_amount),
        co2_tax: normalizeNumberWithCommas(values.co2_tax),
      }

      if(!values.is_operational_subsidy_applicable) {
        delete newValues.num_of_years_for_operational_subsidy
        delete newValues.operational_subsidy_for_heat
        delete newValues.operational_subsidy_for_electricity
      }

      if(!values.is_tax_subsidy_applicable) {
        delete newValues.tax_subsidy
        delete newValues.number_of_years
      }

      if(!values.is_funding_or_grant_applicable) {
        delete newValues.description_of_funding_or_grant
        delete newValues.funding_or_grant_amount
      }

      // delete newValues.operational_subsidy_for_heat

      // if(!values.description_of_funding_or_grant) {
      //   delete newValues.description_of_funding_or_grant
      // }

      await submitStep({
        pathname: location.pathname,
        lastStep: newSimulation.last_step,
        addFunction: () => addSubsidyAndTaxBenefits(token, newSimulation.id, newValues),
        updateFunction: () => updateSubsidyAndTaxBenefits(token, newSimulation.id, newValues),
        updateNewSimulation,
        setIsStepLoading
      })
    }
  })

  // Show saved simulation step
  useEffect(() => {
    formik.setValues({
      is_operational_subsidy_applicable: newSimulation.steps.subsidy_and_tax_benefits.is_operational_subsidy_applicable,
      num_of_years_for_operational_subsidy: newSimulation.steps.subsidy_and_tax_benefits.num_of_years_for_operational_subsidy,
      operational_subsidy_for_heat: newSimulation.steps.subsidy_and_tax_benefits.operational_subsidy_for_heat,
      operational_subsidy_for_electricity: newSimulation.steps.subsidy_and_tax_benefits.operational_subsidy_for_electricity,
      is_tax_subsidy_applicable: newSimulation.steps.subsidy_and_tax_benefits.is_tax_subsidy_applicable,
      tax_subsidy: normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.tax_subsidy),
      number_of_years: newSimulation.steps.subsidy_and_tax_benefits.number_of_years,
      year_1: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_1)),
      year_2: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_2)),
      year_3: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_3)),
      year_4: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_4)),
      year_5: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_5)),
      year_6: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_6)),
      year_7: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_7)),
      year_8: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_8)),
      year_9: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_9)),
      year_10: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_10)),
      year_11: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_11)),
      year_12: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_12)),
      year_13: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_13)),
      year_14: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_14)),
      year_15: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_15)),
      year_16: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_16)),
      year_17: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_17)),
      year_18: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_18)),
      year_19: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_19)),
      year_20: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_20)),
      year_21: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_21)),
      year_22: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_22)),
      year_23: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_23)),
      year_24: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_24)),
      year_25: Number(normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.year_25)),
      is_funding_or_grant_applicable: newSimulation.steps.subsidy_and_tax_benefits.is_funding_or_grant_applicable,
      description_of_funding_or_grant: newSimulation.steps.subsidy_and_tax_benefits.description_of_funding_or_grant,
      funding_or_grant_amount: newSimulation.steps.subsidy_and_tax_benefits.funding_or_grant_amount,
      co2_tax: newSimulation.steps.subsidy_and_tax_benefits.co2_tax,
      co2_tax_escalation_rate: normalizePercentToShow(newSimulation.steps.subsidy_and_tax_benefits.co2_tax_escalation_rate),
      years_error: ''
    })
  }, [newSimulation])

  const handleIsOperationalSubsidyApplicable = (value) => {
    if(value === false) {
      formik.setValues({
        ...formik.values,
        is_operational_subsidy_applicable: false,
        operational_subsidy_for_heat: '',
        operational_subsidy_for_electricity: '',
        num_of_years_for_operational_subsidy: '',
      })
    } else {
      formik.setFieldValue('is_operational_subsidy_applicable', true)
    }
  }

  const handleIsTaxSubsidyApplicable = (value) => {
    if(value === false) {
      formik.setValues({
        ...formik.values,
        is_tax_subsidy_applicable: value,
        tax_subsidy: '',
        number_of_years: '',
        // year_1: 0,
        // year_2: 0,
        // year_3: 0,
        // year_4: 0,
        // year_5: 0,
        // year_6: 0,
        // year_7: 0,
        // year_8: 0,
        // year_9: 0,
        // year_10: 0,
        // year_11: 0,
        // year_12: 0,
        // year_13: 0,
        // year_14: 0,
        // year_15: 0,
        // year_16: 0,
        // year_17: 0,
        // year_18: 0,
        // year_19: 0,
        // year_20: 0,
        // year_21: 0,
        // year_22: 0,
        // year_23: 0,
        // year_24: 0,
        // year_25: 0,
      })
    } else {
      formik.setFieldValue('is_tax_subsidy_applicable', value)
    }
  }

  const handleIsFundingOrGrantApplicable = (value) => {
    if(value === false) {
      formik.setValues({
        ...formik.values,
        is_funding_or_grant_applicable: value,
        description_of_funding_or_grant: '',
        funding_or_grant_amount: '',
      })
    } else {
      formik.setFieldValue('is_funding_or_grant_applicable', value)
    }
  }

  const percentagesPerYear = useMemo(() => {
    // const regex = new RegExp('^[0-9]+$')
    const years = []
    const projectEconomicLifetime = Number(newSimulation.steps.economic_parameters.project_economic_lifetime)
    // const numberOfYears = Number(formik.values.num_of_years_for_operational_subsidy)
    // const isValid = regex.test(formik.values.num_of_years_for_operational_subsidy)

    for (let i = 0; i < 25; i++) {
      years.push({
        label: `Year ${i + 1}`,
        name: `year_${i + 1}`,
        value: formik.values[`year_${i + 1}`],
        error: formik.errors[`year_${i + 1}`],
        disabled: !formik.values.is_tax_subsidy_applicable || i >= projectEconomicLifetime,
      })
    }

    if(!formik.values.is_tax_subsidy_applicable) {
      const value = (100 / projectEconomicLifetime).toFixed(2)

      for (let i = 0; i < projectEconomicLifetime; i++) {
        formik.setFieldValue(`year_${i + 1}`, Number(value))
      }
    }

    return years
  }, [formik.values, newSimulation.steps.subsidy_and_tax_benefits, newSimulation.steps.economic_parameters.project_economic_lifetime])

  return (
    <SimulationFormikHandler formik={formik}>
      <Helmet>
        <title>Create Simulation: Subsidy & Tan Benefits</title>
      </Helmet>

      <CreateSimulationContainer>
        <header className="d-flex align-items-center flex-wrap gap-2">
          <img
            src={PaperIcon}
            className="CreateSimulation__Title-Icon"
            alt='Subsidy & tax benefits'
            title='Subsidy & tax benefits'
          />

          <CreateSimulationTitle>Subsidy & tax benefits</CreateSimulationTitle>
        </header>

        <Checkboxes
          label='Is operational subsidy applicable?'
          options={checkboxOptions}
          optionSelected={formik.values.is_operational_subsidy_applicable}
          small
          handleSelect={(value) => handleIsOperationalSubsidyApplicable(value)}
        />

        <InputSmall
          topLabel='Number of years of operational subsidy'
          rightLabel='years'
          name='num_of_years_for_operational_subsidy'
          placeholder='0'
          value={formik.values.num_of_years_for_operational_subsidy}
          onChange={formik.handleChange}
          error={formik.errors.num_of_years_for_operational_subsidy}
          disabled={!formik.values.is_operational_subsidy_applicable}
        />

        <InputSmall
          topLabel='Average heat subsidy'
          rightLabel='per kWh'
          name='operational_subsidy_for_heat'
          placeholder='0'
          value={formik.values.operational_subsidy_for_heat}
          onChange={formik.handleChange}
          error={formik.errors.operational_subsidy_for_heat}
          disabled={!formik.values.is_operational_subsidy_applicable}
        />

        <InputSmall
          topLabel='Average electricity subsidy'
          rightLabel='per kWh'
          name='operational_subsidy_for_electricity'
          placeholder='0'
          value={formik.values.operational_subsidy_for_electricity}
          onChange={formik.handleChange}
          error={formik.errors.operational_subsidy_for_electricity}
          disabled={!formik.values.is_operational_subsidy_applicable}
        />

        <Checkboxes
          label='Is tax subsidy (accelerated depreciation) applicable?'
          options={checkboxOptions}
          optionSelected={formik.values.is_tax_subsidy_applicable}
          small
          handleSelect={(value) => handleIsTaxSubsidyApplicable(value)}
        />

        <InputSmall
          topLabel='Tax subsidy'
          rightLabel='%'
          name='tax_subsidy'
          value={formik.values.tax_subsidy}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.tax_subsidy}
          disabled={!formik.values.is_tax_subsidy_applicable}
        />

        <InputSmall
          topLabel='Tax depreciation years'
          rightLabel='years'
          name='number_of_years'
          placeholder='0'
          value={formik.values.number_of_years}
          onChange={formik.handleChange}
          error={formik.errors.number_of_years}
          disabled={!formik.values.is_tax_subsidy_applicable}
        />

        <div className="d-flex flex-column gap-2">
          <div className="w-100 d-flex align-items-center gap-2">
            <h3 className='d-flex align-items-center gap-1 text-4 fw-medium'>
              {formik.errors.years_error && (
                <div className='Input__Error-Icon-Container'>
                  <img className='Input__Error-Icon' src={ErrorIcon} />
                </div>
              )}
                  Years
            </h3>
            {formik.errors.years_error && (
              <span className='Input__Error text-6'>{ formik.errors.years_error }</span>
            )}
          </div>

          <div className="d-flex align-items-end gap-2">
            <div className="SubsidyTaxBenefits__Percentages-Grid">
              {percentagesPerYear.map((item, index) => (
                <Input
                  key={`Input-Percentage-Per-Hour-${index}`}
                  label={item.label}
                  name={item.name}
                  value={item.value}
                  placeholder='0'
                  onChange={formik.handleChange}
                  error={item.error}
                  small
                  type='number'
                  disabled={item.disabled}
                />
              ))}
            </div>

            <div className="SubsidyTaxBenefits__Percentages-Grid-Extra">
              <span className="text-6 text-secondary pb-1">%</span>
              <span className="text-6 text-secondary pb-1">%</span>
              <span className="text-6 text-secondary pb-1">%</span>
              <span className="text-6 text-secondary pb-1">%</span>
              <span className="text-6 text-secondary pb-1">%</span>
            </div>
          </div>
        </div>

        <Checkboxes
          label='Is funding/investment subsidy/grant applicable?'
          options={checkboxOptions}
          optionSelected={formik.values.is_funding_or_grant_applicable}
          small
          handleSelect={(value) => handleIsFundingOrGrantApplicable(value)}
        />

        <Input
          label='Description of the funding/investment subsidy/grant'
          name='description_of_funding_or_grant'
          value={formik.values.description_of_funding_or_grant}
          placeholder='Type a description'
          onChange={formik.handleChange}
          error={formik.errors.description_of_funding_or_grant}
          disabled={!formik.values.is_funding_or_grant_applicable}
        />

        <InputSmall
          topLabel='Funding/investment subsidy/grant'
          rightLabel={newSimulation.steps.general_information.currency_symbol}
          name='funding_or_grant_amount'
          value={formik.values.funding_or_grant_amount}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.funding_or_grant_amount}
          disabled={!formik.values.is_funding_or_grant_applicable}
        />

        <InputSmall
          topLabel='CO2 tax'
          rightLabel='per kgCO2/year'
          name='co2_tax'
          value={formik.values.co2_tax}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.co2_tax}
        />

        <InputSmall
          topLabel='CO2 tax escalation rates'
          rightLabel='%'
          name='co2_tax_escalation_rate'
          value={formik.values.co2_tax_escalation_rate}
          placeholder='0'
          onChange={formik.handleChange}
          error={formik.errors.co2_tax_escalation_rate}
        />
      </CreateSimulationContainer>
    </SimulationFormikHandler>
  )
}

export default SubsidyTaxBenefits