import { SVGProps } from 'react'
const SvgILoading = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99999 15.8333C10.766 15.8333 11.5246 15.6825 12.2323 15.3893C12.94 15.0962 13.5831 14.6665 14.1248 14.1248C14.6665 13.5831 15.0961 12.9401 15.3893 12.2323C15.6824 11.5246 15.8333 10.766 15.8333 10C15.8333 9.23396 15.6824 8.47542 15.3893 7.76768C15.0961 7.05995 14.6665 6.41689 14.1248 5.87522C13.5831 5.33354 12.94 4.90386 12.2323 4.61071C11.5246 4.31756 10.766 4.16667 9.99999 4.16667C8.45289 4.16667 6.96916 4.78125 5.8752 5.87522C4.78124 6.96918 4.16666 8.45291 4.16666 10C4.16666 11.5471 4.78124 13.0308 5.8752 14.1248C6.96916 15.2188 8.45289 15.8333 9.99999 15.8333ZM9.99999 18.3333C14.6025 18.3333 18.3333 14.6025 18.3333 10C18.3333 5.39751 14.6025 1.66667 9.99999 1.66667C5.39749 1.66667 1.66666 5.39751 1.66666 10C1.66666 14.6025 5.39749 18.3333 9.99999 18.3333Z"
      fill="currentColor"
    />
    <path
      d="M1.66666 10C1.66666 5.39751 5.39749 1.66667 9.99999 1.66667V4.16667C8.45289 4.16667 6.96916 4.78125 5.8752 5.87522C4.78124 6.96918 4.16666 8.45291 4.16666 10H1.66666Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgILoading