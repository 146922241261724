import { useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { steps } from '../constants'
import useNewSimulationContext from '../hooks/useNewSimulationContext'

const SimulationStepsMobile = () => {
  const { newSimulation, asideSteps } = useNewSimulationContext()
  const [currentStep, setCurrentStep] = useState(1)
  const [currentStepName, setCurrentStepName] = useState('')
  const [currentGroup, setCurrentGroup] = useState(1)
  const [currentGroupName, setCurrentGroupName] = useState('')
  const { pathname } = useLocation()

  // Set current step
  useEffect(() => {
    const stepPath = pathname.split('/').pop()

    const step = asideSteps.findLast((item) => (item.path === stepPath) )
    if(step) {
      setCurrentStep(step.value)
      setCurrentGroup(step.group)
      setCurrentStepName(step.title)
    }

    const group = asideSteps.find(item => item.group === step.group && item.main)
    if(group)
      setCurrentGroupName(group.title)

  }, [pathname])

  const lastStep = useMemo(
    () => newSimulation.last_step,
    [newSimulation.last_step]
  )

  return (
    <aside className="SimulationStepsMobile">
      <div className="SimulationStepsMobile__Current_Step">
        <p className="text-4 fw-bold">{currentGroupName}</p>
        <p className="text-5 fw-normal">{currentStepName}</p>
      </div>
      <div className="SimulationStepsMobile__Steps">
        {asideSteps.map((item) => {
          const lastStepValue = steps.find(
            (step) => step.path === lastStep
          )?.value

          return (
            <div
              key={item.title}
              className={classNames('SimulationStepsMobile__Steps_Circle', {
                Group: item.main,
                Green:
                  item.value <= currentStep,
                Yellow:
                  item.value <= lastStepValue + 1 && item.value > currentStep,
                Current:
                  (item.main && item.group === currentGroup) ||
                  item.value === currentStep,
              })}
            />
          )
        })}
      </div>
    </aside>
  )
}

export default SimulationStepsMobile
